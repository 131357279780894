import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";

@Component({
  selector: 'app-tick-mark',
  templateUrl: './tick-mark.component.html',
  styleUrls: ['./tick-mark.component.scss']
})
export class TickMarkComponent implements OnInit, ICellRendererAngularComp {

  params: ICellRendererParams;
  constructor() { }

  ngOnInit(): void {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    // console.log("__+++*****this.params",this.params.value)
  }

  refresh(params: any): boolean {
    return false;
  }

  // getValue() {
  //   let value = this.params.valueFormatted ? this.params.valueFormatted : this.params.value;
  //   if(typeof value === 'string') {
  //     let vstr: string = value;
  //     console.log(vstr.indexOf('$'))
  //     return vstr.indexOf('$') > -1 ? 'dolr' : 'str'
  //   } else if(typeof value === 'number') {
  //     return 'num';
  //   }
  // }

  // onClickCell(): void {
  //   if (this.params.context) {
  //     const parent = this.params.context.componentParent;
  //     parent.clickOnCell(this.params.value, this.params.colDef.field, this.params.data);
  //   }
  // }
  
}