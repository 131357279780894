import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';


@Component({
  selector: 'app-ag-grid-multiple-checkbox',
  templateUrl: './ag-grid-multiple-checkbox.component.html',
  styleUrls: ['./ag-grid-multiple-checkbox.component.scss']
})
export class AgGridMultipleCheckboxComponent implements ICellRendererAngularComp {
  public params: any;
  public data: any;
  public isSelectedVar = 'isSelected';
  disableStatus: boolean;
  horizontalPosition: MatSnackBarHorizontalPosition = "right";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  constructor(
    private toast: MatSnackBar
  ) { }

  get isCheckboxDisabled(): boolean {
    if (this.params.context?.getIsSelectionDisable) {
      return this.params.context.getIsSelectionDisable(this.data);
    } else {
      return this.disableStatus;
    }
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.data = params.data;
    const disabled = this.params && this.params.context && this.params.context.isDisabled;

    if (this.params.context && this.params.context.isSelectedVar) {
      this.isSelectedVar = this.params.context.isSelectedVar;
    }
    this.isSelectedVar = this.params.colDef.field;

    if (this.data.transaction_type_value === 'Transfer In') {
      this.disableStatus = true;
    } else if (disabled && disabled === 'No') {
      this.disableStatus = false;
    } else if (!this.data.transaction_type_value && (!this.data.case_id || this.data.case_id === "New")) {
      this.disableStatus = true;
    } else if (this.isSelectedVar === 'enable' || this.isSelectedVar === 'selected') {
      this.disableStatus = false;
    } else if (this.data.enable) {
      this.disableStatus = false;
    } else {
      this.disableStatus = true;
    }
    // console.log({ data: this.data, disableStatus: this.disableStatus, isSelectedVar: this.isSelectedVar });
  }

  refresh(params): boolean {
    let rtFlag = false;
    if (this.params.context && this.params.context.isSelectedVar) {
      this.isSelectedVar = this.params.context.isSelectedVar;
    }
    const data = params.data;
    const field = params.colDef.field;
    if (field === 'enable' || field === 'selected') {
      this.disableStatus = false;
      rtFlag = true;
    } else if (data.enable) {
      this.disableStatus = false;
      rtFlag = true;
    } else {
      this.disableStatus = true;
      rtFlag = true;
    }
    if (data.isSelected !== undefined) {
      this.data = data;
      rtFlag = true;
    }
    return rtFlag;
  }

  onSelectChange(): void {
    if (this.params.context.componentParent) {
      const parent = this.params.context.componentParent;
      let isSelected = this.data[this.isSelectedVar];
      const context = this.params.context.onSelectCb;
      if (parent && parent.name && parent.name === 'VetClinicComponent') {
        const row = this.params.data;
        const tier1Price = row.tier1_price && row.tier1_price > 0 ? true : false;
        const tier2Price = row.tier2_price && row.tier2_price > 0 ? true : false;
        const tier3Price = row.tier3_price && row.tier3_price > 0 ? true : false;
        const isAppointmentOrCustomerSegment = context === 'onAppointmentSelect' || context === 'onCustomerSelect' ? true : false;
        if(isAppointmentOrCustomerSegment) {
          isSelected = true;
        } else if (tier1Price && tier2Price && tier3Price) {
          isSelected = true;
        } else {
          isSelected = false;
          setTimeout(() => {
            this.data[this.isSelectedVar] = isSelected;
            this.showToast(parent.message, 'warning-snackbar');
          }, 100)
        }
      }
      if (this.params.context.onSelectCb) {
        if (this.params.context.index !== undefined) {
          parent[this.params.context.onSelectCb](this.params.context.index, this.data, isSelected, this.isSelectedVar);
        } else {
          if (context === 'onVaccinationRowSelected') {
              parent.onvaccinationRowSelected(this.data, isSelected, this.isSelectedVar);
          } else {
            parent[context](this.data, isSelected, this.isSelectedVar);
          }
          
        }
      } else {
        parent.changeUserSelect(this.data, isSelected, this.isSelectedVar);
      }
    }
  }

  showToast(msg: any, panelClass: any): any {
    this.toast.open(msg, 'OK', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2000,
      panelClass
    });
  }
}
