import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { StorageService } from 'src/app/core/services/storage.service';
import { AnimalService } from 'src/app/core/services/animal.service';
import { MatDialog } from '@angular/material/dialog';
import { AnimalDetailsDialogComponent } from '../animal-details-dialog/animal-details-dialog.component';
import { Subscription } from 'rxjs';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';


// import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-animal-tile',
    templateUrl: './animal-tile.component.html',
    styleUrls: ['./animal-tile.component.scss']
})
export class AnimalTileComponent implements OnInit, OnDestroy {
    @Input('animaDataList') animaDataList: any;
    @Output('sendMsg') sendMsg: EventEmitter<any> = new EventEmitter();
    logedInUserTenantId: number;
    subs: Subscription[] = [];

    constructor(
        private animalService: AnimalService,
        public dialog: MatDialog,
        // public sanitizer: DomSanitizer
    ) {
        // this.sanitizer = sanitizer;
    }

    ngOnInit(): void {
       // console.log(this.animaDataList);
        this.logedInUserTenantId = StorageService.getItem('tenant_id')
    }

    // sanitize(url: string) {
    //   return this.sanitizer.bypassSecurityTrustUrl(url);
    // }

    onSendMSG(e, selectedItem) {
        this.sendMsg.emit(selectedItem);
    }

    clickOnTitle(animal) {
        const sub = this.animalService.getAnimalDetailById(animal.animal_id, animal.tenant_animal_mapping_id).subscribe(res => {
            const dialogRef = this.dialog.open(AnimalDetailsDialogComponent, {
                width: '70em',
                data: { animal: res, animalNameHyperlinkFlag: false },
                autoFocus: false
            });
        });
        this.subs.push(sub);
    }

    onImageClick(data: string | any) {
        this.dialog.open(ImageViewerComponent, {
            data: data,
            height: '70%',
            width: '40%',
            panelClass: 'image-viewer-dialog'
        }).afterClosed().subscribe((res: any) => {
            if (res) {
                console.log(res);
            }
        })
    }

    ngOnDestroy() {
        this.subs.forEach(ele => ele.unsubscribe());
    }
}
