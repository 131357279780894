<div mat-dialog-title>
  <div>
    <span>Location - {{data.rowdata?.location_name}}</span>
  </div>
  <button mat-icon-button>
    <mat-icon (click)="onNoClick()">close</mat-icon>
  </button>

</div>
<div mat-dialog-content style="overflow: hidden">
  <form class="main-form" [formGroup]="basicContactInfoForm">
    <div class="row">
      <mat-form-field class="basic-form-field" [floatLabel]="'always'" color="accent" style="margin-right: 2em;">
        <mat-label>Floor</mat-label>
        <mat-select formControlName="floor">
          <mat-option [value]="''">Select</mat-option>
          <mat-option *ngFor="let floor of floors" [value]="floor.id">
            {{floor.value}}
          </mat-option>
        </mat-select>
        <!-- <mat-error *ngIf="basicContactInfoForm.controls['floor'].hasError('required')">
          Type is required
        </mat-error> -->
      </mat-form-field>

      <mat-form-field class="basic-form-field" [floatLabel]="'always'" color="accent" style="margin-right: 2em;">
        <mat-label>Section</mat-label>
        <mat-select formControlName="section">
          <mat-option [value]="''">Select</mat-option>
          <mat-option *ngFor="let section of sections" [value]="section.id">
            {{section.value}}
          </mat-option>
        </mat-select>
        <!-- <mat-error *ngIf="basicContactInfoForm.controls['section'].hasError('required')">
          Section is required
        </mat-error> -->
      </mat-form-field>

      <mat-form-field class="basic-form-field" [floatLabel]="'always'" color="accent">
        <mat-label>Room</mat-label>
        <mat-select formControlName="room">
          <mat-option [value]="''">Select</mat-option>
          <mat-option *ngFor="let room of rooms" [value]="room.id">
            {{room.value}}
          </mat-option>
        </mat-select>
        <!-- <mat-error *ngIf="basicContactInfoForm.controls['room'].hasError('required')">
          Room is required
        </mat-error> -->
      </mat-form-field>
    </div>
    <div class="row" style="display: flex;flex-direction: column; overflow-y: scroll; max-height: 28em;">
      <div class="col px-0">
        <div *ngFor="let sro of selectedRoomObj" style="padding-bottom: 2em;padding-left: 2em;padding-right: 2em;">
          <div class="room-title">Room: {{sro.room_name}}
            ({{sro?.roomObj?.mainObj.totalCount}}/{{sro?.roomObj?.mainObj.empty}})</div>
          <div style="display: flex;flex-wrap: wrap;">
            <div *ngFor="let cl of sro.case_list" style="
              display: flex;flex-direction: column;align-items: center;cursor: pointer;margin-bottom: 0.5em;">
              <div class="cage-div" (click)="cageClick(sro, cl)"
                [ngClass]="{'empty':!cl.children || cl.children?.length == 0 , 'filled': cl.children?.length>0, selected: cl.is_selected}">
                {{cl.name | slice:0:15}}
              </div>
              <div class="animal-div" *ngFor="let animal of cl?.children" (click)="onClickAnimal(animal)">
                {{animal.species}} - {{animal.animal_name}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div *ngIf="selectedRoomObj?.length==0" class="no-data-found">No data found</div>

<div mat-dialog-actions>
  <button mat-raised-button (click)="onNoClick()">CANCEL</button>
  <button *ngIf="selectedRoomObj?.length>0" mat-raised-button color="accent" (click)="onSaveClick()"
    cdkFocusInitial>SELECT</button>

  <button *ngIf="selectedRoomObj?.length==0" mat-raised-button color="accent" [disabled]=true
    cdkFocusInitial>SELECT</button>
</div>