<div mat-dialog-title>
  <div>
    <span>{{data.title}}</span>
  </div>
  <button mat-icon-button>
    <mat-icon (click)="onNoClick()">close</mat-icon>
  </button>
</div>


<div mat-dialog-content>

  <div class="row px-2" [ngClass]="{'ele-align-center': !data.isNested}">
    <div class="col-6 results">
      <div class="results" style="margin-right: 15px;">
        <div [style.width.px]="OptionFirstParentWidth" class="select-box-container select-box-first-child" [ngClass]="{'border-no-b': data.listData.length>0, 'border-all': data.listData.length==0}">
          <span class="title">{{data.nestedDataTitle}}</span>
        </div>
        <div #getParentOptionWidth class="option_parent_list">
          <div class="select-box-container" *ngFor="let nestedNodeData of data.listData; let i=index;"
          (click)="onNestednodeClicked(nestedNodeData)"
          [ngClass]="{'border-no-b': i<data.listData.length-1, 'border-all': i==data.listData.length-1, 'selected': isSelectedType(nestedNodeData)}">
          <mat-icon *ngIf="isNestedNodeHasSelectedData(nestedNodeData)">done</mat-icon>
          <span>{{nestedNodeData.name}}</span>
        </div>
        </div>
      </div>
      <div class="col-12 other_option" *ngIf="showTextarea">
        <input class="other_text" (focusout)="onTextareaChange(selectedDataNode,$event.target.value)" value="{{textAreaVal}}"/>
        <p *ngIf="otherOptionAlert">Required Field</p>
      </div>
      
    </div>
    
    <div class="col-6">
      <div class="col-12 results" *ngIf="data.showDateTimeField && (selectedDataNode && selectedDataNode.isSelected || showDateTimeField)">
        <mat-form-field class="form-field" [floatLabel]="'always'" color="accent" style="width: 40%;">
          <mat-label>{{data.title}} Date</mat-label>
          <input type="text" matInput autocomplete="off" [value]="(selectedDataNode.serviceDate)" [matDatepicker]="picker1" (dateChange)="onServiceDateChanged(selectedDataNode,$event)">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker [startAt]="(selectedDataNode.serviceDate)" #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="form-field" [floatLabel]="'always'" color="accent" style="width: 40%;">
          <input type="time" matInput autocomplete="off" (change)="onServiceTimeChanged(selectedDataNode,$event)" [value]="selectedDataNode.serviceTime"/>
          <!--<p *ngIf="selectedDataNode.serviceDate!='' && selectedDataNode.serviceTime==''" class="errorMsg">Service Time required</p>-->
        </mat-form-field>
      </div>
      <div class="col-12 results" *ngIf="data.showDateTimeField && data.showCommentsField && selectedDataNode && (selectedDataNode.isSelected || showDateTimeField) && (selectedDataNode.serviceDate && selectedDataNode.serviceDate!='Invalid Date') && (selectedDataNode.serviceTime && selectedDataNode.serviceTime!='')">
        <mat-form-field class="form-field" [floatLabel]="'always'" color="accent" style="width:92%;">
          <mat-label>Enter Comments:</mat-label>
          <textarea matInput placeholder="comments" cols="35" (change)="onServiceCommentsChanged(selectedDataNode,$event)" [value]="selectedDataNode.serviceCommets"></textarea>
      </mat-form-field>
      </div>
      <div class="col-12" *ngIf="(data.showDateTimeField && selectedDataNode && (selectedDataNode.serviceDate && selectedDataNode.serviceDate!='Invalid Date') && (selectedDataNode.serviceTime && selectedDataNode.serviceTime!='') && selectedDataNode && selectedDataNode.dataList.length > 0) || (!data.showDateTimeField && selectedDataNode && selectedDataNode.dataList.length > 0)">
        <mat-label style="color:#008f00">{{data.dataTitle}}</mat-label>
      </div>
      <div class="col-12 results" style="margin-top: 15px;" *ngIf="(data.showDateTimeField && selectedDataNode && (selectedDataNode.serviceDate && selectedDataNode.serviceDate!='Invalid Date') && (selectedDataNode.serviceTime && selectedDataNode.serviceTime!='') && selectedDataNode && selectedDataNode.dataList.length > 0) || (!data.showDateTimeField && selectedDataNode && selectedDataNode.dataList.length > 0)">
         
       <!-- <div [style.width.px]="OptionFirstChildWidth" class="select-box-container select-box-first-child"
              [ngClass]="{'border-no-b': selectedDataNode.dataList.length>0, 'border-all': selectedDataNode.dataList.length==0}">
              <span class="title">{{data.dataTitle}}</span>
            </div>--->
            <div class="option_child_list">
              <div class="select-box-container" *ngFor="let nodeData of selectedDataNode.dataList; let i=index;"
                (click)="onNodeClicked(nodeData,selectedDataNode)"
                [ngClass]="{'border-no-b': i<selectedDataNode.dataList.length-1, 'border-all': i==selectedDataNode.dataList.length-1, 'selected': nodeData.isSelected}">
                <mat-icon *ngIf="nodeData.isSelected">done</mat-icon>
                <span>{{nodeData.name}}</span>
              </div>
            </div>
      </div>
      
    </div>
  </div>
</div>


<div mat-dialog-actions>
  <button mat-raised-button class="white-button" (click)="onNoClick()">CANCEL</button>
  <button mat-raised-button class="green-button" (click)="onSaveClicked()" cdkFocusInitial [disabled]="isDisable()" *ngIf="data.title=='Surgery'">
    SELECT
  </button>  
  <button mat-raised-button class="green-button" (click)="onSaveClicked()" cdkFocusInitial *ngIf="data.title!='Surgery'">
    SELECT
  </button>
</div>
