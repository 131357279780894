import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilService } from 'src/app/core/services/util.service';
import { Subscription, Observable, of } from 'rxjs';
import { GridApi, GridOptions } from 'ag-grid-community';
import { Utils } from 'src/app/core/utils/util';
import { Animal } from 'src/app/core/models/animal';
import { AnimalService } from 'src/app/core/services/animal.service';
import { AgGridCustomTimeFilterComponent } from 'src/app/shared/components/ag-grid-custom-time-filter/ag-grid-custom-time-filter.component';

import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';

export interface DialogData {
  animal: Animal;
  animalNameHyperlinkFlag: boolean;
  parent_info?: boolean;
}

@Component({
  selector: 'app-animal-details-dialog',
  templateUrl: './animal-details-dialog.component.html',
  styleUrls: ['./animal-details-dialog.component.scss']
})
export class AnimalDetailsDialogComponent implements OnInit, OnDestroy {

  subs: Subscription[] = [];
  primaryColor: string;
  secondaryColor: string;

  gridApi: GridApi;
  historyGridApi: GridApi;
  gridColumnApi: any;

  notesRowData: Observable<any>;
  notesColumnDefs: any[] = [];
  public frameworkComponents;

  historyRowData: Observable<any>;
  historyColumnDefs: any[] = [];

  defaultColDef: any;
  public gridOptions = {
    context: {
      componentParent: this,
    },
    suppressHorizontalScroll: false,
    rowStyle: { background: 'white' }
  } as GridOptions;

  public gridOptionsHistory = {
    context: {
      componentParent: this,
    },
    rowStyle: { background: 'white' }
  } as GridOptions;

  getRowHeight;
  headerHeight;
  overlayNoRowsTemplate: string;
  overlayNoRowsTemplateForHistory;

  constructor(
    public dialogRef: MatDialogRef<AnimalDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private utilService: UtilService,
    private animalService: AnimalService,
    private dialog: MatDialog
  ) {
    this.getRowHeight = (params) => {
      return 34;
    }
    this.headerHeight = 34;
    this.frameworkComponents = {
      timeFilter: AgGridCustomTimeFilterComponent
    };
  }

  ngOnInit(): void {
    let animalId, mappingId: number;
    if (this.data.parent_info) {
      animalId = this.data.animal.parent_id;
      mappingId = this.data.animal.parent_mapping_id;
    } else {
      animalId = this.data.animal.animal_id;
      mappingId = this.data.animal.mapping_id;
    }
    let sub = this.animalService.getAnimalDetailById(animalId, mappingId).subscribe(res => {
      this.data.animal = res;
      this.historyRowData = this.animalService.getAnimalHistoryById(animalId).pipe(
        map((res: any[]) => {
          res.forEach(ele => {
            ele['intake_date'] = moment(ele.intake_date_time).isValid() ? moment(ele.intake_date_time).format('MM/DD/YYYY') : '';
            ele['outcome_date'] = moment(ele.outcome_date_time).isValid() ? moment(ele.outcome_date_time).format('MM/DD/YYYY') : '';
          })
          return res;
        })
      );
    })
    this.subs.push(sub);
    this.setHistoryColumn();
    this.setNotesColumns();
  }

  setHistoryColumn() {
    this.defaultColDef = Utils.getBaseThemeAgGridCol();
    this.overlayNoRowsTemplateForHistory = '<span style="padding: 10px; color: #929292">No history to display</span>';

    this.historyColumnDefs = [
      { headerName: 'Agency Name', field: 'agency_name', cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' } },
      { headerName: 'Intake Date', field: 'intake_date', cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'center' }, suppressSizeToFit: true, width: 130 },
      // { headerName: 'Intake Mode', field: 'intake_mode', cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'center' } },
      { headerName: 'Intake Type', field: 'intake_type', cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'center' } },
      // { headerName: 'Intake Source', field: 'intake_source', cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' } },
      { headerName: 'Outcome Date', field: 'outcome_date', cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'center' }, suppressSizeToFit: true, width: 130 },
      // { headerName: 'Outcome Mode', field: 'outcome_mode', cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'center' } },
      { headerName: 'Outcome Type', field: 'outcome_type', cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'center' } },
      // { headerName: 'Outcome Destination', field: 'outcome_destination', cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' } }
    ]
  }

  setNotesColumns() {
    this.defaultColDef = Utils.getBaseThemeAgGridCol();
    this.overlayNoRowsTemplate = '<span style="padding: 10px; color: #929292">No notes to display</span>';

    this.notesColumnDefs = [
      {
        headerName: 'Date',
        field: 'modified_time',
        valueFormatter: this.dateFormatter,
        filter: 'agDateColumnFilter',
        filterParams: Utils.dateFilterParams(),
        suppressSizeToFit: true,
        width: 90,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        }
      },
      {
        headerName: 'Time',
        field: 'modified_time',
        valueFormatter: this.timeFormatter,
        filter: "timeFilter",
        suppressSizeToFit: true,
        width: 90,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        }
      },
      {
        headerName: 'Topic',
        field: 'note_name',
        suppressSizeToFit: true,
        width: 177,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'flex-start'
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Function Name',
        field: 'entity',
        suppressSizeToFit: true,
        width: 177,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'flex-start'
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Notes',
        field: 'notes',
      },
      {
        headerName: 'User',
        field: 'user',
        suppressSizeToFit: true,
        width: 144,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.filterParams()
      }
    ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();

    let payload = {
      "entity": 'Animal',
      "entity_id": this.data.animal.animal_id,
      "noteId": "",
      "noteName": "",
      "notes": "",
      "created_by": "",
      "modified_by": ""
    }
    this.notesRowData = this.utilService.getAllNotes(payload);
  }

  onHistoyGridReady(params) {
    this.historyGridApi = params.api;
    this.historyGridApi.sizeColumnsToFit();

    this.historyRowData = of([]);
  }

  getWeight() {
    return `${Utils.numberToString(this.data.animal.weight)} lbs ${Utils.numberToString(this.data.animal.weight_ounces)} oz`;
  }
  onAanimalHeaderClicked() { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  calculateAge(dob: string) {
    return moment(Utils.convertTZ(new Date)).diff(moment(dob, 'MM/DD/YYYY'), 'month')
  }

  dateFormatter(params) {
    return (params.data.modified_time && params.data.modified_time.trim() != "") ? (moment(params.data.modified_time).format("MM/DD/YYYY")) : '';
  }

  timeFormatter(params) {
    if (params.value && params.value.trim() != "Invalid date") {
      let time = new Date(params.value);
      return time.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    }
    return '';
    //return (params.data.modified_time && params.data.modified_time.trim()!="Invalid date") ? (moment(params.data.modified_time).format("HH:mm A")) : '';
  }

  onPreviewClick(item: any): void {
    const data = {
      name: 'Preview',
      primary_image_url: item,
      can_delete: false
    }
    this.dialog.open(ImageViewerComponent, {
      data: data,
      height: '70%',
      width: '40%',
      panelClass: 'image-viewer-dialog'
    }).afterClosed().subscribe((res: any) => {
      if (res) {
        console.log(res);
        
      }
    })
  }

  ngOnDestroy() {
    this.subs.forEach(ele => ele.unsubscribe());
  }
}
