import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss']
})
export class TileComponent implements OnInit {

  @Input('title') title: string;
  @Input('totalCount') totalCount: number;
  @Input('msgCount') msgCount: number;
  @Input('message') message: string;
  @Input('bgColor') bgColor: string;
  @Input('totalCountColor') totalCountColor: string;
  @Input('msgCountColor') msgCountColor: string;
  @Input('messageColor') messageColor: string;
  @Input('currencySign') currencySign: string;
  @Input('minHeight') minHeight: number;
  @Input('messageFontSize') messageFontSize: number;
  @Input('messageTextForans') messageTextForans: string;
  @Input('boxShadow') boxShadow: string;
  //messageTextForans
  @Output('onClick') onClick: EventEmitter<any> = new EventEmitter();
  // bgColor:string='blue'
  constructor() { }

  ngOnInit(): void {
    // console.log("__++boxShadow+", this.boxShadow)
  }
  onEventClick(e) {
    this.onClick.emit(this.message);
  }
}
