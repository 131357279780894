<div mat-dialog-title>
    <div>
        <span>Select Training For - {{data.animal?.name}}</span>
      </div>
    <button mat-icon-button>
      <mat-icon (click)="onNoClick()">close</mat-icon>
    </button>
  </div>
  
  <div mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <form class="row" [formGroup]="trainingForm" style="justify-content: space-between;">
            <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent">
                <mat-label>Training</mat-label>
                <mat-select formControlName="training_value">
                  <mat-option [value]="''">Select</mat-option>
                  <mat-option *ngFor="let trainingName of data.animalTrainingList" [value]="trainingName.value">
                    {{trainingName.value}}
                  </mat-option>
                </mat-select>
               
              </mat-form-field>
    
              <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent">
                <mat-label>Start Date</mat-label>
                <input type="text" matInput formControlName="start_date" autocomplete="off" [matDatepicker]="picker1">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <!--<input type="text" matInput appDateValidator appDateFormatter formControlName="start_date" autocomplete="off">-->
                <mat-error *ngIf="trainingForm.controls['start_date'].hasError('required')">
                  Start date is required
                </mat-error>
                <mat-error *ngIf="trainingForm.controls['start_date'].hasError('invalid')">
                  Invalid date format
                </mat-error>
          </mat-form-field>
  
          <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent">
                <mat-label>End Date</mat-label>
                <input type="text" matInput formControlName="end_date" autocomplete="off" [matDatepicker]="picker2">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <div class="mat-form-field-subscript-wrapper" style="top:31px" *ngIf="trainingForm.errors?.range && trainingForm.touched">
                  <div style="opacity: 1; transform: translateY(0%);">
                      <div class="mat-error">End date can not be less than From date</div>
                  </div>
                </div>
                <mat-error *ngIf="trainingForm.controls['end_date'].hasError('required')">
                  End date is required
                </mat-error>
                <mat-error *ngIf="trainingForm.controls['end_date'].hasError('invalid')">
                  Invalid date format
                </mat-error>
          </mat-form-field>
  
        </form>
        <div class="row">
          <button mat-raised-button class="green-button" (click)="onCreateClicked()" cdkFocusInitial>
            CREATE
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col pl-0">
    
        <div class="row py-2 pl-3">
          <mat-card class="icon-card" matTooltip="Delete Training" [matTooltipPosition]="'above'"
            matTooltipClass="custom-tooltip" (click)="onRemoveSelectedTraining()">
            <mat-icon>close</mat-icon>
          </mat-card>
        </div>
    
        <ag-grid-angular style="width: 100%; height: 200px;" class="ag-theme-alpine" [rowData]="trainingRowData | async"
          [frameworkComponents]="frameworkComponents" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
          [suppressRowClickSelection]="true" [gridOptions]="gridOptions" [getRowHeight]="getRowHeight" [rowSelection]="'multiple'"
          [headerHeight]="headerHeight" (gridReady)="onTrainingGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button class="white-button" (click)="onNoClick()">CANCEL</button>
  <button mat-raised-button class="green-button" (click)="onSaveClicked()" cdkFocusInitial [disabled]="isdisableCheck()">SAVE</button>
</div>