<div class="container-fluid main-container">
  <div class="row" style=" width: 100%; padding-left: 15px;">
    <div>
      <!-- <span class="">saint</span> -->
      <img src="assets/images/logo-saint-white.png" alt="" style="height: 44px;">
    </div>
  </div>
  <mat-card class="login-card mat-elevation-z8">
    <div class="header-text">login</div>
    <mat-card-content>
      <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSignInClicked()">
        <!-- <mat-form-field class="example-full-width">
          <mat-icon matPrefix>supervised_user_circle</mat-icon>
          <input type="text" matInput placeholder="Enter Your Tenant ID" formControlName="tenant_code">
          <mat-error *ngIf="loginForm.controls['tenant_code'].hasError('required')">
            Tenant ID is required
          </mat-error>
        </mat-form-field> -->

        <mat-form-field class="example-full-width">
          <mat-icon matPrefix>email</mat-icon>
          <mat-label style="color: #d5d5d5;">Enter Your Email ID</mat-label>
          <input type="email" matInput formControlName="email">
          <mat-error *ngIf="loginForm.controls['email'].hasError('email') && !loginForm.controls['email'].hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="loginForm.controls['email'].hasError('required')">
            Email is required
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-icon matPrefix>lock</mat-icon>
          <mat-label style="color: #d5d5d5;">Password</mat-label>
          <input type="password" matInput formControlName="password">
          <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
            Password is required
          </mat-error>
        </mat-form-field>
        <input type="submit" style="visibility: hidden;"/>
      </form>
      <div>
        <section class="example-section">
          <mat-checkbox class="remember" [(ngModel)]="isRemember">
            Remember me
          </mat-checkbox>
        </section>
        <span (click)="forgotPassword()">Forgot Password?</span>
      </div>
      <!-- </div> -->
    </mat-card-content>
    <mat-card-actions>
      <button mat-flat-button color="primary" (click)="onSignInClicked()" [disabled]="!loginForm.valid">SIGN IN</button>
    </mat-card-actions>
  </mat-card>
  <div class="row">
    <!-- <span>footer</span> -->
  </div>
</div>