import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import * as _moment from 'moment';
const moment = _moment;
import { Utils } from 'src/app/core/utils/util';

@Component({
  selector: 'app-animal-care-checkbox',
  templateUrl: './animal-care-checkbox.component.html',
  styleUrls: ['./animal-care-checkbox.component.scss']
})
export class AnimalCareCheckboxComponent implements ICellRendererAngularComp {

  public params: any;
  public data: any;

  setCheckboxVal:number=0;
  agInit(params: any): void {
    this.params = params;
    this.data = params.data;
    
    if(this.setCheckboxVal==0 && (!params.data.transferrable || (params.data.animal && !params.data.animal.transferrable))){
      if(params.data.status==1)
        params.node.setSelected(true);
      else 
        params.node.setSelected(false);
    }
  /*  if(params.data.status==1)
      params.node.setSelected(true);
    else 
      params.node.setSelected(false);*/
     // console.log(params.node.selected); 
  }

  refresh(params): boolean  {   
    return params.node.selected;
  }

  onSelectChange(event): void {
   
      let checked = event.checked;
      if (this.params.context.componentParent) {
        const parent = this.params.context.componentParent;
        if (this.params.context.onSelectCb && this.params.node.data.schedule_date) {
          this.setCheckboxVal=1;
          
            if(checked)
                this.params.node.setSelected(true);
            else
                this.params.node.setSelected(false);
          
          parent.onRowSelected(this.params,1);
        }else{
          if(this.params.node.data.status!="OUT" || (this.params.node.data.animal && this.params.node.data.animal.status!="OUT")){
            if(checked){
              (this.params.node.data.animal) ? this.params.node.data.animal.transferrable=(true) : this.params.node.data.transferrable=(true);
            }else
              (this.params.node.data.animal) ? this.params.node.data.animal.transferrable=(false) : this.params.node.data.transferrable=(false);

              parent.onAnimalSpeciesCellValueChanged(this.params);
          }
        }
      }    
  }

  isDisable(){
    if((this.params.node.data.status=="OUT" && this.params.node.data.transferrable!=undefined) || (this.params.node.data.animal && this.params.node.data.animal.status=="OUT" && this.params.node.data.animal.transferrable!=undefined))
      return true;
    else if(this.params.node.data.transferrable!=undefined || (this.params.node.data.animal && this.params.node.data.animal.transferrable!=undefined))
      return false;
    else if(this.params.node.data.schedule_date <= moment(Utils.convertTZ(new Date)).format('MM/DD/YYYY'))
      return false;
    else
      return true;
  }

  isChecked(){
    if((this.params.node.data.status==1) || (this.params.node.data.transferrable==1) || (this.params.node.data.animal && (this.params.node.data.animal.status==1 || this.params.node.data.animal.transferrable==1)))
      return true;
    else
      return false;
   
  }
}