import { Component, OnInit,Inject } from '@angular/core';
import { GridApi, GridOptions } from '@ag-grid-community/all-modules';
import { Utils } from 'src/app/core/utils/util';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnimalService } from 'src/app/core/services/animal.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { AnimalCareCheckboxComponent } from "src/app/shared/dialogs/animal-care-checkbox/animal-care-checkbox.component";
import { ConsentDialogComponent} from 'src/app/shared/dialogs/consent-dialog/consent-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import * as _moment from 'moment';
const moment = _moment;

export interface DialogData {
  care_taker_info:any;
}

@Component({
  selector: 'app-animal-care-items-dialog',
  templateUrl: './animal-care-items-dialog.component.html',
  styleUrls: ['./animal-care-items-dialog.component.scss']
})

export class AnimalCareItemsDialogComponent implements OnInit {
  
  public columnDefs: any[] = [];
  public frameworkComponents;
  public gridOptions = {
    context: {
        componentParent: this,
        onSelectCb: 'onRowSelected',
    },
    suppressHorizontalScroll: false,
    rowStyle: { background: 'white' }
  } as GridOptions;

  gridApi: GridApi;
  gridColumnApi: any;

  defaultColDef: any;
  rowSelection: any;

  getRowHeight;
  headerHeight;

  rowData: Observable<any>;
  rowTemp: any[] = [];
  setAllColStyle:number;
  cellArray=new Array();
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  todatDate=moment(Utils.convertTZ(new Date)).format('MM/DD/YYYY');

  constructor(private _snackBar: MatSnackBar,public dialogRef: MatDialogRef<AnimalCareItemsDialogComponent>,
    private animalService:AnimalService,public dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) { 
        
    this.getRowHeight = (params) => {
      return 34;
    }
    this.headerHeight = 34;
    this.frameworkComponents = {
      checkboxRenderer: AnimalCareCheckboxComponent
    };
  }

  ngOnInit(): void {
    this.setupGridColumn();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

 /* cellStyling(params){
    console.log(params.colDef.field + ' '+params.data[`${params.colDef.field}_result`]+' '+params.value);
    if((params.value && (params.data[`${params.colDef.field}_result`]==1 || (params.colDef.field=="food_quantity" && params.data[`food_result`]==1)))){
       console.log('called');
        return { ...Utils.getBaseThemeAgGridCol().cellStyle,justifyContent: 'flex-start','background-color':'#0076ba','color':'#fff' };
    } else {
        return { ...Utils.getBaseThemeAgGridCol().cellStyle,justifyContent: 'flex-start','background-color': '#fff',color: '#929292' };
    }
 }*/

  setupGridColumn() {
    this.defaultColDef = Utils.getBaseThemeAgGridCol();
    this.columnDefs = [
                        {
                          headerName: '',
                          field: 'status',
                          sortable:false,
                          filter:false,
                          minWidth: 10,
                          width: 31,
                          cellRenderer: "checkboxRenderer",
                          cellStyle: {
                            ...this.defaultColDef.cellStyle,
                            display: 'flex',
                            justifyContent: 'center',
                          //  padding: '0 0 0 10px'
                          },
                        },
                        {
                          headerName: '',
                          field: 'animal_info',
                          cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
                        //  suppressSizeToFit: true,
                          width: 150,
                          sortable:false,
                          filter:false,
                      },
                      {
                        headerName: '',
                        field: 'room_name',
                        cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
                        suppressSizeToFit: true,
                        width: 110,
                        sortable:false,
                        filter:false,
                      },
                      {
                        headerName: 'Food',
                        field: 'food',
                        cellRenderer: function(params) {
                          return (params.value && params.data.food_result==1) ? `<mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">done</mat-icon>${params.value}` : params.value;
                        },
                        width: 100,
                        cellClassRules: {
                          'selected_cell': params => {
                            return (params.value && params.data.food_result)==1;
                          },
                          'deselected_cell': params => {
                            return (params.value && params.data.food_result)==0;
                          }
                        },
                        filterParams: Utils.filterParams(),
                      },
                     
                      {
                        headerName: 'Activity',
                        field: 'activity',
                        cellRenderer: function(params) {
                          return (params.value && params.data.activity_result==1) ? `<mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">done</mat-icon>${params.value}` : params.value;
                        },
                        width: 110,
                        cellClassRules: {
                          'selected_cell': params => {
                            return (params.value && params.data.activity_result)==1;
                          },
                          'deselected_cell': params => {
                            return (params.value && params.data.activity_result)==0;
                          }
                        },
                        filterParams: Utils.filterParams()
                      },
                      {
                          headerName: 'Training',
                          field: 'training',
                          cellRenderer: function(params) {
                            return (params.value && params.data.training_result==1) ? `<mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">done</mat-icon>${params.value}` : params.value;
                          },
                          width: 110,
                          cellClassRules: {
                            'selected_cell': params => {
                              return (params.value && params.data.training_result)==1;
                            },
                            'deselected_cell': params => {
                              return (params.value && params.data.training_result)==0;
                            }
                          },
                          filterParams: Utils.filterParams()
                      },
                      {
                        headerName: 'Medication',
                        field: 'medication',
                        cellRenderer: function(params) {
                          return (params.value && params.data.medication_result==1) ? `<mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">done</mat-icon>${params.value}` : params.value;
                         },
                        width: 110,
                        cellClassRules: {
                          'selected_cell': params => {
                            return (params.value && params.data.medication_result)==1;
                          },
                          'deselected_cell': params => {
                            return (params.value && params.data.medication_result)==0;
                          }
                        },
                        filterParams: Utils.filterParams()
                      },
                      {
                        headerName: 'Vaccination',
                        field: 'vaccinationstr',
                        width: 120,
                        cellRenderer: function(params) {
                          return (params.value && params.data.vaccinationstr_result==1) ? `<mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">done</mat-icon>${params.value}` : params.value;
                         },
                        cellClassRules: {
                          'selected_cell': params => {
                            return (params.value && params.data.vaccinationstr_result)==1;
                          },
                          'deselected_cell': params => {
                            return (params.value && params.data.vaccinationstr_result)==0;
                          }
                        },
                        filterParams: Utils.filterParams()
                      },
                      {
                        headerName: 'Test',
                        field: 'tests',
                        cellRenderer: function(params) {
                          return (params.value && params.data.tests_result==1) ? `<mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">done</mat-icon>${params.value}` : params.value;
                        },
                        width: 110,
                        cellClassRules: {
                          'selected_cell': params => {
                            return (params.value && params.data.tests_result)==1;
                          },
                          'deselected_cell': params => {
                            return (params.value && params.data.tests_result)==0;
                          }
                        },
                        filterParams: Utils.filterParams()
                      },
                      {
                        headerName: 'Surgery',
                        field: 'surgeries',
                        cellRenderer: function(params) {
                          return (params.value && params.data.surgeries_result==1) ? `<mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">done</mat-icon>${params.value}` : params.value;
                        },
                     
                        width: 110,
                        cellClassRules: {
                          'selected_cell': params => {
                            return (params.value && params.data.surgeries_result)==1;
                          },
                          'deselected_cell': params => {
                            return (params.value && params.data.surgeries_result)==0;
                          }
                        },
                        
                        filterParams: Utils.filterParams()
                      }
                      ];
  }

  async onCellClicked(event,cellArray){
   
    if(event.value && (event.colDef.headerName!='') && (this.data.care_taker_info.schedule_date <= moment(Utils.convertTZ(new Date)).format('MM/DD/YYYY'))){
      
      let rowIndex=event.rowIndex;
      let colId=event.column.getColId();
      let animalId=event.data.animal_id;
      let checkCellSelection=event.data[`${colId}_result`];
      if(checkCellSelection==0 || checkCellSelection==undefined){
        
          event.data[`${colId}_result`]=1;
       

          if((event.data['food']== "" || event.data['food_result']==1) && (event.data['training']== "" || event.data['training_result']==1) 
          && (event.data['activity']== "" || event.data['activity_result']==1) && (event.data['tests']== "" || event.data['tests_result']==1) 
          && (event.data['medication']== "" || event.data['medication_result']==1) && (event.data['surgeries']== "" || event.data['surgeries_result']==1) 
          && (event.data['vaccinationstr']== "" || event.data['vaccinationstr_result']==1)){
             this.setAllColStyle=0;
             event.node.setSelected(true);
             event.data['status']=1
          }else
             event.data['status']=0;


          if(cellArray && !cellArray[rowIndex])
             cellArray[rowIndex]=rowIndex;   

      }else if(checkCellSelection==1){

          event.data[`${colId}_result`]=0;
          event.node.setSelected(false);
          event.data['status']=0;

          if((event.data['food_result']==0) && (event.data['training_result']==0) && (event.data['activity_result']==0) && (event.data['tests_result']==0) 
          && (event.data['medication_result']==0) && (event.data['surgeries_result']==0) && (event.data['vaccinationstr_result']==0)){
            this.setAllColStyle=0;
           /* if(cellArray.length > 0 && cellArray[rowIndex])
                cellArray.splice(rowIndex, 1);
            else
                  cellArray=new Array();*/
          }else
              this.setAllColStyle=1;
      }
      
      await event.api.refreshCells({
          force: true,
          columns: [event.column],
          rowNodes: [event.api.getRowNode(rowIndex)]
       });
    }
  }

  /*cellClass(params) {
    let classVal='';
    if((params.value && (params.data[`${params.colDef.field}_result`]==1 || (params.colDef.field=="food_quantity" && params.data[`food_result`]==1))))
       classVal='selected_cell';
    else 
      classVal='deselected_cell';
    return {classVal};
  }*/

  setAllResultOfRow(event,value:number){
        let result=value;
        event.data['food_result']=result;
        event.data['tests_result']=result;
        event.data['activity_result']=result;
  
        event.data['vaccinationstr_result']=result;
        event.data['training_result']=result;
        event.data['medication_result']=result;
        event.data['surgeries_result']=result;
        event.data['status']=result;

        return event.data;
  }

  public onRowSelected(event,clickBycomponenet?) {
      if(this.data.care_taker_info.schedule_date <= moment(Utils.convertTZ(new Date)).format('MM/DD/YYYY')){
          if(this.setAllColStyle==0 || (clickBycomponenet && clickBycomponenet==1)){
              let rowIndex:number;
              if(event.node.selected){
                  rowIndex=event.rowIndex;
                  this.setAllResultOfRow(event,1);
              
                  if(this.cellArray && !this.cellArray[rowIndex])
                      this.cellArray[rowIndex]=rowIndex;   
        
                      event.api.refreshCells({
                        force: true,
                        rowNodes: [event.api.getRowNode(rowIndex)]
                    });
              }else{
                    rowIndex=event.rowIndex;
                    this.setAllResultOfRow(event,0);
                    
                    if(this.cellArray[rowIndex]!=undefined || this.cellArray[rowIndex]==undefined)
                      this.cellArray[rowIndex]=rowIndex;
                    else
                      this.cellArray=new Array();
                    
                    event.api.refreshCells({
                      force: true,
                      rowNodes: [event.api.getRowNode(rowIndex)]
                    });
              }
          }
      }else{
        return false;
      }
  }

  onSelectionChanged(event) {
    return false;
    //console.log(event);
    var rowCount = event.api.getSelectedNodes().length;
  }

  isDisableTopTile() {
    return false;
  }

  onSubmit(){  
    const dialogRef = this.dialog.open(ConsentDialogComponent, {
      width: '40em',
      data: {
          title: `Are you sure you want to UPDATE the TASK?`,
          subtitles: [
              `By clicking Yes, the entire TASK will be Saved`,
              `By clicking No, you can continue to update the TASK`
          ],
          btnYesString: 'Yes',
          btnNoString: 'No'
      },
      autoFocus: true
  });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Yes') {
        if(this.cellArray.length > 0){
          let payload=[];
          this.cellArray.forEach(ele=>{
            let res=this.gridApi.getRowNode(ele).data;
            let payloadData;
            if((res['food_result']==1) || (res['training_result']==1) || (res['activity_result']==1) || (res['tests_result']==1) 
              && (res['medication_result']==1) || (res['surgeries_result']==1) || (res['vaccinationstr_result']==1)){
                payloadData={
                                    "animal_id":res.animal_id,
                                    "mapping_id":res.mapping_id,
                                    "schedule_date":this.data.care_taker_info.schedule_date,
                                    "status":res.status
                                };
                      
            }else{
                  payloadData={
                                    "animal_id":res.animal_id,
                                    "mapping_id":res.mapping_id,
                                    "schedule_date":this.data.care_taker_info.schedule_date,
                                    "status":res.status
                                  };
              }
                          
                    (res['medication_result']==1) ? payloadData["medication"]=res.medication : payloadData["medication"]='';
                    (res['vaccinationstr_result']==1) ? payloadData["vaccination"]=res.vaccinationstr : payloadData["vaccination"]='';
                    (res['tests_result']==1) ? payloadData["test"]=res.tests : payloadData["test"]='';
                    (res['surgeries_result']==1) ? payloadData["surgery"]=res.surgeries : payloadData["surgery"]='';
                    (res['food_result']==1) ? (payloadData["food"]=res.food) : (payloadData["food"]='');
                    (res['activity_result']==1) ? payloadData["activity"]=res.activity : payloadData["activity"]='';
                    (res['training_result']==1) ? payloadData["training"]=res.training : payloadData["training"]='';
    
                    if(res.care_schedule_id)
                        payloadData['id']=res.care_schedule_id;
                        payload.push(payloadData);
            
          })
    
          this.animalService.createModifyAnimalCareSchedule(payload).toPromise().then(result=>{
            this._snackBar.open('Care Schedule updated', 'Ok', {
              duration: 2000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['light-snackbar']
            });
          })
          this.dialogRef.close();
        }
      }
    })
     
  }
  
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.refreshMainGrid();
    
  }

  medicalEventFilterData(ele,countOfRows:number){
    // if (ele.med_service_opted.length > 0) {
      let countCompletedTask=0;
      let countTotalTask=0;
     let allTests: any[] = ele.med_service_opted.filter(elem => elem.service_type == 'TEST');
     if (allTests.length == 1) {
       ele['tests'] = allTests[0].service_name;
     } else if (allTests.length > 1) {
       ele['tests'] = allTests.reduce((prev, cur) => {
         if (typeof prev == 'string') return prev + ', ' + `${cur.service_name}`;
         else return `${prev.service_name}, ${cur.service_name}`;
       })
     } else {
       ele['tests'] = '';
     }
   
      if(ele['tests'] && ele['tests_result']==1)
        countCompletedTask++;

      if(allTests.length >0)
        countTotalTask++;  

      const allSurgeris: any[] = ele.med_service_opted.filter(elm => elm.service_type === 'SURGERY');
       
      if (allSurgeris.length === 1) {
        if (allSurgeris[0].outcome && allSurgeris[0].service_name.toLowerCase() == "other") {
          ele.surgeries = allSurgeris[0].outcome ? `${allSurgeris[0].service_name} - ${allSurgeris[0].outcome}` : "";
        } else {
          ele.surgeries = `${allSurgeris[0].service_name}`;
        }
    } else if (allSurgeris.length > 1) {
        ele.surgeries = allSurgeris.reduce((prev, cur) => {
            let outcomeValue = "";
            if (cur.outcome && cur.service_name.toLowerCase() == "other") {
            outcomeValue = cur.outcome ? ` - ${cur.outcome}` : "";
            }
            if (typeof prev === "string") {
            return prev + ", " + `${cur.service_name} ${outcomeValue}`;
            } else {
            if (prev.outcome && prev.service_name.toLowerCase() == "other") {
                outcomeValue = prev.outcome ? ` - ${prev.outcome}` : "";
            }
            return `${prev.service_name} ${outcomeValue}, ${cur.service_name}`;
            }
        });
    } else {
        ele.surgeries = "";
    }

     if(allSurgeris.length >0)
        countTotalTask++;  

     if(ele['surgeries'] && ele['surgeries_result']==1)
        countCompletedTask++;
 
     if (ele.vaccination.length == 1) {
       ele['vaccinationstr'] = ele.vaccination[0].vaccine;
     } else if (ele.vaccination.length > 1) {
       ele['vaccinationstr'] = [...new Set(ele.vaccination.reduce((prev, cur) => {
         if (typeof prev == 'string') return prev + ', ' + `${cur.vaccine}`;
         else return `${prev.vaccine}, ${cur.vaccine}`;
       }).split(', '))].join(', ');
     } else {
       ele['vaccinationstr'] = '';
     }

     if(ele.vaccination.length >0)
        countTotalTask++; 
      
     if(ele['vaccinationstr'] && ele['vaccinationstr_result']==1)
        countCompletedTask++;

     if (ele.medications_opted.length == 1) {
       ele['medication'] = ele.medications_opted[0].medicine;
     } else if (ele.medications_opted.length > 1) {
       ele['medication'] = [...new Set(ele.medications_opted.reduce((prev, cur) => {
         if (typeof prev == 'string') return prev + ', ' + `${cur.medicine}`;
         else return `${prev.medicine}, ${cur.medicine}`;
       }).split(', '))].join(', ');
     } else {
       ele['medication'] = '';
     }

     if(ele.medications_opted.length >0)
        countTotalTask++; 

     if(ele['medication'] && ele['medication_result']==1)
        countCompletedTask++;

    if (ele.animal_activity.length == 1) {
      ele['activity'] = ele.animal_activity[0].activity_value;
    } else if (ele.animal_activity.length > 1) {
      ele['activity'] = [...new Set(ele.animal_activity.reduce((prev, cur) => {
        if (typeof prev == 'string') return prev + ', ' + `${cur.activity_value}`;
        else return `${prev.activity_value}, ${cur.activity_value}`;
      }).split(', '))].join(', ');
    } else {
      ele['activity'] = '';
    }

    if(ele.animal_activity.length >0)
        countTotalTask++; 

    if(ele['activity'] && ele['activity_result']==1)
        countCompletedTask++;

    if (ele.animal_training.length == 1) {
      ele['training'] = ele.animal_training[0].training_value;
    } else if (ele.animal_training.length > 1) {
      ele['training'] = [...new Set(ele.animal_training.reduce((prev, cur) => {
        if (typeof prev == 'string') return prev + ', ' + `${cur.training_value}`;
        else return `${prev.training_value}, ${cur.training_value}`;
      }).split(', '))].join(', ');
    } else {
      ele['training'] = '';
    }

    if(ele.animal_training.length >0)
        countTotalTask++; 

    if(ele['training'] && ele['training_result']==1)
        countCompletedTask++;

       // console.log(ele.animal_food);
    if (ele.animal_food.length == 1) {
      ele['food'] = `${ele.animal_food[0].food} (${ele.animal_food[0].quantity})`;
     // ele['food_quantity']=`${ele.animal_food[0].quantity} `;
    }else {
      ele['food'] = '';
     // ele['food_quantity']="";
    }
    /* else if (ele.animal_food.length > 1) {
      ele['food'] = [...new Set(ele.animal_food.reduce((prev, cur) => {
        if (typeof prev == 'string') return prev + ', ' + `${cur.food}`;
        else return `${prev.food}, ${cur.food}`;
      }).split(', '))].join(', ');

      
    } */
 
    if(ele.animal_food.length >0)
        countTotalTask+=2; 
    ele['room_name']=((ele.room_name) ? ele.room_name : '')+((ele.cage_name) ? ' - '+ele.cage_name : '')
   
    if(ele['food'] && ele['food_result']==1)
        countCompletedTask+=2;
    
    /*if(countCompletedTask==countTotalTask && countCompletedTask > 0 && countTotalTask > 0)
            ele['status']=1;
    else
        ele['status']=0;   */  
 
      if(countCompletedTask > 0){
        this.cellArray[countOfRows]=countOfRows;
        
      }
          

      ele['animal_info']=`${ele.animal_name} - ${ele.animal_id} (${ele.species})`;
     return ele;
 }

  refreshMainGrid(){
    let rowObj=this.data.care_taker_info;
    if(rowObj && rowObj.mapping_id && (rowObj.animal_id.split("/").length==rowObj.mapping_id.split("/").length)){
      //console.log(rowObj);
      let mappingIds=rowObj.mapping_id.split("/");
   //   mappingIds=[ ...new Set(mappingIds) ];
      let apiReqestParams:any[]=[];
      let filterDuplicateAnimals=rowObj.animal_id.split("/");
    //  console.log(filterDuplicateAnimals);
     // filterDuplicateAnimals=[ ...new Set(filterDuplicateAnimals) ];
     // console.log(filterDuplicateAnimals);
      filterDuplicateAnimals.forEach((animalId,index) => {
        apiReqestParams.push({"animal_id":parseInt(animalId),"mapping_id":parseInt(mappingIds[index]),"schedule_date":rowObj.schedule_date});
      });
     
      this.rowData=this.animalService.getAnimalDetailsUsingAnimalIDs(apiReqestParams).pipe(
        map((res: any[]) => {
          let countOfRows=0;                            
          res.forEach(ele => {
            ele.schedule_date=this.data.care_taker_info.schedule_date;
            this.medicalEventFilterData(ele,countOfRows);
            countOfRows++;
          });
          return res;
        })
      );

      //console.log(this.rowData.subscribe(res=>{return res;}));
    }

  }

  onBtnDownloadClicked() {
    let params = this.getDownloadParams();
    this.gridApi.exportDataAsCsv(params);
  }

  getValue(inputSelector) {
    var text = inputSelector;
    switch (text) {
      case 'none':
        return;
      default:
        return text;
    }
  }

  getDownloadParams() {
    return {
      columnKeys: ['animal_info','room_name','food','activity','training','medication','vaccinationstr','tests','surgeries'],
      allColumns: false,
      fileName: `animal_care_items_list_${this.getCurDateStr()}`,
      suppressQuotes: this.getValue('none'),
      columnSeparator: this.getValue('none'),
      customHeader: this.getValue(''),
      customFooter: this.getValue(''),
      /*suppressQuotes: true,
      columnSeparator: null,
      customHeader: null,
      customFooter: null,*/
    };
  }

  getCurDateStr() {
    let d = Utils.convertTZ(new Date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('');
  }

}
