import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { ApiService } from "./api.service";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ProcureService } from "src/app/procure/procure.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  apiUrlChat = "";
  isLoggedIn = new BehaviorSubject(false);

  constructor(private apiService: ApiService, private procureService: ProcureService, private http: HttpClient) {
    this.apiUrlChat = this.apiService.chatApi;
  }

  login(payload: any): Observable<any> {
    return this.apiService.post("auth/login", payload).pipe(
      tap((res) => {
        StorageService.setItem("tenant_id", res.tenant_id);
        StorageService.setItem("token", res.token);
        StorageService.setItem("token_type", res.token_type);
        StorageService.setItem(
          "full_name",
          `${res.first_name} ${res.last_name}`
        );
        StorageService.setItem("role_name", res.role_name);
      })
    );
  }

  adminUserList(auth: any, params?: any): Observable<any> {
    const endPoint = `${this.apiUrlChat}/users.list`;
    return this.http
      .get(endPoint, {
        observe: "response",
        params,
        headers: {
          "X-Auth-Token": auth && auth.authToken,
          "X-User-Id": auth && auth.userId,
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
      .pipe(
        map((data) => {
          return data.body;
        })
      );
  }

  getUserInfo(username: any, auth: any, params?: any): Observable<any> {
    const endpoint = `${this.apiUrlChat}/users.info?userId=${username}`;
    return this.http
      .get(endpoint, {
        observe: "response",
        params,
        headers: {
          "X-Auth-Token": auth && auth.authToken,
          "X-User-Id": auth && auth.userId,
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
      .pipe(
        map((data) => {
          return data.body;
        })
      );
  }

  updateUserInfo(payload: any, auth: any, params?: any): Observable<any> {
    const endpoint = `${this.apiUrlChat}/users.update`;
    return this.http
      .post(endpoint, payload, {
        observe: "response",
        params,
        headers: {
          "X-Auth-Token": auth && auth.authToken,
          "X-User-Id": auth && auth.userId,
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
      .pipe(
        map((data) => {
          return data.body;
        })
      );
  }

  loginToChat(payload: any): Observable<any> {
    const endPoint = `${this.apiUrlChat}/login`;
    return this.http.post(endPoint, payload);
  }

  getUserDetails(email: any, auth: any, params?: any): Observable<any> {
    const endpoint = `
        https://chat.getsaint.com/api/v1/users.list?fields={%22name%22:1,%22username%22:1,%22emails%22:1,%22roles%22:1,%22status%22:1,%22avatarETag%22:1,%22active%22:1}&query={%22$or%22:[{%22emails.address%22:{%22$regex%22:%22${email}%22,%22$options%22:%22i%22}},{%22username%22:{%22$regex%22:%22${email}%22,%22$options%22:%22i%22}},{%22name%22:{%22$regex%22:%22${email}%22,%22$options%22:%22i%22}}]}&sort={%22name%22:1,%22usernames%22:1}&count=25
        `;

    return this.http
      .get(endpoint, {
        observe: "response",
        params,
        headers: {
          'Access-Control-Allow-Origin': '*',
          "X-Auth-Token": auth && auth.authToken,
          "X-User-Id": auth && auth.userId,
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
      .pipe(
        map((data) => {
          return data.body;
        })
      );
  }

  registerUserForChat(payload: any, auth: any, params?: any): Observable<any> {
    const endPoint = `${this.apiUrlChat}/users.create`;
    return this.http
      .post(endPoint, payload, {
        observe: "response",
        params,
        headers: {
          "X-Auth-Token": auth && auth.authToken,
          "X-User-Id": auth && auth.userId,
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
      .pipe(
        map((data) => {
          return data.body;
        })
      );
  }

  forgotPassword(payload: any): Observable<any> {
    return this.apiService.post("auth/reset-password", payload);
  }

  logoutFromChat(): Observable<any> {
    const endPoint = `${this.apiUrlChat}/logout`;
    return this.post(endPoint);
  }

  get(baseUrl: any, params?: any) {
    const me = JSON.parse(localStorage.getItem("me"));
    const id = me && me._id;
    return this.http
      .get(baseUrl, {
        observe: "response",
        params,
        headers: {
          "X-Auth-Token": `${localStorage.getItem("Meteor.loginToken")}`,
          "X-User-Id": `${id}`,
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
      .pipe(
        map((data) => {
          return data.body;
        })
      );
  }

  post(baseUrl: any, payload?: any, params?: any) {
    const me = JSON.parse(localStorage.getItem("me"));
    const id = me && me._id;
    return this.http
      .post(baseUrl, payload, {
        observe: "response",
        params,
        headers: {
          "X-Auth-Token": `${localStorage.getItem("Meteor.loginToken")}`,
          "X-User-Id": `${id}`,
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
      .pipe(
        map((data) => {
          return data.body;
        })
      );
  }

  logoutAfterRegistration(
    auth: any,
    payload?: any,
    params?: any
  ): Observable<any> {
    const endPoint = `${this.apiUrlChat}/logout`;
    return this.http
      .post(endPoint, payload, {
        observe: "response",
        params,
        headers: {
          "X-Auth-Token": auth && auth.authToken,
          "X-User-Id": auth && auth.userId,
          "Content-Type": "application/json",
        },
        responseType: "json",
      })
      .pipe(
        map((data) => {
          return data.body;
        })
      );
  }

  isUserLoggedIn(flag: boolean) {
    this.isLoggedIn.next(flag);
  }

  microchipExpiryValidation(): Observable<any> {
    return this.apiService.get(`api/procure/microchip-expiry-validation`);
  }


  // Megento User Registration API
  registerUserWithMagento(payload: any): Observable<any> {
    // const endpoint = `${this.procureService.getHostname()}/customer_registration.php`;
    const endpoint = `${this.procureService.getHostname()}/customer_registration.php?firstname=${payload.firstname}&lastname=${payload.lastname}&email=${payload.email}&password=${payload.password}`;
    const token = "jtsqqtgnwwr1gp8506t2xnx1t1fmo9oh";
    const header = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.post(endpoint, payload, header);
  }

  getUserRoles(emailId: any): Observable<any> {
    const endpoint = `api/user/get-user-role-access?mailId=${emailId}`;
    return this.apiService.get(endpoint);
  }
}
