<div class="row">

  <div class="col-md-7">
    <!-- Lookup form -->
    <form class="row" [formGroup]="animalInfoForm">

      <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
        <mat-label>Animal Name</mat-label>
        <input type="text" matInput formControlName="animal_name" autocomplete="off">
      </mat-form-field>

      <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
        <mat-label>Species</mat-label>
        <mat-select formControlName="species" (selectionChange)="onSpeciesChange($event)">
          <mat-option *ngFor="let species of speciesList" [value]="species">
            {{species}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
        <mat-label>Primary Breed</mat-label>
        <mat-select formControlName="primary_breed">
          <mat-option *ngFor="let pBreed of primaryBreed" [value]="pBreed">
            {{pBreed}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
        <mat-label>Primary Color</mat-label>
        <mat-select formControlName="primary_color">
          <mat-option *ngFor="let pColor of primaryColorList" [value]="pColor.value">
            {{pColor.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </form>

    <!-- Selected contact info -->
    <div class="row" *ngIf="selectedAnimal">
      <div class="selected-container">
        <span>Animal ID:</span>
        <span class="overflow-wrap">{{selectedAnimal.animal_id}}</span>
      </div>
      <div class="selected-container">
        <span>Adoption Fee:</span>
        <span>{{getAdoptionFee()}}</span>
      </div>
      <div class="selected-container">
        <span>Animal Name:</span>
        <span class="link overflow-wrap" (click)=onAnimalNameClicked()>{{selectedAnimal.name}}</span>
      </div>
      <div class="selected-container">
        <span>Current Stage:</span>
        <span>{{selectedAnimal.existing_status[selectedAnimal.existing_status.length - 1]?.value}}</span>
      </div>
    </div>

  </div>

  <!-- section for right side table -->
  <div class="col link-container">
    <div class="row">
      <div class="col-3 px-0">
        <app-action-tile *ngFor="let titleAcData of actionTilesListLookupAnimal" [message]="titleAcData.message"
          [messageColor]="titleAcData.messageColor" [actionSign]="titleAcData.actionSign"
          [bgColor]="titleAcData.bgColor" [minHeight]="'84px'" [minWidth]="'96px'"
          [messageFontSize]="titleAcData.messageFontSize" [messageTextForans]="titleAcData.messageTextForans"
          [disabled]="isDisableActionTile(titleAcData)" [img]="titleAcData.img" [actionName]="titleAcData.actionName"
          (actionOutput)="actionFired($event)">
        </app-action-tile>
      </div>
      <div class="col pl-0">
        <div class="row mt-3">
          <ag-grid-angular style="width: 95%; height: 230px;" class="ag-theme-alpine"
            [rowData]="animalLookupRowData | async" [columnDefs]="animalLookupColumnDefs" [getRowHeight]="getRowHeight"
            [headerHeight]="headerHeight" [suppressRowClickSelection]="true" [defaultColDef]="defaultColDef"
            [rowSelection]="'single'" [frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions"
            (gridReady)="onAnimalLookupGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>