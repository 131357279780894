<div mat-dialog-title>
  <div>
    <span>Contact:</span>
    <span class="link" (click)="onContactHeaderClicked()">{{data.contact.first_name}} {{data.contact.last_name}}</span>
  </div>

  <span>ID: {{data.contact.contact_id}}</span>

  <button mat-icon-button>
    <mat-icon (click)="onNoClick()">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="row">

    <div class="col-8">
      <div class="row">

        <div class="label-container small">
          <span>Contact Type</span>
          <span>{{contactType}}</span>
        </div>

        <div class="label-container small">
          <span>Status</span>
          <span>{{contactStatus}}</span>
        </div>

        <div class="label-container small">
          <span>Phone</span>
          <span>{{data.contact.phone}}</span>
        </div>

        <div class="label-container small">
          <span>Email</span>
          <span>{{data.contact.email}}</span>
        </div>

        <div class="label-container long">
          <span>Address</span>
          <span>{{getAddress()}}</span>
        </div>

        <div class="label-container small">
          <span>Date of Birth</span>
          <span>{{getDobFormated()}}</span>
        </div>

        <div class="label-container small">
          <span>Age</span>
          <span>{{getAge()}}</span>
        </div>

        <div class="label-check-container">
          <mat-checkbox [checked]="data.contact.do_not_mail" [disabled]="true">
            Do Not Mail
          </mat-checkbox>

          <mat-checkbox [checked]="data.contact.do_not_phone" [disabled]="true">
            Do Not Call
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="row mb-4 label-warning" *ngIf="isWarnigEnabled">
        <span>Warning:</span>
        <span>DO NOT ADOPT</span>
      </div>

      <div class="row">

        <div class="label-container long">
          <span>Account</span>
          <span class="link" (click)="onAccountClicked()">
            {{data && data.contact && data.contact.account_info && data.contact.account_info.account_name}}
          </span>
        </div>

        <div class="label-container long">
          <span>Related Contacts</span>
          <span class="link" *ngFor="let contact of getRelatedContact()" (click)="onRelatedContactClicked(contact)">
            {{contact.first_name}}  {{contact.last_name}}
          </span>
        </div>

      </div>
    </div>

  </div>

  <div class="row">
    <div class="label-container long">
      <span>Notes</span>
      <ag-grid-angular style="width: 100%; height: 200px; margin-top: .5em;" class="ag-theme-alpine" [rowData]="notesRowData | async"
        [columnDefs]="notesColumnDefs" [defaultColDef]="defaultColDef" [getRowHeight]="getRowHeight"
        [headerHeight]="headerHeight" [gridOptions]="gridOptions" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>

  </div>
</div>

<div mat-dialog-actions>
  <!-- <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button> -->
</div>
