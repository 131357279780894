<div class="mat-elevation-z4 sl-card " (click)="onEventClick($event)" *ngIf="!disabled"
  [ngStyle]="{'background-color': this.bgColor,'min-height':this.minHeight,'min-width':this.minWidth}">

  <div style="height: 37px;
  display: flex;
  justify-content: center;
  align-items: flex-end;">
    <mat-icon *ngIf="!img && actionSign != 'add'" class="mat-icon-sty" fontSet="material-icons-outlined">
      {{actionSign}}
    </mat-icon>
    <img *ngIf="img" [src]="img" alt="" height="20px" width="20px">
    <span *ngIf="actionSign === 'add'" class="mat-icon-sty"
      style="font-size: 37px;font-family: HELVETICA_NEUE_THIN; margin-bottom: 2px;">+</span>
  </div>


  <div [ngStyle]="{'color': this.messageColor}" class="text-container">{{message}}</div>

  <!-- </div> -->
</div>

<div class="sl-card" *ngIf="disabled" style="border: 1px #ebebeb solid; opacity: 30%;"
  [ngStyle]="{'background-color': 'this.bgColor','min-height':this.minHeight,'min-width':this.minWidth}">
  <div style="height: 37px; display: flex; justify-content: center; align-items: flex-end;">
    <mat-icon *ngIf="!img && actionSign != 'add'" class="mat-icon-sty" fontSet="material-icons-outlined">
      {{actionSign}}
    </mat-icon>
    <img *ngIf="img" [src]="img" alt="" height="20px" width="20px">
    <span *ngIf="actionSign === 'add'" class="mat-icon-sty"
      style="font-size: 37px;font-family: HELVETICA_NEUE_THIN; margin-bottom: 2px;">+</span>
  </div>
  <div [ngStyle]="{'color': this.messageColor}" class="text-container">{{message}}</div>
</div>