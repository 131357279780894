import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Animal } from 'src/app/core/models/animal';
import { Vaccination } from 'src/app/core/models/vaccination';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, BehaviorSubject, Observable, of } from 'rxjs';
import { GridApi, GridOptions } from 'ag-grid-community';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilService } from 'src/app/core/services/util.service';
import { AgGridClickableCellComponent } from '../../components/ag-grid-clickable-cell/ag-grid-clickable-cell.component';
import { Utils } from 'src/app/core/utils/util';
import * as _moment from 'moment';

const moment = _moment;

export interface DialogData {
  type: string;
  animal: Animal;
  selectedVaccination?: Vaccination[];
  is_from_services?: boolean;
  is_from_medical_event?: boolean;
  masterData?: any;
}


@Component({
  selector: 'app-add-edit-vaccination-dialog',
  templateUrl: './add-edit-vaccination-dialog.component.html',
  styleUrls: ['./add-edit-vaccination-dialog.component.scss']
})
export class AddEditVaccinationDialogComponent implements OnInit, OnDestroy {

  subs: Array<Subscription> = [];
  vaccinationForm: FormGroup;
  medicalMasterData: any;

  vaccinationHistoryGridApi: GridApi;
  vaccinationGridApi: GridApi;
  public gridOptions = {
    context: {
      componentParent: this,
    },
    suppressHorizontalScroll: false,
    rowStyle: { background: 'white' }
  } as GridOptions;
  defaultColDef: any;
  frameworkComponents;
  getRowHeight: any;
  headerHeight: any;

  vaccineHistoryRowData: Observable<any>;
  vaccineHistoryColumnDefs: Array<any> = [];
  vaccinationRowData: BehaviorSubject<Array<Vaccination>>;
  vaccinationColumnDefs: Array<any> = [];
  rowsCreated = false;
  rowsDeleted: boolean = false;
  deleteIds: any[] = [];

  isFromServices = false;
  isFromMedicalEvent = false;

  constructor(
    public dialogRef: MatDialogRef<AddEditVaccinationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private utilService: UtilService
  ) {
    this.frameworkComponents = {
      cusotmClickableCell: AgGridClickableCellComponent,
    };

    this.getRowHeight = (params) => {
      return 34;
    }
    this.headerHeight = 34;
  }

  ngOnInit(): void {
    this.initVaccinationForm();
    this.setupVaccinationHistoryGridColumn();
    this.setupVaccinationGridColumn();
    this.isFromServices = this.data && this.data.is_from_services ? this.data.is_from_services : false;
    this.isFromMedicalEvent = this.data && this.data.is_from_medical_event ? this.data.is_from_medical_event : false;
    if (this.isFromServices) {
      this.getServicesMasterData();
    } else if (this.isFromMedicalEvent) {
      this.getMedicalEventMasterData();
    } else {
      this.getMasterData();
    }
  }

  initVaccinationForm() {
    this.vaccinationForm = this.fb.group({
      vaccine: ['', [Validators.required]],
      dosage: ['0.00', [Validators.required, Validators.min(0.01)]],
      unit: ['', [Validators.required]],
      frequency: ['', [Validators.required]],
      start_date: ['', [Validators.required]],
      end_date: [''],
      instruction: ['']
    }, { validator: this.dateLessThan('start_date', 'end_date') });
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from].value;
      let t = group.controls[to].value;
      if ((f !== '' && t !== '')) {
        return (f < t) ? null : { range: true };
      }
    }
  }

  getServicesMasterData(): void {
    if (this.data.selectedVaccination && this.data.selectedVaccination.length > 0) {
      this.vaccinationRowData = new BehaviorSubject<Vaccination[]>([]);
      this.vaccinationRowData.next(this.data.selectedVaccination);
    }
    let sub = this.utilService.getMasterInputs('MedicalEvent').subscribe(res => {
      const vaccineList = this.data && this.data.masterData.filter((el: any) => el.service_type == 'Vaccination').map((elm: any) => {
        elm.value = elm.service;
        return elm;
      });
      const input = res && res.input;
      input.animal_vaccine_list = vaccineList;
      this.medicalMasterData = input;
    })
    this.subs.push(sub);
  }

  getMedicalEventMasterData(): void {
    if (this.data.selectedVaccination && this.data.selectedVaccination.length > 0) {
      this.vaccinationRowData = new BehaviorSubject<Vaccination[]>([]);
      this.vaccinationRowData.next(this.data.selectedVaccination);
    }

    let sub = this.utilService.getMasterInputs('MedicalEvent').subscribe(res => {
      const vaccineList =  this.data && this.data.masterData;
      const input = res && res.input;
      input.animal_vaccine_list = vaccineList;
      this.medicalMasterData = input;
    })
    this.subs.push(sub);
  }

  getMasterData() {
    if (this.data.selectedVaccination && this.data.selectedVaccination.length > 0) {
      this.vaccinationRowData = new BehaviorSubject<Vaccination[]>([]);
      this.vaccinationRowData.next(this.data.selectedVaccination);
    }
    let sub = this.utilService.getMasterInputs('MedicalEvent').subscribe(res => {
      this.medicalMasterData = res.input;
    })
    this.subs.push(sub);
  }

  setupVaccinationHistoryGridColumn() {
    this.defaultColDef = Utils.getBaseThemeAgGridCol();
    this.vaccineHistoryColumnDefs = [
      {
        headerName: 'Date',
        field: 'date',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.dateFilterParams()
      },
      {
        headerName: 'Time',
        field: 'time',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filter: "timeFilter"
      },
      {
        headerName: 'Vaccine',
        field: 'vaccine',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'flex-start'
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Unit',
        field: 'unit',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Dosage',
        field: 'dosage',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.filterParams()
      },
    ];
  }

  setupVaccinationGridColumn() {
    this.defaultColDef = Utils.getBaseThemeAgGridCol();
    this.vaccinationColumnDefs = [
      {
        headerName: '',
        field: 'check',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        suppressSizeToFit: true,
        sortable: false,
        resizable: false,
        filter: false,
        minWidth: 10,
        width: 31,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          display: 'flex',
          justifyContent: 'center',
          padding: '0 0 0 10px'
        },
      },
      {
        headerName: 'Date',
        field: 'vaccination_date',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.dateFilterParams()
      },
      {
        headerName: 'Time',
        field: 'vaccination_time',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filter: "timeFilter"
      },
      {
        headerName: 'Vaccine',
        field: 'vaccine',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'flex-start'
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Unit',
        field: 'unit',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Dosage',
        field: 'dosage',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Instruction',
        field: 'instruction',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'flex-start'
        },
        filterParams: Utils.filterParams()
      },
    ];
  }

  onSaveClicked() {
    let VaccinationResult = {
      "delete": this.deleteIds,
      "update": this.vaccinationRowData.getValue()
    }
    this.dialogRef.close(VaccinationResult);
  }

  onCreateClicked() {
    if (this.vaccinationForm.invalid) {
      this.vaccinationForm.markAllAsTouched();
      return;
    }

    let vaccinationList: Vaccination[] = this.vaccinationRowData.getValue();
    vaccinationList = [...vaccinationList, ...this.getVaccinationAccordingToForm()];
    this.vaccinationRowData.next(vaccinationList);
    this.rowsCreated = true;
  }

  getVaccinationAccordingToForm(): Array<Vaccination> {
    let vaccinationList: Vaccination[] = [];
    switch (this.vaccinationForm.get('frequency').value) {
      case 'Daily AM':
        vaccinationList = this.getVaccinationListForDailyDose('AM');
        break;

      case 'Daily PM':
        vaccinationList = this.getVaccinationListForDailyDose('PM');
        break;

      case 'Monthly':
        vaccinationList = this.getVccinationListForMonthlyDose();
        break;

      case 'Single Dose':
        vaccinationList = this.getVaccinationListForSingleDose();
        break;

      case 'Twice Daily':
        vaccinationList = this.getVaccinationListForDailyDose('Twice Daily');
        break;

      case 'Twice Monthly':
        vaccinationList = this.getVccinationListForMonthlyDose('Twice Monthly');
        break;

      case 'Twice Weekly':
        vaccinationList = this.getVaccinationListForWeeklyDose('Twice Weekly');
        break;

      case 'Once in 3 weeks':
        vaccinationList = this.getVaccinationListForWeeklyDose('Once in 3 weeks');
        break;

      case 'Weekly AM':
        vaccinationList = this.getVaccinationListForWeeklyDose('AM');
        break;

      case 'Weekly PM':
        vaccinationList = this.getVaccinationListForWeeklyDose('PM');
        break;

      default:
        break;
    }
    return vaccinationList;
  }

  getVaccinationListForSingleDose(): Array<Vaccination> {
    let vaccination: Vaccination = {
      vaccination_id: Utils.getUUIDv4(),
      vaccine: this.vaccinationForm.get('vaccine').value,
      vaccination_date: moment(this.vaccinationForm.get('start_date').value).format('MM/DD/YYYY'),
      vaccination_time: '08:30 AM',
      dosage: this.vaccinationForm.get('dosage').value,
      frequency: this.vaccinationForm.get('frequency').value,
      unit: this.vaccinationForm.get('unit').value,
      instruction: this.vaccinationForm.get('instruction').value
    };
    return [vaccination];
  }

  getVaccinationListForDailyDose(type: string): Array<Vaccination> {
    let vaccinationList: Vaccination[] = [];
    let startDate: _moment.Moment = moment(this.vaccinationForm.get('start_date').value, 'MM/DD/YYYY');
    let endDate: _moment.Moment = moment(this.vaccinationForm.get('end_date').value, 'MM/DD/YYYY');
    for (let index = 0; index <= endDate.diff(startDate, 'days'); index++) {
      let tempDate: _moment.Moment = moment(this.vaccinationForm.get('start_date').value, 'MM/DD/YYYY');

      if (type == 'Twice Daily') {
        let medication: Vaccination = {
          vaccination_id: '',
          vaccine: this.vaccinationForm.get('vaccine').value,
          vaccination_date: tempDate.add(index, 'days').format('MM/DD/YYYY'),
          vaccination_time: '',
          dosage: this.vaccinationForm.get('dosage').value,
          frequency: this.vaccinationForm.get('frequency').value,
          unit: this.vaccinationForm.get('unit').value,
          instruction: this.vaccinationForm.get('instruction').value
        };
        vaccinationList.push({ ...medication, vaccination_time: '8:30 AM', vaccination_id: Utils.getUUIDv4() });
        vaccinationList.push({ ...medication, vaccination_time: '8:30 PM', vaccination_id: Utils.getUUIDv4() });
      } else {
        let medication: Vaccination = {
          vaccination_id: Utils.getUUIDv4(),
          vaccine: this.vaccinationForm.get('vaccine').value,
          vaccination_date: tempDate.add(index, 'days').format('MM/DD/YYYY'),
          vaccination_time: type == 'AM' ? '08:30 AM' : '08:30 PM',
          dosage: this.vaccinationForm.get('dosage').value,
          frequency: this.vaccinationForm.get('frequency').value,
          unit: this.vaccinationForm.get('unit').value,
          instruction: this.vaccinationForm.get('instruction').value
        };
        vaccinationList.push(medication);
      }
    }
    return vaccinationList;
  }

  getVaccinationListForWeeklyDose(type: string): Array<Vaccination> {
    let vaccinationList: Vaccination[] = [];
    let startDate: _moment.Moment = moment(this.vaccinationForm.get('start_date').value, 'MM/DD/YYYY');
    let endDate: _moment.Moment = moment(this.vaccinationForm.get('end_date').value, 'MM/DD/YYYY');
    if (type == 'Once in 3 weeks') {
      for (let index = 0; index <= endDate.diff(startDate, 'weeks'); index += 3) {

        let tempDate: _moment.Moment = moment(this.vaccinationForm.get('start_date').value, 'MM/DD/YYYY');
        let vaccination: Vaccination = {
          vaccination_id: '',
          vaccine: this.vaccinationForm.get('vaccine').value,
          vaccination_date: '',
          vaccination_time: '08:30 AM',
          dosage: this.vaccinationForm.get('dosage').value,
          frequency: this.vaccinationForm.get('frequency').value,
          unit: this.vaccinationForm.get('unit').value,
          instruction: this.vaccinationForm.get('instruction').value
        };
        vaccinationList.push({ ...vaccination, vaccination_date: tempDate.add(index, 'weeks').format('MM/DD/YYYY'), vaccination_id: Utils.getUUIDv4() });
      }
    } else {
      for (let index = 0; index <= endDate.diff(startDate, 'weeks'); index++) {
        let tempDate: _moment.Moment = moment(this.vaccinationForm.get('start_date').value, 'MM/DD/YYYY');

        if (type == 'Twice Weekly') {
          let vaccination: Vaccination = {
            vaccination_id: '',
            vaccine: this.vaccinationForm.get('vaccine').value,
            vaccination_date: '',
            vaccination_time: '08:30 AM',
            dosage: this.vaccinationForm.get('dosage').value,
            frequency: this.vaccinationForm.get('frequency').value,
            unit: this.vaccinationForm.get('unit').value,
            instruction: this.vaccinationForm.get('instruction').value
          };
          vaccinationList.push({ ...vaccination, vaccination_date: tempDate.add(index, 'weeks').format('MM/DD/YYYY'), vaccination_id: Utils.getUUIDv4() });
          if (endDate.diff(tempDate.add(4, 'days'), 'days') >= 0)
            vaccinationList.push({ ...vaccination, vaccination_date: tempDate.format('MM/DD/YYYY'), vaccination_id: Utils.getUUIDv4() });
        } else {
          let vaccination: Vaccination = {
            vaccination_id: Utils.getUUIDv4(),
            vaccine: this.vaccinationForm.get('vaccine').value,
            vaccination_date: tempDate.add(index, 'weeks').format('MM/DD/YYYY'),
            vaccination_time: type == 'AM' ? '08:30 AM' : '08:30 PM',
            dosage: this.vaccinationForm.get('dosage').value,
            frequency: this.vaccinationForm.get('frequency').value,
            unit: this.vaccinationForm.get('unit').value,
            instruction: this.vaccinationForm.get('instruction').value
          };
          vaccinationList.push(vaccination);
        }
      }
    }

    return vaccinationList;
  }

  getVccinationListForMonthlyDose(type?: string): Array<Vaccination> {
    let vaccinationList: Vaccination[] = [];
    let startDate: _moment.Moment = moment(this.vaccinationForm.get('start_date').value, 'MM/DD/YYYY');
    let endDate: _moment.Moment = moment(this.vaccinationForm.get('end_date').value, 'MM/DD/YYYY');
    for (let index = 0; index <= endDate.diff(startDate, 'months'); index++) {
      let tempDate: _moment.Moment = moment(this.vaccinationForm.get('start_date').value, 'MM/DD/YYYY');

      if (type == 'Twice Monthly') {
        let vaccination: Vaccination = {
          vaccination_id: '',
          vaccine: this.vaccinationForm.get('vaccine').value,
          vaccination_date: '',
          vaccination_time: '08:30 AM',
          dosage: this.vaccinationForm.get('dosage').value,
          frequency: this.vaccinationForm.get('frequency').value,
          unit: this.vaccinationForm.get('unit').value,
          instruction: this.vaccinationForm.get('instruction').value
        };
        vaccinationList.push({ ...vaccination, vaccination_date: tempDate.add(index, 'month').format('MM/DD/YYYY'), vaccination_id: Utils.getUUIDv4() });
        if (endDate.diff(tempDate.add(15, 'days'), 'days') >= 0)
          vaccinationList.push({ ...vaccination, vaccination_date: tempDate.format('MM/DD/YYYY'), vaccination_id: Utils.getUUIDv4() });
      } else {
        let vaccination: Vaccination = {
          vaccination_id: Utils.getUUIDv4(),
          vaccine: this.vaccinationForm.get('vaccine').value,
          vaccination_date: tempDate.add(index, 'month').format('MM/DD/YYYY'),
          vaccination_time: '08:30 AM',
          dosage: this.vaccinationForm.get('dosage').value,
          frequency: this.vaccinationForm.get('frequency').value,
          unit: this.vaccinationForm.get('unit').value,
          instruction: this.vaccinationForm.get('instruction').value
        };
        vaccinationList.push(vaccination);
      }
    }
    return vaccinationList;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onVaccineHistoryGridReady(params) {
    this.vaccinationHistoryGridApi = params.api;
    this.vaccinationHistoryGridApi.sizeColumnsToFit();
    this.vaccineHistoryRowData = of([]);
  }

  onVaccinationGridReady(params) {
    this.vaccinationGridApi = params.api;
    this.vaccinationGridApi.sizeColumnsToFit();
    if (this.data.selectedVaccination.length == 0 || !this.data.selectedVaccination)
      this.vaccinationRowData = new BehaviorSubject<Vaccination[]>([]);
  }

  onRemoveSelectedVaccination() {
    let selectedMedication: Vaccination[] = this.vaccinationGridApi.getSelectedRows();
    let allVaccination: Vaccination[] = this.vaccinationRowData.getValue();
    let filteredMedication = allVaccination.filter(vac => {
      this.rowsDeleted = true;
      let findIndexVal = selectedMedication.findIndex(ele => ele.vaccination_id == vac.vaccination_id);
      if (findIndexVal == -1)
        return findIndexVal;
      else if (Number(vac.vaccination_id))
        (vac.vaccination_id) ? this.deleteIds.push(vac.vaccination_id) : '';
    });
    this.vaccinationRowData.next(filteredMedication);
  }

  isdisableCheck() {
    if (this.vaccinationRowData) {
      let allVaccination: Vaccination[] = this.vaccinationRowData.getValue();
      if (this.rowsDeleted || (allVaccination.length > 0 && this.rowsCreated))
        return false;
      else
        return true;
    } else
      return true;
  }

  onFocusOutEvent(event) {
    if (!isNaN(event.target.value)) {
      const valueSplit = (event.target.value).split(".");
      if (valueSplit[0].length > 0) {
        if (valueSplit[0].length < 4) {
          this.vaccinationForm.get('dosage').setValue(parseFloat(event.target.value).toFixed(2));
        } else {
          this.vaccinationForm.get('dosage').setValue(parseFloat(`${valueSplit[0].substring(0, 3)}.${valueSplit[1]}`).toFixed(2))
          return false;
        }
      } else {
        this.vaccinationForm.get('dosage').setValue(parseFloat(event.target.value).toFixed(2));
      }
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
