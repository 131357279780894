<div mat-dialog-title>
    <div>
      <span>Microchip Injection - {{data.animal?.name}}</span>
    </div>
    <button mat-icon-button>
      <mat-icon (click)="onNoClick()">close</mat-icon>
    </button>
  </div>
  
  <div mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <form class="row" [formGroup]="microchipForm" style="justify-content: space-between;">
          <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent" style="width: 32%;">
            <mat-label>Manufacturer</mat-label>
            <mat-select matInput (selectionChange)="brandOnChange($event)" formControlName="brand" [disabled]="isDisable()">
              <mat-option [value]="''">Select</mat-option>
              <mat-option *ngFor="let brand of brandList;" [value]="brand">
                {{brand}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="microchipForm.controls['brand'].hasError('required')">
              Manufacturer is required
            </mat-error>
          </mat-form-field>

            <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent" style="width: 32%;">
              <mat-label>Microchip</mat-label>
              <mat-select matInput formControlName="microchip" *ngIf="microchipAvailableData.length > 0" [disabled]="isDisable()">
                <mat-option [value]="''">Select</mat-option>
                <mat-option *ngFor="let microchipVal of microchipAvailableData;" [value]="microchipVal">
                  {{microchipVal}}
                </mat-option>
              </mat-select>
              <input type="text" matInput formControlName="microchip" *ngIf="microchipAvailableData.length==0" [readonly]="isDisable()"/>
              <mat-error *ngIf="microchipForm.controls['microchip'].hasError('required')">
                Microchip is required
              </mat-error>
            </mat-form-field>
  
          <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent" style="width: 28%;">
            <mat-label>Injection Date</mat-label>
            <input type="text" matInput formControlName="injection_date" autocomplete="off" [matDatepicker]="picker1">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <!--<input type="text" matInput appDateValidator appDateFormatter formControlName="start_date" autocomplete="off">-->
            <mat-error *ngIf="microchipForm.controls['injection_date'].hasError('required')">
              Injection date is required
            </mat-error>
            <mat-error *ngIf="microchipForm.controls['injection_date'].hasError('invalid')">
              Invalid date format
            </mat-error>
            
          </mat-form-field>

          <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent" style="width: 28%;">
            <mat-label>Injection Time</mat-label>
            <input type="time" matInput formControlName="injection_time" autocomplete="off">
            <!--<input type="text" matInput appDateValidator appDateFormatter formControlName="start_date" autocomplete="off">-->
            <mat-error *ngIf="microchipForm.controls['injection_time'].hasError('required')">
              Injection time is required
            </mat-error>
            <mat-error *ngIf="microchipForm.controls['injection_time'].hasError('invalid')">
              Invalid time format
            </mat-error>
            
          </mat-form-field>
  
        </form>

      </div>
  
    </div>
  

  </div>
  
  <div mat-dialog-actions>
    <button mat-raised-button class="white-button" (click)="onNoClick()">CANCEL</button>
    <button mat-raised-button class="green-button" (click)="onSaveClicked()" cdkFocusInitial>
      SAVE
    </button>
  </div>