import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,HttpEventType} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import { debounceTime,tap,finalize,catchError } from 'rxjs/operators';

import 'rxjs/add/operator/do';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    requestCount:number=0;
    constructor(private router: Router, private toast: MatSnackBar
        ,private spinner: NgxSpinnerService
        ) {
            
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            let lastResponse: HttpEvent<any>;
            let error: HttpErrorResponse;
            this.spinner.show();
            this.requestCount++;
            return next.handle(request)
              .pipe(
                tap((response: HttpEvent<any>) => {
                  lastResponse = response;
                  if (response.type === HttpEventType.Response) {
                    if (response instanceof HttpResponse) {
                        debounceTime(1000),
                        this.requestCount--;
                        if (this.requestCount === 0) {
                            this.spinner.hide();
                        }
                    }
                  //  console.log('success response', response);
                  }
                }),
                catchError((err: any) => {
                  error = err;
                  debounceTime(1000),
                    this.spinner.hide();
                    
                    if (err instanceof HttpErrorResponse) {
                        debounceTime(1000),
                        this.requestCount--;
                        if (this.requestCount === 0) {
                            this.spinner.hide();
                        }
                        const resMessage = err && err.error && err.error.message ? err.error.message || err.error : err.statusText;
                        const isChatUrl = err && err.url.includes('chat.getsaint.com');
                        const chatLogin = err.statusText === 'Unauthorized' || err.statusText === 'Bad Request';
                        const endDocUrl = err && err.url.includes(`api/clinic/get-doctor-availability-by-date`);
                        if (chatLogin && isChatUrl) {}
                        else if(endDocUrl) {

                        } else {
                            this.toast.open(resMessage, 'Ok', {
                                duration: 2000,
                                horizontalPosition: this.horizontalPosition,
                                verticalPosition: this.verticalPosition,
                                panelClass: ['danger-snackbar']
                            });
                        }
                    }
                  return throwError(err);        
                }),    
                finalize(() => {
                  if (lastResponse.type === HttpEventType.Sent && !error) {
                    debounceTime(1000),
                    this.requestCount--;
                    if (this.requestCount === 0) {
                        this.spinner.hide();
                    }
                    // last response type was 0, and we haven't received an error
                   // console.log('aborted request');
                  }
                })
              );

        /*  this.spinner.show();
          this.requestCount++;
          return next.handle(request).do((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                debounceTime(1000),
                this.requestCount--;
                if (this.requestCount === 0) {
                    this.spinner.hide();
                }
            }
             
        }, (err: any) => {
            debounceTime(1000),
            this.spinner.hide();
            
            if (err instanceof HttpErrorResponse) {
                
                const resMessage = err && err.error && err.error.message ? err.error.message || err.error : err.statusText;
                const isChatUrl = err && err.url.includes('chat.getsaint.com');
                const chatLogin = err.statusText === 'Unauthorized' || err.statusText === 'Bad Request';
                if (chatLogin && isChatUrl) {
                    
                    
                } else {
                    this.toast.open(resMessage, 'Ok', {
                        duration: 2000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        panelClass: ['danger-snackbar']
                    });
                }
            }
        });*/
    }

    // To do not used
    private handleError(error: HttpErrorResponse) {
        debounceTime(1000),
        this.spinner.hide();

        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                // error,
                `Backend returned code ${error.status}, ` +
                `body was: ${error.message}`);
        }
        // Return an observable with a user-facing error message.
        return throwError('Something bad happened; please try again later.');
    }
}
