import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-ag-grid-clickable-cell',
  templateUrl: './ag-grid-clickable-cell.component.html',
  styleUrls: ['./ag-grid-clickable-cell.component.scss']
})
export class AgGridClickableCellComponent implements OnInit, ICellRendererAngularComp {

  params: ICellRendererParams;
  constructor() { }

  ngOnInit(): void {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  getValue() {
    let value = this.params.valueFormatted ? this.params.valueFormatted : this.params.value;
    if(typeof value === 'string') {
      let vstr: string = value;
      return vstr.indexOf('$') > -1 ? 'dolr' : 'str'
    } else if(typeof value === 'number') {
      return 'num';
    }
  }

  onClickCell(): void {
    if (this.params.context) {
      const parent = this.params.context.componentParent;
      parent.clickOnCell(this.params.value, this.params.colDef.field, this.params.data);
    }
  }
  
}
