<!-- <div mat-dialog-title>
    <div></div>
    <button mat-icon-button>
        <mat-icon (click)="onCancelClick()">close</mat-icon>
    </button>

</div> -->
<div mat-dialog-content>
    <div class="dialog-qu">Which one you want to remove?</div>
    <mat-checkbox *ngFor="let arrEle of data.rowdata.controls; let i=index" (change)="toggleCheckbox($event,arrEle,i)"
        style="font-size: 12px; color: #008f00; font-family: HELVETICA_NEUE_THIN;margin-right: 0.5em;">
        {{checkLabel}} {{i+1}}</mat-checkbox>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="onCancelClick()">Cancel</button>
    <button mat-raised-button color="accent" (click)="onRemoveClick()" cdkFocusInitial>Remove</button>
</div>