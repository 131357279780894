<div class="row" style="margin-top: 11px;">
  <mat-card class="icon-card">
    <mat-icon fontSet="material-icons-outlined" (click)="openDialog(false)">add</mat-icon>
  </mat-card>
  <mat-card class="icon-card" *ngIf="!isDisable()">
    <mat-icon fontSet="material-icons-outlined" (click)="openDialog(true)">edit</mat-icon>
  </mat-card>
  <mat-card class="icon-card diabled" *ngIf="isDisable()">
    <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
  </mat-card>
</div>
<div class="row notes-table" style="margin-top: 1em;">
  <ag-grid-angular style="width: 100%; height: 200px;" class="ag-theme-alpine notes-table-content" [rowData]="notesRowData | async"
    [frameworkComponents]="frameworkComponents" [columnDefs]="notesColumnDefs" [defaultColDef]="defaultColDef"
    [suppressRowClickSelection]="true" [rowSelection]="rowSelection"
    [headerHeight]="headerHeight" [gridOptions]="gridOptions" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    (gridReady)="onGridReady($event)" (columnResized)="onColumnResized($event)">
  </ag-grid-angular>
</div>
