import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Utils } from '../utils/util';

@Injectable({
  providedIn: 'root'
})


export class SettingsService {

  constructor(
    private apiService: ApiService
  ) { }

  public selectedData:any;

  getTransactionTypeMapping(): Observable<any> {
    return this.apiService.get('api/agency/get-transaction-type-mapping-for-tenant');
  }
  getAgenList(): Observable<any> {
    return this.apiService.get('api/input/agency-transaction-types');
  }

  getJurisdictionList(): Observable<any> {
    return this.apiService.get('api/agency/get-jurisdictions-for-tenant');
  }

  addEditJurisdiction(data: any): Observable<any> {
    return this.apiService.post('api/agency/create-modify-jurisdiction-for-tenant', data);
  }

  deleteJurisdiction(data: any): Observable<any> {
    return this.apiService.delete('api/agency/delete-jurisdiction-for-tenant', data);
  }

  getVoilationList(): Observable<any> {
    return this.apiService.get('api/agency/get-violations-for-tenant');
  }

  addEditVoilation(data: any): Observable<any> {
    return this.apiService.post('api/agency/create-modify-violation-for-tenant', data);
  }

  deleteVoilation(data: any): Observable<any> {
    return this.apiService.delete('api/agency/delete-violation-for-tenant', data);
  }

  getFinesList(): Observable<any> {
    return this.apiService.get('api/agency/get-fines-for-tenant');
  }

  addEditFine(data: any): Observable<any> {
    return this.apiService.post('api/agency/create-modify-fines', data);
  }

  deleteFine(data: any): Observable<any> {
    return this.apiService.delete('api/agency/remove-fines', data);
  }


  updateAgencyDetails(data: any): Observable<any> {
    const endPoint = 'api/agency/create-modify-transactiontype-mapping';
    return this.apiService.post(endPoint, data);
  }

  getAgencyMasterData(): Observable<any> {
    return this.apiService.get(`api/public/input-for-agency`);
  }

  getAgencyDetails(is_campus_list?:boolean): Observable<any> {
    let apiUrl;
    if(is_campus_list)
    apiUrl=this.apiService.get(`api/agency/get-agency-detail?is_campus_list=true`)
    else
    apiUrl=this.apiService.get(`api/agency/get-agency-detail?is_campus_list=false`);

    return apiUrl.pipe(
      map((res: any[]) => {
        let data = res[0];
        let resDateSegment = data.creation_time.split(' ')[0].split('-');
        data.creation_time = `${resDateSegment[1]}/${resDateSegment[2]}/${resDateSegment[0]}`
        return data;
      })
    );
  }

  getAgencyDetailsForGrid(): Observable<any> {
    return this.apiService.get(`api/agency/get-agency-detail?is_campus_list=false`).pipe(
      map((res: any) => {
        let addresses: any[] = res[0].addresses;
        addresses.forEach(ele => {
          ele['address'] = `${this.concat(ele.street1, ',')} ${this.concat(ele.street2, ',')} ${this.concat(ele.city, ',')} ${this.concat(ele.county, ',')} ${this.concat(ele.state, ',')} ${this.concat(ele.zip_code, '')}`;
        })
        addresses.sort((a, b) => a.is_primary ? -1 : 0)
        addresses = addresses.filter(e => e.is_active == true)
        return addresses;
      })
    );
  }

  addAgencyAddress(payload: any): Observable<any> {
    return this.apiService.post(`api/agency/create-modify-address`, payload);
  }

  editAgencyAddress(payload: any): Observable<any> {
    return this.apiService.post(`api/agency/create-modify-address`, payload);
  }
  deleteAgencyAddress(payload: any): Observable<any> {
    return this.apiService.delete(`api/agency/delete-addresses`, payload);
  }

  changePrimaryAgencyAddress(payload: any): Observable<any> {
    return this.apiService.post(`api/agency/make-primary`, payload);
  }
  getAgencyAddressDetails(): Observable<any> {
    return this.apiService.get(`api/agency/get-agency-address-detail`);
  }


  geUserMasterData(): Observable<any> {
    return this.apiService.get(`api/input/User`);
  }

  getUser(): Observable<any> {
    let status = [
      {
        id: 1,
        name: 'Active'
      },
      {
        id: 2,
        name: 'Inactive'
      },
      {
        id: 3,
        name: 'Not Verified'
      }
    ];
    return this.apiService.get(`api/user-management/user`).pipe(
      map((res: any[]) => {
        res.forEach(ele => {
          ele.full_name = `${ele.first_name} ${ele.last_name}`;
          ele.registration_date = ele.registration_date && new Date(ele.registration_date).toLocaleDateString('en-US');
          ele.status = this.statusDataCorrection(ele.status);
        });
        return res;
      })
    );
  }

  addUser(payload): Observable<any> {
    return this.apiService.post(`api/user-management/user`, payload);
  }
  editUser(payload): Observable<any> {
    return this.apiService.put(`api/user-management/user/${payload.id}`, payload);
  }

  reserPassword(payload): Observable<any> {
    return this.apiService.get(`api/user-management/user/${payload}/reset-password`, null);
  }

  concat(data, append) {
    return data ? `${data}${append}` : '';
  }
  deleteUser(payload: any): Observable<any> {
    return this.apiService.delete(`api/user-management/user`, payload);
  }
  //aditional func
  statusDataCorrection(data) {
    if (data === 'ACTIVE') {
      return 'Active';
    } else if (data === 'INACTIVE') {
      return 'Inactive';
    } else if (data === 'NOT_VERIFIED') {
      return 'Not Verified';
    } else {
      return data;
    }
  }

  agencyLogoUpload(payload): Observable<any> {
    return this.apiService.post(`api/agency/upload-agency-logo`, payload);
  }

  createAndUpdateLocation(data: any, createFor): Observable<any> {
    let url = '';

    if (createFor === 'FLOOR') {
      url = 'api/agencycage/create-modify-floor';
    } else if (createFor === 'SECTION') {
      url = 'api/agencycage/create-modify-section';
    } else if (createFor === 'ROOM') {
      url = 'api/agencycage/create-modify-room';
    } else if (createFor === 'CAGE') {
      url = 'api/agencycage/create-modify-cage';
    }

    return this.apiService.post(url, data);
  }

  geUserLocationTreeData(): Observable<any> {
    return this.apiService.get(`api/agencycage/get-agency-location-design`)
    // .pipe(
    //   map((res: any) => {
    //     res.forEach(lEle => {
    //       lEle.type = 'location'
    //       lEle.floors ?.forEach(fEle => {
    //         fEle['floor_id'] = fEle.id;
    //         fEle.type = 'floor';
    //         fEle.sections ?.forEach(sEle => {
    //           sEle['section_id'] = sEle.id;
    //           sEle.type = 'section'
    //           sEle.rooms ?.forEach(rEle => {
    //             rEle['room_id'] = rEle.id;
    //             rEle.type = 'room'
    //             rEle.cages ?.forEach(cEle => {
    //               cEle['cage_id'] = cEle.id;
    //               cEle.type = 'cage'
    //             })
    //           })
    //         })
    //       });
    //     });
    //     return res;
    //   })
    // );
  }

  deleteLocationOrCage(componentName, locationId, id): Observable<any> {
    return this.apiService.delete('api/agencycage/delete-agency-cage-components/' + componentName +'/'+ locationId +'/'+ id, null);
  }

  stripeAccountCreation(postData): Observable<any> {  
    return this.apiService.post('api/agency/create-stripe-account',postData);
  }

  stripeAccountUpdate(postData,stripeAccountId): Observable<any> {  
    return this.apiService.post(`api/agency/update-stripe-account?stripe_account_id=${stripeAccountId}`,postData);
  }

  getStripeAccountDetailsByTenantId(): Observable<any> {  
    return this.apiService.get('api/agency/get-stripe-account');
  }

  getPaymentStatus(id, type): Observable<any> {
    return this.apiService.get(`api/agency/get-payment-status?category_id=${id}&payment_category=${type}`);
  }

  stripeAccountStatus(payload): Observable<any> {
    return this.apiService.put(`api/agency/update-stripe-account-status`, payload)
  }

  setSelectedData(selectedTableRowData : any){
    this.selectedData = selectedTableRowData;
  }
  
  getSelectedData(){
    return this.selectedData;
  }
  
}