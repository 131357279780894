<div mat-dialog-title>
    <div>
      <span>{{data.title}}</span>
    </div>
    <button mat-icon-button>
      <mat-icon (click)="onNoClick()">close</mat-icon>
    </button>
  </div>
  
  <div mat-dialog-content>
    <div class="row px-2">
      <div class="col-2 results animal_allocation" *ngIf="data && data.seletctedScheduleShift.length > 0">
        <div class="Heading select-box-container select-box-first-child">
            <div class="Cell">Date</div>
            <div class="Cell">Shift</div>
        </div>
        <div class="Row select-box-container"  *ngFor="let nestedNodeData1 of data.seletctedScheduleShift">
            <div class="Cell text-center">{{nestedNodeData1.schedule_date | date:'MMM-d'}}</div>
            <div class="Cell text-center">{{nestedNodeData1.shift}}</div>
        </div>
    </div>

      <div class="col-10">
  <!-- <ag-grid-angular
    #agGrid
    style="width: 100%; height: 100%;"
    id="myGrid"
    class="ag-theme-balham"
    [getRowHeight]="getRowHeight"
    [headerHeight]="headerHeight"
    [columnDefs]="columnDefs"
    [rowData]="rowData"
    [rowSelection]="rowSelection"
    (rowSelected)="onRowSelected($event)"
    [rowMultiSelectWithClick]="true"
    (selectionChanged)="onSelectionChanged($event)"
    (gridReady)="onGridReady($event)"
    ></ag-grid-angular> -->
    <!-- (gridReady)="onGridReady($event)"
    [getRowHeight]="getRowHeight"
    [headerHeight]="headerHeight"
    [columnDefs]="columnDefs"
    [rowData]="rowData"
    [rowSelection]="'multiple'"
    (rowSelected)="onRowSelected($event)"
    [rowMultiSelectWithClick]="true"
    (selectionChanged)="onSelectionChanged($event)" -->
    
    
    
        <ag-grid-angular (gridReady)="onGridReady($event)" [getRowHeight]="getRowHeight" [headerHeight]="headerHeight" [columnDefs]="columnDefs"
        (rowSelected)="onRowSelected($event)"
        [rowMultiSelectWithClick]="true"
        (selectionChanged)="onSelectionChanged($event)"
        [rowSelection]="'multiple'" [rowData]="rowData"
           [defaultColDef]="defaultColDef" [frameworkComponents]="frameworkComponents"
           
           class="ag-theme-alpine animal_allocation_aggrid"
          style="width: 100%; height: 330px;">
      </ag-grid-angular>
      </div>
      
    </div>

    <!-- <div class="row px-2" [ngClass]="{'ele-align-center': !data.isNested}">
      <div class="col-2 results animal_allocation" *ngIf="data && data.seletctedScheduleShift.length > 0">
        <div class="Heading select-box-container select-box-first-child">
            <div class="Cell">Date</div>
            <div class="Cell">Shift</div>
        </div>
        <div class="Row select-box-container"  *ngFor="let nestedNodeData1 of data.seletctedScheduleShift">
            <div class="Cell text-center">{{nestedNodeData1.schedule_date | date:'MMM-d'}}</div>
            <div class="Cell text-center">{{nestedNodeData1.shift}}</div>
        </div>
    </div>

      <div class="col-4 results">
        <div class="results">
          <div [style.width.px]="OptionFirstParentWidth" class="select-box-container select-box-first-child" [ngClass]="{'border-no-b': data.listData.length>0, 'border-all': data.listData.length==0}">
            <span class="title">{{data.nestedDataTitle}}</span>
          </div>
          <div #getParentOptionWidth class="option_parent_list">
            <div class="select-box-container" *ngFor="let nestedNodeData of data.listData; let i=index;"
            (click)="onNestednodeClicked(nestedNodeData)"
            [ngClass]="{'border-no-b': i<data.listData.length-1, 'border-all': i==data.listData.length-1, 'selected': isSelectedType(nestedNodeData)}">
            <mat-icon *ngIf="isNestedNodeHasSelectedData(nestedNodeData)">done</mat-icon>
            <span>{{nestedNodeData.name}}</span>
          </div>
          </div>
        </div>
      </div>
  
      <div class="col-6 results animal_allocation" *ngIf="selectedDataNode && selectedDataNode.dataList.length > 0">
            
            <div class="Heading select-box-container select-box-first-child" [style.width.px]="OptionFirstChildWidth" [ngClass]="{'border-no-b': selectedDataNode.dataList.length>0, 'border-all': selectedDataNode.dataList.length==0}">
                <div class="Cell">ID</div>
                <div class="Cell">Animal Name</div>
                <div class="Cell">Species</div>
                <div class="Cell">Breed</div>
                <div class="Cell">Cage</div>
                <div class="Cell">Contact</div>
            </div>
            <div class="Row select-box-container" *ngFor="let nodeData of selectedDataNode.dataList; let i=index;" (click)="onNodeClicked(nodeData,selectedDataNode)" #getChildOptionWidth
                            [ngClass]="{'selected': nodeData.isSelected, 'option_child_list' : i==0}">
                <div class="Cell">{{nodeData.id}}</div>
                <div class="Cell"><mat-icon *ngIf="nodeData.isSelected">done</mat-icon>{{nodeData.animal_name}}</div>
                <div class="Cell">{{nodeData.species}}</div>
                <div class="Cell">{{nodeData.breed}}</div>
                <div class="Cell">{{nodeData.cage}}</div>
                <div class="Cell">{{nodeData.contact_name}}</div>
            </div>
            <ng-container *ngFor="let nodeData of selectedTests; let i=index;">
                  <div class="Row select-box-container" *ngIf="nodeData.node_name!=selectedDataNode.name" (click)="onNodeClicked(nodeData,selectedDataNode)"
                                [ngClass]="{'selected': nodeData.isSelected}">
                    <div class="Cell">{{nodeData.id}}</div>
                    <div class="Cell"><mat-icon *ngIf="nodeData.isSelected">done</mat-icon>{{nodeData.animal_name}}</div>
                    <div class="Cell">{{nodeData.species}}</div>
                    <div class="Cell">{{nodeData.breed}}</div>
                    <div class="Cell">{{nodeData.cage}}</div>
                    <div class="Cell">{{nodeData.contact_name}}</div>
                  </div>
            </ng-container >
           
            
        </div>

    </div> -->
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button class="white-button" (click)="onNoClick()">CANCEL</button>
    
    <button mat-raised-button class="green-button" (click)="onSaveClicked()" cdkFocusInitial>
      ALLOCATE
    </button>
  </div>