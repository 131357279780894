<div mat-dialog-title>
  <div>
    <span>Agency Lookup</span>
  </div>
  <button mat-icon-button>
    <mat-icon (click)="onNoClick()">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>

  <form class="row" [formGroup]="agencyLookupForm" style="justify-content: space-between;">

    <mat-form-field class="form-field" [floatLabel]="'always'" color="accent" style="width: 50%;">
      <mat-label>Business Name</mat-label>
      <input type="text" matInput appTitlecase formControlName="name" autocomplete="off">
      <mat-error *ngIf="agencyLookupForm.controls['name'].hasError('required')">
        Business name is required
      </mat-error>
    </mat-form-field>

    <!-- <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent"
      style="margin-right: 25%;">
      <mat-label>Type</mat-label>
      <input type="text" matInput formControlName="type" autocomplete="off">
      <mat-error *ngIf="agencyLookupForm.controls['type'].hasError('required')">
        Type is required
      </mat-error>
    </mat-form-field> -->

    <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent" style="margin-right: 25%;">
      <mat-label>Type</mat-label>
      <mat-select formControlName="type">
        <mat-option [value]="''">None</mat-option>
        <mat-option *ngFor="let type of agencymasterData?.agency_type" [value]="type.value">
          {{type.value}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="agencyLookupForm.controls['type'].hasError('required')">
        Type is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>Street 1</mat-label>
      <input type="text" matInput appTitlecase formControlName="street1" autocomplete="off">
      <mat-error *ngIf="agencyLookupForm.controls['street1'].hasError('required')">
        Street 1 is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>Zip Code</mat-label>
      <input type="text" matInput formControlName="zip_code" autocomplete="off" (change)="onZipcodeInputChange($event)">
<!--      <mat-error *ngIf="agencyLookupForm.controls['zip_code'].hasError('invalid')">-->
<!--        Zip Code is invalid-->
<!--      </mat-error>-->
      <mat-error *ngIf="agencyLookupForm.controls['zip_code'].hasError('required')">
        Zip Code is required
      </mat-error>
      <mat-error *ngIf="agencyLookupForm.controls['zip_code'].invalid">
        Enter valid zipcode
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>State</mat-label>
      <mat-select formControlName="state">
        <mat-option *ngFor="let state of states" [value]="state">
          {{state}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="agencyLookupForm.controls['state'].hasError('required')">
        State is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>City</mat-label>
      <mat-select formControlName="city">
        <mat-option *ngFor="let city of cities" [value]="city">
          {{city}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="agencyLookupForm.controls['city'].hasError('required')">
        State is required
      </mat-error>
    </mat-form-field>

  </form>

  <div class="row mt-4" style="justify-content: flex-end;">
    <button mat-raised-button class="gray-button" [disabled]="isDisableButtons()" (click)="onRefreshClicked()" style="margin-right: 1em;">
      REFRESH
    </button>
    <button mat-raised-button class="blue-button" [disabled]="isDisableButtons()" (click)="onSearchClicked()">
      SEARCH
    </button>
    <button mat-raised-button class="green-button" [disabled]="isDisableButtons()" (click)="onCreateNewClicked()">
      CREATE NEW
    </button>
  </div>

  <div class="row mt-4">
    <ag-grid-angular style="width: 100%; height: 230px;" class="ag-theme-alpine" [rowData]="rowData | async"
      [columnDefs]="columnDefs" [getRowHeight]="getRowHeight" [headerHeight]="headerHeight"
      [suppressRowClickSelection]="true" [defaultColDef]="defaultColDef" [rowSelection]="'single'"
      [frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button class="white-button" (click)="onNoClick()">CANCEL</button>
  <button mat-raised-button class="green-button" [mat-dialog-close]="gridApi?.getSelectedRows()[0]" cdkFocusInitial
    [disabled]="gridApi?.getSelectedRows().length<1">
    SELECT
  </button>
</div>
