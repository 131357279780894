<div mat-dialog-title>
    <div>
      <span>{{data.type | titlecase}} Report - {{tableTitleName}}</span>
    </div>
    
    <button mat-icon-button>
      <mat-icon (click)="onNoClick()">close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content *ngIf="data.type=='summary'">
  <div class="row">
      <div class="col-sm-3">
       <table class="table table-bordered col-12 reportDataSelection">
          <thead>
            <tr>
              <th>From Date</th>
              <th>To Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{data.from_date}}</td>
              <td>{{data.to_date}}</td>
            </tr>
          </tbody>
        </table>
       
      </div>
      <div class="col-sm-8">
        <table class="table table-bordered col-12 reportDataSelection">
          <thead>
            <tr>
              <th>Summary Frequency</th>
              <th>Summarization</th>
              <th>Filter Applied</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="text-align:left">{{ data.summary_frequency }}</td>
              <td style="text-align:left">{{ summaryOptionVal }}</td>
              <td style="text-align:left">{{data.filter_option}}</td>
            </tr>
          </tbody>
        </table>
        
    </div>
  </div>
  <div class="download_button"  *ngIf="csvSummaryOutput.length > 0">
    
    <button mat-raised-button (click)="exportAsExcelFile()">DOWNLOAD</button>
  </div>
  <div class="row">
    <div class="table-wrapper-scroll-y my-custom-scrollbar col-12 table-responsive">      
            <table class="table table-bordered reportResults" *ngIf="csvSummaryOutput.length > 0">
              <tr *ngFor="let tableRowData1 of csvSummaryOutput;let i=index">
                <td *ngFor="let tableRowData2 of tableRowData1;let i1=index1">{{tableRowData2}}</td>
              </tr>
            </table>

            <table class="table table-bordered reportResults" *ngIf="csvSummaryOutput.length==0">
              <tr><td>Data Not Found</td></tr>
            </table>
        </div>
  </div>
  </div>

  <div mat-dialog-content *ngIf="data.type=='detailed'">
    <div class="row">
        <div class="col-sm-3">
         <table class="table table-bordered col-12 reportDataSelection">
            <thead>
              <tr>
                <th>From Date</th>
                <th>To Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{data.from_date}}</td>
                <td>{{data.to_date}}</td>
              </tr>
            </tbody>
          </table>
         
        </div>
        <div class="col-sm-8">
          
          <table class="table table-bordered col-12 reportDataSelection">
            <thead>
              <tr>
                <th>Filter Applied</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="height:27px;text-align:left">{{data.filter_option}}</td>
              </tr>
            </tbody>
          </table>
          
      </div>
    </div>

    <div class="row grid-title" style="margin-bottom: 10px;">
      <div>
        <span>{{tableTitleName}}</span>
        <span>({{resultCount}})</span>
      </div>
      <div>
        <button mat-raised-button (click)="onBtnDownloadClicked()">DOWNLOAD</button>
      </div>
    </div>
    <!---<div class="download_button" style="margin-bottom: 10px;">
          <div>{{data.animal_mode}} ({{resultCount}})</div> 
          <button mat-raised-button (click)="onBtnDownloadClicked()">DOWNLOAD</button>
    </div>-->
    <div class="row">
      <ag-grid-angular (gridReady)="onGridReady($event)"
            [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [frameworkComponents]="frameworkComponents"
            [getRowHeight]="getRowHeight" [gridOptions]="gridOptions" [headerHeight]="headerHeight" [rowData]="rowData | async"
            [rowSelection]="rowSelection" [suppressRowClickSelection]="true" class="ag-theme-alpine"
            style="width: 100%; height: 300px;">
        </ag-grid-angular>     
    </div>
    </div>
