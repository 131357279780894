import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GridApi, GridOptions} from 'ag-grid-community';
import {Observable, of, Subscription} from 'rxjs';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {Utils} from 'src/app/core/utils/util';
import {AgGridClickableCellComponent} from '../ag-grid-clickable-cell/ag-grid-clickable-cell.component';
import {UtilService} from 'src/app/core/services/util.service';
import {CaseService} from 'src/app/core/services/case.service';
import {map} from 'rxjs/operators';
import {AccountsService} from 'src/app/core/services/accounts.service';
import * as _ from "lodash";


export interface DialogData {
    name: string;
    filterInnetwork?: boolean;
    disableFilter?: boolean;
}

@Component({
    selector: 'app-agency-lookup-dialog',
    templateUrl: './agency-lookup-dialog.component.html',
    styleUrls: ['./agency-lookup-dialog.component.scss']
})
export class AgencyLookupDialogComponent implements OnInit, OnDestroy {

    agencyLookupForm: FormGroup;

    public gridOptions = {
        context: {
            componentParent: this,
        },
        suppressHorizontalScroll: false,
        rowStyle: {background: 'white'}
    } as GridOptions;
    gridApi: GridApi;
    gridColumnApi: any;
    defaultColDef: any;
    frameworkComponents;
    getRowHeight: any;
    headerHeight: any;
    rowData: Observable<any>;
    columnDefs: any[] = [];
    subs: Subscription[] = [];
    materData: any;
    cities: any[] = [];
    states: any[] = [];
    counties: any[] = [];
    agencymasterData: any;
    tenantId: any;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(
        public dialogRef: MatDialogRef<AgencyLookupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private fb: FormBuilder,
        private utilService: UtilService,
        private caseService: CaseService,
        private accountService: AccountsService,
        private _snackBar: MatSnackBar,
    ) {
        this.frameworkComponents = {
            cusotmClickableCell: AgGridClickableCellComponent
        };

        this.getRowHeight = (params) => {
            return 34;
        }
        this.headerHeight = 34;
    }

    ngOnInit(): void {
        this.getMasterData();
        this.initAgencyLookupForm();
        this.setupGridColumn();
    }

    getMasterData() {
        let sub = this.accountService.getPublicAgencyMasterData().subscribe(res => {
            this.agencymasterData = res.input;
        })
        this.subs.push(sub);
    }

    initAgencyLookupForm() {
        this.agencyLookupForm = this.fb.group({
            name: [''],
            type: [''],
            street1: [''],
            zip_code: [''],
            state: [''],
            city: [''],
            county: ['']
        });
    }

    setupGridColumn() {
        this.defaultColDef = Utils.getBaseThemeAgGridCol();
        this.columnDefs = [
            {
                headerName: '',
                field: 'check',
                checkboxSelection: true,
                suppressSizeToFit: true,
                width: 31,
                sortable:false,
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0 0 0 10px'
                },
            },
            {
                headerName: 'Name',
                field: 'name',
                width: 100,
                suppressSizeToFit: true,
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Type',
                field: 'type',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'flex-start',
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Address',
                field: 'address',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'flex-start',
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Phone',
                field: 'phone',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Email',
                field: 'email',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'flex-start',
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Connected?',
                field: 'connected',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'flex-start',
                },
                filterParams: Utils.filterParams()
            },
        ]
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        // this.rowData = of([]);
        this.initialNamePopulate();
        this.onSearchClicked();
    }

    initialNamePopulate() {
        this.agencyLookupForm.patchValue({
            name: this.data.name ? this.data.name : '',
        })
    }

    async removeFormErrors(){
        this.agencyLookupForm.get('name').setErrors(null);
        this.agencyLookupForm.get('type').setErrors(null);
        this.agencyLookupForm.get('street1').setErrors(null);
        this.agencyLookupForm.get('zip_code').setErrors(null);
        this.agencyLookupForm.get('state').setErrors(null);
        this.agencyLookupForm.get('city').setErrors(null);
    }

    onSearchClicked() {
        this.removeFormErrors();
        let count = 0;
        Object.keys(this.agencyLookupForm.value).forEach(ele => {
            if (this.agencyLookupForm.value[ele] !== '' && this.agencyLookupForm.value[ele] !== null) count = count + 1;
        });
       
        if (count == 0) {
            this.rowData = of([]);
           /* this._snackBar.open('No agency found', 'Ok', {
                duration: 3000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                panelClass: ['warning-snackbar']
            });*/
            return;
        }
        
        this.rowData = this.caseService.agencyLookup(this.agencyLookupForm.value).pipe(
            map((res: any[]) => {

                if (!this.data.disableFilter) {
                    if (!this.data.filterInnetwork) {
                        res = res.filter(element => {
                            if (!element.in_network) {
                                return element;
                            }
                        })
                    } else {
                        res = res.filter(element => {
                            if (element.in_network) {
                                return element;
                            }
                        })
                    }
                }


                if (res.length == 0) {
                    this._snackBar.open('No agency found', 'Ok', {
                        duration: 3000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        panelClass: ['warning-snackbar']
                    });
                }
                // this._snackBar.open('Agency Searched', 'Ok', {
                //   duration: 3000,
                //   horizontalPosition: this.horizontalPosition,
                //   verticalPosition: this.verticalPosition,
                //   panelClass: ['light-snackbar']
                // });
                /*  res = res.filter(element => {
                   if (element.origination == 'Existing') {
                     return element;
                   }
                 })
                 res.forEach(ele => {
                   ele['type'] = this.materData?.contact_type.find(elm => elm.id == ele.contact_type_id)?.value;
                   ele['age'] = moment().diff(moment(ele['dob'], 'YYYY-MM-DD'), 'years');
                   ele['dob'] = moment(ele['dob'], 'YYYY-MM-DD').format('MM/DD/YYYY');
                 }) */
                return res;
            })
        );

    }

    makeFieldsRequired() {
        this.agencyLookupForm.controls.name.setValidators([Validators.required]);
        this.agencyLookupForm.controls.name.updateValueAndValidity();

        this.agencyLookupForm.controls.type.setValidators([Validators.required]);
        this.agencyLookupForm.controls.type.updateValueAndValidity();

        this.agencyLookupForm.controls.street1.setValidators([Validators.required]);
        this.agencyLookupForm.controls.street1.updateValueAndValidity();

        this.agencyLookupForm.controls.zip_code.setValidators([Validators.required]);
        this.agencyLookupForm.controls.zip_code.updateValueAndValidity();

        this.agencyLookupForm.controls.state.setValidators([Validators.required]);
        this.agencyLookupForm.controls.state.updateValueAndValidity();

        this.agencyLookupForm.controls.city.setValidators([Validators.required]);
        this.agencyLookupForm.controls.city.updateValueAndValidity();
    }

    onCreateNewClicked() {
        this.makeFieldsRequired();

        if (this.agencyLookupForm.valid) {
            this.tenantId = '';
            let payload = {
                tenant_id: this.tenantId ? this.tenantId : '',
                agency_type: this.agencyLookupForm.get('type').value,
                business_name: this.agencyLookupForm.get('name').value,
                annual_revenue: '',
                employee_size: '',
                agency_website: '',
                income_tax_return: '',
                contract_agreement: '',
                in_network: false,
                is_active: false,
                creation_time: '',
                user: null,
                // user: {
                //   first_name: this.basicaAgencyForm.value.primary_contact_first_name,
                //   last_name: this.basicaAgencyForm.value.primary_contact_last_name,
                //   phone_number: this.basicaAgencyForm.value.phone,
                //   email: this.basicaAgencyForm.value.email,
                //   position: '',
                //   locationID: '',
                //   role: ''
                // },
                addresses: [
                    {
                        agency_sub_id: '',
                        location_name: 'HQ',
                        phone: '',
                        street1: this.agencyLookupForm.get('street1').value,
                        street2: '',
                        zip_code: this.agencyLookupForm.get('zip_code').value,
                        state: this.agencyLookupForm.get('state').value,
                        city: this.agencyLookupForm.get('city').value,
                        county: this.agencyLookupForm.get('county').value,
                        is_primary: true
                    }
                ]
            }
            let sub = this.accountService.createModifyNetworkAgency(payload).subscribe(res => {
                this._snackBar.open('Agency Created', 'Ok', {
                    duration: 2000,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    panelClass: ['light-snackbar']
                });

                this.onSearchClicked();
                // let sub2 = this.accountService.getAgencyById(res.id).subscribe(res => {
                //   this.dialogRef.close(res);
                // })
                // this.subs.push(sub2);
            });
            this.subs.push(sub);
        } else {
            this.agencyLookupForm.markAllAsTouched();
        }
    }

    onRefreshClicked() {
        this.agencyLookupForm.reset();
        this.rowData = of([]);
    }

    onZipcodeInputChange(event) {
        const payload = {
            zipcode: event.target.value,
            city: '',
            state: '',
        };
        this.cities = [];
        this.states = [];
        this.counties = [];
        this.getAddressInfo(payload);
        // this.utilService.getSmartyZipCode(payload).subscribe(res => {
        //   if (res[0].status == 'invalid_zipcode') {
        //     this.agencyLookupForm.controls.zip_code.setErrors({ invalid: true })
        //     return;
        //   }
        //   res[0].city_states?.forEach(city_state => {
        //     if (!this.cities.includes(city_state.city)) {
        //       this.cities.push(city_state.city)
        //     }
        //     if (!this.states.includes(city_state.state)) {
        //       this.states.push(city_state.state)
        //     }
        //   });
        //   res[0].zipcodes?.forEach(zipcode => {
        //     if (!this.counties.includes(zipcode.county_name)) {
        //       this.counties.push(zipcode.county_name)
        //     }
        //   });
        //
        //   this.agencyLookupForm.patchValue({
        //     city: this.cities.length > 0 ? this.cities[0] : '',
        //     county: this.counties.length > 0 ? this.counties[0] : '',
        //     state: this.states.length > 0 ? this.states[0] : '',
        //   })
        // });
    }

    getAddressInfo(payload: any): any {
        this.utilService.getAddressInfo(payload).subscribe((res: any) => {
            if (res && res.length) {
                const data = res[0];
                const cities = [];
                cities.push(data.primary_city);
                const acceptableCities = data && data.acceptable_cities && data.acceptable_cities.split('_');
                if (acceptableCities && acceptableCities.length) {
                    _.forEach(acceptableCities, (item: any) => cities.push(item));
                }
                _.forEach(cities, (item) => this.cities.push(item));
                this.states.push(data.state);
                this.counties.push(data.county);
                this.agencyLookupForm.patchValue({
                    city: this.cities.length > 0 ? this.cities[0] : '',
                    county: this.counties.length > 0 ? this.counties[0] : '',
                    state: this.states.length > 0 ? this.states[0] : '',
                });
            } else {
                this.agencyLookupForm.controls.zip_code.setErrors({incorrect: true});
                this._snackBar.open(
                    'You have entered invalid Zipcode',
                    'ok', {
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                        duration: 2000,
                        panelClass: 'danger'
                    });
            }
        });
    }

    isDisableButtons() {
        return false;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.subs.forEach(ele => ele.unsubscribe());
    }
}
