<div mat-dialog-title style="margin-bottom: 0px;">
  <div></div>
  <button mat-icon-button style="margin-right: -28px;margin-top: -18px;">
    <mat-icon (click)="onNoClick()">close</mat-icon>
  </button>

</div>
<div mat-dialog-content>
  <div class="row">
    <div class="col-sm-5">
      <div *ngIf="!callFormCase" class="col contact-search-container">
        <span class="row label">Search Agency</span>
        <div class="row">
          <input class="col px-0" type="text" [(ngModel)]="searchedAgency" (keyup)="onKeyAgencySearch($event)">
          <mat-icon>search</mat-icon>
        </div>
      </div>

      <div *ngIf="!callFormCase" class="button-container">
        <button mat-raised-button (click)="onSelectRow()"
          style="margin-right: 0px;border-radius: unset;">SELECT</button>
      </div>
      <div style="margin-top: 2.5em;"><span class="ag-name-label">Agency:</span>
        <span class="ag-name-text">{{agencyName}}</span></div>
    </div>
    <div *ngIf="!callFormCase" class="col-sm-7">
      <ag-grid-angular style="width: 100%; height: 241px;" class="ag-theme-alpine" [rowData]="rowData | async"
            [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [frameworkComponents]="frameworkComponents"
            [suppressRowClickSelection]="true" [rowSelection]="rowSelection" [gridOptions]="gridOptions"
            [getRowHeight]="getRowHeight" [headerHeight]="headerHeight" (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
  </div>
  <form class="main-form" [formGroup]="newMsg">
    <div class="row">
      <div style="width: 42%;">
        <mat-form-field class="basic-form-field" [floatLabel]="'always'" color="accent">
          <mat-label>Topic</mat-label>
          <input type="text" matInput formControlName="topic" autocomplete="off">
          <mat-error *ngIf="newMsg.controls['topic'].hasError('required')">
            Topic is required
          </mat-error>
        </mat-form-field>
      </div>
      <div style="width: 100%;">
      </div>
      <!-- <div style="width: 100%; margin-bottom: .5em;"><span class="message-label">Message:</span> </div> -->
      <div style="width: 100%;">
        <mat-form-field class="basic-form-field" [floatLabel]="'always'" color="accent">
          <mat-label>Message</mat-label>
          <textarea class="message-box" cols="30" rows="6" matInput formControlName="message"></textarea>
          <mat-error *ngIf="newMsg.controls['message'].hasError('required')">
            Message is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

</div>
<div mat-dialog-actions>
  <div>
    <button mat-raised-button (click)="onNoClick()">CANCEL</button>
    <button mat-raised-button color="accent" (click)="onSendClick()" cdkFocusInitial>SEND</button>
  </div>

</div>