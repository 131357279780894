<div mat-dialog-title class="m-0">
    <div>
        <span>Select Agency</span>
    </div>
    <button mat-icon-button>
        <mat-icon (click)="onClose()">close</mat-icon>
    </button>
</div>

<div mat-dialog-content class="contents mt-3 px-0">
    <div fxLayout="row" *ngIf="!agencyList.length">
        <div fxFlex="100">
            <p class="m-0 text-center">Loading...</p>
        </div>
    </div>
    <div class="container" *ngIf="agencyList.length">
        <div fxLayout="row">
            <div fxFlex="100">
                <form [formGroup]="agencyFilterForm">
                    <mat-form-field appearance="outline" class="w-100 agency-search-input">
                        <!--                        <mat-label>Search Agency</mat-label>-->
                        <div matPrefix>
                            <mat-icon>search</mat-icon>
                        </div>
                        <input matInput (input)="onSearchString($event)" placeholder="Search Agency"
                               formControlName="search_agency" #searchAgency autocomplete="off">
                        <button mat-button *ngIf="filterModel" matSuffix mat-icon-button aria-label="Clear"
                                (click)="filterModel = '';searchAgency.value=''">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </form>
            </div>
        </div>
        <mat-list #agencies>
            <div fxLayout="row">
                <div fxFlex="100" class="list-header">
                    <p class="m-0">Agency List </p>
                </div>
            </div>
            <div class="list-container">
                <mat-list-item
                        (click)="onListItemClick(agency)"
                        *ngFor="let agency of agencyList | filterList: filterModel"
                        [ngClass]="agency.selected ? 'selected' : 'un-selected'">
                    <div class="list-item-wrapper">
                        <span class="m-0 mr-2" *ngIf="agency.selected">
                            <mat-icon [ngStyle]="{'color': agency.selected ? '#FFF' : '#000'}">done</mat-icon>
                        </span>
                        <p [ngStyle]="{'color': agency.selected ? '#FFF' : '#000'}" class="m-0">{{agency.business_name}}
                            <span [ngStyle]="{'color': agency.selected ? '#FFF' : '#000', 'opacity': '70%'}">({{agency.agency_type}} - </span>
                                <span *ngIf="agency.in_network" [ngStyle]="{'color': agency.selected ? '#FFF' : '#000', 'opacity': '70%'}">In Network)</span>
                                <span *ngIf="!agency.in_network" [ngStyle]="{'color': agency.selected ? '#FFF' : '#000', 'opacity': '70%'}">Out Of Network)</span>
                        </p>
                    </div>
                </mat-list-item>
            </div>
        </mat-list>
    </div>
</div>

<!--<hr class="section-divider m-0 mt-2">-->
<div mat-dialog-footer class="dialog-footer">
    <div mat-dialog-actions class="p-0">
        <button mat-raised-button [disabled]="!selectedItem" class="green-button ml-auto" (click)="onAddAgencyClicked()"
                cdkFocusInitial>
            Add Agency
        </button>
    </div>
</div>
