<div mat-dialog-title>
  <div>
    <span>Animal Lookup</span>
  </div>
  <button mat-icon-button>
    <mat-icon (click)="onNoClick()">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>

  <!-- Form section -->
  <form class="row" [formGroup]="animalLookupForm" style="justify-content: space-between;">

    <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>Name</mat-label>
      <input type="text" matInput appTitlecase formControlName="name" autocomplete="off">
      <mat-error *ngIf="animalLookupForm.controls['name'].hasError('required')">
        Name is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>Species</mat-label>
      <mat-select formControlName="species">
        <mat-option [value]="''">Select</mat-option>
        <mat-option *ngFor="let species of speciesList" [value]="species">
          {{species}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="animalLookupForm.controls['species'].hasError('required')">
        Species is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>Primary Breed</mat-label>
      <mat-select formControlName="primary_breed">
        <mat-option [value]="''">Select</mat-option>
        <mat-option *ngFor="let breed of breedList" [value]="breed">
          {{breed}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="animalLookupForm.controls['primary_breed'].hasError('required')">
        Primary breed is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>Secondary Breed</mat-label>
      <mat-select formControlName="secondary_breed">
        <mat-option [value]="''">Select</mat-option>
        <mat-option *ngFor="let breed of breedList" [value]="breed">
          {{breed}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="animalLookupForm.controls['secondary_breed'].hasError('required')">
        Secondary breed is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>Primary Color</mat-label>
      <mat-select formControlName="primary_color">
        <mat-option [value]="''">Select</mat-option>
        <mat-option *ngFor="let primaryColor of primaryColorList" [value]="primaryColor.value">
          {{primaryColor.value}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="animalLookupForm.controls['primary_color'].hasError('required')">
        Primary color is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>Secondary Color</mat-label>
      <mat-select formControlName="secondary_color">
        <mat-option [value]="''">Select</mat-option>
        <mat-option *ngFor="let secondaryColor of secondaryColorList" [value]="secondaryColor.value">
          {{secondaryColor.value}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="animalLookupForm.controls['secondary_color'].hasError('required')">
        Secondary color is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>Pattern</mat-label>
      <mat-select formControlName="pattern">
        <mat-option [value]="''">Select</mat-option>
        <mat-option *ngFor="let pattern of patternList" [value]="pattern.value">
          {{pattern.value}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="animalLookupForm.controls['pattern'].hasError('required')">
        Pattern is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>Gender</mat-label>
      <mat-select formControlName="gender">
        <mat-option [value]="''">Select</mat-option>
        <mat-option *ngFor="let gender of genderList" [value]="gender">
          {{gender}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="animalLookupForm.controls['gender'].hasError('required')">
        Gender is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>Microchip Number</mat-label>
      <input type="text" matInput formControlName="microchip_number" autocomplete="off">
      <mat-error *ngIf="animalLookupForm.controls['microchip_number'].hasError('required')">
        Microchip number is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>Rabies Tag Number</mat-label>
      <input type="text" matInput formControlName="rabis_tag_number" autocomplete="off">
      <mat-error *ngIf="animalLookupForm.controls['rabis_tag_number'].hasError('required')">
        Rabies tag number is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>Village License Number</mat-label>
      <input type="text" matInput formControlName="village_license_number" autocomplete="off">
      <mat-error *ngIf="animalLookupForm.controls['village_license_number'].hasError('required')">
        Village license number is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>Age (months)</mat-label>
      <input type="text" matInput formControlName="age_months" autocomplete="off">
      <mat-error *ngIf="animalLookupForm.controls['age_months'].hasError('required')">
        Age is required
      </mat-error>
    </mat-form-field>

  </form>

  <div class="row mt-4" style="justify-content: flex-end;">
    <button mat-raised-button class="gray-button" [disabled]="isDisableButtons()" (click)="onRefreshClicked()"
      style="margin-right: 1em;">
      REFRESH
    </button>
    <button mat-raised-button class="blue-button" [disabled]="isDisableButtons()" (click)="onSearchClicked()">
      SEARCH
    </button>
    <button mat-raised-button class="green-button" *ngIf="!data.cantCreate" [disabled]="isDisableButtons()" (click)="onCreateNewClicked()">
      CREATE NEW
    </button>
  </div>

  <div class="row mt-4">
    <ag-grid-angular style="width: 100%; height: 230px;" class="ag-theme-alpine" [rowData]="rowData | async"
      [columnDefs]="columnDefs" [getRowHeight]="getRowHeight" [headerHeight]="headerHeight"
      [suppressRowClickSelection]="true" [defaultColDef]="defaultColDef" [rowSelection]="'single'"
      [frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button class="white-button" (click)="onNoClick()">CANCEL</button>
  <button *ngIf="!data.homeSearch" mat-raised-button class="green-button" (click)="onSelectClick()" cdkFocusInitial
    [disabled]="gridApi?.getSelectedRows().length<1">
    SELECT
  </button>
</div>