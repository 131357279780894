import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";

@Component({
  selector: 'app-ag-grid-custom-checkbox',
  templateUrl: './ag-grid-custom-checkbox.component.html',
  styleUrls: ['./ag-grid-custom-checkbox.component.scss']
})
export class AgGridCustomeCheckboxComponent implements ICellRendererAngularComp {

  public params: any;
  public data: any;
  public isSelectedVar = "isSelected";
  desableStatus: Boolean;
  isChecked = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.data = params.data;
    if (this.params.context && this.params.context.isSelectedVar) {
      this.isSelectedVar = this.params.context.isSelectedVar;
    }
    this.isSelectedVar = this.params.colDef.field;
    if (this.data.status === 'Fixed' || this.data.status === 'Deleted') {
      this.desableStatus = true
    } else {
      this.desableStatus = false
    }
  }

  refresh(params): boolean {
    if (this.params.context && this.params.context.isSelectedVar) {
      this.isSelectedVar = this.params.context.isSelectedVar;
    }
    const data = params.data;
    if (data.isSelected !== undefined) {
      this.data = data;
      return true;
    }
    return false;
  }

  isDisabled(): boolean {
    if (this.isSelectedVar.startsWith("multiplicity")) {
      const RE_M1 = /(multiplicity_)(\d)/;
      const RE_M_X = /(multiplicity_)(v_)(\d)(_\d+)/;
      let match = RE_M1.exec(this.isSelectedVar);
      if (match === null) {
        match = RE_M_X.exec(this.isSelectedVar);
      }
      if (match !== null) {
        const ruleIdx = match.length === 3 ? 2 : 3;
        let altFieldKey = "";
        for (let i = 0; i < match.length; i++) {
          if (i === ruleIdx) {
            altFieldKey += match[i] === "1" ? "2" : "1";
          } else {
            altFieldKey = match[i];
          }
        }
        return this.data[altFieldKey];
      }
    }
    return false;
  }

  onSelectChange(): void {
    const data = this.params.data;
    console.log(data);
    if (this.params.context.componentParent) {
      const parent = this.params.context.componentParent;
      let isSelected = this.data[this.isSelectedVar];
      const tier1Price = data.tier1_price && data.tier1_price > 0 ? true : false;
      const tier2Price = data.tier2_price && data.tier2_price > 0 ? true : false;
      const tier3Price = data.tier3_price && data.tier3_price > 0 ? true : false;
      if (tier1Price && tier2Price && tier3Price) {
        this.isChecked = true;
      } else {
          this.isChecked = false;
      }
      isSelected = this.isChecked;
      console.log(isSelected);
      
      parent[this.params.context.onSelectCb](this.data, isSelected, this.isSelectedVar);
    }
  }
}