import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, Observable } from 'rxjs';
import { UtilService } from 'src/app/core/services/util.service';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { GridApi, GridOptions } from 'ag-grid-community';
import { Utils } from 'src/app/core/utils/util';

export interface DialogData {
  account: any;
}

@Component({
  selector: 'app-account-details-dialog',
  templateUrl: './account-details-dialog.component.html',
  styleUrls: ['./account-details-dialog.component.scss']
})
export class AccountDetailsDialogComponent implements OnInit, OnDestroy {

  subs: Subscription[] = [];
  accountType: string;
  primaryContact: any[] = [];
  additonalContact: any[] = [];

  gridApi: GridApi;
  gridColumnApi: any;
  notesRowData: Observable<any>;
  notesColumnDefs: any[] = [];
  defaultColDef: any;
  public gridOptions = {
    context: {
      componentParent: this,
    },
    suppressHorizontalScroll: false,
    rowStyle: { background: 'white' }
  } as GridOptions;
  getRowHeight;
  headerHeight;
  overlayNoRowsTemplate: string;


  constructor(
    public dialogRef: MatDialogRef<AccountDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private utilService: UtilService,
    private accountService: AccountsService
  ) {
    this.getRowHeight = (params) => {
      return 34;
    }
    this.headerHeight = 34;
  }

  ngOnInit(): void {
    this.getDataFromMaster();
    this.setColumns();
    this.setupLinkedContact();
  }

  setupLinkedContact() {
    this.data.account?.contact_list.forEach(element => {
      if (element.is_primary) {
        this.primaryContact.push(element);
      } else {
        this.additonalContact.push(element);
      }
    });
  }

  getDataFromMaster() {
    let sub: Subscription = this.utilService.getMasterInputs('Account').subscribe(res => {
      res.input.account_type?.forEach(masterAccountType => {
        masterAccountType.id == this.data.account.account_type_id ? this.accountType = masterAccountType.value : '';
      });
    });
    this.subs.push(sub);
  }

  setColumns() {
    this.defaultColDef = Utils.getBaseThemeAgGridCol();
    this.overlayNoRowsTemplate = '<span style="padding: 10px; color: #929292">No notes to display</span>';

    this.notesColumnDefs = [
      {
        headerName: 'Date',
        field: 'modified_time',
        suppressSizeToFit: true,
        width: 138,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.dateFilterParams()
      },
      {
        headerName: 'Topic',
        field: 'note_name',
        suppressSizeToFit: true,
        width: 177,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'flex-start'
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Notes',
        field: 'notes',
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'User',
        field: 'user',
        suppressSizeToFit: true,
        width: 144,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.filterParams()
      }
    ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();

    let payload = {
      "entity": 'Account',
      "entity_id": this.data.account.account_id,
      "noteId": "",
      "noteName": "",
      "notes": "",
      "created_by": "",
      "modified_by": ""
    }
    this.notesRowData = this.utilService.getAllNotes(payload);
  }

  getAddress() {
    return `${this.concat(this.data.account.street1, ',')} ${this.concat(this.data.account.street2, ',')} ${this.concat(this.data.account.city, ',')} ${this.concat(this.data.account.county, ',')} ${this.concat(this.data.account.state, ',')} ${this.concat(this.data.account.zip_code, '')}`;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onAccountHeaderClicked() {
    this.dialogRef.close({ msg: 'redirect-to-account-page', data: this.data.account });
  }

  onContactClicked(contact) {
    let sub = this.accountService.getContactById(contact.contact_id).subscribe(res => {
      this.dialogRef.close({ msg: 'redirect-to-contact-edit', data: res });
    })
    this.subs.push(sub);
  }

  concat(data, append) {
    return data ? `${data}${append}` : '';
  }

  ngOnDestroy() {
    this.subs.forEach(ele => {
      ele.unsubscribe();
    })
  }
}
