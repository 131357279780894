import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { NONE_TYPE } from '@angular/compiler';
import { Animal } from 'src/app/core/models/animal';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnimalService } from 'src/app/core/services/animal.service';
import { MedicalService } from 'src/app/core/services/medical.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { GridApi, GridOptions } from 'ag-grid-community';
import { Observable } from 'rxjs';
import * as moment from 'moment';

export interface VitalChartDialogData {
  animal: Animal;
  //event_data;
  eventId;
  serviceId?;
  isFromServices?;
  isFromME?;
  meData?;
}

@Component({
  selector: 'app-vital-chart-dialog',
  templateUrl: './vital-chart-dialog.component.html',
  styleUrls: ['./vital-chart-dialog.component.scss']
})

export class VitalChartDialogComponent implements OnInit {
  vitalChartForm: FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  animalName: string = "";
  animalId: number = 0;
  checkTodayData: boolean = false;
  currentID: number = 0;
  currentDate: string;
  lineChartPlugins = [];
  lineChartType = 'line';
  getRowHeight: any;
  headerHeight: any;
  lineChartData: ChartDataSets[] = [];
  lineChartLabels: Label[] = [];

  /**** Dynamic ag grid ***/
  /*
   vitalTableContent:any[]=[];
   getVitalTableData:any[]=[];
   getTableHeaderNames:any[]=[];*/
  /**** */
  dateHeaders: any[] = [];
  bodyWeight: any[] = [];
  respRate: any[] = [];
  Temperature: any[] = [];
  pulse: any[] = [];
  //body_weight_ounces: any[] = [];
  /**** */

  gridApi: GridApi;
  eventHistoryGridApi: GridApi;
  gridColumnApi: any;
  public gridOptions = {
    context: {
      componentParent: this,
    },
    suppressHorizontalScroll: false,
    rowStyle: { background: 'white' },
  } as GridOptions;

  lineChartOptions = {
    responsive: true,
    elements: { line: { fill: false, tension: 0 } },
    scales: {
      xAxes: [{
        fontFamily: 'HELVETICA_NEUE_LIGHT',
        gridLines: {
          display: true,
          drawOnChartArea: false,
          tickMarkLength: 5,
        },
        ticks: {
          display: true,
          fontColor: '#929292',
          fontFamily: 'HELVETICA_NEUE_LIGHT',
          padding: 2
        },
        offset: true,

      }],
      yAxes: [{
        fontFamily: 'HELVETICA_NEUE_LIGHT',
        offset: true,
        ticks: {
          fontColor: '#929292',
          fontFamily: 'HELVETICA_NEUE_LIGHT',
          padding: 5
        },
        gridLines: {
          // drawBorder: false,
          tickMarkLength: 5,
          //drawTicks:false
        }
      }],
    },
    legend: {
      display: true,
      position: 'right',
      labels: {
        fontColor: "#929292",
        fontFamily: 'HELVETICA_NEUE_LIGHT',
        usePointStyle: true,
        fillStyle: 'transparent',
        padding: 15
      },
    },
  };

  // Define colors of chart segments
  lineChartColors: Color[] =
    [
      {
        borderCapStyle: 'butt',
        borderWidth: 2,
        borderColor: '#85e3ff',
        pointRadius: 2,
        // pointStyle:'line'
      },
      {
        borderCapStyle: 'butt',
        borderWidth: 2,
        borderColor: '#bbaed2 ',
        pointRadius: 2,
        // pointStyle:'line'
      },
      {
        borderCapStyle: 'butt',
        borderWidth: 2,
        borderColor: '#ff9aa2',
        pointRadius: 2,
        //pointStyle:'line'
      },
      {
        borderCapStyle: 'butt',
        borderWidth: 2,
        borderColor: 'b5ead7',
        pointRadius: 2,
        // pointStyle:'line'
      }
    ];

  isFromServices = false;
  isFromME = false;
  constructor(
    private fb: FormBuilder,
    private animalService: AnimalService,
    public dialogRef: MatDialogRef<VitalChartDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VitalChartDialogData,
    private _snackBar: MatSnackBar,
    private medicalService: MedicalService
  ) {

    this.getRowHeight = (params) => {
      return 34;
    }
    this.headerHeight = 34;
    //this.currentDate = moment(this.data.event_data.get('event_date').value).format('MM/DD/YYYY');
  }

  ngOnInit(): void {
    this.animalName = this.data.animal.name;
    this.animalId = this.data.animal.animal_id;
    this.isFromServices = this.data && this.data.isFromServices;
    this.isFromServices = this.isFromServices ? this.isFromServices : false;
    this.isFromME = this.isFromME ? this.isFromME : false;
    this.initVitalChartForm();
    if (this.isFromServices || this.isFromME) {
      this.getVitalDataForServices();
    } else {
      this.getAnimalVitalData();
    }
  }

  initVitalChartForm() {
    this.vitalChartForm = this.fb.group({
      body_weight: ['', [Validators.pattern("^[0-9]*\.?[0-9]*$")]],
      body_weight_ounces: ['', [Validators.pattern("^[0-9]*\.?[0-9]*$"),Validators.max(15)]],
      temperature: ['', [Validators.pattern("^[0-9]*\.?[0-9]*$")]],
      resp_rate: ['', [Validators.pattern("^[0-9]*$")]],
      pulse: ['', [Validators.pattern("^[0-9]*$")]]
    });
  }

  onSaveClicked() {
    if((this.vitalChartForm.get('body_weight').value==0) && (this.vitalChartForm.get('body_weight_ounces').value==0)){
      this._snackBar.open("Body weight should not be empty", 'Ok', {
        duration: 2000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['light-danger']
      });
      return;
    }

    let paylod = {
      // event_id:this.data.eventId,
      animal_id: this.animalId,
      body_weight: (this.vitalChartForm.controls['body_weight'].value) ? (this.vitalChartForm.controls['body_weight'].value) : 0,
      body_weight_ounces:this.vitalChartForm.get('body_weight_ounces').value ? this.vitalChartForm.get('body_weight_ounces').value : 0,
      temperature: this.vitalChartForm.controls['temperature'].value ? parseFloat(this.vitalChartForm.controls['temperature'].value).toFixed(1) : '',
      resp_rate: this.vitalChartForm.controls['resp_rate'].value,
      pulse: this.vitalChartForm.controls['pulse'].value,
      //reading_date: this.currentDate,     
    }

    if (this.data.eventId)
      paylod['event_id'] = this.data.eventId;
    else if (this.data.serviceId)
      paylod['service_id'] = this.data.serviceId;

    if (this.vitalChartForm.valid) {
      this.dialogRef.close(paylod);
      if (this.checkTodayData) paylod['id'] = this.currentID;
    } else
      this.vitalChartForm.markAllAsTouched();

    /*this.animalService.createAnimalVital(paylod).subscribe(res => {
            this._snackBar.open(successMesssage, 'Ok', {
              duration: 2000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['light-snackbar']
            });
      
      this.dialogRef.close(paylod);
    })*/

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getVitalDataForServices() {
    this.animalService.getAnimalVitalById(this.animalId).subscribe((res: any) => {
      const meData = this.data && this.data.meData;
      res.sort(function (a, b) {
        var c = new Date(a.reading_date).getDate();
        var d = new Date(b.reading_date).getDate();
        return c < d ? 1 : -1;;
      });
      let date: any[] = [];
      let body_weight: any[] = [];
      let temperature: any[] = [];
      let pulse: any[] = [];
      let resp_rate: any[] = [];
      if (res.length > 0) {
        for (let i = 0; i < 10; i++) {
          if (res[i]) {
            if ((res[i].event_id == meData.id)) {
              this.checkTodayData = true;
              this.currentID = res[i].id;
              this.vitalChartForm.patchValue({
                body_weight: (res[i].body_weight),
                temperature: res[i].temperature ? res[i].temperature.toFixed(1) : '',
                pulse: res[i].pulse,
                resp_rate: res[i].resp_rate,
              });
            }
            date[i] = moment(res[i].reading_date).format("DD-MMM");
            body_weight[i] = parseInt((res[i].body_weight))+((res[i].body_weight_ounces) ? ((res[i].body_weight_ounces)/16) : 0);
            temperature[i] = res[i].temperature ? res[i].temperature.toFixed(1) : '';
            pulse[i] = res[i].pulse;
            resp_rate[i] = res[i].resp_rate;
          }
        }
      }

      this.dateHeaders = date;
      this.respRate = resp_rate;
      this.pulse = pulse;
      this.bodyWeight = body_weight;
      this.Temperature = temperature;

      this.lineChartData.push({ 'data': body_weight, label: 'Body Weight' },
        { 'data': temperature, label: 'Temperature' },
        { 'data': resp_rate, label: 'Resipiratory' },
        { 'data': pulse, label: 'Pulse' }
      );

      this.lineChartLabels = (date);
    });
  }

  getAnimalVitalData() {
    if (this.data.eventId) {
      this.animalService.getAnimalVitalById(this.animalId).subscribe(res => {
        res.sort(function (a, b) {
          var c = new Date(a.reading_date).getDate();
          var d = new Date(b.reading_date).getDate();
          return c < d ? 1 : -1;;
        });
        let date: any[] = [];
        let body_weight: any[] = [];
        let temperature: any[] = [];
        let pulse: any[] = [];
        let resp_rate: any[] = [];
        if (res.length > 0) {
          for (let i = 0; i < 10; i++) {
            if (res[i]) {
              if ((res[i].event_id == this.data.eventId)) {
                this.checkTodayData = true;
                this.currentID = res[i].id;
                this.vitalChartForm.patchValue({
                  body_weight: (res[i].body_weight),
                  body_weight_ounces: (res[i].body_weight_ounces),
                  temperature: res[i].temperature ? res[i].temperature.toFixed(1) : 0,
                  pulse: res[i].pulse,
                  resp_rate: res[i].resp_rate,
                });
              }
              date[i] = moment(res[i].reading_date).format("DD-MMM");
              body_weight[i] = parseInt((res[i].body_weight))+((res[i].body_weight_ounces) ? ((res[i].body_weight_ounces)/16) : 0);
             // body_weight_ounces[i] = (res[i].body_weight_ounces);
              temperature[i] = res[i].temperature ? res[i].temperature.toFixed(1) : 0;
              pulse[i] = res[i].pulse;
              resp_rate[i] = res[i].resp_rate;
            }
          }
        }

        this.dateHeaders = date;
        this.respRate = resp_rate;
        this.pulse = pulse;
        this.bodyWeight = body_weight;
        this.Temperature = temperature;

        this.lineChartData.push({ 'data': body_weight, label: 'Body Weight' },
          { 'data': temperature, label: 'Temperature' },
          { 'data': resp_rate, label: 'Resipiratory' },
          { 'data': pulse, label: 'Pulse' }
        );

        this.lineChartLabels = (date);
      });
    } else if (this.data.serviceId) {
      this.medicalService.getVitalForAnimal(this.animalId).subscribe(res => {
        res.sort(function (a, b) {
          var c = new Date(a.reading_date).getDate();
          var d = new Date(b.reading_date).getDate();
          return c < d ? 1 : -1;;
        });
        let date: any[] = [];
        let body_weight: any[] = [];
        let temperature: any[] = [];
        let pulse: any[] = [];
        let resp_rate: any[] = [];
        if (res.length > 0) {
          for (let i = 0; i < 10; i++) {
            if (res[i]) {
              if ((res[i].service_id == this.data.serviceId)) {
                this.checkTodayData = true;
                this.currentID = res[i].id;
                this.vitalChartForm.patchValue({
                  body_weight: (res[i].body_weight),
                  body_weight_ounces: (res[i].body_weight_ounces),
                  temperature: res[i].temperature ? res[i].temperature.toFixed(1) : 0,
                  pulse: res[i].pulse,
                  resp_rate: res[i].resp_rate,
                });
              }
              date[i] = moment(res[i].reading_date).format("DD-MMM");
              body_weight[i] = parseInt((res[i].body_weight))+((res[i].body_weight_ounces) ? ((res[i].body_weight_ounces)/16) : 0);
              temperature[i] = res[i].temperature ? res[i].temperature.toFixed(1) : 0;
              pulse[i] = res[i].pulse;
              resp_rate[i] = res[i].resp_rate;
            }
          }
        }

        this.dateHeaders = date;
        this.respRate = resp_rate;
        this.pulse = pulse;
        this.bodyWeight = body_weight;
        this.Temperature = temperature;

        this.lineChartData.push({ 'data': body_weight, label: 'Body Weight' },
          { 'data': temperature, label: 'Temperature' },
          { 'data': resp_rate, label: 'Resipiratory' },
          { 'data': pulse, label: 'Pulse' }
        );

        this.lineChartLabels = (date);

      });
    }

  }
  /**** Dynamic ag grid ***/

  onFocusOutEvent(event, inputName) {
    if (!isNaN(event.target.value) && event.target.value!='') {
      if ((event.target.value) && inputName == "temperature")
        this.vitalChartForm.get('temperature').setValue(parseFloat(event.target.value).toFixed(1));
    } else if (((event.target.value).match(/\d+/g)) != null) {
     if ((event.target.value) && (inputName == "body_weight" || inputName == "body_weight_ounces"))
        this.vitalChartForm.controls[inputName].setValue(event.target.value.replace(/[^0-9.]/g, 0));
      else if ((event.target.value) && inputName == "temperature")
        this.vitalChartForm.get('temperature').setValue(event.target.value.replace(/[^0-9.]/g, ''));
    }else if ((inputName == "body_weight" || inputName == "body_weight_ounces")){
      this.vitalChartForm.controls[inputName].setValue(0);
    }
    
  }
  /*getTableHeader(){
    var x = 0 ;
    for (let key1 in this.getVitalTableData) {
      if (this.getVitalTableData.hasOwnProperty(key1)) {
          var val = this.getVitalTableData[key1];
         if(key1=="0"){
          this.getTableHeaderNames[0] = {'headerName':'Date','sortable': true, 'filter': true,'field':'date'};
            for (let index = 0; index < val.date.length; index++) {
              this.getTableHeaderNames.push({'headerName':val.date[index],'sortable': true, 'filter': true,'field':val.date[index]});
              }
            }
            else{
              var row = [];
              for(var key2 in val){
                row[this.getTableHeaderNames[0].field] = key2;
                for (let index = 0; index < val[key2].length; index++) {  
                  row[this.getTableHeaderNames[index+1].field] = val[key2][index];
                }
                this.vitalTableContent.push(row);
              }
            }
          }
        }       
  }*/
}