import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { TileComponent } from './components/tile/tile.component';
import { AnimalTileComponent } from './components/animal-tile/animal-tile.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChipComponent } from './components/chip/chip.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgbPaginationModule, NgbAlertModule, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { TileActionComponent } from './components/tile-action/tile-action.component'
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { AgGridCheckboxComponent } from './components/ag-grid-checkbox/ag-grid-checkbox.component';
import { AgGridCustomeCheckboxComponent } from './components/ag-grid-custom-checkbox/ag-grid-custom-checkbox.component';
import { AgGridClickableCellComponent } from './components/ag-grid-clickable-cell/ag-grid-clickable-cell.component'
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { NotesComponent } from './components/notes/notes.component';
import { CoreModule } from '../core/core.module';
import { AddEditNoteDialogComponent } from './components/add-edit-note-dialog/add-edit-note-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TickMarkComponent } from './components/tick-mark/tick-mark.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AgGridClickableSegmentedComponent } from './components/ag-grid-clickable-segmented/ag-grid-clickable-segmented.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { ContactLookupComponent } from './components/contact-lookup/contact-lookup.component';
import { AnimalLookupComponent } from './components/animal-lookup/animal-lookup.component';
import { AccountDetailsDialogComponent } from './components/account-details-dialog/account-details-dialog.component';
import { ContactDetailsDialogComponent } from './components/contact-details-dialog/contact-details-dialog.component';
import { AgencyLookupComponent } from './components/agency-lookup/agency-lookup.component';
import { StagesComponent } from './components/stages/stages.component';
import { AnimalDetailsDialogComponent } from './components/animal-details-dialog/animal-details-dialog.component';
import { PdfViewerDialogComponent } from './components/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { SafeUrlPipe } from './safe-url.pipe';
import { AgGridAddressCellComponent } from './components/ag-grid-address-cell/ag-grid-address-cell.component';
import { ContactLookupDialogComponent } from './components/contact-lookup-dialog/contact-lookup-dialog.component';
import { AnimalLookupDialogComponent } from './components/animal-lookup-dialog/animal-lookup-dialog.component';
import { AgencyDetailsDialogComponent } from './components/agency-details-dialog/agency-details-dialog.component';
import { AgencyLookupDialogComponent } from './components/agency-lookup-dialog/agency-lookup-dialog.component';
import { TitlecaseDirective } from './directives/titlecase.directive';
import { AgGridMultipleCheckboxComponent } from './components/ag-grid-multiple-checkbox/ag-grid-multiple-checkbox.component';
import { AnimalDetailsToggleButtonComponent } from "./components/animal-details-toggle-button/animal-details-toggle-button.component";
import { CurrencyInputDirective } from './directives/currency-input.directive';
import { CasePermissionDialogComponent } from './components/case-permission-dialog/case-permission-dialog.component';
import { ConsentDialogComponent } from './dialogs/consent-dialog/consent-dialog.component';
import { DeleteAccordianDialogComponent } from './components/delete-accordian-dialog/delete-accordian-dialog.component';
import { DeleteItemDialogComponent } from './components/delete-item-dialog/delete-item-dialog.component';
import { VitalChartDialogComponent } from './dialogs/vital-chart-dialog/vital-chart-dialog.component';
import { AnimalServicesDialogComponent } from './dialogs/animal-services-dialog/animal-services-dialog.component';
import { AddEditMedicationDialogComponent } from './dialogs/add-edit-medication-dialog/add-edit-medication-dialog.component';
import { ChartsModule } from 'ng2-charts';
import { AnimalMedicalConditionDialogComponent } from './dialogs/animal-medical-condition-dialog/animal-medical-condition-dialog.component';
import { LocationCageDialogComponent } from './components/location-cage-dialog/location-cage-dialog.component';
import { DateFormatterDirective } from './directives/date-formatter.directive';
import { DateValidatorDirective } from './directives/date-validator.directive';
import { MatSliderModule } from '@angular/material/slider';
import { VetLookupDialogComponent } from './dialogs/vet-lookup-dialog/vet-lookup-dialog.component';
import { AnimalImageViewerDialogComponent } from './dialogs/animal-medical-condition-dialog/animal-image-viewer-dialog/animal-image-viewer-dialog.component';
import { TreeviewModule } from 'ngx-treeview';
import { AddEditVaccinationDialogComponent } from './dialogs/add-edit-vaccination-dialog/add-edit-vaccination-dialog.component';
import { SelectListDataDialogComponent } from './dialogs/select-list-data-dialog/select-list-data-dialog.component';
import { ReportsComponent } from './components/reports/reports.component';
import { AnimalFoodComponent } from './routine-dialogs/animal-food/animal-food.component';
import { AnimalActivityComponent } from './routine-dialogs/animal-activity/animal-activity.component';
import { AnimalTrainingComponent } from './routine-dialogs/animal-training/animal-training.component';
import { AnimalAllocationComponent } from './resource-planning-dialogs/animal-allocation/animal-allocation.component';
import { ResourceAnimalDialogsComponent } from './components/resource-animal-dialogs/resource-animal-dialogs.component';
import { AnimalCareItemsDialogComponent } from './components/animal-care-items-dialog/animal-care-items-dialog.component';
import { AnimalCareCheckboxComponent } from './dialogs/animal-care-checkbox/animal-care-checkbox.component';
import { AddEditMicrochipInjectionComponent } from './dialogs/add-edit-microchip-injection/add-edit-microchip-injection.component';
import { AgGridCustomTimeFilterComponent } from './components/ag-grid-custom-time-filter/ag-grid-custom-time-filter.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AssignAnimalParentComponent } from './components/assign-animal-parent/assign-animal-parent.component';
import { NgxEventCalendarModule } from 'ngx-event-calendar';
import { AgGridCustomClickableCellComponent } from './components/ag-grid-custom-clickable-cell/ag-grid-custom-clickable-cell.component';
import { AgGridClickableImagesComponent } from './components/ag-grid-clickable-images/ag-grid-clickable-images.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';

@NgModule({
  declarations: [
    SafeUrlPipe,
    TileComponent,
    TickMarkComponent,
    AnimalTileComponent,
    ChipComponent,
    SearchbarComponent,
    TileActionComponent,
    AgGridCheckboxComponent,
    AgGridCustomeCheckboxComponent,
    AgGridClickableCellComponent,
    NotesComponent,
    AddEditNoteDialogComponent,
    AgGridClickableSegmentedComponent,
    ToggleButtonComponent,
    ContactLookupComponent,
    AnimalLookupComponent,
    AccountDetailsDialogComponent,
    ContactDetailsDialogComponent,
    AgencyLookupComponent,
    StagesComponent,
    AnimalDetailsDialogComponent,
    PdfViewerDialogComponent,
    AgGridAddressCellComponent,
    ContactLookupDialogComponent,
    AnimalLookupDialogComponent,
    AgencyDetailsDialogComponent,
    AgencyLookupDialogComponent,
    AgGridMultipleCheckboxComponent,
    TitlecaseDirective,
    AgGridMultipleCheckboxComponent,
    AnimalDetailsToggleButtonComponent,
    CurrencyInputDirective,
    CasePermissionDialogComponent,
    ConsentDialogComponent,
    DeleteAccordianDialogComponent,
    DeleteItemDialogComponent,
    VitalChartDialogComponent,
    AnimalServicesDialogComponent,
    AddEditMedicationDialogComponent,
    AnimalMedicalConditionDialogComponent,
    LocationCageDialogComponent,
    VetLookupDialogComponent,
    DateFormatterDirective,
    DateValidatorDirective,
    AnimalImageViewerDialogComponent,
    AddEditVaccinationDialogComponent,
    SelectListDataDialogComponent,
    ReportsComponent,
    AnimalFoodComponent,
    AnimalActivityComponent,
    AnimalTrainingComponent,
    AnimalAllocationComponent,
    ResourceAnimalDialogsComponent,
    AnimalCareItemsDialogComponent,
    AnimalCareCheckboxComponent,
    AddEditMicrochipInjectionComponent,
    AgGridCustomTimeFilterComponent,
    AssignAnimalParentComponent,
    AgGridCustomClickableCellComponent,
    AgGridClickableImagesComponent,
    ImageViewerComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatChipsModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgbAccordionModule,
    AgGridModule.withComponents([]),
    MatMenuModule,
    MatSnackBarModule,
    MatTooltipModule,
    NgxExtendedPdfViewerModule,
    ChartsModule,
    MatSliderModule,
    NgxSpinnerModule,
    TreeviewModule.forRoot(),
  ],
  exports: [
    SafeUrlPipe,
    AnimalTileComponent,
    TileComponent,
    TickMarkComponent,
    TileActionComponent,
    SearchbarComponent,
    ChipComponent,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatExpansionModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatDialogModule,
    AgGridCheckboxComponent,
    AgGridCustomeCheckboxComponent,
    AgGridClickableCellComponent,
    AgGridClickableSegmentedComponent,
    AgGridAddressCellComponent,
    MatMenuModule,
    NotesComponent,
    MatSnackBarModule,
    MatTooltipModule,
    ToggleButtonComponent,
    ContactLookupComponent,
    AnimalLookupComponent,
    AccountDetailsDialogComponent,
    ContactDetailsDialogComponent,
    AnimalDetailsDialogComponent,
    NgbPaginationModule,
    NgbAlertModule,
    NgbAccordionModule,
    AgencyLookupComponent,
    StagesComponent,
    PdfViewerDialogComponent,
    NgxExtendedPdfViewerModule,
    ContactLookupDialogComponent,
    AnimalLookupDialogComponent,
    AgencyDetailsDialogComponent,
    AgencyLookupDialogComponent,
    TitlecaseDirective,
    AnimalDetailsToggleButtonComponent,
    CurrencyInputDirective,
    DateFormatterDirective,
    DateValidatorDirective,
    ConsentDialogComponent,
    VitalChartDialogComponent,
    AnimalServicesDialogComponent,
    VetLookupDialogComponent,
    ChartsModule,
    AnimalMedicalConditionDialogComponent,
    AddEditVaccinationDialogComponent,
    AddEditMedicationDialogComponent,
    SelectListDataDialogComponent,
    TreeviewModule,
    NgxSpinnerModule
  ],
  entryComponents: [
    AddEditNoteDialogComponent,
    AccountDetailsDialogComponent,
    ContactDetailsDialogComponent,
    AnimalDetailsDialogComponent,
    PdfViewerDialogComponent,
    ContactLookupDialogComponent,
    AnimalLookupDialogComponent,
    AgencyDetailsDialogComponent,
    AgencyLookupDialogComponent,
    ConsentDialogComponent,
    VitalChartDialogComponent,
    AnimalServicesDialogComponent,
    VetLookupDialogComponent,
    AnimalMedicalConditionDialogComponent,
    AnimalImageViewerDialogComponent,
    AddEditVaccinationDialogComponent,
    AddEditMedicationDialogComponent,
    SelectListDataDialogComponent
  ],
  providers: [
    TitleCasePipe
  ]
})
export class SharedModule { }
