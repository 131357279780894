import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-stages',
  templateUrl: './stages.component.html',
  styleUrls: ['./stages.component.scss']
})
export class StagesComponent implements OnInit {
  @Input('status') status: string;//
  @Input('isEmpty') isEmpty: string;
  @Input('date_stamped') date_stamped: string;
  @Output('actionOutput') actionOutput: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  onEventClick(e) {
    this.actionOutput.emit([this.status,this.date_stamped]);
  }
}
