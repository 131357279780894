<div mat-dialog-title>
  <span *ngIf="data.title">{{data.title}}</span>
  <button mat-icon-button>
    <mat-icon (click)="onNoClick()">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="container" style="height: 36em; padding: 0;">
    <ngx-extended-pdf-viewer [src]="data.pdf" useBrowserLocale="true" height="500px" [showBookmarkButton]=false
      [showRotateButton]=false [showPagingButtons]=false [showOpenFileButton]=false [showSecondaryToolbarButton]=false
      [showSpreadButton]=false [showSidebarButton]=false>
    </ngx-extended-pdf-viewer>
  </div>
</div>

<div mat-dialog-actions>

</div>