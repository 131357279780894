<div mat-dialog-title>
  <div>
    <span>Account:</span>
    <span class="link" (click)="onAccountHeaderClicked()">{{this.data.account?.account_name}}</span>
  </div>

  <span>ID: {{this.data.account?.account_id}}</span>

  <button mat-icon-button>
    <mat-icon (click)="onNoClick()">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="row">

    <div class="col-8">
      <div class="row">

        <div class="label-container small">
          <span>Account Type</span>
          <span>{{accountType}}</span>
        </div>

        <div class="label-check-container">
          <mat-checkbox [checked]="this.data.account?.do_not_mail" [disabled]="true">
            Do Not Mail
          </mat-checkbox>

          <mat-checkbox [checked]="this.data.account?.do_not_call" [disabled]="true">
            Do Not Call
          </mat-checkbox>
        </div>

        <div class="label-container small">
          <span>Mailing Name</span>
          <span>{{this.data.account?.mailing_name}}</span>
        </div>

        <div class="label-container small">
          <span>Mailing Address</span>
          <span>{{getAddress()}}</span>
        </div>

        <div class="label-container small">
          <span>Phone</span>
          <span>{{this.data.account?.phone}}</span>
        </div>

        <div class="label-container small">
          <span>Email</span>
          <span>{{this.data.account?.email}}</span>
        </div>

      </div>
    </div>

    <div class="col" style="padding-left: 3em;">
      <div class="row">

        <div class="label-container long">
          <span>Primary Contact</span>
          <span class="link" *ngFor="let pContact of primaryContact" (click)="onContactClicked(pContact)">
            {{pContact.first_name}} {{pContact.last_name}}
          </span>
        </div>

        <div class="label-container long">
          <span>Additional Contact</span>
          <span class="link" *ngFor="let aContact of additonalContact" (click)="onContactClicked(aContact)">
            {{aContact.first_name}} {{aContact.last_name}}
          </span>
        </div>

      </div>
    </div>

  </div>

  <div class="row">
    <div class="label-container long">
      <span>Notes</span>
      <ag-grid-angular style="width: 100%; height: 200px; margin-top: .5em;" class="ag-theme-alpine" [rowData]="notesRowData | async"
        [columnDefs]="notesColumnDefs" [defaultColDef]="defaultColDef" [getRowHeight]="getRowHeight"
        [headerHeight]="headerHeight" [gridOptions]="gridOptions" [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>

</div>


<div mat-dialog-actions>
  <!-- <button mat-button (click)="onNoClick()">No Thanks</button>
    <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button> -->
</div>