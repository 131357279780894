<div mat-dialog-title>
    <div>
      <span>Assign Parent - </span>
    </div>
    <button mat-icon-button>
      <mat-icon (click)="onNoClick()">close</mat-icon>
    </button>
  
  </div>
  <div mat-dialog-content style="overflow: hidden">
    <ag-grid-angular (gridReady)="onAllBulkParentMapGridReady($event)" [getRowHeight]="getRowHeight" (rowSelected)="onRowSelected($event)" 
                        [headerHeight]="headerHeight" [columnDefs]="bulkParentMapColumnDefs" [rowMultiSelectWithClick]="true" [gridOptions]="gridOptions"
                        [rowSelection]="'single'" [rowData]="bulkParentMapRowData" [defaultColDef]="defaultColDef"
                        [frameworkComponents]="frameworkComponents" class="ag-theme-alpine" style="width: 100%; height: 330px;">
        </ag-grid-angular>
  </div>
    
  <div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()">CANCEL</button>
   
    <button mat-raised-button color="accent" (click)="onSaveClick()">SELECT</button>
  </div>