<div mat-dialog-title class=m-0>
  <div>
    <span>Condition</span>
  </div>
  <button mat-icon-button>
    <mat-icon (click)="onNoClick()">close</mat-icon>
  </button>
</div>
<div mat-dialog-header>
  <div class="container my-2">

    <div class="row">
      <div [ngStyle]="{'border': conditionScore > 3 ? '1px solid #86c788' : '1px solid #ff948b'}"
        class="col-2 over-all-score">
        <h3 [ngStyle]="{'color': conditionScore > 3 ? '#86c788' : '#ff948b'}" class="w-100 text-center large-text">
          {{getScore()}}</h3>
        <p style="text-transform: uppercase; color: #929292;" class="w-100 my-1 text-center">
          <span *ngIf="hasPreviousCondition">0</span>
        </p>
        <span style="text-transform: uppercase;" [ngStyle]="{'color': conditionScore > 3 ? '#86c788' : '#ff948b'}"
          class="w-100 mt-1 text-center">{{getTag()}}</span>
      </div>
      <div class="col-10">
        <div class="row p-0">
          <div class="col-2"></div>
          <div class="titleValueContainer text-center">
            <span class="title-value">BODY</span>
          </div>
          <div class="titleValueContainer text-center">
            <span class="title-value">NOSE</span>
          </div>
          <div class="titleValueContainer text-center">
            <span class="title-value">EARS</span>
          </div>
          <div class="titleValueContainer text-center">
            <span class="title-value">EYES</span>
          </div>
          <div class="titleValueContainer text-center">
            <span class="title-value">TEETH</span>
          </div>
          <div class="titleValueContainer text-center p-0">
            <span class="title-value">GENITALS</span>
          </div>
          <div class="titleValueContainer text-center">
            <span class="title-value">SKIN</span>
          </div>
        </div>
        <div class="row my-2 p-0">
          <div class="col-2 text-right">
            <span class="left-heading">CURRENT</span>
          </div>
          <div class="conditionParamContainer text-center">
            <span class="condition-param" [ngStyle]="{'color': body > 3 ? '#86c788' : '#ff948b'}"
              [ngClass]="(body > 3) ? 'good' : 'warning'">{{body}}</span>
          </div>
          <div class="conditionParamContainer text-center">
            <span class="condition-param" [ngStyle]="{'color': nose > 3 ? '#86c788' : '#ff948b'}"
              [ngClass]="(nose > 3) ? 'good' : 'warning'">{{nose}}</span>
          </div>
          <div class="conditionParamContainer text-center">
            <span class="condition-param" [ngStyle]="{'color': ears > 3 ? '#86c788' : '#ff948b'}"
              [ngClass]="(ears > 3) ? 'good' : 'warning'">{{ears}}</span>
          </div>
          <div class="conditionParamContainer text-center">
            <span class="condition-param" [ngStyle]="{'color': eyes > 3 ? '#86c788' : '#ff948b'}"
              [ngClass]="(eyes > 3) ? 'good' : 'warning'">{{eyes}}</span>
          </div>
          <div class="conditionParamContainer text-center">
            <span class="condition-param" [ngStyle]="{'color': teeth > 3 ? '#86c788' : '#ff948b'}"
              [ngClass]="(teeth > 3) ? 'good' : 'warning'">{{teeth}}</span>
          </div>
          <div class="conditionParamContainer text-center p-0">
            <span class="condition-param" [ngStyle]="{'color': genitals > 3 ? '#86c788' : '#ff948b'}"
              [ngClass]="(genitals > 3) ? 'good' : 'warning'">{{genitals}}</span>
          </div>
          <div class="conditionParamContainer text-center">
            <span class="condition-param" [ngStyle]="{'color': skin > 3 ? '#86c788' : '#ff948b'}"
              [ngClass]="(skin > 3) ? 'good' : 'warning'">{{skin}}</span>
          </div>
        </div>

        <!-- Previous Condition Data -->
        <div *ngIf="hasPreviousCondition" class="row p-0">
          <div class="col-2 text-right">
            <span class="left-heading">PREVIOUS</span>
          </div>
          <div class="conditionParamContainer text-center">
            <span class="disabled-value">5</span>
          </div>
          <div class="conditionParamContainer text-center">
            <span class="disabled-value">5</span>
          </div>
          <div class="conditionParamContainer text-center">
            <span class="disabled-value">5</span>
          </div>
          <div class="conditionParamContainer text-center">
            <span class="disabled-value">5</span>
          </div>
          <div class="conditionParamContainer text-center">
            <span class="disabled-value">5</span>
          </div>
          <div class="conditionParamContainer text-center p-0">
            <span class="disabled-value">5</span>
          </div>
          <div class="conditionParamContainer text-center">
            <span class="disabled-value">5</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div mat-dialog-content class="contents mt-3 px-0">

  <div class="container text-center my-3">
    <div class="row row-title">
      <div class="col-md-2 text-left">
        <span class="conditionTitle">BODY</span>
      </div>
      <div class="col-md-4 text-left">
        <!-- <span class="showImageCheckbox" style="color: #86c788;">
          <section class="check-section">
            <mat-checkbox (change)="showBodyImages = !showBodyImages" class="remember">
              Show Sample Images
            </mat-checkbox>
          </section>
        </span> -->
      </div>
    </div>
    <div class="row ml-0">
      <div class="col-12">
        <div class="row">
          <div class="col-12 text-left pl-0">
            <mat-slider min="1" max="5" invert="invert" [value]="data.selectedConditionData!=null ? data.selectedConditionData.body : 5" class="w-80 pt-0 condition-slider" color="primary"
              (change)="onBodySliderChange($event)" step="1" tickInterval="1"></mat-slider>
          </div>
        </div>
        <div class="row condition-value-container">
          <div class="conditionTextContainer">
            <span class="conditionValue">
              5
            </span>
            <span class="description-text">Ideal</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              4
            </span>
            <span class="description-text">Slightly Overweight <br>OR<br> Slightly Underweight</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              3
            </span>
            <span class="description-text">Overweight <br>OR<br> Underweight</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              2
            </span>
            <span class="description-text">Fat <br>OR<br> Thin</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              1
            </span>
            <span class="description-text">Obese <br>OR<br> Very Thin</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container text-center my-3">
    <div class="row row-title">
      <div class="col-md-2 text-left">
        <span class="conditionTitle">NOSE</span>
      </div>
      <div class="col-md-4 text-left">
        <span class="showImageCheckbox" style="color: #86c788;">
          <section class="check-section">
            <mat-checkbox (change)="showNoseImages = !showNoseImages"
              class="w-80 remember">
              Show Sample Images
            </mat-checkbox>
          </section>
        </span>
      </div>
    </div>
    <div class="row ml-0">
      <div class="col-12">
        <div class="row">
          <div class="col-12 text-left pl-0">
            <mat-slider min="1" max="5" invert="invert" [value]="data.selectedConditionData!=null ? data.selectedConditionData.nose : 5" color="primary" (change)="onNoseSliderChange($event)"
              class="w-80 pt-0" step="1" tickInterval="1"></mat-slider>
          </div>
        </div>
        <div class="row condition-value-container">
          <div class="conditionTextContainer">
            <span class="conditionValue">
              5
            </span>
            <span class="description-text">No Nose Wounds, <br> Obstruction,
              Discharge <br>Or Sneezing</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              4
            </span>
            <span class="description-text">Mild Sneezing with minimal <br>Discharge, or Clear Nasal <br>Discharge</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              3
            </span>
            <span class="description-text">Mild To Moderate <br>Mucous Nasal <br>Discharge</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              2
            </span>
            <span class="description-text">Heavy Mucous Discharge, <br>Moderate Anatomic
              <br>Stenosis (closure) of <br>Nasal Openings</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              1
            </span>
            <span class="description-text">Mucous Discharge Occluding
              <br>Nasal Passages, Poor Ability <br>to Breath,
              Severe Closure Of <br>Nasal Openings</span>
          </div>
        </div>

        <div class="row my-3 image-section" style="width: 100%;" *ngIf="showNoseImages">
          <div class="conditionImageContainer pl-3">
            <div class="filteredImages" *ngFor="let image of scoreFiveNoseImage; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreFourNoseImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreThreeNoseImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreTwoNoseImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreOneNoseImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="container text-center my-3">
    <div class="row row-title">
      <div class="col-md-2 text-left">
        <span class="conditionTitle">EARS</span>
      </div>
      <div class="col-md-4 text-left">
        <span class="showImageCheckbox" style="color: #86c788;">
          <section class="check-section">
            <mat-checkbox (change)="showEarsImages = !showEarsImages" class="remember">
              Show Sample Images
            </mat-checkbox>
          </section>
        </span>
      </div>
    </div>
    <div class="row ml-0">
      <div class="col-12">
        <div class="row">
          <div class="col-12 pl-0 text-left">
            <mat-slider min="1" max="5" invert="invert" [value]="data.selectedConditionData!=null ? data.selectedConditionData.ears : 5" color="primary" (change)="onEarsSliderChange($event)"
              class="w-80 pt-0" step="1" tickInterval="1"></mat-slider>
          </div>
        </div>
        <div class="row condition-value-container">
          <div class="conditionTextContainer">
            <span class="conditionValue">
              5
            </span>
            <span class="description-text">Normal ear, No Redness,
              <br>Irritation, or Debris in Canals.<br>No Wounds</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              4
            </span>
            <span class="description-text">Mild Waxy Debris, No<br>Noticeable Inflammation or<br>Redness</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              3
            </span>
            <span class="description-text">Moderate to Heavy debris,<br>Notable Inflammation or<br>Redness, Ear Mites,
              <br>Mild Fly Bites</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              2
            </span>
            <span class="description-text">Heavy Debris in Canals or <br>Redness, Closure due to <br>Swelling of Canals,
              Severe Fly <br>Bites, Healed Hematoma,
              <br>Recently-Cropped Ears</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              1
            </span>
            <span class="description-text">Pus in Canals, Severe Closure <br>due to Swelling of Ear Canals, <br>Ear
              Hematoma</span>
          </div>
        </div>

        <div class="row my-3 image-section" style="width: 100%;" *ngIf="showEarsImages">
          <div class="conditionImageContainer pl-3">
            <div class="filteredImages" *ngFor="let image of scoreFiveImage; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreFourImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreThreeImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreTwoImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreOneImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container text-center my-3">
    <div class="row row-title">
      <div class="col-md-2 text-left">
        <span class="conditionTitle">EYES</span>
      </div>
      <div class="col-md-4 text-left">
        <span class="showImageCheckbox" style="color: #86c788;">
          <section class="check-section">
            <mat-checkbox (change)="showEyesImages = !showEyesImages" class="remember">
              Show Sample Images
            </mat-checkbox>
          </section>
        </span>
      </div>
    </div>
    <div class="row ml-0">
      <div class="col-12">
        <div class="row">
          <div class="col-12 pl-0 text-left">
            <mat-slider min="1" max="5" invert="invert" [value]="data.selectedConditionData!=null ? data.selectedConditionData.eyes : 5" color="primary" (change)="onEyesSliderChange($event)"
              class="w-80 pt-0" step="1" tickInterval="1"></mat-slider>
          </div>
        </div>
        <div class="row condition-value-container">
          <div class="conditionTextContainer">
            <span class="conditionValue">
              5
            </span>
            <span class="description-text">Normal Eye. No Swelling, <br>Squinting, or Discharge. No <br>concerns with
              Eyelids</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              4
            </span>
            <span class="description-text">Mild Clear Discharge, Mild <br>Conjunctivitis (swelling of <br>lining of the
              eyelids)</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              3
            </span>
            <span class="description-text">Moderate Conjunctivitis <br>(visible without spreading <br>eyelids),
              Squinting, Heavy
              <br>Watery or Mild Mucousy Eye
              <br>Discharge</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              2
            </span>
            <span class="description-text">Severe Conjunctivitis <br>(obscuring view of the eye), <br>Moderate Mucousy
              <br>Discharge, Mild to Moderate <br>Entropion
              (rolling in of eyelids), <br>Scars on Cornea from Healing <br>Ulcers</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              1
            </span>
            <span class="description-text">Severe Entropion (rolled-in <br>eyelids), Severe Ulcers, Cherry <br>Eye,
              Cataracts,
              Ruptured Eye, <br>Severe Eye
              Discharge</span>
          </div>
        </div>

        <div class="row my-3 image-section" style="width: 100%;" *ngIf="showEyesImages">
          <div class="conditionImageContainer pl-3">
            <div class="filteredImages" *ngFor="let image of scoreFiveEyeImage; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreFourEyeImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreThreeEyeImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreTwoEyeImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreOneEyeImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="container text-center my-3">
    <div class="row row-title">
      <div class="col-md-2 text-left">
        <span class="conditionTitle">TEETH</span>
      </div>
      <div class="col-md-4 text-left">
        <span class="showImageCheckbox" style="color: #86c788;">
          <section class="check-section">
            <mat-checkbox (change)="showTeethImages = !showTeethImages" class="remember">
              Show Sample Images
            </mat-checkbox>
          </section>
        </span>
      </div>
    </div>

    <div class="row ml-0">
      <div class="col-12">
        <div class="row">
          <div class="col-12 pl-0 text-left">
            <mat-slider min="1" max="5" invert="invert" [value]="data.selectedConditionData!=null ? data.selectedConditionData.teeth : 5" color="primary" (change)="onTeethSliderChange($event)"
              class="w-80 pt-0" step="1" tickInterval="1"></mat-slider>
          </div>
        </div>
        <div class="row condition-value-container">
          <div class="conditionTextContainer">
            <span class="conditionValue">
              5
            </span>
            <span class="description-text">No Tartar or Gingivitis. No <br>concerns with the teeth or <br>gums. Rare
              except in very <br>young dogs and cats.</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              4
            </span>
            <span class="description-text">Minimal Tartar and Mild <br>Gingivitis. Happens with very <br>young dogs and
              cats due to <br>genetics or diet.</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              3
            </span>
            <span class="description-text">Moderate Tartar and more <br>Gingivitis. It is still reversible at <br>this
              stage with cleaning.</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              2
            </span>
            <span class="description-text">Heavy Tartar and significant <br>Gingivitis. Let adopters know <br>will
              likely need a dental to <br>maintain health
              and comfort</span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              1
            </span>
            <span class="description-text">Heavy Tartar, Severe <br>Gingivitis, and Pus may be <br>present around the
              teeth/<br>gums. Requires a dental.
              <br>Multiple extractions are likely.</span>
          </div>
        </div>

        <div class="row my-3 image-section" style="width: 100%;" *ngIf="showTeethImages">
          <div class="conditionImageContainer pl-3">
            <div class="filteredImages" *ngFor="let image of scoreFiveTeethImage; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreFourTeethImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreThreeTeethImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreTwoTeethImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreOneTeethImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="container text-center my-3">
    <div class="row row-title">
      <div class="col-md-2 text-left">
        <span class="conditionTitle">GENITALS</span>
      </div>
      <div class="col-md-4 text-left">
        <span class="showImageCheckbox" style="color: #86c788;">
          <section class="check-section">
            <mat-checkbox (change)="showGenitalsImages = !showGenitalsImages"
              class="remember">
              Show Sample Images
            </mat-checkbox>
          </section>
        </span>
      </div>
    </div>
    <div class="row ml-0">
      <div class="col-12">
        <div class="row">
          <div class="col-12 pl-0 text-left">
            <mat-slider min="1" max="5" invert="invert" [value]="data.selectedConditionData!=null ? data.selectedConditionData.genitals : 5" color="primary"
              (change)="onGenitalsSliderChange($event)" class="w-80 pt-0" step="1" tickInterval="1"></mat-slider>
          </div>
        </div>
        <div class="row condition-value-container">
          <div class="conditionTextContainer">
            <span class="conditionValue">
              5
            </span>
            <span class="description-text">
              No Hernias and No issues <br>with Testicles, Vulva, and <br>Mammary Chain
            </span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              4
            </span>
            <span class="description-text">
              Recently had a litter, have <br>Mammary development <br>that might affect ability to
              <br>spay, a small umbilical hernia <br>may be present
            </span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              3
            </span>
            <span class="description-text">
              Mild Mastitis (inflammation of <br>the Mammary Chain), Large <br>Umbilical Hernias
            </span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              2
            </span>
            <span class="description-text">
              Significant Mastitis, Significant <br>Swelling or Masses of the <br>Mammary Chain, Vaginal <br>Polyps,
              Mild Vaginal
              Prolapse, <br>Cryptorchidism (one or both <br>testicles not descended in an <br>unneutered dog)
            </span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">
              1
            </span>
            <span class="description-text">
              Severe Mastitis, Severe <br>Vaginal or Uterine Prolapse, <br>Tumors of the Reproductive <br>Tract,
              Pyometra (uterine
              <br>infection/vaginal discharge)
            </span>
          </div>
        </div>

        <div class="row my-3 image-section" style="width: 100%;" *ngIf="showGenitalsImages">
          <div class="conditionImageContainer pl-3">
            <div class="filteredImages" *ngFor="let image of scoreFiveGenitalImage; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" *ngIf="image && image.path" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreFourGenitalImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreThreeGenitalImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreTwoGenitalImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreOneGenitalImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="container text-center my-3">
    <div class="row row-title">
      <div class="col-md-2 text-left">
        <span class="conditionTitle">SKIN</span>
      </div>
      <div class="col-md-4 text-left">
        <span class="showImageCheckbox" style="color: #86c788;">
          <section class="check-section">
            <mat-checkbox (change)="showSkinImages = !showSkinImages" class="remember">
              Show Sample Images
            </mat-checkbox>
          </section>
        </span>
      </div>
    </div>
    <div class="row ml-0">
      <div class="col-12">
        <div class="row">
          <div class="col-12 pl-0 text-left">
            <mat-slider min="1" max="5" invert="invert" [value]="data.selectedConditionData!=null ? data.selectedConditionData.skin : 5" color="primary" (change)="onSkinSliderChange($event)"
              class="w-80 pt-0" step="1" tickInterval="1"></mat-slider>
          </div>
        </div>
        <div class="row condition-value-container">
          <div class="conditionTextContainer">
            <span class="conditionValue">5</span>
            <span class="description-text">
              Skin is free of Wounds, <br>Masses. No Dermatitis (skin <br>infection). Haircoat is full with <br>no
              hair loss or
              other <br>conditions.
            </span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">4</span>
            <span class="description-text">
              May be Flea Dirt present, <br>Occasional Skin Lesions, or <br>Healing Wounds/Scars.
            </span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">3</span>
            <span class="description-text">
              Live Fleas or Ticks in small <br>numbers. Skin Lesions over a <br>larger portion of the body.
              <br>Numerous Bumps/Lesions. <br>Haircoat requires minor <br>grooming. Mild Hair Loss. May <br>be healing
              wounds.
            </span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">2</span>
            <span class="description-text">
              Live Fleas or Ticks in <br>moderate numbers. Skin <br>Lesions over majority of the <br>body. Infected
              Wounds.
              <br>Haircoat requires significant <br>grooming. Hair Loss over <br>large portions of the body.
              <br>Masses noted.
              Suspected <br>Ringworm.
            </span>
          </div>
          <div class="conditionTextContainer">
            <span class="conditionValue">1</span>
            <span class="description-text">
              Severe Flea or Tick Infestation. <br>Severe Wounds or Skin <br>Lesions/Infections.
              <br>Widespread/Severe Hair Loss. <br>Severe Matted Haircoat.
              <br>Multiple or Significant <br>Tumors/Masses/Swellings.
            </span>
          </div>
        </div>

        <div class="row my-3 image-section" style="width: 100%;" *ngIf="showSkinImages">
          <div class="conditionImageContainer pl-3">
            <div class="filteredImages" *ngFor="let image of scoreFiveSkinImage; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreFourSkinImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreThreeSkinImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreTwoSkinImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
          <div class="conditionImageContainer">
            <div class="filteredImages" *ngFor="let image of scoreOneSkinImages; let i=index;">
              <div class="image-container" title="{{image.title}}">
                <img class="sample-images" [src]="image.path" [alt]="image.title">
                <button (click)="onImageContainerClick(image)" mat-flat-button class="image-open-button">
                  Open
                </button>
              </div>
              <span class="d-block text-center image-title">{{image.title}}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- <div class="row">
    <div class="label-container">
      <span class="mb-2">Pre-condition</span>
      <div class="row pl-3">
        <mat-checkbox class="check" *ngFor="let precondition of conditionMasterData?.animal_pre_condition"
          (change)="onPreconditionToggled($event, precondition)">
          {{precondition.value}}
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent" style="width: 32%;">
      <mat-label>Condition</mat-label>
      <mat-select [(ngModel)]="condition">
        <mat-option [value]="''">Select</mat-option>
        <mat-option *ngFor="let condition of conditionMasterData?.animal_condition" [value]="condition.value">
          {{condition.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div> -->

<div mat-dialog-footer class="dialog-footer">
  <div mat-dialog-actions class="p-0">
    <button mat-raised-button class="white-button" (click)="onNoClick()">CANCEL</button>
    <button mat-raised-button class="green-button" (click)="onSaveClicked()" cdkFocusInitial>
      SAVE
    </button>
  </div>
</div>