<div mat-dialog-title class="flex-container" style="margin:0">
    <div class="row">
        <div class="col-6">
            <h1 mat-dialog-title>Vital Chart - {{animalName}}</h1>
          </div>
          <div class="col-6 text-right">
                <button mat-button class="close-icon" [mat-dialog-close]="true">
                    <mat-icon (click)="onNoClick()">close</mat-icon>
                </button>
          </div>
    </div>
    
</div>
<div mat-dialog-content>      
        <div class="row my-2">
            <div class="col" style="width: 100%;">          
              <form [formGroup]="vitalChartForm" class="vitalChartForm">
                  <mat-form-field class="form-field basic-form-field" [floatLabel]="'always'" color="accent">
                    <mat-label>Body Weight (lbs)</mat-label>
                    <input type="text" matInput formControlName="body_weight" autocomplete="off" (focusout)="onFocusOutEvent($event,'body_weight')"/>
                    <mat-error *ngIf="vitalChartForm.controls['body_weight'].hasError('required')">
                      Body Weight is required
                    </mat-error>
                    <mat-error *ngIf="vitalChartForm.controls['body_weight'].hasError('pattern')">
                      Body Weight should be integer
                    </mat-error>
                    <mat-error *ngIf="vitalChartForm.controls['body_weight'].hasError('min')">
                      Body Weight should be greater than 0
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="form-field basic-form-field" [floatLabel]="'always'" color="accent">
                    <mat-label>Body Weight (oz)</mat-label>
                    <input type="text" matInput formControlName="body_weight_ounces" autocomplete="off" (focusout)="onFocusOutEvent($event,'body_weight_ounces')"/>
                    <mat-error *ngIf="vitalChartForm.controls['body_weight_ounces'].hasError('required')">
                      Body Weight in ounces is required
                    </mat-error>
                    <mat-error *ngIf="vitalChartForm.controls['body_weight_ounces'].hasError('pattern')">
                      Body Weight in ounces should be integer or float
                    </mat-error>
                    <mat-error *ngIf="vitalChartForm.controls['body_weight_ounces'].hasError('max')">
                      Weight should be less than 15
                    </mat-error>
                  </mat-form-field>
        
                  <mat-form-field class="form-field basic-form-field" [floatLabel]="'always'" color="accent">
                    <mat-label>Temperature (°F)</mat-label>
                    <input type="text" matInput formControlName="temperature" autocomplete="off" (focusout)="onFocusOutEvent($event,'temperature')"/>
                    <mat-error *ngIf="vitalChartForm.controls['temperature'].hasError('pattern')">
                      Temperature should be integer or float
                    </mat-error>
                    <mat-error *ngIf="vitalChartForm.controls['temperature'].hasError('min')">
                      Temperature should be greater than 0
                    </mat-error>
                  </mat-form-field>
        
                  <mat-form-field class="form-field" [floatLabel]="'always'" color="accent">
                    <mat-label>Respiratory Rate (BPM)</mat-label>
                    <input type="text" matInput formControlName="resp_rate" autocomplete="off"/>
                    <mat-error *ngIf="vitalChartForm.controls['resp_rate'].hasError('pattern')">
                      Respiratory Rate should be numeric
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="form-field" [floatLabel]="'always'" color="accent">
                    <mat-label>Pulse (BPM)</mat-label>
                    <input type="text" matInput formControlName="pulse" autocomplete="off"/>
                    <mat-error *ngIf="vitalChartForm.controls['pulse'].hasError('pattern')">
                      Pulse should be numeric
                    </mat-error>
                  </mat-form-field>

                  <button mat-raised-button class="green-button" (click)="onSaveClicked()">SAVE</button>
            </form>
        </div>      
    </div>
    <div class="row">
        <div class="chart-wrapper col-9 my-2" *ngIf="lineChartData.length > 0">
            <canvas baseChart 
                [datasets]="lineChartData" 
                [labels]="lineChartLabels" 
                [options]="lineChartOptions"
                [colors]="lineChartColors" 
                [chartType]="lineChartType" 
                [plugins]="lineChartPlugins">
            </canvas>
        </div>
    </div>
    <div class="row">
      <!--<ag-grid-angular style="width: 100%; height: 173px;" class="ag-theme-alpine"
              [rowData]="vitalTableContent" 
              [columnDefs]="getTableHeaderNames"
              [gridOptions]="gridOptions" [getRowHeight]="getRowHeight"
              [headerHeight]="headerHeight">
            </ag-grid-angular>--->
      
            <table class="table table-bordered col-12 vitalChartTable">
            <tr *ngIf="dateHeaders.length > 0">
                <th></th>
                <th *ngFor="let dateVal of dateHeaders">
                  {{dateVal}}
                </th>
                
              </tr>
              <tr>
                <td>Body Weight</td>
                <td *ngFor="let bodyWeightVal of bodyWeight">
                  {{bodyWeightVal}}
                </td>
              </tr>
              <tr>
                <td>Temperature</td>
                <td *ngFor="let temperatureVal of Temperature">
                  {{temperatureVal}}
                </td>
              </tr>
              <tr>
                <td>Resipiratory Rate</td>
                <td *ngFor="let respRateVal of respRate">
                  {{respRateVal}}
                </td>
              </tr>
              <tr>
                <td>Pulse</td>
                <td *ngFor="let pulseVal of pulse">
                  {{pulseVal}}
                </td>
              </tr>
        </table>    
    </div>
    
</div>