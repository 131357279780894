import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef, OnChanges, SimpleChange, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { Animal } from 'src/app/core/models/animal';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UtilService } from 'src/app/core/services/util.service';
import { Subscription } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSliderChange } from '@angular/material/slider';
import { AnimalMedicalConditionDialogService } from './animal-medical-condition-dialog.service';
import { AnimalImageViewerDialogComponent } from './animal-image-viewer-dialog/animal-image-viewer-dialog.component';

export interface DialogData {
  animal: Animal;
  selectedConditionData?: any;
}

@Component({
  selector: 'app-animal-medical-condition-dialog',
  templateUrl: './animal-medical-condition-dialog.component.html',
  styleUrls: ['./animal-medical-condition-dialog.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnimalMedicalConditionDialogComponent implements OnInit, OnChanges, OnDestroy {

  conditionMasterData: any;
  condition: any;
  subs: Array<Subscription> = [];
  selectedPrecondition: string[] = [];

  /** Variables */
  body = 5;
  nose = 5;
  ears = 5;
  eyes = 5;
  teeth = 5;
  genitals = 5;
  skin = 5;
  hasPreviousCondition = false;
  conditionScore: any = 5;
  conditionTag = 'Excellent';

  showBodyImages = false;
  showNoseImages = false;
  showEarsImages = false;
  showEyesImages = false;
  showTeethImages = false;
  showGenitalsImages = false;
  showSkinImages = false;

  scoreFiveImage = [];
  scoreFourImages = [];
  scoreThreeImages = [];
  scoreTwoImages = [];
  scoreOneImages = [];

  scoreFiveEyeImage = [];
  scoreFourEyeImages = [];
  scoreThreeEyeImages = [];
  scoreTwoEyeImages = [];
  scoreOneEyeImages = [];

  scoreFiveBodyImage = [];
  scoreFourBodyImages = [];
  scoreThreeBodyImages = [];
  scoreTwoBodyImages = [];
  scoreOneBodyImages = [];

  scoreFiveGenitalImage = [];
  scoreFourGenitalImages = [];
  scoreThreeGenitalImages = [];
  scoreTwoGenitalImages = [];
  scoreOneGenitalImages = [];

  scoreFiveSkinImage = [];
  scoreFourSkinImages = [];
  scoreThreeSkinImages = [];
  scoreTwoSkinImages = [];
  scoreOneSkinImages = [];

  scoreFiveTeethImage = [];
  scoreFourTeethImages = [];
  scoreThreeTeethImages = [];
  scoreTwoTeethImages = [];
  scoreOneTeethImages = [];

  scoreFiveNoseImage = [];
  scoreFourNoseImages = [];
  scoreThreeNoseImages = [];
  scoreTwoNoseImages = [];
  scoreOneNoseImages = [];

  bodyImages: any;
  noseImages: any;
  earImages: any = [];
  eyeImages: any;
  teethImages: any;
  genitalImages: any;
  skinImages: any;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AnimalMedicalConditionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private utilService: UtilService,
    private changeDetector: ChangeDetectorRef,
    private animalMedicalConditionDialogService: AnimalMedicalConditionDialogService
  ) { }

  ngOnInit(): void {
    const me = this;
    this.getMasterData();
    // this.getEarImages();
    me.getImages();
  }

  getMasterData() {
    if(this.data.selectedConditionData) {
      this.body = this.data.selectedConditionData.body;
      this.nose = this.data.selectedConditionData.nose;
      this.ears = this.data.selectedConditionData.ears;
      this.eyes = this.data.selectedConditionData.eyes;
      this.teeth = this.data.selectedConditionData.teeth;
      this.genitals = this.data.selectedConditionData.genitals;
      this.skin = this.data.selectedConditionData.skin;
      this.conditionScore = this.data.selectedConditionData.overAllScore;
      this.conditionTag = this.data.selectedConditionData.conditionTag;
    }
    let sub = this.utilService.getMasterInputs('MedicalEvent').subscribe(res => {
      this.conditionMasterData = res.input;
    });
    this.subs.push(sub);
  }

  onPreconditionToggled(event: MatCheckboxChange, precondition: any) {
    if (event.checked) {
      this.selectedPrecondition.push(precondition.value);
    } else {
      this.selectedPrecondition.splice(this.selectedPrecondition.indexOf(precondition.value), 1);
    }
  }

  onSaveClicked() {
    const me = this;
    let payload = {
      body: me.body,
      nose: me.nose,
      ears: me.ears,
      eyes: me.eyes,
      teeth: me.teeth,
      genitals: me.genitals,
      skin: me.skin,
      overAllScore: me.conditionScore,
      conditionTag: me.conditionTag
    };
    
        // console.log(payload);
    // this.dialogRef.close({ preCoditions: this.selectedPrecondition, preCoditionStr: this.getPreConditonString(), condition: this.condition });
    me.dialogRef.close(payload);
  }


  getImages() {
    const me = this;
    me.getBodyImages();
    me.getNoseImages();
    me.getEarImages();
    me.getEyesImages();
    me.getGenitalImages();
    me.getTeethImages();
    me.getSkinImages();
  }

  getEarImages() {
    const me = this;
    me.animalMedicalConditionDialogService.getEarImages().subscribe((res: AnimalCondition) => {
      me.earImages = res;
      me.filterImages();
    });
  }

  getEyesImages() {
    const me = this;
    me.animalMedicalConditionDialogService.getEyeImages().subscribe((res: AnimalCondition) => {
      me.eyeImages = res;
      me.filterEyesImages();
    });
  }

  getBodyImages() {
    const me = this;
    me.animalMedicalConditionDialogService.getBodyImages().subscribe((res: AnimalCondition) => {
      me.bodyImages = res;
      me.filterBodyImages();
    });
  }

  getNoseImages() {
    const me = this;
    me.animalMedicalConditionDialogService.getNoseImages().subscribe((res: AnimalCondition) => {
      me.noseImages = res;
      me.filterNoseImages();
    });
  }

  getGenitalImages() {
    const me = this;
    me.animalMedicalConditionDialogService.getGenitalImages().subscribe((res: AnimalCondition) => {
      me.genitalImages = res;
      me.filterGenitalImages();
    });
  }

  getSkinImages() {
    const me = this;
    me.animalMedicalConditionDialogService.getSkinImages().subscribe((res: AnimalCondition) => {
      me.skinImages = res;
      me.filterSkinImages();
    });
  }

  getTeethImages() {
    const me = this;
    me.animalMedicalConditionDialogService.getTeethImages().subscribe((res: AnimalCondition) => {
      me.teethImages = res;
      me.filterTeethImages();
    });
  }

  filterImages() {
    const me = this;
    me.earImages.filter((item: any) => {
      if (item.score === '5') {
        me.scoreFiveImage.push(item);
      }
      if (item.score === '4') {
        me.scoreFourImages.push(item);
      }
      if (item.score === '3') {
        me.scoreThreeImages.push(item);
      }
      if (item.score === '2') {
        me.scoreTwoImages.push(item);
      }
      if (item.score === '1') {
        me.scoreOneImages.push(item);
      }
    });
  }

  filterBodyImages() {
    const me = this;
    me.bodyImages.filter((item: any) => {
      if (item.score === '5') {
        me.scoreFiveBodyImage.push(item);
      }
      if (item.score === '4') {
        me.scoreFourBodyImages.push(item);
      }
      if (item.score === '3') {
        me.scoreThreeBodyImages.push(item);
      }
      if (item.score === '2') {
        me.scoreTwoBodyImages.push(item);
      }
      if (item.score === '1') {
        me.scoreOneBodyImages.push(item);
      }
    });
  }

  filterNoseImages() {
    const me = this;
    me.noseImages.filter((item: any) => {
      if (item.score === '5') {
        me.scoreFiveNoseImage.push(item);
      }
      if (item.score === '4') {
        me.scoreFourNoseImages.push(item);
      }
      if (item.score === '3') {
        me.scoreThreeNoseImages.push(item);
      }
      if (item.score === '2') {
        me.scoreTwoNoseImages.push(item);
      }
      if (item.score === '1') {
        me.scoreOneNoseImages.push(item);
      }
    });
  }

  filterEyesImages() {
    const me = this;
    me.eyeImages.filter((item: any) => {
      if (item.score === '5') {
        me.scoreFiveEyeImage.push(item);
      }
      if (item.score === '4') {
        me.scoreFourEyeImages.push(item);
      }
      if (item.score === '3') {
        me.scoreThreeEyeImages.push(item);
      }
      if (item.score === '2') {
        me.scoreTwoEyeImages.push(item);
      }
      if (item.score === '1') {
        me.scoreOneEyeImages.push(item);
      }
    });
  }

  filterTeethImages() {
    const me = this;
    me.teethImages.filter((item: any) => {
      if (item.score === '5') {
        me.scoreFiveTeethImage.push(item);
      }
      if (item.score === '4') {
        me.scoreFourTeethImages.push(item);
      }
      if (item.score === '3') {
        me.scoreThreeTeethImages.push(item);
      }
      if (item.score === '2') {
        me.scoreTwoTeethImages.push(item);
      }
      if (item.score === '1') {
        me.scoreOneTeethImages.push(item);
      }
    });
  }

  filterGenitalImages() {
    const me = this;
    me.genitalImages.filter((item: any) => {
      if (item.score === '5') {
        me.scoreFiveGenitalImage.push(item);
      }
      if (item.score === '4') {
        me.scoreFourGenitalImages.push(item);
      }
      if (item.score === '3') {
        me.scoreThreeGenitalImages.push(item);
      }
      if (item.score === '2') {
        me.scoreTwoGenitalImages.push(item);
      }
      if (item.score === '1') {
        me.scoreOneGenitalImages.push(item);
      }
    });
  }

  filterSkinImages() {
    const me = this;
    me.skinImages.filter((item: any) => {
      if (item.score === '5') {
        me.scoreFiveSkinImage.push(item);
      }
      if (item.score === '4') {
        me.scoreFourSkinImages.push(item);
      }
      if (item.score === '3') {
        me.scoreThreeSkinImages.push(item);
      }
      if (item.score === '2') {
        me.scoreTwoSkinImages.push(item);
      }
      if (item.score === '1') {
        me.scoreOneSkinImages.push(item);
      }
    });
  }

  getString(text: string) {
    const count = 15;
    return text.slice(0, count) + (text.length > count ? '...' : '');
  }

  onImageContainerClick(image: any) {
    const dialogRef = this.dialog.open(AnimalImageViewerDialogComponent, {
      width: '100%',
      height: '650px',
      data: image
    });

    dialogRef.afterClosed().subscribe((afterClose: any) => {
      console.log(afterClose);
    });
  }

  getPreConditonString(): string {
    return this.selectedPrecondition.reduce((prv, cur) => {
      if (prv) return prv + ', ' + cur;
      else return cur;
    });
  }

  onBodySliderChange(event: MatSliderChange): any {
    const me = this;
    me.body = event.value;
  }

  onNoseSliderChange(event: MatSliderChange): any {
    const me = this;
    me.nose = event.value;
  }

  onEarsSliderChange(event: MatSliderChange): any {
    const me = this;
    me.ears = event.value;
  }

  onEyesSliderChange(event: MatSliderChange): any {
    const me = this;
    me.eyes = event.value;
  }

  onTeethSliderChange(event: MatSliderChange): any {
    const me = this;
    me.teeth = event.value;
  }

  onGenitalsSliderChange(event: MatSliderChange): any {
    const me = this;
    me.genitals = event.value;
  }

  onSkinSliderChange(event: MatSliderChange): any {
    const me = this;
    me.skin = event.value;
  }

  ngOnChanges(changes: SimpleChanges) {
    const me = this;
    console.log(changes);
    me.changeDetector.detectChanges();
  }

  getScore() {
    const me = this;
    setTimeout(() => {
      // let score: any = 5;
      let sum: any = 0;
      let lesserValues;
      const healthArray = me.createArray();
      lesserValues = healthArray.filter((item) => {
        return item <= 3;
      });
      if (lesserValues && lesserValues.length) {
        sum = lesserValues.reduce((a, b) => {
          return a + b;
        });
        me.conditionScore = (sum / lesserValues.length).toFixed(1).replace(/\.00$/, '');
      } else {
        const data = [];
        healthArray.forEach((val) => {
          if (val < 5) {
            data.push(val);
          } else {
            me.conditionScore = 5;
          }
        });

        if (data && data.length) {
          const newSum = healthArray.reduce((a, b) => a + b);
          me.conditionScore = (newSum / healthArray.length).toFixed(1).replace(/\.00$/, '');
        }
      }
      // me.conditionScore = score;
    });
    return me.conditionScore;
  }

  getTag() {
    const me = this;
    let tag = 'Excellent';
    if (me.conditionScore === 5) {
      tag = 'Excellent';
    } else if (me.conditionScore < 5 && me.conditionScore >= 4) {
      tag = 'Good';
    } else if (me.conditionScore < 4 && me.conditionScore >= 3) {
      tag = 'Concerning';
    } else if (me.conditionScore < 3 && me.conditionScore >= 2) {
      tag = 'Poor';
    } else if (me.conditionScore < 2 && me.conditionScore >= 1) {
      tag = 'Emergency';
    } else if (me.conditionScore < 1) {
      tag = 'Emergency';
    }
    me.conditionTag = tag;
    return tag;
  }

  createArray() {
    const me = this;
    const conditionArray: any = [];
    conditionArray.push(me.body);
    conditionArray.push(me.nose);
    conditionArray.push(me.ears);
    conditionArray.push(me.eyes);
    conditionArray.push(me.teeth);
    conditionArray.push(me.genitals);
    conditionArray.push(me.skin);

    return conditionArray;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

interface AnimalCondition {
  score: any;
  title: string;
  path: string;
}
