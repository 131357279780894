import {Component, Input, OnInit} from '@angular/core';
import {GridApi, GridOptions} from 'ag-grid-community';
import {Utils} from 'src/app/core/utils/util';
import {UtilService} from 'src/app/core/services/util.service';
import {Observable,of} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {AddEditNoteDialogComponent} from '../add-edit-note-dialog/add-edit-note-dialog.component';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {AgGridCustomTimeFilterComponent} from 'src/app/shared/components/ag-grid-custom-time-filter/ag-grid-custom-time-filter.component';

import * as moment from 'moment';

@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss']
})

export class NotesComponent implements OnInit {

    @Input('entity') entity: string;
    @Input('entityId') entityId: string;

    gridApi: GridApi;
    gridColumnApi: any;
    notesRowData: Observable<any>;
    notesColumnDefs: any[] = [];
    defaultColDef: any;
    rowSelection: any;
    frameworkComponents;

    public gridOptions = {
        context: {
            componentParent: this,
        },
        suppressHorizontalScroll: false,
        rowStyle: {background: 'white'}
    } as GridOptions;

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    getRowHeight;
    headerHeight;
    overlayNoRowsTemplate: string;

    constructor(
        private utilService: UtilService,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar
    ) {
        this.frameworkComponents = {
            timeFilter:AgGridCustomTimeFilterComponent
        };
        this.getRowHeight = (params) => {
            return 34;
        };
        this.headerHeight = 34;
    }

    ngOnInit(): void {
      //  console.log('called');
        this.setColumns();
        this.notesRowData=of([]);
    }

    setColumns() {
        this.rowSelection = 'single';
        this.defaultColDef = Utils.getBaseThemeAgGridColNotes();
        this.overlayNoRowsTemplate =
            '<span style="padding: 10px; color: #929292">No notes to display</span>';

        this.notesColumnDefs = [
            {
                headerName: '',
                field: 'check',
                checkboxSelection: true,
                suppressSizeToFit: true,
                minWidth: 10,
                sortable:false,
                width: 31,
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0 0 0 10px'
                },
            },
            {
                headerName: 'Date',
                field: 'modified_time',
                valueFormatter: this.dateFormatter,
                filter: 'agDateColumnFilter',
                filterParams: Utils.dateFilterParams(),
                suppressSizeToFit: true,
                width: 100,
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center'
                }
            },
            {
                headerName: 'Time',
                field: 'modified_time',
                valueFormatter: this.timeFormatter,
                filter: "timeFilter",
                suppressSizeToFit: true,
                width: 100,
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center'
                }
            },
            {
                headerName: 'Topic',
                field: 'note_name',
                suppressSizeToFit: true,
                minWidth: 350,
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'flex-start'
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Function Name',
                field: 'entity',
                suppressSizeToFit: true,
                minWidth: 150,
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'flex-start'
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Notes',
                field: 'notes',
                minWidth: 250,
                filterParams: Utils.filterParams(),
                autoHeight: true,
                wrapText: true,
                cellClass:'cell-text-wrap'
            },
            {
                headerName: 'User',
                field: 'user',
                suppressSizeToFit: true,
                minWidth: 100,
                cellStyle: {...this.defaultColDef.cellStyle, justifyContent: 'flex-start'},
                filterParams: Utils.filterParams()
            }
        ];
    }

    onColumnResized(event: any) {
        if (event.finished) {
          this.gridApi.resetRowHeights();
        }
      }

    onGridReady(params) {
        //alert();
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.gridApi.resetRowHeights();

        const payload = {
            entity: this.entity,
            entity_id: this.entityId,
            noteId: '',
            noteName: '',
            notes: '',
            created_by: '',
            modified_by: ''
        };
        this.notesRowData = this.utilService.getAllNotes(payload);
    }

    isDisable() {
        if (this.gridApi?.getSelectedRows().length > 0) {
            return false;
        } else {
            return true;
        }
    }

    openDialog(editFlag) {

        // dialog open
        const dialogRef = this.dialog.open(AddEditNoteDialogComponent, {
            width: '50em',
            data: {isEdit: editFlag, note: this.gridApi.getSelectedRows()[0]},
            autoFocus: false
        });

        // dialog close with result
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const payload = {
                    entity: this.entity,
                    entity_id: this.entityId,
                    noteId: result.note_id ? result.note_id : '',
                    noteName: result.noteName,
                    notes: result.notes,
                    created_by: '',
                    modified_by: ''
                };
                // if (!editFlag) {
                this.utilService.createNote(payload).subscribe(res => {
                    this._snackBar.open('Note updated', 'Ok', {
                        duration: 3000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        panelClass: ['light-snackbar']
                    });
                    payload.noteName = '';
                    payload.notes = '';
                    this.notesRowData = this.utilService.getAllNotes(payload);
                });
                // }
            }
        });
    }

    dateFormatter(params) {
        return (params.data.modified_time && params.data.modified_time.trim()!="") ? (moment(params.data.modified_time).format("MM/DD/YYYY")) : '';
    }

    timeFormatter(params){
        if(params.value && params.value.trim()!="Invalid date"){
          //  console.log(params.value);
            let time = new Date(params.value);
           // console.log(time.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }));
            return time.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        }
        return '';
    }
}
