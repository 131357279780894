import { Component, OnInit } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: "app-ag-grid-custom-clickable-cell",
  templateUrl: "./ag-grid-custom-clickable-cell.component.html",
  styleUrls: ["./ag-grid-custom-clickable-cell.component.scss"],
})
export class AgGridCustomClickableCellComponent
  implements OnInit, ICellRendererAngularComp
{
  params: ICellRendererParams;
  systemGen = {
    value: "",
    isNew: false,
  };

  constructor() {}

  ngOnInit(): void {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const val = this.valueBasic(params);
    let id = val && val.value;
    if (typeof id === "string") {
      id = Number(id.trim());
    }

    this.params.value = id;
  }

  refresh(params: any): boolean {
    return false;
  }

  private valueBasic(params: ICellRendererParams): any {
    let value: any = params.value;
    this.systemGen = {
      value: value,
      isNew: false,
    };
    if (typeof value === "string") {
      if (value.includes("New")) {
        value = value.replace("New", "");
        this.systemGen = {
          value: value,
          isNew: true,
        };
      }
    }
    return this.systemGen;
  }

  getValue() {
    let value = this.params.valueFormatted
      ? this.params.valueFormatted
      : this.params.value;
    if (typeof value === "string") {
      let vstr: string = value;
      return vstr.indexOf("$") > -1 ? "dolr" : "str";
    } else if (typeof value === "number") {
      return "num";
    }
  }

  getFlag(flag: boolean): any {
    let newFlag = 'New';
    if (flag) {
      newFlag = 'New';
    } else {
      newFlag = '';
    }
    return newFlag;
  }

  getTxId(txId: any): any {
    let id = '';
    if (txId) {
      id = txId;
    } else {
      id = '';
    }

    return id;
  }

  onClickCell(): void {
    if (this.params.context) {
      const parent = this.params.context.componentParent;
      this.params.data.transaction_id = this.params.value;
      parent.clickOnCell(
        this.params.value,
        this.params.colDef.field,
        this.params.data
      );
    }
  }
}
