import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
  providedIn: "root"
})

export class CaseService {

  constructor(
    private apiService: ApiService
  ) { }


  getAllCase(): Observable<any> {
    return this.apiService.get(`api/cases`);
  }

  createCase(payload: any): Observable<any> {
    return this.apiService.post(`api/cases`, payload);
  }

  deleteCase(id: number): Observable<any> {
    return this.apiService.delete('api/cases/' + id, null);
  }

  deleteAnimal(data: any): Observable<any> {
    return this.apiService.delete('api/transact/delete-tx-animal-for-cases', data);
  }

  getGenerateNotice(caseId,txId,anima_id,tMappingId): Observable<any> {
    return this.apiService.get(`api/cases/generate-violation-notice?caseId=${caseId}&txId=${txId}&aId=${anima_id}&tAId=${tMappingId}`);
  }

  updateCase(payload: any, id: any): Observable<any> {
    return this.apiService.put(`api/cases/${id}`, payload);
  }

  getAllAco(): Observable<any> {
    return this.apiService.get(`api/cases/aco`);
  }

  getAllUserList(): Observable<any> {
    return this.apiService.get(`api/user`);
  }

  assignAco(payload): Observable<any> {
    return this.apiService.post(`api/cases/assign`, payload);
  }

  getCaseById(id: any): Observable<any> {
    return this.apiService.get(`api/cases/${id}`);
  }


  getAssociatedCasesByContactID(contactId: number, casesId?: number): Observable<any> {
    let reqUrl = `api/cases/associated-cases?contact_id=${contactId}`;
    if (casesId) {
      reqUrl += `&case_id=${casesId}`;
    }
    return this.apiService.get(reqUrl);
  }

  getAssociatedCasesByParm(params: any): Observable<any> {
    let reqUrl: string = 'api/cases/assotiated_cases?';
    Object.keys(params).forEach((ele, indx) => {
      if (indx > 0) {
        reqUrl = reqUrl + `&${ele}=${params[ele]}`;
      } else {
        reqUrl = reqUrl + `${ele}=${params[ele]}`;
      }
    })
    console.log(params, reqUrl);
    return this.apiService.get(reqUrl);
  }

  getAssociatedCasesByOldParm(params: any): Observable<any> {
    let reqUrl: string = 'api/cases/assotiated_cases_old?';
    Object.keys(params).forEach((ele, indx) => {
      if (indx > 0) {
        reqUrl = reqUrl + `&${ele}=${params[ele]}`;
      } else {
        reqUrl = reqUrl + `${ele}=${params[ele]}`;
      }
    })
    console.log(params, reqUrl);
    return this.apiService.get(reqUrl);
  }

  getCaseJurisdictions(): Observable<any> {
    return this.apiService.get(`api/agency/get-jurisdictions-for-tenant`);
  }

  updateCaseStatus(id: any, type: string): Observable<any> {
    return this.apiService.patch(`api/cases/${id}/action`, { action: type });
  }

  uploadImage(payload: any, id: any): Observable<any> {
    return this.apiService.post(`api/cases/${id}/upload-image`, payload);
  }

  uploadDocument(payload: any, id: any): Observable<any> {
    return this.apiService.post(`api/cases/${id}/upload-attachment`, payload);
  }

  agencyLookup(payload: any): Observable<any> {
    return this.apiService.post(`api/cases/lookup-agency`, payload);
  }

  createRecheckDate(date,payload: any): Observable<any> {
    return this.apiService.post(`api/cases/recheck-case?recheck_date=${date}`, payload);
  }

  createCopyCase(payload: any): Observable<any> {
    return this.apiService.post(`api/cases/clone-case`, payload);
  }

  getBlobUrl(url): Observable<any> {
    return this.apiService.getBlobUrl(url);
  }
}