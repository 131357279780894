import {Component, OnInit} from '@angular/core';
import * as _moment from 'moment';

const moment = _moment;

@Component({
    selector: 'app-ag-grid-custom-time-filter',
    template: `<div class="ag-filter-body-wrapper ag-simple-filter-body-wrapper">
                  <div class="ag-labeled ag-label-align-left ag-select" role="presentation" ref="eOptions1">
                      <label ref="eLabel" class="ag-label ag-hidden"></label>
                      <div ref="eWrapper" class="ag-wrapper ag-picker-field-wrapper" tabindex="0">
                          <div ref="eDisplayField" class="ag-picker-field-display">Time</div>
                          <input type="time" [value]="textValue" (input)="textValue = $event.target.value;" style="min-height:inherit;border-color:transparent;">
                      </div>
                  </div>
<div class="ag-filter-apply-panel"><button type="button" ref="applyFilterButton" (click)="applyFilterButton()" class="ag-standard-button ag-filter-apply-panel-button">Apply Filter</button><button type="button" (click)="resetFilterButton()" ref="resetFilterButton" class="ag-standard-button ag-filter-apply-panel-button">Reset Filter</button></div>
</div>`,
    styles: [ ]
})

export class AgGridCustomTimeFilterComponent{
    public time:any;
    public params: any;
    filterActive;
    textValue;
    
    agInit(params): void {
        this.params = params;
    }

    resetFilterButton(){
      if(this.textValue){
        this.textValue='';
        this.time=null;
        this.filterActive = false;
        this.params.filterChangedCallback();
      }
    }

    applyFilterButton(){
      if(this.textValue){
        let time = new Date("03/03/2021 "+this.textValue);
        this.time=time.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        this.filterActive = true;
        this.params.filterChangedCallback();
       // this.params.context.componentParent.gridOptions.onFilterChanged(this.time,this.params);
      }
    }

    doesFilterPass(params) {
      if(this.time!=null){
        const colName= this.params.colDef.field;
        
        const isValidFirst   = moment(params.data[colName], 'MM/DD/YYYY HH:mm', true).isValid();
        const isValidSecond   = moment(params.data[colName], 'YYYY-MM-DD HH:mm:ss', true).isValid();
       
        if(isValidFirst || isValidSecond){
            let temDate:any = new Date(params.data[colName]);
            temDate=temDate.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
            return temDate==this.time;
        }else if(moment(params.data[colName],"HH:mm",true).isValid()){
            let temDate:any = new Date("03/03/22 "+params.data[colName]);
            temDate=temDate.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
                return temDate==this.time;
        }else{
          return params.data[colName] == this.time;
        }
        
      }else{
        const colName= this.params.colDef.field
        return params.data[colName] == '';
      }
      
    }

    isFilterActive() {
        return this.filterActive;
    }

    ngOnDestroy() {
      console.log(`Destroying DateComponent`);
    }
}