import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, of, Subscription,forkJoin } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GridApi, GridOptions } from 'ag-grid-community';
import { Utils } from 'src/app/core/utils/util';
import { AgGridClickableCellComponent } from '../ag-grid-clickable-cell/ag-grid-clickable-cell.component';
import { UtilService } from 'src/app/core/services/util.service';
import * as _moment from 'moment';
import { SettingsService } from 'src/app/core/services/settings.service';
import { AnimalService } from 'src/app/core/services/animal.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { Animal } from 'src/app/core/models/animal';
import { MedicalService } from '../../../core/services/medical.service';

const moment = _moment;


export interface DialogData {
    name?: string;
    microchip_number?: string;
    species?: string;
    primary_breed?: string;
    statusFilter?: string;
    cantCreate?: boolean;
    isFilterOwn?: boolean;
    isGetFromTx?: boolean;
    reading_date?: string;
    isGetTransactionId?: boolean;
    isFromServices?: boolean;
    is_services?: boolean;
    is_agency_selected?: boolean;
    agency_info?: any;
    homeSearch?:boolean;
}

@Component({
    selector: 'app-animal-lookup-dialog',
    templateUrl: './animal-lookup-dialog.component.html',
    styleUrls: ['./animal-lookup-dialog.component.scss']
})
export class AnimalLookupDialogComponent implements OnInit, OnDestroy {

    subs: Subscription[] = [];
    animalLookupForm: FormGroup;
    speciesAndbreedList: any;

    public gridOptions = {
        context: {
            componentParent: this,
        },
        suppressHorizontalScroll: false,
        rowStyle: { background: 'white' }
    } as GridOptions;
    gridApi: GridApi;
    gridColumnApi: any;
    defaultColDef: any;
    frameworkComponents;
    getRowHeight: any;
    headerHeight: any;
    rowData: Observable<any>;
    columnDefs: any[] = [];
    agencyDetails: any;
    agencyLocationList: any[];
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    speciesList: any[] = [];
    breedList: any[] = [];
    primaryColorList: any[] = [];
    secondaryColorList: any[] = [];
    patternList: any[] = [];
    genderList = ['Male', 'Female'];

    constructor(
        public dialogRef: MatDialogRef<AnimalLookupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private fb: FormBuilder,
        private utilService: UtilService,
        private settingsService: SettingsService,
        private animalService: AnimalService,
        private toast: MatSnackBar,
        private medicalService: MedicalService
    ) {
        this.frameworkComponents = {
            cusotmClickableCell: AgGridClickableCellComponent
        };

        this.getRowHeight = (params) => {
            return 34;
        };
        this.headerHeight = 34;
    }

    ngOnInit(): void {
        this.initAnimalLookupForm();
        this.setupGridColumn();
        this.setMasterData();
    }

    async setMasterData() {
        
        /*let sub = await forkJoin(
            this.animalService.getAllAnimalSpeciesBreedList(),
            this.utilService.getMasterInputs('Animal'),
            this.settingsService.getAgencyDetails(),
          ).subscribe(res => {
            this.speciesAndbreedList = res[0].animal_species_breed;
            this.speciesList = Object.keys(res[0].animal_species_breed);})*/
       /* const sub2 = this.utilService.getMasterInputs('Cases').subscribe(res => {
            this.speciesList = res.input.breeds_per_species.sort((a, b) => a.id - b.id);
            if (this.data.species) {
                this.breedList = this.speciesList.find(ele => ele.value == this.data.species).child_items;
            }

            this.startListeningChangeSpecies();
        });
        this.subs.push(sub2);*/
        let sub = await forkJoin(
                this.animalService.getAllAnimalSpeciesBreedList(),
                this.utilService.getMasterInputs('Animal'),
                this.settingsService.getAgencyDetails(),
          ).subscribe(res => {
               /* this.speciesList = res[0].animal_species_breed;
                if (this.data.species) {
                    this.breedList = this.speciesList.find(ele => ele.value == this.data.species).child_items;
                }*/
                this.speciesAndbreedList = res[0].animal_species_breed;
                this.speciesList = Object.keys(res[0].animal_species_breed);
                if (this.data.species) {
                    this.breedList = this.speciesAndbreedList[this.data.species];
                }
                this.startListeningChangeSpecies();
                this.primaryColorList = res[1].input.animal_primary_color;
                this.secondaryColorList = res[1].input.animal_secondary_color;
                this.patternList =res[1].input.animal_color_pattern;
                this.agencyDetails = res[2];
                this.agencyLocationList = res[2].addresses;
          })

        this.subs.push(sub);
    }

    initAnimalLookupForm() {
        this.animalLookupForm = this.fb.group({
            name: ['',[Validators.required]],
            species: ['',[Validators.required]],
            primary_breed: ['',[Validators.required]],
            secondary_breed: [''],
            primary_color: [''],
            secondary_color: [''],
            pattern: [''],
            gender: [''],
            microchip_number: [''],
            rabis_tag_number: [''],
            village_license_number: [''],
            age_months: ['',[Validators.pattern("^[0-9]*.?[0-9]*$")]]
        });
    }

    startListeningChangeSpecies() {
        const sub = this.animalLookupForm.get('species').valueChanges.subscribe(value => {
            this.breedList = this.speciesAndbreedList[value];
            if(this.breedList && this.breedList.length >0 && this.animalLookupForm.get('primary_breed').value){
                this.animalLookupForm.get('primary_breed').setValue('');
                this.animalLookupForm.get('secondary_breed').setValue('');
            }
        });

        this.subs.push(sub);
    }

    setupGridColumn() {
        this.defaultColDef = Utils.getBaseThemeAgGridCol();
        this.columnDefs = [
            {
                headerName: '',
                field: 'check',
                checkboxSelection: true,
                suppressSizeToFit: true,
                width: 31,
                sortable:false,
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0 0 0 10px'
                },
            },
            {
                headerName: 'Name',
                field: 'name',
                width: 100,
                suppressSizeToFit: true,
                filterParams: Utils.filterParams(),
            },
            {
                headerName: 'Species',
                field: 'species',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filterParams: Utils.filterParams(),
            },
            {
                headerName: 'Primary Breed',
                field: 'primary_breed',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Secondary Breed',
                field: 'secondary_breed',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Primary Color',
                field: 'primary_color',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Secondary Color',
                field: 'secondary_color',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Gender',
                field: 'gender',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Status',
                field: 'status',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Age',
                field: 'age_months',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filter:'agNumberColumnFilter',
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Microchip',
                field: 'microchip_number',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Rabies',
                field: 'rabis_tag_number',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'License',
                field: 'village_license_number',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filterParams: Utils.filterParams()
            }
        ];

        if(this.data.homeSearch){
            this.columnDefs.splice(11, 0,{
                headerName: 'Entity Type',
                field: 'entity_type',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filterParams: Utils.filterParams()
            });
            this.columnDefs.splice(12, 0,{
                headerName: 'Entity Name',
                field: 'entity_name',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filterParams: Utils.filterParams()
            });
            this.columnDefs.splice(13, 0,{
                headerName: 'Contact Email',
                field: 'contact_email',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filterParams: Utils.filterParams()
            });
            this.columnDefs.splice(14, 0,{
                headerName: 'Contact Phone',
                field: 'contact_phone',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filterParams: Utils.filterParams()
            });
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.rowData = of([]);
        this.initialPopulate();
        this.onSearchClicked();
    }

    initialPopulate() {
        this.animalLookupForm.patchValue({
            name: this.data.name ? this.data.name : '',
            microchip_number: this.data.microchip_number ? this.data.microchip_number : '',
            species: this.data.species ? this.data.species : '',
            primary_breed: this.data.primary_breed ? this.data.primary_breed : ''
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    isDisableButtons() {
        return false;
    }

    onSearchClicked() {
        if (this.data.isGetFromTx) {
            this.lookAnimalfromTx();
        } else if (this.data && this.data.is_services) {
            const isAgencySelected = this.data && this.data.is_agency_selected;
            if (isAgencySelected) {
                if(this.data.agency_info.in_network)
                    this.lookupInNetworkInAnimals();
                else
                    this.lookupAnimals();
            } else{
                this.lookupAnimals();
            }
        }else {
            this.lookupAnimals();
        }
    }

    lookAnimalfromTx() {
        let count = 0;
        const filterFormKeys: string[] = [];
        Object.keys(this.animalLookupForm.value).forEach(ele => {
            if (this.animalLookupForm.value[ele] !== '') {
                count = count + 1;
                filterFormKeys.push(ele);
            }
        });
        this.getTenantAnimals();
    }

    getTenantAnimals(): void {
        let count = 0;
        const filterFormKeys: string[] = [];
        Object.keys(this.animalLookupForm.value).forEach(ele => {
            if (this.animalLookupForm.value[ele] !== '') {
                count = count + 1;
                filterFormKeys.push(ele);
            }
        });
        this.rowData = this.animalService.getAnimalTransactInfo().pipe(
            map((res: any[]) => {
             /*   const isServices = this.data && this.data.is_services;
                const isAgencySelected = this.data && this.data.is_agency_selected;
                if (isServices && isAgencySelected) {
                    const agency = this.data && this.data.agency_info;
                    // console.log(agency);
                    res = res.filter(ele => {
                        let outcomeDate = null;
                        let readingDate = null;
                        outcomeDate = moment(ele.outcome_transaction_datetime).format('MM/DD/YYYY');
                        readingDate = this.data.reading_date;
                        if (ele.animal.status === 'IN') {
                            if (this.data.isGetTransactionId) {
                                ele.animal.transaction_id = ele.transaction_id;
                            }
                            return ele;
                        } else if (ele.animal.status === 'OUT' && ele.outcome_mode != null && outcomeDate != null && (readingDate <= outcomeDate)) {
                            return ele;
                        }

                    });
                    // res = res.filter((item: any) => );
                    res = res.map(ele => ele.animal);
                    res.forEach(ele => {
                        ele.age_months = moment().diff(moment(ele.dob, 'MM/DD/YYYY'), 'month');
                        ele.rabis_tag_number = ele.rabies_tag_number;
                        ele.pattern = ele.color_pattern;
                    });

                    if (count > 0) {
                        filterFormKeys.forEach(flKey => {
                            res = res.filter(ele => {
                                const matchString: string = ele[flKey];
                                if (matchString.includes(this.animalLookupForm.get(flKey).value)) {
                                    return ele;
                                }
                            });

                        });
                    }

                    return res;
                } else {*/
                    res = res.filter(ele => {
                        let outcomeDate = null;
                        let readingDate = null;
                        outcomeDate = moment(ele.outcome_transaction_datetime).format('MM/DD/YYYY');
                        readingDate = this.data.reading_date;
                        if (ele.animal.status === 'IN') {
                            if (this.data.isGetTransactionId) {
                                ele.animal.transaction_id = ele.transaction_id;
                            }
                            return ele;
                        } else if (ele.animal.status === 'OUT' && ele.outcome_mode != null && outcomeDate != null && (readingDate <= outcomeDate)) {
                            return ele;
                        }

                    });
                    res = res.map(ele => ele.animal);
                    res.forEach(ele => {
                        ele.age_months = ele.age;
                        ele.rabis_tag_number = ele.rabies_tag_number;
                        ele.pattern = ele.color_pattern;
                    });

                    if (count > 0) {
                     //   console.log(filterFormKeys);
                     //   console.log(res);
                        filterFormKeys.forEach(flKey => {
                            res = res.filter(ele => {
                                const matchString: string = ele[flKey];
                                // console.log(matchString);
                                if (matchString && matchString.includes(this.animalLookupForm.get(flKey).value)) {
                                    return ele;
                                }
                            });

                        });
                    }

                    return res;
               // }
            })
        );
    }

    getAgencyAnimals(): void {
        let count = 0;
        const tenantId = this.data && this.data.agency_info && this.data.agency_info.tenant_id;
        const filterFormKeys: string[] = [];
        Object.keys(this.animalLookupForm.value).forEach(ele => {
            if (this.animalLookupForm.value[ele] !== '') {
                count = count + 1;
                filterFormKeys.push(ele);
            }
        });
       
        this.rowData = this.animalService.getAgencyAnimalInfo(tenantId).pipe(
            map((res: any[]) => {
                    res = res.filter(ele => {
                        let outcomeDate = null;
                        let readingDate = null;
                        outcomeDate = moment(ele.outcome_transaction_datetime).format('MM/DD/YYYY');
                        readingDate = this.data.reading_date;
                        if (ele.animal.status === 'IN') {
                            if (this.data.isGetTransactionId) {
                                ele.animal.transaction_id = ele.transaction_id;
                            }
                            return ele;
                        } else if (ele.animal.status === 'OUT' && ele.outcome_mode != null && outcomeDate != null && (readingDate <= outcomeDate)) {
                            return ele;
                        }

                    });
                    res = res.map(ele => ele.animal);
                    res.forEach(ele => {
                        ele.age_months = ele.age;;
                        ele.rabis_tag_number = ele.rabies_tag_number;
                        ele.pattern = ele.color_pattern;
                    });

                    if (count > 0) {
                        filterFormKeys.forEach(flKey => {
                            res = res.filter(ele => {
                                const matchString: string = ele[flKey];
                                if (matchString.includes(this.animalLookupForm.get(flKey).value)) {
                                    return ele;
                                }
                            });

                        });
                    }

                    return res;
            })
        );
    }

    lookupAnimals() {
        let count = 0;
        Object.keys(this.animalLookupForm.value).forEach(ele => {
            if (this.animalLookupForm.value[ele] != '') {
                count = count + 1;
            }
        });
        if (count == 0) {
            this.rowData = of([]);
           /* this.toast.open('No animal found', 'Ok', {
                duration: 2000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                panelClass: ['warning-snackbar']
            });*/
            return;
        }
        this.rowData = this.utilService.lookupAnimal(this.animalLookupForm.value).pipe(
            map((res: any[]) => {
                if (this.data.isFilterOwn) {
                    res = res.filter(ele => ele.is_own);
                }
                if (this.data.statusFilter && this.data.statusFilter!='') {
                    res = res.filter(ele => ele.status == this.data.statusFilter);
                }
                if (res.length == 0) {
                    this.toast.open('No animal found', 'Ok', {
                        duration: 2000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        panelClass: ['warning-snackbar']
                    });
                }
                return res;
            })
        );
    }

    lookupInNetworkInAnimals():void{
        let count = 0;
        Object.keys(this.animalLookupForm.value).forEach(ele => {
            if (this.animalLookupForm.value[ele] != '') {
                count = count + 1;
            }
        });
        this.rowData = of([]);
        if (count == 0) {
            this.rowData = of([]);
            /*this.toast.open('No animal found', 'Ok', {
                duration: 2000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                panelClass: ['warning-snackbar']
            });*/
            return;
        }
        const tenantId = this.data && this.data.agency_info && this.data.agency_info.tenant_id;
        this.rowData = this.medicalService.lookupInNetworkInAnimals(tenantId,this.animalLookupForm.value).pipe(
            map((res: any[]) => {
               // console.log(res);
                if (res.length == 0) {
                    this.toast.open('No animal found', 'Ok', {
                        duration: 2000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        panelClass: ['warning-snackbar']
                    });
                }
                // console.log(res);
                return res;
            })
        );
    }

    /*lookupServiceAnimals() {
        let count = 0;
        Object.keys(this.animalLookupForm.value).forEach(ele => {
            if (this.animalLookupForm.value[ele] !== '') {
                count = count + 1;
            }
        });
        if (count === 0) {
            this.rowData = of([]);
            this.toast.open('No animal found', 'Ok', {
                duration: 2000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                panelClass: ['warning-snackbar']
            });
            return;
        }
        this.rowData = this.medicalService.lookupServiceAnimals(this.animalLookupForm.value).pipe(
            map((res: any[]) => {
                if (this.data.isFilterOwn) {
                    res = res.filter(ele => ele.is_own);
                }
                if (this.data.statusFilter) {
                    res = res.filter(ele => ele.status === this.data.statusFilter);
                }
                if (res.length === 0) {
                    this.toast.open('No animal found', 'Ok', {
                        duration: 2000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        panelClass: ['warning-snackbar']
                    });
                }
                return res;
            })
        );
    }*/

    makeFieldsRequired() {
        this.animalLookupForm.controls.name.setValidators([Validators.required]);
        this.animalLookupForm.controls.name.updateValueAndValidity();

        this.animalLookupForm.controls.species.setValidators([Validators.required]);
        this.animalLookupForm.controls.species.updateValueAndValidity();

        this.animalLookupForm.controls.primary_breed.setValidators([Validators.required]);
        this.animalLookupForm.controls.primary_breed.updateValueAndValidity();
    }

    onCreateNewClicked() {
       // this.makeFieldsRequired();
        const isServices = this.data && this.data.is_services;
        const isAgencySelected = this.data && this.data.is_agency_selected;
        if (this.animalLookupForm.valid) {
            // let tenantId = StorageService.getItem('tenant_id')
            if (isServices) {
                const selectedRow = this.gridApi?.getSelectedRows()[0];
                const loctionObj = this.agencyLocationList.find(e => e.is_primary);
                const animal: Animal = {
                    name: this.animalLookupForm.get('name').value,
                    species: this.animalLookupForm.get('species').value,
                    primary_breed: this.animalLookupForm.get('primary_breed').value,
                    secondary_breed: this.animalLookupForm.get('secondary_breed').value,
                    primary_color: this.animalLookupForm.get('primary_color').value,
                    secondary_color: this.animalLookupForm.get('secondary_color').value,
                    color_pattern: this.animalLookupForm.get('pattern').value,
                    microchip_number: this.animalLookupForm.get('microchip_number').value,
                    rabies_tag_number: this.animalLookupForm.get('rabis_tag_number').value,
                    village_license_number: this.animalLookupForm.get('village_license_number').value,
                    gender: this.animalLookupForm.get('gender').value,
                    // dob: moment().subtract(this.animalLookupForm.get('age_months').value, 'months').format('MM/DD/YYYY'),
                    dob: null,
                    years:0,
                    months:Number(this.animalLookupForm.get('age_months').value),
                    days:0,
                    // age_months: Number(this.animalLookupForm.get('age_months').value),
                    age: Number(this.animalLookupForm.get('age_months').value),
                    unit: 'Months',
                    weight: 0,
                    weight_ounces: 0,
                    transferrable: false,
                    new_additions: [
                        {
                            stage: 'INTAKE',
                            date_time_stamp: moment(Utils.convertTZ(new Date)).format('MM/DD/YYYY HH:mm:ss'),
                        }
                    ],
                    status: 'IN',
                    location_id: loctionObj && loctionObj.agency_sub_id,
                    location_state: loctionObj && loctionObj.state,
                    location_city: loctionObj && loctionObj.city,
                };

                const sub = this.medicalService.createAnimals(animal).subscribe(res => {
                    this.toast.open('Animal updated', 'Ok', {
                        duration: 2000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        panelClass: ['light-snackbar']
                    });
                    const sub2 = this.medicalService.getAnimalDetailById(res.id, res.mapping_id).subscribe((r: any) => {
                        this.dialogRef.close(r);
                    });
                    this.subs.push(sub2);
                });
                this.subs.push(sub);

            } else {
                const loctionObj = this.agencyLocationList.find(e => e.is_primary);
                const animal: Animal = {
                    name: this.animalLookupForm.get('name').value,
                    species: this.animalLookupForm.get('species').value,
                    primary_breed: this.animalLookupForm.get('primary_breed').value,
                    secondary_breed: this.animalLookupForm.get('secondary_breed').value,
                    primary_color: this.animalLookupForm.get('primary_color').value,
                    secondary_color: this.animalLookupForm.get('secondary_color').value,
                    color_pattern: this.animalLookupForm.get('pattern').value,
                    microchip_number: this.animalLookupForm.get('microchip_number').value,
                    rabies_tag_number: this.animalLookupForm.get('rabis_tag_number').value,
                    village_license_number: this.animalLookupForm.get('village_license_number').value,
                    gender: this.animalLookupForm.get('gender').value,
                    dob:null,
                    // dob: moment().subtract(this.animalLookupForm.get('age_months').value, 'months').format('MM/DD/YYYY'),
                    // age_months: this.animalLookupForm.get('age_months').value,
                    age: Number(this.animalLookupForm.get('age_months').value),
                    years:0,
                    months:Number(this.animalLookupForm.get('age_months').value),
                    days:0,
                    unit: 'Months',
                    weight: 0,
                    weight_ounces: 0,
                    transferrable: false,
                    new_additions: [
                        {
                            stage: 'INTAKE',
                            date_time_stamp: moment(Utils.convertTZ(new Date)).format('MM/DD/YYYY HH:mm:ss'),
                        }
                    ],
                    status: 'IN',
                    location_id: loctionObj && loctionObj.agency_sub_id,
                    location_state: loctionObj && loctionObj.state,
                    location_city: loctionObj && loctionObj.city,
                };

                const sub = this.animalService.createAnimals(animal).subscribe(res => {
                    this.toast.open('Animal created', 'Ok', {
                        duration: 2000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        panelClass: ['light-snackbar']
                    });
                    const sub2 = this.animalService.getAnimalDetailById(res.id, res.mapping_id).subscribe(res => {
                        this.dialogRef.close(res);
                    });
                    this.subs.push(sub2);
                });
                this.subs.push(sub);
            }
        } else {
            this.animalLookupForm.markAllAsTouched();
        }

    }

    onSelectClick() {
        /*const isServices = this.data && this.data.is_services;
        const isAgencySelected = this.data && this.data.is_agency_selected;
        if (isServices) {
            const selectedRow = this.gridApi?.getSelectedRows()[0];
            const loctionObj = this.agencyLocationList.find(e => e.is_primary);
            const animal: Animal = {
                animal_id: selectedRow.id,
                name: selectedRow.name,
                gender: selectedRow.gender,
                species: selectedRow.species,
                microchip_number: selectedRow.microchip_number,
                village_license_number: selectedRow.village_license_number,
                color_pattern: selectedRow.pattern,
                primary_color: selectedRow.primary_color,
                primary_breed: selectedRow.primary_breed,
                rabies_tag_number: selectedRow.rabis_tag_number,
                secondary_breed: selectedRow.secondary_breed,
                secondary_color: selectedRow.secondary_color,
                status: 'IN',
                weight: 0,
                new_additions: [{
                    stage: 'INTAKE',
                    date_time_stamp: moment().format('MM/DD/YYYY HH:mm:ss'),
                }],
                dob: moment().subtract(selectedRow.age_months, 'month').format('MM/DD/YYYY'),
                location_id: loctionObj.agency_sub_id,
                location_state: loctionObj.state,
                location_city: loctionObj.city,
                transferrable: true
            };

            const sub = this.medicalService.createAnimals(animal).subscribe(res => {
                this.toast.open('Animal updated', 'Ok', {
                    duration: 2000,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    panelClass: ['light-snackbar']
                });
                const sub2 = this.medicalService.getAnimalDetailById(res.id, res.mapping_id).subscribe((r: any) => {
                    this.dialogRef.close(r);
                });
                this.subs.push(sub2);
            });
            this.subs.push(sub);

        } else */
        if (!this.data.isGetFromTx) {
            const selectedRow = this.gridApi?.getSelectedRows()[0];
            const loctionObj = this.agencyLocationList.find(e => e.is_primary);
            const animal: Animal = {
                animal_id: selectedRow && selectedRow.id,
                name: selectedRow && selectedRow.name,
                gender: selectedRow && selectedRow.gender,
                species: selectedRow && selectedRow.species,
                microchip_number: selectedRow && selectedRow.microchip_number,
                village_license_number: selectedRow && selectedRow.village_license_number,
                color_pattern: selectedRow && selectedRow.pattern,
                primary_color: selectedRow && selectedRow.primary_color,
                primary_breed: selectedRow && selectedRow.primary_breed,
                rabies_tag_number: selectedRow && selectedRow.rabis_tag_number,
                secondary_breed: selectedRow && selectedRow.secondary_breed,
                secondary_color: selectedRow && selectedRow.secondary_color,
                status: 'IN',
                weight: 0,
                weight_ounces: 0,
                years:selectedRow && selectedRow.years,
                months:selectedRow && selectedRow.months,
                days:selectedRow && selectedRow.days,
                new_additions: [{
                    stage: 'INTAKE',
                    date_time_stamp: moment(Utils.convertTZ(new Date)).format('MM/DD/YYYY HH:mm:ss'),
                }],
                dob: selectedRow && selectedRow.dob,
                location_id: loctionObj && loctionObj.agency_sub_id,
                location_state: loctionObj && loctionObj.state,
                location_city: loctionObj && loctionObj.city,
                transferrable: true
            };


            const sub = this.animalService.createAnimals(animal).subscribe(res => {
                this.toast.open('Animal updated', 'Ok', {
                    duration: 2000,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    panelClass: ['light-snackbar']
                });
                const sub2 = this.animalService.getAnimalDetailById(res.id, res.mapping_id).subscribe(res => {
                    this.dialogRef.close(res);
                });
                this.subs.push(sub2);
            });
            this.subs.push(sub);
        } else {
            this.dialogRef.close(this.gridApi?.getSelectedRows()[0]);
        }
    }

    onRefreshClicked() {
        this.animalLookupForm.reset();
        this.rowData = of([]);
        this.breedList = [];
        this.animalLookupForm.patchValue({
            species: '',
            primary_breed: '',
            secondary_breed: '',
            primary_color: '',
            secondary_color: '',
            pattern: '',
            gender: '',
        });
    }

    ngOnDestroy() {
        this.subs.forEach(ele => ele.unsubscribe());
    }
}
