import { Component, OnInit, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

export interface DialogData {
  nestedDataTitle: string;
  title: string;
  isNested: boolean;
  dataTitle?: string;
  listData: Array<NestedDataNode>;
  showDateTimeField?: boolean;
  showCommentsField?: boolean;
}

export interface NestedDataNode {
  name: string;
  isSelected?: boolean;
  dataList: Array<DataNode>;
  otherText?;
  serviceDate?;
  serviceTime?;
  serviceCommets?;
  showDateTimeField?;
  id?;
}

export interface DataNode {
  name: string;
  isSelected: boolean;
}

@Component({
  selector: 'app-select-list-data-dialog',
  templateUrl: './select-list-data-dialog.component.html',
  styleUrls: ['./select-list-data-dialog.component.scss']
})
export class SelectListDataDialogComponent implements OnInit {

  selectedDataNode: NestedDataNode;
  showTextarea: boolean = false;
  textAreaVal: any;
  otherOptionAlert: boolean = false;
  showDateTimeField: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SelectListDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private cdRef: ChangeDetectorRef
  ) { }
  OptionFirstParentWidth;
  OptionFirstChildWidth;
  @ViewChild('getParentOptionWidth') getParentOptionWidth: ElementRef;
  @ViewChild('getChildOptionWidth') getChildOptionWidth: ElementRef;

  ngOnInit(): void {
    if (this.data.title.toLowerCase() == "surgery") {
      let check = this.data.listData.find(elm => elm.name.toLowerCase() == "other");
      if (check && check.isSelected) { this.textAreaVal = check.otherText; this.showTextarea = true }
    }
  }

  ngAfterViewInit() {
    this.OptionFirstParentWidth = this.getParentOptionWidth.nativeElement.offsetWidth;
  }

  ngAfterViewChecked() {
    setTimeout(() => this.getChildWidth(), 0);
    this.cdRef.detectChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveClicked() {
    if (this.data.title.toLowerCase() == "surgery") {
      let check = this.data.listData.find(elm => elm.name.toLowerCase() == "other");
      if (check) check.dataList = [{ name: this.textAreaVal, isSelected: true }];
    }
    this.dialogRef.close(this.data.listData);
  }

  isDisable() {
    const disabled = this.data.listData.find(elm => elm.name && elm.name.toLowerCase() == "other");
    let check = disabled && disabled.isSelected;
    if (check) {
      if (this.textAreaVal == "" || this.textAreaVal == undefined) {
        this.otherOptionAlert = true;
        return true;
      } else {
        this.otherOptionAlert = false;
        return false;
      }

    } else
      return false;
  }

  onNestednodeClicked(nestedData: NestedDataNode) {
    // console.log('nestedData: ', nestedData);
    
    // console.log(nestedData.isSelected);
    if (nestedData.name.toLowerCase() == "other" && this.data.title.toLowerCase() == "surgery") {
      (!nestedData.isSelected) ? this.showTextarea = true : this.showTextarea = false; //Additional Surgery Other
    }
    if (nestedData.showDateTimeField && nestedData.showDateTimeField == true) {
      if (nestedData.isSelected) {
        nestedData.isSelected = true;
        this.selectedDataNode = nestedData;
      }
    } else if ((!nestedData.isSelected) && this.data.showDateTimeField) {
      this.selectedDataNode = nestedData;
      this.showDateTimeField = !nestedData.isSelected;
      nestedData.isSelected = !nestedData.isSelected
      if (nestedData && nestedData.serviceDate && nestedData.serviceTime) {
        nestedData.isSelected = !nestedData.isSelected;
      }
    } else if (nestedData.dataList.length > 0) {
      if (!nestedData.isSelected) {
        nestedData.isSelected = true;
        this.selectedDataNode = nestedData;
      } else {
        this.selectedDataNode = null;
        nestedData.isSelected = false;
      }
    } else {
      nestedData.isSelected = !nestedData.isSelected;
    }

    if (this.data.showDateTimeField) {
      if (nestedData.serviceDate && nestedData.serviceDate != '')
        nestedData.serviceDate = new Date(nestedData.serviceDate);
      else
        nestedData.serviceDate = '';

      if (nestedData.serviceTime && nestedData.serviceTime != '')
        nestedData.serviceTime = (nestedData.serviceTime);
      else
        nestedData.serviceTime = '';
    }

    if (this.data.showCommentsField) {
      if (nestedData.serviceCommets && nestedData.serviceCommets != '')
        nestedData.serviceCommets = (nestedData.serviceCommets);
      else
        nestedData.serviceCommets = '';

    }
  }


  getChildWidth() {
    if (this.getChildOptionWidth && this.getChildOptionWidth != undefined) {
      this.OptionFirstChildWidth = this.getChildOptionWidth.nativeElement.offsetWidth;
    }
  }

  isSelectedType(nestedData: NestedDataNode): boolean {

    /*if((nestedData.serviceDate && nestedData.serviceDate!='Invalid Date') && (nestedData.serviceTime && nestedData.serviceTime!='')){
        return true;
    }*/
    /*if (!this.data.isNested) {
      return false;
     // return nestedData.isSelected;
    } else {*/
    if (!this.selectedDataNode)
      return false;
    else if (nestedData.name == this.selectedDataNode.name) {
      return true;
    }
    else
      return false;
    // }
  }

  onNodeClicked(nodeData: DataNode, nestedData?: NestedDataNode) {
    if (nestedData.dataList.length == 2 && this.data.title.toLowerCase() == "tests") {
      let checkPositive = nestedData.dataList.find(elm => elm.name.toLowerCase() == "positive");
      let checkNegative = nestedData.dataList.find(elm => elm.name.toLowerCase() == "negative");
      if (checkPositive && checkNegative) {
        if (nodeData.name.toLowerCase() == "positive")
          checkNegative.isSelected = false;
        else if (nodeData.name.toLowerCase() == "negative")
          checkPositive.isSelected = false;
      }
      nodeData.isSelected = !nodeData.isSelected;
    } else {
      nodeData.isSelected = !nodeData.isSelected;
    }
  }

  onTextareaChange(nestedData: NestedDataNode, elementValue) {
    (elementValue != '') ? (this.otherOptionAlert = false) : (this.otherOptionAlert = true);
    this.textAreaVal = elementValue;
  }

  onServiceDateChanged(nestedData: NestedDataNode, event) {
    nestedData.serviceDate = event.target.value;
    if(event.target.value)
      nestedData.isSelected = true;
  }

  onServiceTimeChanged(nestedData: NestedDataNode, event) {
    nestedData.serviceTime = event.target.value;
    if(event.target.value)
      nestedData.isSelected = true;
  }

  onServiceCommentsChanged(nestedData: NestedDataNode, event) {
    nestedData.serviceCommets = event.target.value;
    if(event.target.value)
      nestedData.isSelected = true;
  }

  isNestedNodeHasSelectedData(nestedData: NestedDataNode): boolean {

    if ((nestedData.serviceDate && (nestedData.serviceDate != 'Invalid Date' || nestedData.serviceDate != '')) && (nestedData.serviceTime && nestedData.serviceTime != '')) {
      return true;
    } else if (nestedData.isSelected && nestedData.showDateTimeField && nestedData.showDateTimeField === true) {
      return true;
    } else {
      if ((!nestedData.dataList || nestedData.dataList.length == 0) && (nestedData.serviceDate && (nestedData.serviceDate != 'Invalid Date' || nestedData.serviceDate != '')) && (nestedData.serviceTime && nestedData.serviceTime != '')) {
        return nestedData.isSelected;
      }

      let selectedCount: number = 0;
      nestedData.dataList.forEach(ele => {
        if (ele.isSelected) return selectedCount++;
      });
      return selectedCount > 0;
    }
  }
}