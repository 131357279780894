import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-animal-details-toggle-button',
    templateUrl: './animal-details-toggle-button.component.html',
    styleUrls: ['./animal-details-toggle-button.component.scss']
})
export class AnimalDetailsToggleButtonComponent implements OnInit, OnChanges {
    @Input('stagesList') stagesList: any;
    @Input('selectedList') selectedList: any;
    @Input('animalCurrentStage') animalCurrentStage: string;
    @Output('actionOutput') actionOutput: EventEmitter<any> = new EventEmitter();
    @Output('deleteItem') deleteItem: EventEmitter<any> = new EventEmitter();
    @Input('stageClick') stageClick: boolean;
    disabledStages:any[]=['intake','adopted','booked','released','euthanized'];

    constructor() {

    }

    ngOnInit(): void {

    }

    ngOnChanges() {
    }

    onEventClick(e) {
        if(this.disabledStages.indexOf(e.target.innerText.toLowerCase().trim())== -1)
            this.actionOutput.emit(e.target.innerText);
    }

    onDelete(e, text) {
        this.deleteItem.emit([e, text]);
    }

    isDisableSystemDefined(stage){
        if(this.disabledStages.indexOf(stage && stage.toLowerCase().trim())== -1)
        return false;
        else
        return true;
    }
}
