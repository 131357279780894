import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { UtilService } from 'src/app/core/services/util.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { HomeService } from 'src/app/core/services/home.service';
import { NewMsgDialogComponent } from 'src/app/home/components/new-msg-dialog/new-msg-dialog.component';

export interface DialogData {
  rowdata: any
}

@Component({
  selector: 'app-case-permission-dialog',
  templateUrl: './case-permission-dialog.component.html',
  styleUrls: ['./case-permission-dialog.component.scss']
})

export class CasePermissionDialogComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  basicContactInfoForm: FormGroup;
  is_primary: boolean;
  editStatus: boolean;

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    public dialogRef: MatDialogRef<CasePermissionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private utilService: UtilService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick() {
    //console.log("__+onYesClick+++++",this.data.rowdata)
    const dialogRef = this.dialog.open(NewMsgDialogComponent, {
      width: '70em',
      data: { is_edit: false, msgInfoRow: this.data.rowdata },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
    //  console.log("__+onYesClick++++++",result)
      this.dialogRef.close({...result});
    });
  }



  ngOnDestroy() {
    this.subs.forEach(ele => ele.unsubscribe());
  }
}

