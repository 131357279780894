import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

export interface ServicesDialogData {
  type: string;
  services: Array<AnimalMedicalService>;
}

export interface AnimalMedicalService {
  name: string;
  isSelected: boolean;
}

@Component({
  selector: 'app-animal-services-dialog',
  templateUrl: './animal-services-dialog.component.html',
  styleUrls: ['./animal-services-dialog.component.scss']
})
export class AnimalServicesDialogComponent implements OnInit, OnDestroy {

  subs: Array<Subscription> = [];
  selectedServiceData: ServicesDialogData;

  constructor(
    public dialogRef: MatDialogRef<AnimalServicesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Array<ServicesDialogData>,
  ) { }

  ngOnInit(): void {
  }

  onSaveClicked() {
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onServiceTypeClicked(serviceData: ServicesDialogData) {
    this.selectedServiceData = serviceData;
  }

  onServiceClicked(service: AnimalMedicalService) {
    service.isSelected = !service.isSelected;
  }

  isSelectedType(serviceData: ServicesDialogData): boolean {
    if (!this.selectedServiceData)
      return false;
    else if (serviceData.type == this.selectedServiceData.type)
      return true;
    else
      return false;
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
