import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/core/services/storage.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/core/utils/util';

@Component({
  selector: 'app-layout-no-permisson',
  templateUrl: './layout-no-permisson.component.html',
  styleUrls: ['./layout-no-permisson.component.scss']
})
export class LayoutNoPermissonComponent implements OnInit {

  constructor(private router: Router) { }
  userName:string;
  userRole: string

  ngOnInit(): void {
    this.userName = StorageService.getItem('full_name');
    this.userRole = Utils.userRole(StorageService.getItem('role_name'))
  }

  onLogout() {
    this.router.navigateByUrl('auth');
    StorageService.clearAllOnLogout();
  }
}
