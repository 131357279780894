import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
  selector: '[appCurrencyInput]'
})
export class CurrencyInputDirective {

  constructor(
    public ngControl: NgControl
  ) { }

  @HostListener('ngModelChange', ['$event'])
  onInputChange(value) {
    let convertedStr = this.convertToCurrency(value);
    console.log(convertedStr);
    this.ngControl.valueAccessor.writeValue(convertedStr);
  }


  convertToCurrency(str: string): string {
    let num: number = this.convertToNumber(str);
    if (num)
      return `$${num}`;
  }

  convertToNumber(str: string): number {
    return parseFloat(str.replace(/\D/g, ''));
  }

  @HostListener('focusout', ['$event'])
  onInputFocusOut(event) {
    console.log(event);
    if(event.target.value) {
      let str = `$${parseFloat((event.target.value).split('$')[1]).toFixed(2)}`
      this.ngControl.valueAccessor.writeValue(str);
    }
  }
}
