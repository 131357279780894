<div class="container-fluid">
  <div class="row top-nav-bar pl-1 pr-4">
    <div class="col-md-1 px-0">
      <img src="assets/images/logo_saint_blue.png" alt="">
    </div>
    <div class="col-md">
      <div class="right-container">
      </div>
    </div>
    <div class="col-md-1 icon-container">
      <img src="assets/images/profileIcon.png" alt="" [matMenuTriggerFor]="menu">
      <!-- <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onLogout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu> -->
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item  style="height: 60px;display: flex;flex-wrap: wrap;text-align: left;">
          <span style="width: 100%;height: 16px;margin-top: -18px;">{{userName}}</span>
          <span style="width:100%;height: 30px;font-size: 8px;font-family: HELVETICA_NEUE_LIGHT">{{userRole}}</span>
        </button>
      <button mat-menu-item  (click)="onLogout()" style="height: 40px">
        <span class="mr-1">Logout</span>
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </mat-menu>
    </div>
  </div>
  <div class="main-layout">
    <div
      style="padding: 2rem 0 0 2rem;font-size: 18px;color: #424242;font-family: Helvetica_Neue_Regular;height: 11rem;">
      Welcome to Saint. Your agency is under review. Once approved, you will have full access to the platform.</div>
  </div>
</div>