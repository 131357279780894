import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/core/services/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AppComponent } from "../../../app.component";
import { HomeService } from "../../../core/services/home.service";
import * as _ from "lodash";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isRemember = false;
  duration = 1000;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private homeService: HomeService,
    public dialog: MatDialog,
    private parent: AppComponent
  ) { }

  ngOnInit(): void {
    this.initLoginForm();
  }

  initLoginForm() {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }

  onSignInClicked() {
    const formVal = this.loginForm && this.loginForm.value;
    const payload = {
      user: formVal && formVal.email,
      password: formVal && formVal.password,
    };
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value).subscribe((res) => {
        
        const userObject = {
          firstName: res && res.first_name,
          lastName: res && res.last_name,
          roleName: res && res.role_name,
          tenantId: res && res.tenant_id,
          email: formVal && formVal.email,
          timezone:res && res.preferred_timezone
        };
        localStorage.setItem("user", JSON.stringify(userObject));
        localStorage.setItem("token", res && res.token);
        localStorage.setItem("token_type", res && res.token_type);
        const megentoData = {
          firstname: userObject && userObject.firstName,
          lastname: userObject && userObject.lastName,
          email: userObject && userObject.email,
          password: payload && payload.password,
        };

        // Call Get User Role Service
        this.authService.getUserRoles(userObject.email).subscribe((roleObj: any) => {
          let rolePermissions = roleObj && roleObj.role_permissions;
          rolePermissions = rolePermissions.filter((el: any) => el.access != 'N');
          sessionStorage.setItem('user_roles', JSON.stringify(roleObj));
          setTimeout(() => {
            if (res.token) {
              if (rolePermissions && rolePermissions.length == 0) {
                this.router.navigateByUrl("nopermisson");
                console.clear();
              } else {
                this.router.navigateByUrl("dashboard");
                this.initiateChatLoginProcess(this.loginForm.value, userObject, res);
                this.intitiateMagentoRegistration(megentoData);
                // console.clear();

                // this.homeService.showMenu(true);
                // this.router.navigate(['dashboard/resources/subscription'])
              }
            }
          });
        });
      }, err => {
        if (err) {
          console.log(err);
        }
      });
    }
  }

  initiateChatLoginProcess(formVal: any, userObject: any, res: any): any {
    const adminCreds = {
      user: "pinaki@fx-pros.com",
      password: "password",
    };
    const payload = {
      user: formVal && formVal.email,
      password: formVal && formVal.password,
    };
    this.authService.loginToChat(adminCreds).subscribe((adminLoginRes: any) => {
      const status = adminLoginRes && adminLoginRes.status;
      const authToken =
        adminLoginRes && adminLoginRes.data && adminLoginRes.data.authToken;
      const userId =
        adminLoginRes && adminLoginRes.data && adminLoginRes.data.userId;
      const auth = {
        authToken,
        userId,
      };
      if (status === "success") {
        this.authService.adminUserList(auth).subscribe((chatUsers: any) => {
          const users = chatUsers.users;
          const userInfo = [];
          _.forEach(users, (item: any) => {
            const emails = item && item.emails;
            if (emails && emails.length) {
              const emailAddress = item.emails[0].address;
              userInfo.push({ id: item._id, email: emailAddress });
            }
          });
          const currentUser = userInfo.find(
            (e: any) => e.email === userObject.email
          );
          if (currentUser && currentUser.id) {
            const userName = userObject.firstName + " " + userObject.lastName;
            const updateUser = {
              userId: currentUser.id,
              data: {
                email: this.loginForm.value.email,
                password: this.loginForm.value.password,
                name: userName,
                // username: this.getUserName(users, userName, this.loginForm.value.email),
                roles: ["admin"],
              },
            };
            this.authService
              .updateUserInfo(updateUser, auth)
              .subscribe((response: any) => {
                const success = response.success;
                if (success) {
                  // Login User to Chat if user is already registered
                  this.authService.loginToChat(payload).subscribe(
                    (loginRes: any) => {
                      const status = loginRes && loginRes.status;
                      if (status === "success") {
                        const data = loginRes && loginRes.data;
                        const token = data && data.authToken;
                        const me = data && data.me;
                        localStorage.setItem("Meteor.loginToken", token);
                        localStorage.setItem("Meteor.userId", me && me._id);
                        localStorage.setItem("me", JSON.stringify(me));
                        // this.authService.isUserLoggedIn(true);
                        setTimeout(() => {
                          const endpoint = `https://chat.getsaint.com/home?resumeToken=${token}`;
                          this.parent.loadChat(endpoint);
                        }, this.duration);
                      }
                    },
                    (err: HttpErrorResponse) => {
                      // console.log(err);
                      const eStatus = err && err.status;
                      const resMessage = err && err.error && err.error.message;
                      if (eStatus === 401 && resMessage === "Unauthorized") {
                        // Registering the user because it is not registered i.e => status === 401
                        // Register and Login User to Chat if user is not already registered
                        this.registerAndLoginUserForChat(
                          userObject,
                          formVal,
                          res,
                          auth,
                          users
                        );
                      }
                    }
                  );
                }
              });
          } else {
            this.registerAndLoginUserForChat(
              userObject,
              formVal,
              res,
              auth,
              users
            );
          }
        });
      }
    });
  }

  intitiateMagentoRegistration(payload: any): any {
    this.authService.registerUserWithMagento(payload).subscribe((res: any) => { });
    this.authService.microchipExpiryValidation().subscribe();
  }

  registerAndLoginUserForChat(
    userObject: any,
    formVal: any,
    loginRes: any,
    auth: any,
    userList: any
  ): void {
    const adminCreds = {
      user: "pinaki@fx-pros.com",
      password: "password",
    };

    const userName = userObject.firstName + " " + userObject.lastName;
    const payload = {
      email: formVal && formVal.email,
      password: formVal && formVal.password,
      name: userName,
      username: userName.replace(/\s/g, ""),
      roles: ["admin"],
    };
    // Register the user with admin credentials
    this.authService.registerUserForChat(payload, auth).subscribe(
      (register: any) => {
        const registered = register && register.success;
        if (registered === true || registered === "true") {
          // Logout the admin once registration is successfull
          this.LogInUserAfterUpdate(formVal, loginRes, auth);
        }
      },
      (error: any) => {
        // console.log(error);
        console.clear();
        const errorType = error.error.errorType;
        if (errorType === "error-field-unavailable") {
          this.authService
            .getUserDetails(payload.email, auth)
            .subscribe((r: any) => {
              const username =
                r && r.users && r.users.length && r.users[0].username;
              this.authService
                .getUserInfo(username, auth)
                .subscribe((res: any) => {
                  const success = res && res.success;
                  if (success) {
                    const userInfo = res && res.user;
                    const updateUser = {
                      userId: userInfo && userInfo._id,
                      data: {
                        email: this.loginForm.value.email,
                        password: this.loginForm.value.password,
                        name: userName,
                        // username: this.getUserName(users, userName, this.loginForm.value.email),
                        roles: ["admin"],
                      },
                    };
                    this.authService
                      .updateUserInfo(updateUser, auth)
                      .subscribe((onUpdate: any) => {
                        const success = onUpdate && onUpdate.success;
                        if (success) {
                          // Logout the admin once registration is successfull
                          this.LogInUserAfterUpdate(formVal, loginRes, auth);
                        }
                      });
                  } else {
                    const updatedPayload = {
                      email: formVal && formVal.email,
                      password: formVal && formVal.password,
                      name: userName,
                      username: this.getUserName(
                        userList,
                        userName,
                        formVal.email
                      ),
                    };
                    this.authService
                      .registerUserForChat(updatedPayload, auth)
                      .subscribe((register: any) => {
                        const registered = register && register.success;
                        if (registered === true || registered === "true") {
                          // Logout the admin once registration is successfull
                          this.LogInUserAfterUpdate(formVal, loginRes, auth);
                        }
                      });
                  }
                });
            });
          //   }
          // );
        }
      }
    );
  }

  LogInUserAfterUpdate(formVal: any, res: any, auth: any): any {
    this.authService.logoutAfterRegistration(auth).subscribe((logout: any) => {
      console.log(logout);

      if (logout && logout.status === "success") {
        const loginPayload = {
          user: formVal && formVal.email,
          password: formVal && formVal.password,
        };

        // After admin Logout, login and redirect new user to application
        this.authService
          .loginToChat(loginPayload)
          .subscribe((chatLoginRes: any) => {
            const status = chatLoginRes && chatLoginRes.status;
            if (status === "success") {
              const data = chatLoginRes && chatLoginRes.data;
              const token = data && data.authToken;
              const me = data && data.me;
              localStorage.setItem("Meteor.loginToken", token);
              localStorage.setItem("Meteor.userId", me && me._id);
              localStorage.setItem("me", JSON.stringify(me));
              // setTimeout(() => {
              //   if (res.token) {
              //     if (res.permissions.length === 0) {
              //       this.router.navigateByUrl("nopermisson");
              //       console.clear();
              //     } else {
              setTimeout(() => {
                const endpoint = `https://chat.getsaint.com/home?resumeToken=${token}`;
                this.parent.loadChat(endpoint);
              }, this.duration);
            }
          });
      }
    });
  }

  getUserName(userList: any[], userName: any, email: any): any {
    let username = `${userName.replace(/\s/g, "")}`;
    const user = _.find(userList, (item: any) => {
      if (item && item.emails && item.emails.length) {
        return email === item.emails[0].address;
      }
    });
    const chatUserNames = _.map(userList, (item: any) => item.username);
    const userNameAvailable = _.includes(chatUserNames, username);
    const chatEmails = _.map(userList, (item: any) => {
      if (item && item.emails && item.emails.length) {
        return item.emails[0].address;
      }
    });
    const emailRegistered = _.includes(chatEmails, email);
    if (user && user._id) {
      if (userNameAvailable && emailRegistered) {
        username = user.username;
      } else if (userNameAvailable && !emailRegistered) {
        username = `${username}${this.getRandomNumber()}`;
      } else {
        username = username;
      }
    } else {
      username = `${username}${this.getRandomNumber()}`;
    }
    return username;
  }

  getRandomNumber(): any {
    const length = 2;
    return Math.floor(
      Math.pow(10, length - 1) +
      Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
    );
  }

  forgotPassword() {
    this.router.navigateByUrl("forgot");
    // this.router.navigateByUrl('login');
  }
}
