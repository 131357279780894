<div mat-dialog-title>
  <div>
    <span>Services</span>
  </div>
  <button mat-icon-button>
    <mat-icon (click)="onNoClick()">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="row px-2">
    <div class="col-4">
      <div class="select-box-container" [ngClass]="{'border-no-b': data.length>0, 'border-all': data.length==0}">
        <span class="title">Type</span>
      </div>
      <div class="select-box-container" *ngFor="let serviceData of data; let i=index;"
        (click)="onServiceTypeClicked(serviceData)"
        [ngClass]="{'border-no-b': i<data.length-1, 'border-all': i==data.length-1, 'selected': isSelectedType(serviceData)}">
        <span>{{serviceData.type}}</span>
      </div>
    </div>
    <div class="col" *ngIf="selectedServiceData && selectedServiceData.services.length > 0">
      <div class="select-box-container"
        [ngClass]="{'border-no-b': selectedServiceData.services.length>0, 'border-all': selectedServiceData.services.length==0}">
        <span class="title">Service</span>
      </div>
      <div class="select-box-container" *ngFor="let service of selectedServiceData.services; let i=index;"
        (click)="onServiceClicked(service)"
        [ngClass]="{'border-no-b': i<selectedServiceData.services.length-1, 'border-all': i==selectedServiceData.services.length-1, 'selected': service.isSelected}">
        <mat-icon *ngIf="service.isSelected">done</mat-icon>
        <span>{{service.name}}</span>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button class="white-button" (click)="onNoClick()">CANCEL</button>
  <button mat-raised-button class="green-button" (click)="onSaveClicked()" cdkFocusInitial>
    SAVE
  </button>
</div>