
<div class="row mb-4" style="align-items: center; padding-right: 5px;">

    <div class="status-tile-container">
        <span>{{status}}</span>
        <span>05/02/2020</span>
    </div>
    <div class="col dash">
        <div class="row"></div>
    </div>
<!-- <div class="status-tile-container">
        <span>UNDER REVIEW</span>
        <span>05/02/2020</span>
    </div>
    <div class="col dash">
        <div class="row"></div>
    </div>
    <div class="status-tile-container">
        <span>ON HOLD</span>
        <span>05/02/2020</span>
    </div>
    <div class="col dash">
        <div class="row"></div>
    </div>
    <div class="status-tile-container">
        <span>ENRICHMENT</span>
        <span>05/02/2020</span>
    </div>
    <div class="col dash">
        <div class="row"></div>
    </div>
    <div class="status-tile-container">
        <span>READY TO ADOPT</span>
        <span>05/02/2020</span>
    </div>
    <div class="col dash">
        <div class="row"></div>
    </div>
    <div class="status-tile-container">
        <span>HOLDING</span>
        <span>05/02/2020</span>
    </div>
    <div class="col dash">
            <div class="row"></div>
        </div>
    <div class="status-tile-container">
        <span>RELEASED</span>
        <span>05/02/2020</span>
    </div> -->
</div>