<div class="mat-elevation-z4 sl-card app-tile" (click)="onEventClick($event)"
    [ngStyle]="{'background-color': this.bgColor,'min-height':this.minHeight,'box-shadow':this.boxShadow}">
    <span class="sl-card-header-font pl-2">{{title}}</span>
    <div class="sl-card-body">
        <div class="sl-body-child-1" [ngStyle]="{'color': this.totalCountColor}">
            {{totalCount | number}}
        </div>
        <div class="sl-body-child-2" [ngStyle]="{'font-size': this.messageFontSize, 'text-transform':this.messageTextForans}">            
            <!-- <span *ngIf="currencySign" [ngStyle]="{'color': this.msgCountColor}">{{msgCount |currency:'USD':true}}</span> -->
            <p *ngIf="currencySign"
                [ngStyle]="{'color': this.msgCountColor}">{{currencySign}}{{msgCount | number}}</p>
            <p *ngIf="!currencySign" [ngStyle]="{'color': this.msgCountColor}">{{msgCount |number}}</p>
            <p class="titleText" [ngStyle]="{'color': this.messageColor}">{{message}}</p>
        </div>
    </div>
</div>