import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Animal } from 'src/app/core/models/animal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilService } from 'src/app/core/services/util.service';
import { Subscription, Observable, of, BehaviorSubject } from 'rxjs';
import { GridApi, GridOptions, _ } from 'ag-grid-community';
import { AgGridClickableCellComponent } from '../../components/ag-grid-clickable-cell/ag-grid-clickable-cell.component';
import { Utils } from 'src/app/core/utils/util';
import * as _moment from 'moment';
import { Medication } from 'src/app/core/models/medication';
import { difference, update } from 'lodash';
//import { parse } from 'path';

const moment = _moment;

export interface DialogData {
  type: string;
  animal: Animal;
  selectedMedication?: Medication[];
  is_from_services?: boolean;
  is_from_medical_event?: boolean;
  masterData?: any;
}

// interface Medication {
//   date: string;
//   time: string;
//   medicine: string;
//   unit: string;
//   dosage: number;
//   instruction: string;
// }

@Component({
  selector: 'app-add-edit-medication-dialog',
  templateUrl: './add-edit-medication-dialog.component.html',
  styleUrls: ['./add-edit-medication-dialog.component.scss']
})
export class AddEditMedicationDialogComponent implements OnInit, OnDestroy {

  subs: Array<Subscription> = [];
  medicationForm: FormGroup;
  medicalMasterData: any;

  medicationHistoryGridApi: GridApi;
  medicationGridApi: GridApi;
  public gridOptions = {
    context: {
      componentParent: this,
    },
    suppressHorizontalScroll: false,
    rowStyle: { background: 'white' }
  } as GridOptions;
  defaultColDef: any;
  frameworkComponents;
  getRowHeight: any;
  headerHeight: any;
  columnDefs: any[] = [];

  medicationHistoryRowData: Observable<any>;
  medicationHistoryColumnDefs: Array<any> = [];
  medicationRowData: BehaviorSubject<Array<Medication>>;
  medicationColumnDefs: Array<any> = [];
  rowsCreated: boolean = false;
  rowsDeleted: boolean = false;
  deleteIds: any[] = [];

  isFromServices = false;
  isFromMedicalEvent = false;
  constructor(
    public dialogRef: MatDialogRef<AddEditMedicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private utilService: UtilService
  ) {
    this.frameworkComponents = {
      cusotmClickableCell: AgGridClickableCellComponent,
    };

    this.getRowHeight = (params) => {
      return 34;
    }
    this.headerHeight = 34;
  }

  ngOnInit(): void {
    this.initMedicationForm();
    this.setupMedicationHistoryGridColumn();
    this.setupMedicationGridColumn();
    this.isFromServices = this.data && this.data.is_from_services ? this.data.is_from_services : false;
    this.isFromMedicalEvent = this.data && this.data.is_from_medical_event ? this.data.is_from_medical_event : false;
    this.setMasterData();
  }

  setMasterData(): void {
    if (this.isFromServices) {
      this.getServicesMasterData();
    } else if (this.isFromMedicalEvent) {
      this.getMedicalEventMasterData();
    }
    if (!this.isFromServices && !this.isFromMedicalEvent) {
      this.getMasterData();
    }
  }

  initMedicationForm() {
    this.medicationForm = this.fb.group({
      medicine: ['', [Validators.required]],
      dosage: ['0.00', [Validators.required, Validators.min(0.01)]],
      unit: ['', [Validators.required]],
      frequency: ['', [Validators.required]],
      start_date: ['', [Validators.required]],
      end_date: [''],
      instruction: ['']
    }, { validator: this.dateLessThan('start_date', 'end_date') });
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from].value;
      let t = group.controls[to].value;
      if ((f !== '' && t !== '')) {
        return (f <= t) ? null : { range: true };
      }
    }
  }

  getServicesMasterData(): void {
    if (this.data.selectedMedication && this.data.selectedMedication.length > 0) {
      this.medicationRowData = new BehaviorSubject<Medication[]>([]);
      this.medicationRowData.next(this.data.selectedMedication);
    }
    let sub = this.utilService.getMasterInputs('MedicalEvent').subscribe(res => {
      const medicineList = this.data && this.data.masterData.filter((el: any) => el.service_type == 'Medications').map((elm: any) => {
        elm.value = elm.service;
        return elm;
      });
      const input = res && res.input;
      input.animal_medicine_list = medicineList;
      this.medicalMasterData = input;
    })
    this.subs.push(sub);
  }

  getMedicalEventMasterData(): void {
    if (this.data.selectedMedication && this.data.selectedMedication.length > 0) {
      this.medicationRowData = new BehaviorSubject<Medication[]>([]);
      this.medicationRowData.next(this.data.selectedMedication);
    }

    let sub = this.utilService.getMasterInputs('MedicalEvent').subscribe(res => {
      const medicineList = this.data && this.data.masterData;
      const input = res && res.input;
      input.animal_medicine_list = medicineList;
      this.medicalMasterData = input;
    })
    this.subs.push(sub);
  }

  getMasterData() {
    if (this.data.selectedMedication && this.data.selectedMedication.length > 0) {
      this.medicationRowData = new BehaviorSubject<Medication[]>([]);
      this.medicationRowData.next(this.data.selectedMedication);
    }
    let sub = this.utilService.getMasterInputs('MedicalEvent').subscribe(res => {
      this.medicalMasterData = res.input;
      // this.medicalMasterData?.animal_medicine_freq.push({"id":23,"value":"Once In 3 Weeks"})
    })
    this.subs.push(sub);
  }

  setupMedicationHistoryGridColumn() {
    this.defaultColDef = Utils.getBaseThemeAgGridCol();
    this.medicationHistoryColumnDefs = [
      {
        headerName: 'Date',
        field: 'date',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        cellRenderer: this.columnRender(),
        filterParams: Utils.dateFilterParams()
      },
      {
        headerName: 'Time',
        field: 'time',
        filter: "timeFilter",
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        }
      },
      {
        headerName: 'Medicine',
        field: 'medicine',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'flex-start'
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Unit',
        field: 'unit',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Dosage',
        field: 'dosage',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.filterParams()
      },
    ];
  }

  setupMedicationGridColumn() {
    this.defaultColDef = Utils.getBaseThemeAgGridCol();
    this.medicationColumnDefs = [
      {
        headerName: '',
        field: 'check',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        suppressSizeToFit: true,
        sortable: false,
        resizable: false,
        filter: false,
        minWidth: 10,
        width: 31,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          display: 'flex',
          justifyContent: 'center',
          padding: '0 0 0 10px'
        },
      },
      {
        headerName: 'Date',
        field: 'medication_date',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.dateFilterParams()
      },
      {
        headerName: 'Time',
        field: 'medication_time',
        filter: "timeFilter",
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        }
      },
      {
        headerName: 'Medicine',
        field: 'medicine',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'flex-start'
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Unit',
        field: 'unit',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Dosage',
        field: 'dosage',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Instruction',
        field: 'instruction',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'flex-start'
        },
        filterParams: Utils.filterParams()
      },
    ];
  }

  columnRender() {
    return 'cusotmClickableCell';
  }

  onSaveClicked() {
    let MedicationResult = {
      "delete": this.deleteIds,
      "update": this.medicationRowData.getValue()
    }
    this.dialogRef.close(MedicationResult);
  }


  onCreateClicked() {
    if (this.medicationForm.invalid) {
      this.medicationForm.markAllAsTouched();
      return;
    }

    let medications: Medication[] = this.medicationRowData.getValue();
    medications = [...medications, ...this.getMedicationAccordingToForm()];
    this.medicationRowData.next(medications);
    this.rowsCreated = true;
  }

  getMedicationAccordingToForm(): Array<Medication> {
    let medicationList: Medication[] = [];
    switch (this.medicationForm.get('frequency').value) {
      case 'Daily AM':
        medicationList = this.medicationListForDailyDose('AM');
        break;

      case 'Daily PM':
        medicationList = this.medicationListForDailyDose('PM');
        break;

      case 'Monthly':
        medicationList = this.medicationListForMonthlyDose();
        break;

      case 'Single Dose':
        medicationList = this.medicationListForSingleDose();
        break;

      case 'Twice Daily':
        medicationList = this.medicationListForDailyDose('Twice Daily');
        break;

      case 'Twice Monthly':
        medicationList = this.medicationListForMonthlyDose('Twice Monthly');
        break;

      case 'Twice Weekly':
        medicationList = this.medicationListForWeeklyDose('Twice Weekly');
        break;

      case 'Once in 3 weeks':
        medicationList = this.medicationListForWeeklyDose('Once in 3 weeks');
        break;

      case 'Weekly AM':
        medicationList = this.medicationListForWeeklyDose('AM');
        break;

      case 'Weekly PM':
        medicationList = this.medicationListForWeeklyDose('PM');
        break;

      default:
        break;
    }
    return medicationList;
  }

  medicationListForDailyDose(type: string): Array<Medication> {
    let medicationList: Medication[] = [];
    let startDate: _moment.Moment = moment(this.medicationForm.get('start_date').value, 'MM/DD/YYYY');
    let endDate: _moment.Moment = moment(this.medicationForm.get('end_date').value, 'MM/DD/YYYY');
    for (let index = 0; index <= endDate.diff(startDate, 'days'); index++) {
      let tempDate: _moment.Moment = moment(this.medicationForm.get('start_date').value, 'MM/DD/YYYY');

      if (type == 'Twice Daily') {
        let medication: Medication = {
          medical_id: '',
          medicine: this.medicationForm.get('medicine').value,
          medication_date: tempDate.add(index, 'days').format('MM/DD/YYYY'),
          medication_time: '',
          dosage: parseFloat(this.medicationForm.get('dosage').value).toFixed(2),
          frequency: this.medicationForm.get('frequency').value,
          unit: this.medicationForm.get('unit').value,
          instruction: this.medicationForm.get('instruction').value
        };
        medicationList.push({ ...medication, medication_time: '8:30 AM', medical_id: Utils.getUUIDv4() });
        medicationList.push({ ...medication, medication_time: '8:30 PM', medical_id: Utils.getUUIDv4() });
      } else {
        let medication: Medication = {
          medical_id: Utils.getUUIDv4(),
          medicine: this.medicationForm.get('medicine').value,
          medication_date: tempDate.add(index, 'days').format('MM/DD/YYYY'),
          medication_time: type == 'AM' ? '08:30 AM' : '08:30 PM',
          dosage: parseFloat(this.medicationForm.get('dosage').value).toFixed(2),
          frequency: this.medicationForm.get('frequency').value,
          unit: this.medicationForm.get('unit').value,
          instruction: this.medicationForm.get('instruction').value
        };
        medicationList.push(medication);
      }
    }
    return medicationList;
  }

  medicationListForWeeklyDose(type: string): Array<Medication> {
    let medicationList: Medication[] = [];
    let startDate: _moment.Moment = moment(this.medicationForm.get('start_date').value, 'MM/DD/YYYY');
    let endDate: _moment.Moment = moment(this.medicationForm.get('end_date').value, 'MM/DD/YYYY');
    if (type == 'Once in 3 weeks') {
      for (let index = 0; index <= endDate.diff(startDate, 'weeks'); index += 3) {

        let tempDate: _moment.Moment = moment(this.medicationForm.get('start_date').value, 'MM/DD/YYYY');
        let medication: Medication = {
          medical_id: '',
          medicine: this.medicationForm.get('medicine').value,
          medication_date: '',
          medication_time: '08:30 AM',
          dosage: parseFloat(this.medicationForm.get('dosage').value).toFixed(2),
          frequency: this.medicationForm.get('frequency').value,
          unit: this.medicationForm.get('unit').value,
          instruction: this.medicationForm.get('instruction').value
        };
        medicationList.push({ ...medication, medication_date: tempDate.add(index, 'weeks').format('MM/DD/YYYY'), medical_id: Utils.getUUIDv4() });
      }
    } else {
      for (let index = 0; index <= endDate.diff(startDate, 'weeks'); index++) {
        let tempDate: _moment.Moment = moment(this.medicationForm.get('start_date').value, 'MM/DD/YYYY');

        if (type == 'Twice Weekly') {
          let medication: Medication = {
            medical_id: '',
            medicine: this.medicationForm.get('medicine').value,
            medication_date: '',
            medication_time: '08:30 AM',
            dosage: parseFloat(this.medicationForm.get('dosage').value).toFixed(2),
            frequency: this.medicationForm.get('frequency').value,
            unit: this.medicationForm.get('unit').value,
            instruction: this.medicationForm.get('instruction').value
          };
          medicationList.push({ ...medication, medication_date: tempDate.add(index, 'weeks').format('MM/DD/YYYY'), medical_id: Utils.getUUIDv4() });
          if (endDate.diff(tempDate.add(4, 'days'), 'days') >= 0)
            medicationList.push({ ...medication, medication_date: tempDate.format('MM/DD/YYYY'), medical_id: Utils.getUUIDv4() });
        } else {
          let medication: Medication = {
            medical_id: Utils.getUUIDv4(),
            medicine: this.medicationForm.get('medicine').value,
            medication_date: tempDate.add(index, 'weeks').format('MM/DD/YYYY'),
            medication_time: type == 'AM' ? '08:30 AM' : '08:30 PM',
            dosage: parseFloat(this.medicationForm.get('dosage').value).toFixed(2),
            frequency: this.medicationForm.get('frequency').value,
            unit: this.medicationForm.get('unit').value,
            instruction: this.medicationForm.get('instruction').value
          };
          medicationList.push(medication);
        }
      }
    }

    return medicationList;
  }

  medicationListForMonthlyDose(type?: string): Array<Medication> {
    let medicationList: Medication[] = [];
    let startDate: _moment.Moment = moment(this.medicationForm.get('start_date').value, 'MM/DD/YYYY');
    let endDate: _moment.Moment = moment(this.medicationForm.get('end_date').value, 'MM/DD/YYYY');
    for (let index = 0; index <= endDate.diff(startDate, 'months'); index++) {
      let tempDate: _moment.Moment = moment(this.medicationForm.get('start_date').value, 'MM/DD/YYYY');

      if (type == 'Twice Monthly') {
        let medication: Medication = {
          medical_id: '',
          medicine: this.medicationForm.get('medicine').value,
          medication_date: '',
          medication_time: '08:30 AM',
          dosage: parseFloat(this.medicationForm.get('dosage').value).toFixed(2),
          frequency: this.medicationForm.get('frequency').value,
          unit: this.medicationForm.get('unit').value,
          instruction: this.medicationForm.get('instruction').value
        };
        medicationList.push({ ...medication, medication_date: tempDate.add(index, 'month').format('MM/DD/YYYY'), medical_id: Utils.getUUIDv4() });
        if (endDate.diff(tempDate.add(15, 'days'), 'days') >= 0)
          medicationList.push({ ...medication, medication_date: tempDate.format('MM/DD/YYYY'), medical_id: Utils.getUUIDv4() });
      } else {
        let medication: Medication = {
          medical_id: Utils.getUUIDv4(),
          medicine: this.medicationForm.get('medicine').value,
          medication_date: tempDate.add(index, 'month').format('MM/DD/YYYY'),
          medication_time: '08:30 AM',
          dosage: parseFloat(this.medicationForm.get('dosage').value).toFixed(2),
          frequency: this.medicationForm.get('frequency').value,
          unit: this.medicationForm.get('unit').value,
          instruction: this.medicationForm.get('instruction').value
        };
        medicationList.push(medication);
      }
    }
    return medicationList;
  }

  onFocusOutEvent(event) {
    if (!isNaN(event.target.value)) {
      const valueSplit = (event.target.value).split(".");
      if (valueSplit[0].length > 0) {
        if (valueSplit[0].length < 4) {
          this.medicationForm.get('dosage').setValue(parseFloat(event.target.value).toFixed(2));
        } else {
          this.medicationForm.get('dosage').setValue(parseFloat(`${valueSplit[0].substring(0, 3)}.${valueSplit[1]}`).toFixed(2))
          return false;
        }
      } else {
        this.medicationForm.get('dosage').setValue(parseFloat(event.target.value).toFixed(2));
      }
    }
  }

  medicationListForSingleDose(): Array<Medication> {
    let medication: Medication = {
      medical_id: Utils.getUUIDv4(),
      medicine: (this.medicationForm.get('medicine').value),
      medication_date: moment(this.medicationForm.get('start_date').value).format('MM/DD/YYYY'),
      medication_time: '08:30 AM',
      dosage: parseFloat(this.medicationForm.get('dosage').value).toFixed(2),
      frequency: this.medicationForm.get('frequency').value,
      unit: this.medicationForm.get('unit').value,
      instruction: this.medicationForm.get('instruction').value
    };
    return [medication];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onMedicationHistoryGridReady(params) {
    this.medicationHistoryGridApi = params.api;
    this.medicationHistoryGridApi.sizeColumnsToFit();
    this.medicationHistoryRowData = of([]);
  }

  onMedicationGridReady(params) {
    this.medicationGridApi = params.api;
    this.medicationGridApi.sizeColumnsToFit();
    if (this.data.selectedMedication.length == 0 || !this.data.selectedMedication)
      this.medicationRowData = new BehaviorSubject<Medication[]>([]);
  }

  onDateChange(event, field_name) {
    if (moment(event.target.value).isValid()) {
      let date = moment(event.target.value, 'MM/DD/YYYY');
      let patchObj = {};
      patchObj[field_name] = date.format('MM/DD/YYYY');
      this.medicationForm.patchValue(patchObj)
    } else {
      this.medicationForm.controls[field_name].setErrors({ invalid: true });
    }
  }

  onRemoveSelectedMedication() {
    let selectedMedication: Medication[] = this.medicationGridApi.getSelectedRows();
    let allMedication: Medication[] = this.medicationRowData.getValue();

    let filteredMedication = allMedication.filter(med => {
      this.rowsDeleted = true;
      let findIndexVal: any;
      if (med.hasOwnProperty('medical_id')) {
        findIndexVal = selectedMedication.findIndex(ele => ele.medical_id == med.medical_id);
      } else if (!med.hasOwnProperty('medical_id') && findIndexVal == undefined) {
        findIndexVal = selectedMedication.findIndex((elm: any) => elm.medicine.toLowerCase() == med.medicine.toLowerCase());
      }
      if (findIndexVal == -1) {
        return findIndexVal;
      } else if (Number(med.medical_id)) {
        (med.medical_id) ? this.deleteIds.push(med.medical_id) : '';
      }

      //return selectedMedication.findIndex(ele => ele.medical_id == med.medical_id) == -1;
    });
    this.medicationRowData.next(filteredMedication);
  }

  isdisableCheck() {
    if (this.medicationRowData) {
      let allMedication: Medication[] = this.medicationRowData.getValue();
      if (this.rowsDeleted || (allMedication.length > 0 && this.rowsCreated))
        return false;
      else
        return true;
    } else
      return true;
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
