import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-action-tile',
  templateUrl: './tile-action.component.html',
  styleUrls: ['./tile-action.component.scss']
})
export class TileActionComponent implements OnInit {

  @Input('message') message: string;
  @Input('actionName') actionName: string;
  @Input('bgColor') bgColor: string;
  @Input('messageColor') messageColor: string;
  @Input('actionSign') actionSign: string;
  @Input('minHeight') minHeight: number;
  @Input('minWidth') minWidth: number;
  @Input('img') img: string;
  @Input('messageFontSize') messageFontSize: number;
  @Input('messageTextForans') messageTextForans: string;
  @Input('disabled') disabled: boolean;
  @Output('actionOutput') actionOutput: EventEmitter<any> = new EventEmitter();
 //
  constructor() {

   }

  ngOnInit(): void {
  }
  onEventClick(e) {
    this.actionOutput.emit(this.actionName);
  }
}
