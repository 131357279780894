<div class="mat-dialog-title">
    <div>
        <span>{{ data && data.name }}</span>
    </div>
    <button (click)="onNoClick()">
        <!-- <mat-icon >close</mat-icon> -->
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg"
            viewBox="0 0 16 16">
            <path
                d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
        </svg>
    </button>
</div>
<div class="container image-viewer-container">
    <img [src]="data.primary_image_url" alt="">
</div>
<div class="row w-100 m-0 my-4" [hidden]="!canDelete">
    <div class="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
        <button matTooltip="Delete Image" class="delete-button" (click)="onDeleteClick()">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>