<!-- <div mat-dialog-title>
    <div></div>
    <button mat-icon-button>
        <mat-icon (click)="onCancelClick()">close</mat-icon>
    </button>

</div> -->
<div mat-dialog-content>
    <div class="dialog-qu">{{data.deleteMsg}}</div>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="onNolClick()">No</button>
    <button mat-raised-button color="accent" (click)="onYesClick()" cdkFocusInitial>Yes</button>
</div>