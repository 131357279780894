import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AnimalService } from 'src/app/core/services/animal.service';
import { Utils } from 'src/app/core/utils/util';
import { GridApi, GridOptions, GridReadyEvent, RowDataTransaction, RowNode, StartEditingCellParams } from 'ag-grid-community';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
  rowdata: any
}

@Component({
  selector: 'app-assign-animal-parent',
  templateUrl: './assign-animal-parent.component.html',
  styleUrls: ['./assign-animal-parent.component.scss']
})
export class AssignAnimalParentComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  // gridApi: GridApi;
  // gridColumnApi: any;
  frameworkComponents;
  defaultColDef: any;

  getRowHeight: any;
  headerHeight: any;

  public gridOptions = {
    context: {
      componentParent: this
    },
    suppressHorizontalScroll: false,
    rowStyle: { background: 'white' },

    getRowClass: params => {
      if (params.data.animal_id === this.data.rowdata.parent_id) {
        params.node.setSelected(true);
        return 'ag-row-selected'
      }
    },

  } as GridOptions;

  bulkParentMapGridApi: GridApi;
  bulkParentMapColumnDefs: any[] = [];
  bulkParentMapRowData: any[] = [];

  constructor(public dialogRef: MatDialogRef<AssignAnimalParentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private animalService: AnimalService, private snackBar: MatSnackBar) {
    this.getRowHeight = (params) => {
      return 34;
    };
    this.headerHeight = 34;
  }

  ngOnInit(): void {
    //console.log(this.data.rowdata)
    //console.log(this.data.rowdata.name)
    this.setupbulkParentMapColumn();
    this.getAllAnimals();
  }

  async getAllAnimals() {
    await this.animalService.getAnimalParentInfo().toPromise().then((res: any) => {
      this.bulkParentMapRowData = res;
    })
    // await this.animalService.getAnimalTransactForTenant().toPromise().then((res: any) => {
    //   this.bulkParentMapRowData=res;
    // })
  }

  setupbulkParentMapColumn() {
    this.defaultColDef = Utils.getBaseThemeAgGridCol();
    this.bulkParentMapColumnDefs = [
      {
        headerName: 'ID',
        field: 'animal_id',
        cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
        suppressSizeToFit: true,
        width: 70,
        filter: 'agNumberColumnFilter',
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Name',
        field: 'name',
        cellRenderer: (params,) => {
          //  console.log(params.node.isSelected());
          return (params.node.selected) ? (`<mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">done</mat-icon>` + params.value) : params.value;
        },
        cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
        suppressSizeToFit: true,
        width: 150,
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Species',
        field: 'species',
        cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
        width: 120,
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Primary Breed',
        field: 'primary_breed',
        cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
        width: 170,
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Secondary Breed',
        field: 'secondary_breed',
        cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
        width: 150,
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Primary Color',
        field: 'primary_color',
        cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
        width: 150,
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Secondary Color',
        field: 'secondary_color',
        cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
        width: 150,
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Gender',
        field: 'gender',
        cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
        width: 150,
        filterParams: Utils.filterParams()
      }];
  }

  onAllBulkParentMapGridReady(params) {
    this.bulkParentMapGridApi = params.api;
    this.bulkParentMapGridApi.sizeColumnsToFit();

  }

  onRowSelected(event) {
    if (event.node.isSelected()) {
      event.node.setSelected(true);
    } else
      event.node.setSelected(false);
    event.api.refreshCells({
      force: true,
      rowNodes: [event.api.getRowNode(event.rowIndex)]
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveClick() {
    if (this.bulkParentMapGridApi.getSelectedRows().length > 0 && this.data.rowdata.animal_id == this.bulkParentMapGridApi.getSelectedRows()[0].animal_id) {
      this.snackBar.open("You can not select same animal for parent", 'Ok', {
        duration: 2000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['danger-snackbar']
      });
    } else if (this.bulkParentMapRowData.length == 0) {
      this.snackBar.open("Please Select Animal", 'Ok', {
        duration: 2000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['danger-snackbar']
      });
    } else if (this.bulkParentMapGridApi.getSelectedRows().length > 0) {
      this.dialogRef.close(this.bulkParentMapGridApi.getSelectedRows());
    }
  }

}
