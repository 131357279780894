import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
// import * as _moment from 'moment';
// const moment = _moment;

@Component({
    selector: 'app-toggle-button',
    templateUrl: './toggle-button.component.html',
    styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit, OnChanges {
    @Input('stagesList') stagesList: any;
    @Input('selectedList') selectedList: any;
    @Input('animalCurrentStage') animalCurrentStage: string;
    @Input('stageClick') stageClick: boolean;
    @Output('actionOutput') actionOutput: EventEmitter<any> = new EventEmitter();
    @Output('deleteItem') deleteItem: EventEmitter<any> = new EventEmitter();
    
    disabledStages:any[]=['intake','adopted','booked','released','euthanized'];

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges() {
    }

    onEventClick(e) {
        if(!this.stageClick || (this.disabledStages.indexOf(e.target.innerText.toLowerCase().trim())== -1))
            this.actionOutput.emit(e.target.innerText);
    }

    onDelete(e, text) {
        this.deleteItem.emit([e, text]);
    }

    isDisableSystemDefined(stage){
        if(!this.stageClick || (this.disabledStages.indexOf(stage.toLowerCase().trim())== -1)) return false;
        else return true;
    }
}
