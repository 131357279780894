import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef, OnChanges, SimpleChange, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { Animal } from 'src/app/core/models/animal';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UtilService } from 'src/app/core/services/util.service';

export interface DialogData {
  animal: Animal;
}

@Component({
  selector: 'app-animal-image-viewer-dialog',
  templateUrl: './animal-image-viewer-dialog.component.html',
  styleUrls: ['./animal-image-viewer-dialog.component.scss']
})
export class AnimalImageViewerDialogComponent implements OnInit {

  imageSource = '';
  imageTitle = '';

  constructor(
    private dialogRef: MatDialogRef<AnimalImageViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    const me = this;
  }

  onCloseDialog() {
    const me = this;
    me.dialogRef.close('closed');
  }

}

interface AnimalCondition {
  score: any;
  title: string;
  path: string;
}