import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

export interface State {
  flag: string;
  name: string;
  population: string;
}

export interface Animal {
  img: string;
  name: string;
  species: string;
}

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit {

  animalCtrl = new FormControl();
  filteredAnimales: Observable<Animal[]>;

  animals: Animal[] = [
    {
      img: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
      name: 'Moscato',
      species: 'dog'
    },
    {
      img: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
      name: 'Namita',
      species: 'dog'
    },
    {
      img: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
      name: 'Hinata',
      species: 'dog'
    },
    {
      img: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
      name: 'Bakumaru',
      species: 'dog'
    },
    {
      img: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
      name: 'Kuro',
      species: 'dog'
    },
    {
      img: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
      name: 'Shichan',
      species: 'dog'
    }
  ]

  constructor() {
    this.filteredAnimales = this.animalCtrl.valueChanges
      .pipe(
        startWith(''),
        map(animal => animal ? this._filterAnimales(animal) : this.animals.slice())
      );
  }

  ngOnInit(): void {
  }

  private _filterAnimales(value: string): Animal[] {
    const filterValue = value.toLowerCase();

    return this.animals.filter(animal => animal.name.toLowerCase().indexOf(filterValue) === 0);
  }

}
