<!-- <app-animal-tile [name]="'ggx'" [img]="'https://material.angular.io/assets/img/examples/shiba2.jpg'"></app-animal-tile> -->
<div class="container-fluid p-0">
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="square-jelly-box"
    [fullScreen]="true"
    ><p style="color: white">Loading...</p></ngx-spinner
  >
  <div style="background-color: #1a6f1a;" *ngIf="isLogin">
    <marquee style="color: #fff; padding: 6px 0px;">Welcome to Saint! Subscribe and connect with other animal shelters to be a voice for animals in need. Your access to Saint will end in 5 days from registration. <a style="cursor: pointer;" (click)="subscription()">Click Here</a> to start your subscription and continue supporting our mission.</marquee>
  </div>
  <router-outlet></router-outlet>

  <div
    class="col-sm-12 col-md-12 col-lg-12 chat-view"
    [ngStyle]="{ display: homeLoaded ? 'grid' : 'none' }"
  >
    <!-- <iframe id="chatFrame" name="chatFrame"></iframe> -->
    <span>
      <mat-icon
        (click)="onAddChannelClick('start')"
        [hidden]="!chatLoaded"
        matTooltip="New Chat"
        class="fab-button"
        aria-label="New Message"
        >add</mat-icon
      >
    </span>
  </div>
</div>
