<div mat-dialog-title>
  <div>
    <span>Animal:</span>
    <!-- <span class="link" (click)="onAanimalHeaderClicked()" *ngIf="data.animalNameHyperlinkFlag">{{data.animal.name}}</span> -->
    <span>{{data.animal.name}}</span>
  </div>

  <span>ID: {{data.animal.animal_id}}</span>

  <button mat-icon-button>
    <mat-icon (click)="onNoClick()">close</mat-icon>
  </button>
</div>


<div mat-dialog-content>

  <!-- section for animal image -->
  <div class="row mb-2">
    <div *ngFor="let imgURL of data.animal.image_urls" class="imgContainer">
      <img mat-card-image [src]='imgURL'
        style="width: 100%; object-fit: contain; width: 104px; height: 93px; object-position: center; margin-bottom: 0px;" />
        <div class="hover-overlay">
          <button mat-icon-button (click)="onPreviewClick(imgURL)" color="info" mat-icon-button>
            <mat-icon style="color: #fff;">preview</mat-icon>
          </button>
        </div>
    </div>
  </div>

  <!-- section for animal info ban green -->
  <div class="row info-ban-green">
    <div class="tag-container">
      <span class="gray mr-2">ID:</span>
      <span>{{data.animal.animal_id}}</span>
    </div>
    <div class="tag-container">
      <span class="gray mr-2">Status:</span>
      <span>{{data.animal.status}}</span>
    </div>
    <div class="tag-container">
      <span class="gray mr-2">Stage:</span>
      <span>
        {{data.animal.existing_status[data.animal.existing_status.length - 1]?.stage}}
      </span>
    </div>
    <div class="tag-container">
      <span class="gray mr-2">Transferable:</span>
      <span>
        {{data.animal.transferrable ? 'Yes' : 'No'}}
      </span>
    </div>
    <!-- <mat-checkbox [checked]="data.animal.transferrable" [disabled]="true">Transferable</mat-checkbox> -->
  </div>

  <!-- section for animal info ban normal -->
  <div class="row info-ban-normal my-2 pl-2">
    <div class="small">
      <span class="gray">Species:</span>
      <span>{{data.animal.species}}</span>
    </div>

    <div class="small">
      <span class="gray">Gender:</span>
      <span>{{data.animal.gender}}</span>
    </div>

    <div class="small">
      <span class="gray">Age:</span>
      <span>{{(data.animal.age)}} Months</span>
    </div>

    <div class="small" style="width: 23% !important; overflow: hidden; text-overflow: ellipsis;">
      <span class="gray">Microchip:</span>
      <span>{{data.animal.microchip_number}}</span>
    </div>

    <div class="small">
      <span class="gray">Weight:</span>
      <span>{{data.animal.weight}} lbs {{data.animal.weight_ounces}} oz</span>
    </div>

    <div class="large">
      <span class="gray">Breed:</span>
      <span>
        {{data.animal.primary_breed}} {{data.animal.secondary_breed ? '/':''}} {{data.animal.secondary_breed}}
      </span>
    </div>

    <div class="small">
      <span class="gray">Color:</span>
      <span>{{data.animal.primary_color}} {{data.animal.secondary_color ? '/':''}} {{data.animal.secondary_color}}
      </span>
    </div>

    <div class="small" style="width: 23% !important; overflow: hidden; text-overflow: ellipsis;">
      <span class="gray">Pattern:</span>
      <span>{{data.animal.color_pattern}}</span>
    </div>

    <div class="small" style="word-break: break-word;;">
      <span class="gray">Intake Group ID:</span>
      <span>{{data.animal.intake_group_id}}
      </span>
    </div>

    <div class="small" style="width: 100%;">
      <span class="gray">Outcome Group ID:</span>
      <span>{{data.animal.outcome_group_id}}</span>
    </div>
    
  </div>

  <!-- section for animal history -->
  <div class="row">
    <div class="label-container long">
      <span>History</span>
      <ag-grid-angular style="width: 100%; height: 200px; margin-top: .5em;" class="ag-theme-alpine"
        [rowData]="historyRowData | async" [columnDefs]="historyColumnDefs" [defaultColDef]="defaultColDef"
        [getRowHeight]="getRowHeight" [headerHeight]="headerHeight" [gridOptions]="gridOptionsHistory"
        [overlayNoRowsTemplate]="overlayNoRowsTemplateForHistory" (gridReady)="onHistoyGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>

  <!-- section for animal notes -->
  <div class="row">
    <div class="label-container long">
      <span>Notes</span>
      <ag-grid-angular style="width: 100%; height: 200px; margin-top: .5em;" class="ag-theme-alpine" [frameworkComponents]="frameworkComponents"
        [rowData]="notesRowData | async" [columnDefs]="notesColumnDefs" [defaultColDef]="defaultColDef"
        [getRowHeight]="getRowHeight" [headerHeight]="headerHeight" [gridOptions]="gridOptions"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate" (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>

  </div>
</div>
