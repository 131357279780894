<div *ngIf="!desableStatus">
    <input
      type="checkbox"
      [(ngModel)]="data[isSelectedVar]"
      name="isSelected"
      [disabled]="isDisabled()"
      (change)="onSelectChange()"
    />
  </div>
  <div *ngIf="desableStatus">
    <input
      type="checkbox"
      [(ngModel)]="data[isSelectedVar]"
      name="isSelected"
      [disabled]="isDisabled()"
      (change)="onSelectChange()"
      disabled="disabled"
    />
  </div>