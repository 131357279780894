import {Utils} from './../utils/util';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})


export class HomeService {
    apiUrlChat = '';
    getMessageCount = new BehaviorSubject(0);
    homeLoaded = new BehaviorSubject(false);
    chatLoaded = new BehaviorSubject(false);
    isAddButtonClicked = new BehaviorSubject<string>('stop');
    showSubscriptionBanner = new BehaviorSubject<boolean>(true);
    showSubscription = this.showSubscriptionBanner.asObservable();

    showMenuOption = new BehaviorSubject(true);
    showMenuList = this.showMenuOption.asObservable();

    constructor(
        private apiService: ApiService,
        private http: HttpClient
    ) {
        this.apiUrlChat = this.apiService.chatApi;
    }

    getPrivateSettings(): Observable<any> {
        const enPoint = `${this.apiUrlChat}/settings.public`;
        return this.get(enPoint);
    }

    getAllAgencies(): Observable<any> {
        return this.apiService.get(`api/agency/get-agency-all`).pipe(
            map((res: any) => {
                res.forEach(ele => {
                    const addData = ele.addresses;
                    if (addData) {
                        addData.sort((a, b) => a.is_primary ? -1 : 0);
                        ele.address = addData[0].location_name;
                        ele.phone = addData[0].phone;
                    }
                });
                return res;
            })
        );
    }

    getAgencyList(): Observable<any> {
        return this.apiService.get('api/agency/get-agency-all');
    }

    getAgencyDetails(): Observable<any> {
        return this.apiService.get('api/agency/get-agency-detail?is_campus_list=false');
    }

    getMessages(roomId: string): Observable<any> {
        const endPoint = `${this.apiUrlChat}/channels.messages?roomId=${roomId}`;
        return this.get(endPoint);
    }

    getRooms(): Observable<any> {
        const endPoint = `${this.apiUrlChat}/rooms.adminRooms`;
        return this.get(endPoint);
    }

    getDiscussions(): Observable<any> {
        const endPoint = `${this.apiUrlChat}/rooms.getDiscussions`;
        return this.get(endPoint);
    }

    getAgencyUsers(tenantId: number): Observable<any> {
        const endPoint = `api/agency/get-users-by-agency-id/${tenantId}`;
        return this.apiService.get(endPoint);
    }

    getChatUserList(): Observable<any> {
        const endPoint = `${this.apiUrlChat}/users.list?count=50000`;
        return this.get(endPoint);
    }

    getUserInfoForChat(userName: string): Observable<any> {
        const endPoint = `${this.apiUrlChat}/users.info?username=${userName}`;
        return this.get(endPoint);
    }

    channelInfo(channelName: string): Observable<any> {
        const endPoint = `${this.apiUrlChat}/channels.info?roomName=${channelName}`;
        return this.get(endPoint);
    }

    getUserChannelList(): Observable<any> {
        const endPoint = `${this.apiUrlChat}/channels.list.joined`;
        return this.get(endPoint);
    }

    getChannelList(): Observable<any> {
        const endPoint = `${this.apiUrlChat}/channels.list`;
        return this.get(endPoint);
    }

    getRoomUnreadCount(roomId: any): Observable<any> {
        const endPoint = `${this.apiUrlChat}/channels.counters?roomId=${roomId}`;
        return this.get(endPoint);
    }

    createChannel(payload: any): Observable<any> {
        const endPoint = `${this.apiUrlChat}/channels.create`;
        return this.post(endPoint, payload);
    }

    sendMessageToChannel(payload: any): Observable<any> {
        const endPoint = `${this.apiUrlChat}/chat.sendMessage`;
        return this.post(endPoint, payload);
    }

    getAllMessageList(): Observable<any> {
        return this.apiService.get(`api/message/get-agency-message-list`).pipe(
            map((res: any[]) => {
                res.forEach(ele => {
                    ele.message_date = Utils.phoneNumberFormatChange(ele.message_date)
                    ele.sender_name = ele.sender_first_name + ' ' + ele.sender_last_name
                });
                res.sort((a, b) => {
                    return b.message_id - a.message_id
                });
                return res;
            })
        );
    }

    createMessage(payload): Observable<any> {
        return this.apiService.post(`api/message/create-message`, payload);
    }

    replyMessage(payload): Observable<any> {
        return this.apiService.post(`api/message/reply-message`, payload);
    }

    getAllMessageHistory(conversationId): Observable<any> {
        return this.apiService.get(`api/message/get-message-history-for-conversation?conversationId=${conversationId}`).pipe(
            map((res: any[]) => {
                res.forEach(ele => {
                    ele.message_date = Utils.phoneNumberFormatChange(ele.message_date)
                    ele.sender_name = ele.sender_first_name + ' ' + ele.sender_last_name
                })
                // res.sort((a, b) => { return b.message_id - a.message_id })
                return res;
            })
        );
    }


    getAllAnimalList(searchString): Observable<any> {
        return this.apiService.get(`api/animal/get-animal-search-results?search_string=${encodeURIComponent(searchString)}`).pipe(
            map((res: any) => {
                res.forEach(ele => {
                    ele.breed = ele.primary_breed ? ele.primary_breed : '' + ' ' + ele.secondary_breed ? ele.secondary_breed : '';
                    ele.weight_lbs = Utils.numberToString(ele.weight_lbs)
                    ele.weight_to_show = Utils.weightInLbs(ele.weight_lbs)
                    ele.gender_to_show = Utils.getGenderShort(ele.gender)
                    ele.birth_date = ele.birth_date ? Utils.animalDBOFormat(ele.birth_date) : '';
                })
                return res;
            })
        );
    }


    getReportMaster(): Observable<any> {
        return this.apiService.get(`api/input/Reports`);
    }

    getReportsData(payload): Observable<any> {
        return this.apiService.post(`api/reports/get-reports-for-animal-intake-outcome`, payload);
    }


    get(baseUrl: any, params?: any) {
        const me = JSON.parse(localStorage.getItem('me'));
        const id = me && me._id;
        return this.http
            .get(baseUrl, {
                observe: 'response',
                params,
                headers: {
                    'X-Auth-Token': `${localStorage.getItem('Meteor.loginToken')}`,
                    'X-User-Id': `${id}`,
                    'Content-Type': 'application/json'
                },
                responseType: 'json',
            })
            .pipe(
                map((data) => {
                    return data.body;
                })
            );
    }

    post(baseUrl: any, payload?: any, params?: any) {
        const me = JSON.parse(localStorage.getItem('me'));
        const id = me && me._id;
        return this.http
            .post(baseUrl, payload, {
                observe: 'response',
                params,
                headers: {
                    'X-Auth-Token': `${localStorage.getItem('Meteor.loginToken')}`,
                    'X-User-Id': `${id}`,
                    'Content-Type': 'application/json'
                },
                responseType: 'json',
            })
            .pipe(
                map((data) => {
                    return data.body;
                })
            );
    }

    setMessageCount(count: number) {
        this.getMessageCount.next(count);
    }

    setLoginBanner(boolean){
        this.showSubscriptionBanner.next(boolean); 
    }

    showMenu(value : boolean) {
        this.showMenuOption.next(value)
    }

    isHomeLoaded(loaded: boolean) {
        this.homeLoaded.next(loaded);
    }

    isChatLoaded(loaded: boolean) {
        this.chatLoaded.next(loaded);
    }

    setAddButtonClick(flag: string) {
        if (flag === 'start') {
            this.isAddButtonClicked.next(flag);
        }
    }

    getAddButtonClick(): Observable<any> {
        return this.isAddButtonClicked.asObservable();
    }
}
