import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { join } from 'lodash';

@Component({
  selector: 'app-ag-grid-address-cell',
  templateUrl: './ag-grid-address-cell.component.html',
  styleUrls: ['./ag-grid-address-cell.component.scss']
})
export class AgGridAddressCellComponent implements OnInit, ICellRendererAngularComp {

  params: ICellRendererParams;
  constructor() { }

  ngOnInit(): void {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  getAddress(): string {
    return this.params.data.address.split('|').join(',');
  }
}
