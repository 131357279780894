import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { ImageViewerComponent } from "src/app/shared/components/image-viewer/image-viewer.component";
import { PdfViewerDialogComponent } from "src/app/shared/components/pdf-viewer-dialog/pdf-viewer-dialog.component";
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-ag-grid-clickable-images',
  templateUrl: './ag-grid-clickable-images.component.html',
  styleUrls: ['./ag-grid-clickable-images.component.scss']
})
export class AgGridClickableImagesComponent implements OnInit, ICellRendererAngularComp {
  params: ICellRendererParams;
  constructor(public dialog: MatDialog,private sanitizer: DomSanitizer) { }

  ngOnInit(): void {}

  agInit(params: ICellRendererParams): void {
    // console.log(params.data.attachment_urls);
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  getDocTag(docName: string) {
   // console.log(docName);
    if(docName)
    return docName.split('.')[(docName.split('.').length) - 1];
  }

  async clickOnImage(imageData){
    const getDocType=this.getDocTag(imageData);

    if(getDocType!='pdf'){
      console.log(imageData);
    
    const url = this.sanitizer.bypassSecurityTrustUrl(imageData)
    const data = {
      name: '',
      primary_image_url:  url
    };
    this.dialog.open(ImageViewerComponent, {
      data: data,
      height: '70%',
      width: '40%',
      panelClass: 'image-viewer-dialog'
    }).afterClosed().subscribe((res: any) => {
      if (res) {
        console.log(res);
      }
    })
    
    }else{
      const dialogRef = this.dialog.open(PdfViewerDialogComponent, {
        width: '70em',
        data: {title:'',pdf: imageData },
        autoFocus: false
      });
      let subDialog = dialogRef.afterClosed().subscribe(result => { });
    }
          
  }

}