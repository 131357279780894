import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export interface NotesDialogData {
  isEdit: boolean,
  note?: any
}

@Component({
  selector: 'app-add-edit-note-dialog',
  templateUrl: './add-edit-note-dialog.component.html',
  styleUrls: ['./add-edit-note-dialog.component.scss']
})


export class AddEditNoteDialogComponent implements OnInit {

  noteForm: FormGroup;
  
  constructor(
    public dialogRef: MatDialogRef<AddEditNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NotesDialogData,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initNoteForm();
    if(this.data.isEdit) {
      this.setFormValue();
    }
  }

  initNoteForm() {
    this.noteForm = this.fb.group({
      'noteName': ['', [Validators.required]],
      'notes': ['', Validators.required],
    })
  }

  setFormValue() {
    this.noteForm.patchValue({
      'noteName': this.data.note.note_name,
      'notes': this.data.note.notes
    });
  }

  onSaveClick() {
    if(this.noteForm.valid) {
      if(this.data.isEdit) {
        this.dialogRef.close({...this.noteForm.value, note_id: this.data.note.note_id});
      } else {
        this.dialogRef.close(this.noteForm.value);
      }
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
