import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HomeService} from '../../../core/services/home.service';
import * as _ from 'lodash';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppComponent} from '../../../app.component';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'app-new-message-dialog',
    templateUrl: './new-message-dialog.component.html',
    styleUrls: ['./new-message-dialog.component.scss'],
    // encapsulation: ViewEncapsulation.None
})

export class NewMessageDialogComponent implements OnInit {

    agencyList: Agency[] = [];
    agencyFilterForm: FormGroup;
    filterModel: any;
    @ViewChild('agencies', {static: false}) public agencies: any;
    filterMetadata = 0;
    selectedItem: Agency;

    constructor(
        private dialogRef: MatDialogRef<NewMessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private toast: MatSnackBar,
        private homeService: HomeService,
        private authService: AuthService,
        private parent: AppComponent
    ) {
    }

    ngOnInit(): void {
        this.initFilterForm();
        this.getAgencyList();
    }

    initFilterForm(): void {
        this.agencyFilterForm = this.fb.group({
            search_agency: ['', !Validators.required]
        });
    }

    getAgencyList(): void {
        this.homeService.getAgencyList().subscribe((list: Agency[]) => {
            this.homeService.getAgencyDetails().subscribe((agencyDetails: any) => {
                const userAgency = agencyDetails[0];
                const tenantId = userAgency.tenant_id;
                const isMessaging = this.data && this.data.is_messaging;
                const isServices = this.data && this.data.is_service;
                const listItem = list.findIndex((item: any) => item && item.tenant_id === tenantId);
                if (isServices) {
                    _.forEach(list, (item: Agency, index: number) => {
                        if (item.business_name !== '[object HTMLInputElement]') {
                            this.agencyList.push(item);
                       }
                    });
                    this.agencyList = this.agencyList.filter((el: Agency) => el.business_name.length > 5);
                } else {
                    _.forEach(list, (item: Agency, index: number) => {
                        if (item.is_active && item.in_network) {
                            if (index !== listItem) {
                                this.agencyList.push(item);
                            }
                        }
                        // if (item.business_name && item.agency_type) {
                        //     if (index !== listItem) {
                        //         this.agencyList.push(item);
                        //     }
                        // }
                    });
                }
                // this.agencyList = this.agencyList.filter((el: Agency) => el.business_name.length > 5);
            });
        });
    }

    onSearchString(event: any): void {
        const input = event.target.value;
        this.filterModel = input;
    }

    onClose(res?: any): void {
        const result = res ? res : '';
        this.dialogRef.close(result);
        this.homeService.setAddButtonClick('stop');
    }

    onAddAgencyClicked(): void {
        const item: Agency = this.selectedItem;
        this.selectedItem = null;
        let payload: any;
        const users: any[] = [];
        const tenantIds: number[] = [];
        const chatUsers: any[] = [];
        const isMessaging = this.data && this.data.is_messaging;
        const isServices = this.data && this.data.is_service;
        if (item) {
            const tenantId = item.tenant_id;
            if (isMessaging) {
                this.homeService.getAgencyDetails().subscribe((agencyDetails: any) => {
                    const userAgency = agencyDetails[0];
                    let agencyName: any = item.business_name.replace(/ /g, '');
                    let userAgencyName = userAgency.business_name.replace(/ /g, '');
                    agencyName = agencyName.replace(/[^a-zA-Z0-9]/g, '');
                    userAgencyName = userAgencyName.replace(/[^a-zA-Z0-9]/g, '');
                    agencyName = {
                        name: `${agencyName}-${userAgencyName}`,
                        member_agency: agencyName,
                        user_agency: userAgencyName
                    };
                    tenantIds.push(tenantId);
                    tenantIds.push(userAgency.tenant_id);

                    return new Promise((resolve) => {
                        _.forEach(tenantIds, (id: number, index: number) => {
                            this.homeService.getAgencyUsers(id).subscribe((userList: any) => {
                                _.forEach(userList, (user: any) => {
                                    users.push(user);
                                    if (index === 1) {
                                        resolve(users);
                                    }
                                });
                            });
                        });
                    }).then((userList) => {
                        const list: any = userList;
                        let counter = 0;
                        return new Promise((resolve) => {
                            this.homeService.getChatUserList().subscribe((chatUserList: any) => {
                                const chatRegisteredUsers = chatUserList.users;
                                const chatUserEmails = chatRegisteredUsers.map((registeredUser: any) => {
                                    if (registeredUser.emails) {
                                        const email = registeredUser && registeredUser.emails && registeredUser.emails.length && registeredUser.emails[0].address;
                                        return email;
                                    }
                                });
                                const chatUserNames = chatRegisteredUsers.map((registeredUser: any) => registeredUser.username);
                                const chatUserObj = chatRegisteredUsers.map((registeredUser: any) => {
                                    if (registeredUser.emails) {
                                        const email = registeredUser && registeredUser.emails && registeredUser.emails.length && registeredUser.emails[0].address;
                                        const obj = {
                                            email,
                                            username: registeredUser.username
                                        };
                                        return obj;
                                    }
                                });
                                _.forEach(list, (u: any) => {
                                    counter++;
                                    const firstName = String(u.first_name).replace(/ /g, '');
                                    const lastName = String(u.last_name).replace(/ /g, '');
                                    const userName = `${firstName}${lastName}`;
                                    // const userExists = chatUserNames.includes(userName);
                                    const userExists = _.includes(chatUserEmails, u.email);
                                    
                                    if (userExists) {
                                        const user = _.find(chatUserObj, (e: any) => e && e.email === u.email);
                                        const username = user && user.username;
                                        chatUsers.push(username);
                                    } else {
                                        // Need to register the user, but basic user details required like name, email and password
                                    }
                                });
                                if (counter === list.length) {
                                    resolve(chatUsers);
                                }
                            });
                        });
                    }).then((result: any) => {
                        const channels = [];
                        result = _.uniqBy(result, (userName: any) => userName);
                        let channelName: any;
                        payload = {name: agencyName.name, members: result};
                        const memberAgency = `${agencyName.member_agency}-${agencyName.user_agency}`;
                        const meAgency = `${agencyName.user_agency}-${agencyName.member_agency}`;
                        this.homeService.getChannelList().subscribe((list: any) => {
                            const channelList = list.channels;
                            _.forEach(channelList, (i: any) => {
                                channels.push(i.name);
                            });
                            const uAgency = _.find(channels, name => name.toLowerCase() === memberAgency.toLowerCase());
                            const mAgency = _.find(channels, name => name.toLowerCase() === meAgency.toLowerCase());
                            if (uAgency !== undefined) {
                                channelName = memberAgency.replace(/ /g, '');
                            }
                            if (mAgency !== undefined) {
                                channelName = meAgency.replace(/ /g, '');
                            }
                            if (uAgency || mAgency) {
                                const channel = channelList.find((c: any) => c.name.toLowerCase() === channelName.toLowerCase());
                                if (channel) {
                                    this.toast.open('This channel already exists', 'ok', {
                                        horizontalPosition: 'right',
                                        verticalPosition: 'top',
                                        duration: 2000
                                    });
                                    const channelLink = `https://chat.getsaint.com/channel/${channelName.toLowerCase()}`;
                                    this.parent.loadChannel(channelLink);
                                    setTimeout(() => {
                                        this.onClose('Open Channel');
                                    }, 1000);
                                }
                            } else {
                                this.homeService.createChannel(payload).subscribe((res: any) => {
                                    const channelId = res && res.channel && res.channel._id;
                                    const created = res && res.success;
                                    if (created) {
                                        this.onClose(created);
                                    }
                                }, (error) => {
                                    console.log(error);
                                });
                            }
                        });
                    });
                });
            }
            if (isServices) {
                this.onClose(item);
            }
        }
    }

    getAgencyUsers(tenantIds: number[]): any {
        const users: any[] = [];
    }

    onListItemClick(agency: Agency) {
        if (agency.selected) {
            agency.selected = false;
            this.selectedItem = null;
        } else {
            const tenantId = agency.tenant_id;
            // const payload: any;
            const users: any[] = [];
            const agencyName = agency.business_name.replace(/ /g, '-');
            _.forEach(this.agencyList, (item: Agency) => {
                if (item.tenant_id === agency.tenant_id) {
                    item.selected = true;
                    this.selectedItem = item;
                } else {
                    item.selected = false;
                }
            });
            // this.homeService.getAgencyUsers(tenantId).subscribe((userList: any) => {
            //     console.log(userList);
            //     _.forEach(userList, (user: any) => {
            //         const userName = `${user.first_name}${user.last_name}`;
            //         this.homeService.getUserInfoForChat(userName).subscribe((record: any) => {
            //             console.log(record);
            //             if (record.success) {
            //                 const username = record && record.user && record.user.username;
            //                 users.push(username);
            //                 payload = {name: agencyName, members: users};
            //                 // this.homeService.createChannel(payload).subscribe((res: any) => {
            //                 //     chatRes = res;
            //                 //     const channelId = res && res.channel && res.channel._id;
            //                 //     const created = res && res.success;
            //                 //     this.onClose(created);
            //                 // });
            //             }
            //         });
            //     });
            // });
        }
    }

    getRandomNumber(): any {
        const length = 2;
        return Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1));
    }
}

export interface Agency {
    addresses: Array<Address>;
    agency_logo_url: any;
    agency_type: any;
    agency_website: any;
    annual_revenue: string;
    business_name: string;
    contract_agreement: string;
    creation_time: string;
    employee_size: string;
    fein: string;
    in_network: boolean;
    income_tax_return: string;
    is_active: boolean;
    tenant_id: number;
    user: User;
    selected: boolean;
}

interface User {
    email: string;
    first_name: string;
    last_name: string;
    location_id: number;
    phone_number: string;
    position: string;
    role: string;
}

interface Address {
    address_type: any;
    agency_sub_id: number;
    city: string;
    county: any;
    foster_home_animal_size: number;
    is_active: boolean;
    is_primary: boolean;
    location_name: string;
    phone: any;
    state: string;
    street1: string;
    street2: string;
    zip_code: string;
}
