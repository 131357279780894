import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { HomeService } from "./core/services/home.service";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { AuthService } from "./core/services/auth.service";
import {
  Channel,
  UnreadCount,
} from "./home/components/home-messages/home-messages.component";
import * as _ from "lodash";
import { MatDialog } from "@angular/material/dialog";
import { NewMessageDialogComponent } from "./home/components/new-message/new-message-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = "sl-agency-portal";
  homeLoaded: boolean;
  chatLoaded = false;
  iframe: any;
  authToken: any;
  unReadItems: any[] = [];
  unReadCount = 0;
  horizontalPosition: MatSnackBarHorizontalPosition = "right";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  isLogin = true

  constructor(
    private homeService: HomeService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private toast: MatSnackBar,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router : Router
  ) { }

  ngOnInit(): void {
    // this.loadChatEnv();
    if(window.location.pathname == '/auth/login'){
      this.isLogin = false
      this.homeService.setLoginBanner(false)
    }

    this.homeService.showSubscription.subscribe(user => this.isLogin = user);

  }

  subscription() {
    this.router.navigate(['dashboard/resources/subscription'])
  }

  ngAfterViewInit(): void {
    this.authToken = localStorage.getItem("Meteor.loginToken");
    this.homeService.homeLoaded.subscribe((loaded: any) => {
      this.homeLoaded = loaded;
      this.cdr.detectChanges();
      if (this.homeLoaded) {
        // setTimeout(() => {
          if (this.authToken) {
            const endPoint = `https://chat.getsaint.com/home?resumeToken=${this.authToken}`;
            this.setUpChat(endPoint);
          }
        // }, 500);
      }
    });
  }

  setUpChat(src: any): any {
    this.iframe = document.querySelector("#chatFrame");
    this.iframe.onload = (e) => {
      this.iframe.contentWindow.postMessage(
        {
          externalCommand: "login-with-token",
          token: this.authToken,
        },
        "*"
      );
    };
    this.iframe.src = src;
    this.handleChatEvents();
  }

  loadChannel(src: any): any {
    this.iframe = document.querySelector("#chatFrame");
    this.iframe.src = src;
    this.handleChatEvents();
  }

  loadChat(src: any): any {
    this.iframe = document.querySelector("#chatFrame");
    this.iframe.onload = (e) => {
      this.iframe.contentWindow.postMessage(
        {
          externalCommand: "login-with-token",
          token: this.authToken,
        },
        "*"
      );
    };
    this.iframe.src = src;
    window.location.reload();
    // this.ngOnInit();
  }

  handleChatEvents(): void {
    window.addEventListener("message", (e) => {
      // console.log(e);
      const eventName = e.data.eventName;
      const unreadCount =
        e && e.data && e.data.data && e.data.data.unread
          ? e.data.data.unread
          : 0;
      switch (eventName) {
        case "status-changed":
          setTimeout(() => {
            this.chatLoaded = true;
            this.homeService.isChatLoaded(this.chatLoaded);
          }, 1500);
          break;
        case "unread-changed":
          if (this.authToken) {
            this.getUserChannelList();
          }
          break;
        case "unread-changed-by-subscription":
          // this.getUserChannelList();
          break;
        case "room-opened":
          if (this.authToken) {
            this.getUserChannelList();
          }
          break;
      }
      this.homeService.setMessageCount(unreadCount);
    });
  }

  loadChatEnv(): void {
    const me = JSON.parse(localStorage.getItem("user"));
    if (me) {
      // this.onHomeLoaded();
    } else {
      this.authService.isLoggedIn.subscribe((flag: boolean) => {
        if (flag) {
          // this.onHomeLoaded();
        }
      });
    }
  }

  onHomeLoaded(): void {
    this.authToken = localStorage.getItem("Meteor.loginToken");
    this.iframe = document.querySelector("#chatFrame");
    this.homeService.homeLoaded.subscribe((loaded: any) => {
      this.homeLoaded = loaded;
      this.cdr.detectChanges();
    });
    if (this.authToken) {
      this.iframe.contentWindow.postMessage(
        {
          externalCommand: "login-with-token",
          token: this.authToken,
        },
        "*"
      );
      const endPoint = `https://chat.getsaint.com/home?resumeToken=${this.authToken}`;
      this.iframe.src = endPoint;
    }
  }

  getUserChannelList(): void {
    const token = localStorage.getItem("Meteor.loginToken");
    if (token) {
      this.homeService.getUserChannelList().subscribe(
        (channelList: any) => {
          // console.log(channelList);
          const channels: Channel = channelList && channelList.channels;
          _.forEach(channels, (channel: any) => {
            const room = { id: channel._id };
            this.homeService
              .getRoomUnreadCount(room.id)
              .subscribe((roomInfo: any) => {
                const item: UnreadCount = {
                  roomId: room.id,
                  roomName: channel.name,
                  count: roomInfo.unreads,
                };
                if (item.count) {
                  this.addItems(item);
                }
              });
          });
        },
        (err: any) => {
          console.clear();
        }
      );
    }
  }

  addItems(item: UnreadCount) {
    this.unReadItems.push(item);
    this.unReadCount = item.count;
    // console.log(`Unread Count: ${this.unReadCount}`);
    this.homeService.setMessageCount(this.unReadCount);
  }

  onAddChannelClick(msg: string) {
    if (msg === "start") {
      const dialogRef = this.dialog
        .open(NewMessageDialogComponent, {
          width: "100%",
          data: {
            is_messaging: true,
            is_service: false,
          },
        })
        .afterClosed()
        .subscribe((closed: any) => {
          if (closed) {
            this.homeService.setAddButtonClick("stop");
          }
        });
      this.homeService.setAddButtonClick("stop");
    }
  }
}
