<div mat-dialog-content>
  <div class="col">
    <div class="row title">{{data.title}}</div>
    <div class="row subtitle" *ngFor="let subtitle of data.subtitles">{{subtitle}}</div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button class="white-button" (click)="onNoClick()">{{data.btnNoString}}</button>
  <button mat-raised-button class="green-button" (click)="onYesClick()" cdkFocusInitial>
      {{data.btnYesString}}
  </button>
</div>