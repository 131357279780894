import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GridOptions, GridApi } from 'ag-grid-community';
import { Observable, of, Subscription } from 'rxjs';
import { AgGridClickableCellComponent } from '../ag-grid-clickable-cell/ag-grid-clickable-cell.component';
import { AgGridClickableSegmentedComponent } from '../ag-grid-clickable-segmented/ag-grid-clickable-segmented.component';
import { Utils } from 'src/app/core/utils/util';
import { AnimalService } from 'src/app/core/services/animal.service';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { UtilService } from 'src/app/core/services/util.service';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { AnimalDetailsDialogComponent } from '../animal-details-dialog/animal-details-dialog.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-animal-lookup',
  templateUrl: './animal-lookup.component.html',
  styleUrls: ['./animal-lookup.component.scss']
})
export class AnimalLookupComponent implements OnInit, OnDestroy {

  @Input('selectedAnimal') selectedAnimal: any;
  @Input('enableStageVlidation') enableStageVlidation: boolean;
  @Input('enableTransferVlidation') enableTransferVlidation: boolean;
  @Output('onSelectAnimal') onSelectAnimal: EventEmitter<any> = new EventEmitter();

  actionTilesListLookupAnimal = [
    {
      message: 'Lookup',
      messageColor: '#419010',
      actionSign: 'search',
      bgColor: '',
      messageFontSize: '13px',
      img: null,
      actionName: 'lookupAnimal',
      messageTextForans: 'uppercase'
    },
    {
      message: 'Select',
      messageColor: '#419010',
      actionSign: '',
      bgColor: '',
      messageFontSize: '13px',
      actionName: 'selectAnimal',
      img: 'assets/images/icon_select.png',
      messageTextForans: 'uppercase'
    },
  ];

  public gridOptions = {
    context: {
      componentParent: this,
    },
    suppressHorizontalScroll: false,
    rowStyle: { background: 'white' }
  } as GridOptions;
  animalLookupGridApi: GridApi;
  animalLookupGridColumnApi: any;
  defaultColDef: any;
  frameworkComponents;
  getRowHeight: any;
  headerHeight: any;

  animalLookupRowData: Observable<any>;
  animalLookupColumnDefs: any[] = [];

  animalInfoForm: FormGroup;

  primaryColorList: any[];
  speciesList: string[] = [];
  primaryBreed: string[] = [];
  speciesBreedObj: any;

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private animalService: AnimalService,
    private _snackBar: MatSnackBar,
    private utilService: UtilService,
    public dialog: MatDialog
  ) {
    this.frameworkComponents = {
      cusotmClickableCell: AgGridClickableCellComponent,
      customSegmetCell: AgGridClickableSegmentedComponent
    };

    this.getRowHeight = (params) => {
      return 34;
    }
    this.headerHeight = 34;
  }

  ngOnInit(): void {
    this.initAnimalInfoForm();
    this.setupAnimalLookupGridColumn();
    this.setMasterData()
    if (this.selectedAnimal) {
      this.setValues();
    }
  }

  setValues() {
    this.animalInfoForm.patchValue({
      animal_name: this.selectedAnimal.name,
      species: this.selectedAnimal.species,
      primary_breed: this.selectedAnimal.primary_breed,
      primary_color: this.selectedAnimal.primary_color
    });
  }

  initAnimalInfoForm() {
    this.animalInfoForm = this.fb.group({
      animal_name: [''],
      species: [''],
      primary_breed: [''],
      primary_color: ['']
    });
  }

  setMasterData() {
    let sub = this.utilService.getMasterInputs('Animal').subscribe(res => {
      this.primaryColorList = res.input.animal_primary_color;
    });

    let sub2 = this.animalService.getAllAnimalSpeciesBreedList().subscribe(res => {
      this.speciesBreedObj = res.animal_species_breed;
      Object.keys(res.animal_species_breed).forEach(ele => {
        this.speciesList.push(ele);
      })
      if (this.selectedAnimal) {
        this.primaryBreed = this.speciesBreedObj[this.selectedAnimal.species];
      }
    })

    this.subs.push(sub);
    this.subs.push(sub2);
  }

  onSpeciesChange(event: MatSelectChange) {
    this.primaryBreed = this.speciesBreedObj[event.value];
  }

  setupAnimalLookupGridColumn() {
    this.defaultColDef = Utils.getBaseThemeAgGridCol();
    this.animalLookupColumnDefs = [
      {
        headerName: '',
        field: 'check',
        checkboxSelection: true,
        suppressSizeToFit: true,
        sortable:false,
        width: 31,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          display: 'flex',
          justifyContent: 'center',
          padding: '0 0 0 10px'
        },
      },
      {
        headerName: 'Name',
        field: 'name',
        width: 100,
        suppressSizeToFit: true,
        cellRenderer: this.columnRender(),
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Species',
        field: 'species',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center',
        },
        filterParams: Utils.filterParams(),
      },
      {
        headerName: 'Breed',
        field: 'primary_breed',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center',
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Stage',
        field: 'stage',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center',
        },
        filterParams: Utils.filterParams()
      },
    ]
  }

  columnRender() {
    return 'cusotmClickableCell';
  }

  onAnimalLookupGridReady(params) {
    this.animalLookupGridApi = params.api;
    this.animalLookupGridColumnApi = params.columnApi;
    this.animalLookupGridApi.sizeColumnsToFit();

    this.animalLookupRowData = of([]);
  }

  actionFired(event) {
    if (event == 'lookupAnimal') {
      let payload = {
        animal_name: this.animalInfoForm.value.animal_name ? this.animalInfoForm.value.animal_name : '',
        species: this.animalInfoForm.value.species ? this.animalInfoForm.value.species : '',
        primary_breed: this.animalInfoForm.value.primary_breed ? this.animalInfoForm.value.primary_breed : '',
        primary_color: this.animalInfoForm.value.primary_color ? this.animalInfoForm.value.primary_color : '',
      }
      this.animalLookupRowData = this.animalService.lookupAnimal(payload).pipe(
        map((res: any[]) => {
          res.forEach(ele => {
            ele['stage'] = ele.existing_status[ele.existing_status.length - 1]?.value;
          })
          if (this.enableTransferVlidation)
            res = res.filter(ele => ele.transferrable)

         // console.log(this.enableStageVlidation);
          if (this.enableStageVlidation)
            res = res.filter(ele => ele.existing_status.length > 0 && ele.existing_status[ele.existing_status.length - 1].value == 'READY TO ADOPT');

          return res;
        })
      );
    } else if (event == 'selectAnimal') {
      let animalLookSelect = this.animalLookupGridApi.getSelectedRows()[0];

      // if(!this.enableStageVlidation) {
      //   this.selectedAnimal = this.animalLookupGridApi.getSelectedRows()[0];
      //   this.primaryBreed = this.speciesBreedObj[this.selectedAnimal.species];
      //   this.animalInfoForm.patchValue({
      //     animal_name: this.selectedAnimal.name,
      //     species: this.selectedAnimal.species,
      //     primary_breed: this.selectedAnimal.primary_breed,
      //     primary_color: this.selectedAnimal.primary_color
      //   });

      //   this.onSelectAnimal.emit(this.selectedAnimal);

      //   return;
      // }

      if (this.enableTransferVlidation) {
        if (animalLookSelect.transferrable) {
          this.selectedAnimal = this.animalLookupGridApi.getSelectedRows()[0];
          this.primaryBreed = this.speciesBreedObj[this.selectedAnimal.species];
          this.animalInfoForm.patchValue({
            animal_name: this.selectedAnimal.name,
            species: this.selectedAnimal.species,
            primary_breed: this.selectedAnimal.primary_breed,
            primary_color: this.selectedAnimal.primary_color
          });
          this.onSelectAnimal.emit(this.selectedAnimal);
        } else {
          this._snackBar.open('Selected animal is not transferable', 'Ok', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['warning-snackbar']
          });
        }
        return;
      } else if (this.enableStageVlidation) {
        if (animalLookSelect.existing_status[animalLookSelect.existing_status.length - 1].value == 'READY TO ADOPT') {
          this.selectedAnimal = this.animalLookupGridApi.getSelectedRows()[0];
          this.primaryBreed = this.speciesBreedObj[this.selectedAnimal.species];
          this.animalInfoForm.patchValue({
            animal_name: this.selectedAnimal.name,
            species: this.selectedAnimal.species,
            primary_breed: this.selectedAnimal.primary_breed,
            primary_color: this.selectedAnimal.primary_color
          });

          this.onSelectAnimal.emit(this.selectedAnimal);
        } else {
          this._snackBar.open('Selected animal status is not ready to adopt', 'Ok', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['warning-snackbar']
          });
        }
        return;
      }
    }
  }

  clickOnCell(clickedRowId, clickedCellName, rowObj) {
    this.openAnimalDialog(rowObj);
  }

  onAnimalNameClicked() {
    let sub = this.animalService.getAnimalById(this.selectedAnimal.animal_id).subscribe(res => {
      this.openAnimalDialog(res);
      // const dialogRef = this.dialog.open(AnimalDetailsDialogComponent, {
      //   width: '70em',
      //   data: { animal: res, animalNameHyperlinkFlag: true },
      //   autoFocus: false
      // });
    })
    this.subs.push(sub);
  }

  openAnimalDialog(animal) {
    const dialogRef = this.dialog.open(AnimalDetailsDialogComponent, {
      width: '70em',
      data: { animal: animal, animalNameHyperlinkFlag: true },
      autoFocus: false
    });
  }

  getAdoptionFee() {
    // return parseFloat(this.selectedAnimal.adoption_fee).toFixed(2);
    return '$' + Utils.numberToString(Utils.dollerStringToNumber(`$${this.selectedAnimal.adoption_fee}`));
  }

  isDisableActionTile(data) {
    if (data.actionName == 'selectAnimal') {
      if (this.animalLookupGridApi?.getSelectedRows().length > 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  ngOnDestroy() {
    this.subs.forEach(element => element.unsubscribe());
  }

}
