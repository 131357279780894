<div class="row">

  <div class="col-md-7">
    <!-- Lookup form -->
    <form class="row" [formGroup]="agencyInfoForm">

      <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
        <mat-label>Agency Name</mat-label>
        <input type="text" matInput formControlName="agency_name" autocomplete="off">
      </mat-form-field>

      <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
        <mat-label>Address 1</mat-label>
        <input type="text" matInput formControlName="address_1" autocomplete="off">
      </mat-form-field>

      <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
        <mat-label>City</mat-label>
        <input type="text" matInput formControlName="city" autocomplete="off">
      </mat-form-field>

      <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
        <mat-label>Zip Code</mat-label>
        <input type="text" matInput formControlName="zip_code" autocomplete="off">
      </mat-form-field>

    </form>

    <!-- Selected agency info -->
    <div class="row" *ngIf="selectedAgency">
      <div class="selected-container" style="width: 100% !important;">
        <span>Agency ID:</span>
        <span class="overflow-wrap">{{selectedAgency.tenant_id}}</span>
      </div>
      <div class="selected-container">
        <span>Agency Name:</span>
        <span class="link overflow-wrap">{{selectedAgency.business_name}}</span>
      </div>
      <div class="selected-container">
        <span>Category:</span>
        <span class="normal">{{selectedAgency.category}}</span>
      </div>
    </div>

  </div>


  <!-- section for right side table -->
  <div class="col link-container">
    <div class="row">
      <div class="col-3 px-0">
        <app-action-tile *ngFor="let titleAcData of actionTilesListLookupAgency" [message]="titleAcData.message"
          [messageColor]="titleAcData.messageColor" [actionSign]="titleAcData.actionSign"
          [bgColor]="titleAcData.bgColor" [minHeight]="'84px'" [minWidth]="'96px'"
          [messageFontSize]="titleAcData.messageFontSize" [messageTextForans]="titleAcData.messageTextForans"
          [disabled]="isDisableActionTile(titleAcData)" [img]="titleAcData.img" [actionName]="titleAcData.actionName"
          (actionOutput)="actionFired($event)">
        </app-action-tile>
      </div>
      <div class="col pl-0">
        <div class="row mt-3">
          <ag-grid-angular style="width: 95%; height: 230px;" class="ag-theme-alpine"
            [rowData]="agencyLookupRowData | async" [columnDefs]="agencyLookupColumnDefs" [getRowHeight]="getRowHeight"
            [headerHeight]="headerHeight" [suppressRowClickSelection]="true" [defaultColDef]="defaultColDef"
            [rowSelection]="'single'" [frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions"
            (gridReady)="onAgencyLookupGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>

</div>