import {Injectable} from "@angular/core";

@Injectable({
    providedIn: "root"
})

export class StorageService {
    constructor() {}

    public static getItem<T>(key: string): T {
        return JSON.parse(sessionStorage.getItem(key));
    }

    public static setItem<T>(key: string, value: T): void {
        sessionStorage.setItem(key, JSON.stringify(value));
        if (key === "token") {
            localStorage.setItem(key, JSON.stringify(value));
            localStorage.removeItem(key);
        }
    }

    public static removeItem(key: string): void {
        if (StorageService.getItem(key)) {
            sessionStorage.removeItem(key);
        }
    }

    public static clearAll(): void {
        sessionStorage.clear();
    }

    public static clearAllOnLogout(): void {
        sessionStorage.clear();
        localStorage.clear();
        localStorage.setItem("clearSession", "triggerEvent");
        localStorage.removeItem("clearSession");
    }
}
