import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Animal } from 'src/app/core/models/animal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilService } from 'src/app/core/services/util.service';
import { AnimalService } from 'src/app/core/services/animal.service';

import { Subscription, Observable, of, BehaviorSubject } from 'rxjs';
import { GridApi, GridOptions } from 'ag-grid-community';
import { AgGridClickableCellComponent } from '../../components/ag-grid-clickable-cell/ag-grid-clickable-cell.component';
import { Utils } from 'src/app/core/utils/util';
import * as _moment from 'moment';
import { Medication } from 'src/app/core/models/medication';
import { difference, update } from 'lodash';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
//import { parse } from 'path';

const moment = _moment;

export interface DialogData {
  animal: Animal;
  event_date,
  event_time,
  selectedMicrochipData?;
}

// interface Medication {
//   date: string;
//   time: string;
//   medicine: string;
//   unit: string;
//   dosage: number;
//   instruction: string;
// }

@Component({
  selector: 'app-add-edit-microchip-injection',
  templateUrl: './add-edit-microchip-injection.component.html',
  styleUrls: ['./add-edit-microchip-injection.component.scss']
})

export class AddEditMicrochipInjectionComponent implements OnInit, OnDestroy {

  subs: Array<Subscription> = [];
  microchipForm: FormGroup;
  microchipAvailableData:any[]=[];
  brandFullList:any[]=[];
  brandList:any[]=[];
  brandCurrentVal:any;

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    public dialogRef: MatDialogRef<AddEditMicrochipInjectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private utilService: UtilService,
    private animalService:AnimalService,
    private toast: MatSnackBar
  ) {
    
  }

  ngOnInit(): void {
    this.getMasterData();
    this.initMicrochipForm();
  }

  initMicrochipForm() {
    let defaultInjectionDate=(this.data.selectedMicrochipData) ? (new Date(this.data.selectedMicrochipData.injection_date)) : this.data.event_date;
    let defaultBrand=(this.data.selectedMicrochipData) ? ((this.data.selectedMicrochipData.manufacturer)) : '';
    let defaultInjectionTime=(this.data.selectedMicrochipData) ? (moment(this.data.selectedMicrochipData.injection_date).format("HH:mm")) : this.data.event_time;
    //let microchip=(this.data.selectedMicrochipData) ? (this.data.selectedMicrochipData.microchip) : '';
    this.microchipForm = this.fb.group({
      brand: [defaultBrand,[Validators.required]],
      microchip: ['',[Validators.required]],
      injection_date: [defaultInjectionDate,[Validators.required]],
      injection_time: [defaultInjectionTime,[Validators.required]]
    });
  }

  getMasterData() {
    let sub = this.utilService.getMasterInputs('microchips').subscribe(res => {
      if (res){
        this.brandFullList=res.microchip_master;
        this.brandFullList['Other']=[];
        this.brandList=Object.keys(res.microchip_master);
        if(this.data.selectedMicrochipData && this.data.selectedMicrochipData.microchip){
          this.microchipAvailableData=this.brandFullList[this.data.selectedMicrochipData.manufacturer];
          this.microchipForm.get('microchip').setValue(this.data.selectedMicrochipData.microchip);
        }
      }else{
          this.brandFullList=[];
          this.brandList=[];
        }
    })
    this.subs.push(sub);
  }

  brandOnChange(e) {
    this.brandCurrentVal=e.value;
    this.microchipForm.get('microchip').setValue('');
    if(this.brandFullList[e.value].length > 0){
      this.microchipAvailableData = this.brandFullList[e.value];
    }else
      this.microchipAvailableData =[];
  }

  isDisable(){
    if(this.data.selectedMicrochipData) 
     return true
    else
       return false;
  }

  onSaveClicked() {
    if (this.microchipForm.valid){
      let payload = {
        manufacturer:this.microchipForm.controls['brand'].value,
        microchip:this.microchipForm.controls['microchip'].value,
        injection_date:(`${moment(this.microchipForm.controls['injection_date'].value).format("MM/DD/YYYY")} ${(this.microchipForm.controls['injection_time'].value)}`),
        custom:0
      };
       if((this.brandFullList[this.microchipForm.controls['brand'].value]).length==0){
         const mc = this.microchipForm.controls['microchip'].value;
         const animalId = this.data && this.data.animal && this.data.animal.animal_id;
        this.animalService.validateMicrochip(mc, animalId).subscribe(result => {
          if(result && !result.error){
            payload['custom']=1;
            this.dialogRef.close(payload);
          }
            
          else{
            this.toast.open(result.message, 'Ok', {
              duration: 2000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['danger-snackbar']
          });
          }  
        })
       }else
          this.dialogRef.close(payload);
              
    }else
        this.microchipForm.markAllAsTouched();
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

