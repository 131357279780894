import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Transaction } from '../models/transaction';
import { stripeUrl } from '../constant/constant';
import { loadStripe } from '@stripe/stripe-js';


@Injectable({
  providedIn: "root"
})

export class TransactService {

  isStripeKey = false
  private stripePromise:any;
  stripeBaseUrl = 'https://api.stripe.com/v1';

  constructor(
    private apiService: ApiService
  ) {
    if(window.location.hostname == 'dev-agency.getsaint.com' || window.location.hostname == 'localhost' || window.location.hostname == 'demo-agency.getsaint.com') {
      this.isStripeKey = false
    } else if (window.location.hostname == 'agency.getsaint.com') {
      this.isStripeKey = true
    }
  }

  createModifyAdoption(payload): Observable<any> {
    return this.apiService.post(`api/transact/create-modify-adoption`, payload);
  }

  getAllAdoptions(): Observable<any> {
    return this.apiService.get(`api/transact/get-all-transactions`);
  }

  getAdoptionById(id): Observable<any> {
    return this.apiService.get(`api/transact/transaction-by-id/${id}`);
  }

  getContractDocById(id): Observable<any> {
    return this.apiService.getBlob(`api/transact/get-contract-doc/${id}`);
  }
  //transfer
  getAllTransfer(): Observable<any> {
    return this.apiService.get(`api/transact/transfer`);
  }
  getAllTransferById(transactionId): Observable<any> {
    return this.apiService.get(`api/transact/transfer/${transactionId}`);
  }
  createModifyTransfer(payload): Observable<any> {
    return this.apiService.post(`api/transact/transfer`, payload);
  }
  transferAction(transactionId, payload): Observable<any> {
    return this.apiService.post(`api/transact/transfer/${transactionId}/action`, payload);
  }
  transferDocUpload(payload: any, transactionId): Observable<any> {
    return this.apiService.post(`api/transact/transfer/${transactionId}/upload`, payload);
  }

  getPickupListsForIntake(): Observable<any> {
    return this.apiService.get(`api/agency/get-transaction-type-mapping-for-tenant`);
  }

  //New Changes
  
  createModifyIntakeTransaction(transaction: Transaction): Observable<any> {
    return this.apiService.post(`api/transact/create-modify-intake-transaction`, transaction);
  }

  createModifyOutcomeTransaction(transaction: Transaction,is_updateDate?:boolean): Observable<any> {
    if(is_updateDate) return this.apiService.post(`api/transact/create-modify-outcome?is_updateDate=${is_updateDate}`, transaction);
    else return this.apiService.post(`api/transact/create-modify-outcome`, transaction);
  }

  createModifyInitiateIntake(transaction: Transaction): Observable<any> {
    return this.apiService.post(`api/transact/create-intake-transaction-cases`, transaction);
  }

  getAllIntakeTransaction(): Observable<any> {
    return this.apiService.get(`api/transact/get-transactions-for-tenant`);
  }
  
  getTransactionById(id: any, mapping_id: any): Observable<any> {
    return this.apiService.get(`api/transact/get-transaction-details-by-id?transactionId=${id}&tenantMappingId=${mapping_id}`);
  }

  // Donation
  createModifyDonation(payload): Observable<any> {
    return this.apiService.post(`api/transact/create-modify-donation`, payload);
  }

  getDonationById(id: number): Observable<any> {
    return this.apiService.get(`api/transact/get-donation-by-id?id=${id}`);
  }

  getDonationInputList(): Observable<any> {
    return this.apiService.get(`api/input/Donation`);
  }

  getDonationList(): Observable<any> {
    return this.apiService.get(`api/transact/get-donations-for-tenant`);
  }

  deleteDonations(donationIds): Observable<any> {
    return this.apiService.delete(`api/transact/delete-donations`,donationIds);
  }

  //Services
  createModifyTransactServices(payload): Observable<any> {
    return this.apiService.post(`api/transact/create-modify-transaction-services`, payload);
  }
  

  // Delete intake
  deleteIntakeTransaction(payload: any): Observable<any> {
    const endpoit = `api/transact/delete-intake-transaction`;
    return this.apiService.delete(endpoit, payload);
  }

  deleteOutcomeTransaction(payload: any): Observable<any> {
    const endpoint = `api/transact/delete-outcome-transaction`;
    return this.apiService.delete(endpoint, payload);
  }

  //Get Quote
  getQuote(payload): Observable<any> {
    const endpoint = `api/transact/create-insurance-quote`;
    return this.apiService.post(endpoint, payload);
  }

  //Vaidate State
  validateState(state: string): Observable<any> {
    const endpoint = `api/transact/validate-state?state=${state}`;
    return this.apiService.get(endpoint);
  }

  //Payment Link
  quotePaymentLink(payload,sendMail): Observable<any> {
    const endpoint = `api/transact/create-policy?send_mail=${sendMail}`;
    return this.apiService.post(endpoint,payload);
  }

  createPaymentIntent(payload) {
    const endpoint = `${this.stripeBaseUrl}/payment_intents`;
    return this.apiService.postStripe(endpoint,payload);
  }
  
  
  // private stripePromise = loadStripe(this.isStripeKey ? stripeUrl.producationPublicKey : stripeUrl.testStripePublicKey);

  async getStripe() {
    // this.stripePromise = loadStripe(this.isStripeKey ? stripeUrl.producationPublicKey : stripeUrl.testStripePublicKey);
    return loadStripe(this.isStripeKey ? stripeUrl.producationPublicKey : stripeUrl.testStripePublicKey);
  }
  
  
   makePayment(payload) {
    const endpoint = `api/agency/make-payment`;
    return this.apiService.post(endpoint,payload);
  }

  addSubscription(payload){
   const endpoint = 'api/agency/add-subscription';
   return this.apiService.post(endpoint,payload);
  }
  
   confirmPayment(payload,id) {
    const endpoint = `${this.stripeBaseUrl}/payment_intents/${id}/confirm`;
    return this.apiService.postStripe(endpoint,payload);
  }

  updatePaymentIntent(payload, id) {
    const endpoint = `${this.stripeBaseUrl}/payment_intents/${id}`;
    return this.apiService.postStripe(endpoint,payload);
  }

  getPaymentList(categoryId , paymentCategory){
    const endpoint = `api/agency/get-payments-by-category-id?category_id=${categoryId}&payment_category=${paymentCategory}`
    return this.apiService.get(endpoint);
  }
}