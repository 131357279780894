<div mat-dialog-title>
  <div>
    <span>New Medication <span *ngIf="data.animal?.name">- {{data.animal?.name}}</span></span>
  </div>
  <button mat-icon-button>
    <mat-icon (click)="onNoClick()">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="row">
    <div class="col-7">
      <form class="row" [formGroup]="medicationForm" style="justify-content: space-between;">
          <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent" style="width: 32%;">
            <mat-label>Medicine</mat-label>
            <mat-select formControlName="medicine">
              <mat-option [value]="''">Select</mat-option>
              <mat-option *ngFor="let medicine of medicalMasterData?.animal_medicine_list" [value]="medicine.value">
                {{medicine.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="medicationForm.controls['medicine'].hasError('required')">
              Medicine is required
            </mat-error>
          </mat-form-field>
  
          <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent" style="width: 28%;">
            <mat-label>Dosage</mat-label>
            <input type="number" matInput formControlName="dosage" autocomplete="off" min="0.01" step="0.01" (focusout)="onFocusOutEvent($event)">
            <mat-error *ngIf="medicationForm.controls['dosage'].hasError('required')">
              Dosage is required
            </mat-error>
            <mat-error *ngIf="medicationForm.controls['dosage'].hasError('min')">
              Dosage should be greater than 0.01
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent" style="width: 28%;">
            <mat-label>Unit</mat-label>
            <mat-select formControlName="unit">
              <mat-option [value]="''">Select</mat-option>
              <mat-option *ngFor="let unit of medicalMasterData?.animal_medicine_unit" [value]="unit.value">
                {{unit.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="medicationForm.controls['unit'].hasError('required')">
              Unit is required
            </mat-error>
          </mat-form-field>
        
        <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent" style="width: 32%;">
          <mat-label>Frequency</mat-label>
          <mat-select formControlName="frequency">
            <mat-option [value]="''">Select</mat-option>
            <mat-option *ngFor="let frequency of medicalMasterData?.animal_medicine_freq" [value]="frequency.value">
              {{frequency.value}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="medicationForm.controls['frequency'].hasError('required')">
            Frequency is required
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent" style="width: 28%;">
          <mat-label>Start Date</mat-label>
          <input type="text" matInput formControlName="start_date" autocomplete="off" [matDatepicker]="picker1">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
          <!--<input type="text" matInput appDateValidator appDateFormatter formControlName="start_date" autocomplete="off">-->
          <mat-error *ngIf="medicationForm.controls['start_date'].hasError('required')">
            Start date is required
          </mat-error>
          <mat-error *ngIf="medicationForm.controls['start_date'].hasError('invalid')">
            Invalid date format
          </mat-error>
          
        </mat-form-field>

        <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent" style="width: 28%;"
          *ngIf="medicationForm.get('frequency').value != 'Single Dose'">
          <mat-label>End Date</mat-label>
          <input type="text" matInput formControlName="end_date" autocomplete="off" [matDatepicker]="picker2">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <!---<input type="text" matInput appDateValidator appDateFormatter formControlName="end_date" autocomplete="off">-->
          <div class="mat-form-field-subscript-wrapper" style="top:31px" *ngIf="medicationForm.errors?.range && medicationForm.touched">
            <div style="opacity: 1; transform: translateY(0%);">
                <div class="mat-error">End date can not be less than From date</div>
            </div>
          </div>
          <mat-error *ngIf="medicationForm.controls['end_date'].hasError('required')">
            End date is required
          </mat-error>
          <mat-error *ngIf="medicationForm.controls['end_date'].hasError('invalid')">
            Invalid date format
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field" [floatLabel]="'always'" color="accent" style="width: 32%;">
          <mat-label>Instruction</mat-label>
          <mat-select formControlName="instruction">
            <mat-option [value]="''">Select</mat-option>
            <mat-option *ngFor="let instruction of medicalMasterData?.animal_medicine_inst" [value]="instruction.value">
              {{instruction.value}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="medicationForm.controls['instruction'].hasError('required')">
            Instruction is required
          </mat-error>
        </mat-form-field>

      </form>
      <div class="row">
        <button mat-raised-button class="green-button" (click)="onCreateClicked()" cdkFocusInitial>
          CREATE
        </button>
      </div>
    </div>

    <div class="col">
      <div class="label-container">
        <span class="mb-2">Medication History</span>
        <ag-grid-angular style="width: 100%; height: 200px;" class="ag-theme-alpine"
          [rowData]="medicationHistoryRowData | async" [frameworkComponents]="frameworkComponents"
          [columnDefs]="medicationHistoryColumnDefs" [defaultColDef]="defaultColDef" [suppressRowClickSelection]="true"
          [gridOptions]="gridOptions" [getRowHeight]="getRowHeight" [headerHeight]="headerHeight"
          (gridReady)="onMedicationHistoryGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col pl-0">

      <div class="row py-2 pl-3">
        <mat-card class="icon-card" matTooltip="close medicine" [matTooltipPosition]="'above'"
          matTooltipClass="custom-tooltip" (click)="onRemoveSelectedMedication()">
          <mat-icon>close</mat-icon>
        </mat-card>
      </div>

      <ag-grid-angular style="width: 100%; height: 200px;" class="ag-theme-alpine" [rowData]="medicationRowData | async"
        [frameworkComponents]="frameworkComponents" [columnDefs]="medicationColumnDefs" [defaultColDef]="defaultColDef"
        [suppressRowClickSelection]="true" [gridOptions]="gridOptions" [getRowHeight]="getRowHeight" [rowSelection]="'multiple'"
        [headerHeight]="headerHeight" (gridReady)="onMedicationGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button class="white-button" (click)="onNoClick()">CANCEL</button>
  <button mat-raised-button class="green-button" (click)="onSaveClicked()" cdkFocusInitial [disabled]="isdisableCheck()">
    SAVE
  </button>
</div>