import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GridApi, GridOptions} from 'ag-grid-community';
import {Observable, of, Subscription} from 'rxjs';
import {AgGridClickableCellComponent} from '../ag-grid-clickable-cell/ag-grid-clickable-cell.component';
import {Utils} from 'src/app/core/utils/util';
import {AccountsService} from 'src/app/core/services/accounts.service';
import {map} from 'rxjs/operators';
import {UtilService} from 'src/app/core/services/util.service';
import * as moment from 'moment';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import * as _ from 'lodash';

export interface DialogData {
    first_name: string;
    last_name: string;
}

@Component({
    selector: 'app-contact-lookup-dialog',
    templateUrl: './contact-lookup-dialog.component.html',
    styleUrls: ['./contact-lookup-dialog.component.scss']
})
export class ContactLookupDialogComponent implements OnInit, OnDestroy {

    contactLookupForm: FormGroup;

    public gridOptions = {
        context: {
            componentParent: this,
        },
        suppressHorizontalScroll: false,
        rowStyle: {background: 'white'}
    } as GridOptions;
    gridApi: GridApi;
    gridColumnApi: any;
    defaultColDef: any;
    frameworkComponents;
    getRowHeight: any;
    headerHeight: any;
    rowData: Observable<any>;
    columnDefs: any[] = [];
    subs: Subscription[] = [];
    materData: any;
    cities: any[] = [];
    states: any[] = [];
    counties: any[] = [];

    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(
        public dialogRef: MatDialogRef<ContactLookupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private fb: FormBuilder,
        private accountService: AccountsService,
        private utilService: UtilService,
        private _snackBar: MatSnackBar,
    ) {
        this.frameworkComponents = {
            cusotmClickableCell: AgGridClickableCellComponent
        };

        this.getRowHeight = (params) => {
            return 34;
        }
        this.headerHeight = 34;
    }

    ngOnInit(): void {
        this.initContactLookupForm();
        this.setupGridColumn();
        this.getMaterData();
    }

    initContactLookupForm() {
        this.contactLookupForm = this.fb.group({
            first_name: [''],
            last_name: [''],
            email: [''],
            phone: [''],
            street1: [''],
            zip_code: [''],
            state: [''],
            city: [''],
            contact_type_id: [''],
            contact_status_id: [''],
            'billing_street1': [''],
            'billing_street2': [''],
            'billing_zip_code': [''],
            'billing_city': [''],
            'billing_state': [''],
            'billing_county': [''],
            'employment_status': [''],
            'employment_type': [''],
            'employer_name': [''],
            'employer_phone': ['']
        });
    }

    setupGridColumn() {
        this.defaultColDef = Utils.getBaseThemeAgGridCol();
        this.columnDefs = [
            {
                headerName: '',
                field: 'check',
                checkboxSelection: true,
                suppressSizeToFit: true,
                width: 31,
                sortable:false,
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0 0 0 10px'
                },
            },
            {
                headerName: 'Contact',
                field: 'full_name',
                width: 100,
                suppressSizeToFit: true,
                cellRenderer: this.columnRender(),
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Type',
                field: 'type',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'flex-start',
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Address',
                field: 'address',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'flex-start',
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Phone',
                field: 'phone',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'Email',
                field: 'email',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'flex-start',
                },
                filterParams: Utils.filterParams()
            },
            {
                headerName: 'DOB',
                field: 'dob',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filterParams: Utils.dateFilterParams()
            },
            {
                headerName: 'Age ( In Years)',
                field: 'age',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                filter:'agNumberColumnFilter',
                filterParams: Utils.filterParams()
            },
        ]
    }

    initialNamePopulate() {
        this.contactLookupForm.patchValue({
            first_name: this.data.first_name ? this.data.first_name : '',
            last_name: this.data.last_name ? this.data.last_name : ''
        })
    }

    getMaterData() {
        let sub = this.utilService.getMasterInputs('Contact').subscribe(res => {
            this.materData = res.input;
        })
        this.subs.push(sub);
    }

    columnRender() {
        return 'cusotmClickableCell';
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        // this.rowData = of([]);
        this.initialNamePopulate();
        this.onSearchClicked();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSearchClicked() {
        let count = 0;
        Object.keys(this.contactLookupForm.value).forEach(ele => {
            if (this.contactLookupForm.value[ele] != '') count = count + 1;
        })
        if (count == 0) {
            this.rowData = of([]);
           /* this._snackBar.open('No contact found', 'Ok', {
                duration: 2000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                panelClass: ['warning-snackbar']
            });*/
            return;
        }
        let payload = {
            first_name: this.contactLookupForm.value.first_name ? this.contactLookupForm.value.first_name : '',
            last_name: this.contactLookupForm.value.last_name ? this.contactLookupForm.value.last_name : '',
            phone: this.contactLookupForm.value.phone ? this.contactLookupForm.value.phone : '',
            email: this.contactLookupForm.value.email ? this.contactLookupForm.value.email : '',
            street1: this.contactLookupForm.value.street1 ? this.contactLookupForm.value.street1 : '',
            zipCode: this.contactLookupForm.value.zip_code ? this.contactLookupForm.value.zip_code : '',
            county: '',
            state: this.contactLookupForm.value.state ? this.contactLookupForm.value.state : '',
            city: this.contactLookupForm.value.city ? this.contactLookupForm.value.city : '',
        }
        this.rowData = this.accountService.lookupContact(payload).pipe(
            map((res: any[]) => {
                // res = res.filter(element => {
                //   if (element.origination == 'Existing') {
                //     return element;
                //   }
                // })
                res.forEach(ele => {
                    
                    ele['type'] = this.materData?.contact_type.find(elm => elm.id == ele.contact_type_id)?.value;
                    ele['age'] = moment(ele['dob']).isValid() ? (moment(Utils.convertTZ(new Date)).diff(moment(ele['dob']), 'years')) : '';
                    ele['dob'] = moment(ele['dob']).isValid() ? (moment(ele['dob']).format('MM/DD/YYYY')) : '';
                })

                if (res.length == 0) {
                    this._snackBar.open('No contact found', 'Ok', {
                        duration: 2000,
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        panelClass: ['warning-snackbar']
                    });
                }
                return res;
            })
        );
    }

    onCreateNewClicked() {
        // this.dialogRef.close();
        this.contactLookupForm.controls.first_name.setValidators([Validators.required]);
        this.contactLookupForm.controls.first_name.updateValueAndValidity();

        this.contactLookupForm.controls.last_name.setValidators([Validators.required]);
        this.contactLookupForm.controls.last_name.updateValueAndValidity();

        this.contactLookupForm.controls.email.setValidators([Validators.required]);
        this.contactLookupForm.controls.email.updateValueAndValidity();

        this.contactLookupForm.controls.phone.setValidators([Validators.required]);
        this.contactLookupForm.controls.phone.updateValueAndValidity();

        this.contactLookupForm.controls.street1.setValidators([Validators.required]);
        this.contactLookupForm.controls.street1.updateValueAndValidity();

        this.contactLookupForm.controls.zip_code.setValidators([Validators.required]);
        this.contactLookupForm.controls.zip_code.updateValueAndValidity();

        this.contactLookupForm.controls.state.setValidators([Validators.required]);
        this.contactLookupForm.controls.state.updateValueAndValidity();

        this.contactLookupForm.controls.city.setValidators([Validators.required]);
        this.contactLookupForm.controls.city.updateValueAndValidity();

        console.log(this.contactLookupForm.controls);

        if (this.contactLookupForm.valid) {
            this.contactLookupForm.patchValue({
                contact_type_id: 1,
                contact_status_id: 1,
                employment_type: 'Part Time',
                employment_status: 'Yes'
            });
            let payload = this.contactLookupForm.value;
            payload['dob'] = moment(Utils.convertTZ(new Date)).format('YYYY-MM-DD');
            payload['county'] = this.counties[0];
            payload['existing'] = true;
            payload['created_by'] = '';
            payload['contact_id'] = '';
            payload['origin'] = true;
            payload['bill_address_same'] = true;
            this.accountService.createContact(payload).subscribe(res => {
                this._snackBar.open('Contact created', 'Ok', {
                    duration: 2000,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    panelClass: ['light-snackbar']
                });
                this.onSearchClicked();
            })
        } else {
            this.contactLookupForm.markAllAsTouched();
        }
    }

    onRefreshClicked() {
        this.contactLookupForm.reset();
        this.rowData = of([]);
    }

    isDisableButtons() {
        return false;
    }

    onPhoneInputChange(event) {
        let phone: string = event.target.value;
        if (phone.indexOf('-') > -1) {
            phone = phone.split('-').join('');
        }
        if (phone.length == 10) {
            this.contactLookupForm.patchValue({
                phone: `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, 10)}`
            });
        } else {
            this.contactLookupForm.controls.phone.setErrors({len: true});
        }
    }

    onZipcodeInputChange(event) {
        const payload = {
            zipcode: event.target.value,
            city: '',
            state: '',
        };
        this.cities = [];
        this.states = [];
        this.counties = [];
        this.getAddressInfo(payload);
        // this.utilService.getSmartyZipCode(payload).subscribe(res => {
        //   if (res[0].status == 'invalid_zipcode') {
        //     this.contactLookupForm.controls.zip_code.setErrors({ invalid: true })
        //     return;
        //   }
        //   res[0].city_states?.forEach(city_state => {
        //     if (!this.cities.includes(city_state.city)) {
        //       this.cities.push(city_state.city)
        //     }
        //     if (!this.states.includes(city_state.state)) {
        //       this.states.push(city_state.state)
        //     }
        //   });
        //   res[0].zipcodes?.forEach(zipcode => {
        //     if (!this.counties.includes(zipcode.county_name)) {
        //       this.counties.push(zipcode.county_name)
        //     }
        //   });
        //
        //   this.contactLookupForm.patchValue({
        //     city: this.cities.length > 0 ? this.cities[0] : '',
        //     // county: this.counties.length > 0 ? this.counties[0] : '',
        //     state: this.states.length > 0 ? this.states[0] : '',
        //   })
        // });
    }

    getAddressInfo(payload: any): void {
        this.utilService.getAddressInfo(payload).subscribe((res: any) => {
            if (res && res.length) {
                const data = res[0];
                const cities = [];
                cities.push(data.primary_city);
                const acceptableCities = data && data.acceptable_cities && data.acceptable_cities.split('_');
                if (acceptableCities && acceptableCities.length) {
                    _.forEach(acceptableCities, (item: any) => cities.push(item));
                }
                _.forEach(cities, (item) => this.cities.push(item));
                this.states.push(data.state);
                this.counties.push(data.county);
                this.contactLookupForm.patchValue({
                    city: this.cities.length > 0 ? this.cities[0] : '',
                    county: this.counties.length > 0 ? this.counties[0] : '',
                    state: this.states.length > 0 ? this.states[0] : '',
                });
            } else {
                this.contactLookupForm.controls.zip_code.setErrors({incorrect: true});
                this._snackBar.open(
                    'You have entered invalid Zipcode',
                    'ok', {
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                        duration: 2000,
                        panelClass: 'danger'
                    });
            }
        });
    }


    onSelect() {
        let contact = this.gridApi?.getSelectedRows()[0];
        contact = _.omit(contact, ['created_by', 'created_time']);
        if (contact.origination === 'In-Network') {
            const sub = this.accountService.createContact(contact).subscribe(res => {
                this.dialogRef.close(contact);
            });
            this.subs.push(sub);
        } else {
            this.dialogRef.close(contact);
        }
    }

    ngOnDestroy() {
        this.subs.forEach(ele => ele.unsubscribe());
    }
}
