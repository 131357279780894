import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GridApi, GridOptions } from 'ag-grid-community';
import { AnimalService } from 'src/app/core/services/animal.service';
import { Subscription, Observable, of, BehaviorSubject } from 'rxjs';
import { Utils } from 'src/app/core/utils/util';
import { map, filter } from 'rxjs/operators';
import { DeleteItemDialogComponent } from 'src/app/shared/components/delete-item-dialog/delete-item-dialog.component'
import { MatDialog } from '@angular/material/dialog';

export interface DialogData {
  title: string; 
  listData;
  user;
  seletctedScheduleShift;
}

@Component({
  selector: 'app-animal-allocation',
  templateUrl: './animal-allocation.component.html',
  styleUrls: ['./animal-allocation.component.scss']
})
export class AnimalAllocationComponent implements OnInit {
  public columnDefs: any[] = [];
  public gridOptions = {
    context: {
        componentParent: this,
    },
    suppressHorizontalScroll: false,
    rowStyle: { background: 'white' },
    
  } as GridOptions;

  gridApi: GridApi;
  gridColumnApi: any;

  defaultColDef: any;
  frameworkComponents;
  getRowHeight;
  headerHeight;

  rowData: any[]=[];
  selectedTests: any[] = [];

  constructor(public dialogRef: MatDialogRef<AnimalAllocationComponent>,@Inject(MAT_DIALOG_DATA) public data: DialogData,private animalService:AnimalService,public dialog: MatDialog) {

      this.getRowHeight = (params) => {
        return 34;
      }
      this.headerHeight = 34;
  }
    
    ngOnInit(): void {
     this.rowData= this.data.listData;
     this.setupGridColumn();
    }

    onRowSelected(event) {
     
      let rowIndex=event.rowIndex;
      if(event.node.selected==false){
        event.node.data['animal_name']=event.node.data.animal_name;
      }
      event.api.refreshCells({
        force: true,
      //  columns: [event.column],
        rowNodes: [event.api.getRowNode(rowIndex)]
     });
      
    }
  
    onSelectionChanged(event) {
      var rowCount = event.api.getSelectedNodes().length;
    }
  
    onNoClick(): void {
      this.dialogRef.close();
    }

    setupGridColumn() {
      this.defaultColDef = Utils.getBaseThemeAgGridCol();
      this.columnDefs = [
                          {
                            headerName: 'ID',
                            field: 'animal_id',
                            cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'center' },
                            suppressSizeToFit: true,
                            width: 70,
                            cellClassRules: {
                              'partial_allocation': params => {
                                return (params.data.contact_name)=="Partial";
                              }
                            },
                            filter:'agNumberColumnFilter',
                            filterParams: Utils.filterParams()
                        },
                        {
                          headerName: 'Name',
                          field: 'animal_name',
                          cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
                          suppressSizeToFit: true,
                          width: 150,
                          cellRenderer: (params,userId=this.data.user) => {
                         //   console.log(params);
                            return (params.node.selected) ? (`<mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">done</mat-icon>`+params.value) : params.value;
                          },
                          cellClassRules: {
                            'partial_allocation': params => {
                              return (params.data.contact_name)=="Partial";
                            }
                          },
                         // cellClass: function(params) { return (params.data.contact_name == "Partial") ? "partial_allocation" : ""; },
                          filterParams: Utils.filterParams()
                        },
                        {
                          headerName: 'Species',
                          field: 'species',
                          cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
                          width: 120,
                          cellClassRules: {
                            'partial_allocation': params => {
                              return (params.data.contact_name)=="Partial";
                            }
                          },
                          filterParams: Utils.filterParams(),
                        },
                        {
                          headerName: 'Breed',
                          field: 'breed',
                          cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
                          width: 150,
                          cellClassRules: {
                            'partial_allocation': params => {
                              return (params.data.contact_name)=="Partial";
                            }
                          },
                          filterParams: Utils.filterParams()
                        },
                        {
                          headerName: 'Room',
                          field: 'room',
                          cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
                          suppressSizeToFit: true,
                          width: 130,
                          cellClassRules: {
                            'partial_allocation': params => {
                              return (params.data.contact_name)=="Partial";
                            }
                          },
                          filterParams: Utils.filterParams()
                        },
                        {
                          headerName: 'Kennel',
                          field: 'cage',
                          cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
                          suppressSizeToFit: true,
                          width: 100,
                          cellClassRules: {
                            'partial_allocation': params => {
                              return (params.data.contact_name)=="Partial";
                            }
                          },
                          filterParams: Utils.filterParams()
                        },
                        
                        {
                          headerName: 'Contact',
                          field: 'contact_name',
                          cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
                          width: 100,
                        //  cellClass: function(params) { return (params.data.contact_name == "Partial") ? "partial_allocation" : ""; },
                          cellClassRules: {
                            'partial_allocation': params => {
                              return (params.data.contact_name)=="Partial";
                            }
                          },
                          filterParams: Utils.filterParams()
                        }
                        ];   
    }

    cellClass(params) {
      // && params.data.contact_id!=params.data.contact_refrence_id
      if(params.data.contact_name == "Partial"){
        return "partial_allocation";
      }
    }

   onGridReady(params) {
      
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.sizeColumnsToFit();
      
      this.gridApi.forEachNode((node) => {
        if (node.data.contact_id === this.data.user){
          node.setSelected(true);
        }
      })
    //  await this.refreshMainGrid();
      
    }
  
    async refreshMainGrid(){
      let filterCriteria={
        "contact_id":this.data.user,
        "seletcted_schedule_shift":this.data.seletctedScheduleShift
      }

      await this.animalService.getAnimalListResourceAssignment(filterCriteria).toPromise().then((res: any[]) => {
        this.rowData=res;
      })
    }
  
    onSaveClicked() {
      let partialCheck=this.gridApi.getSelectedRows().filter(res=>res.contact_name=="Partial");
      if(partialCheck.length > 0){
        const dialogRef = this.dialog.open(DeleteItemDialogComponent, {
          width: '30em',
          data: { deleteMsg: 'Are you sure you want to allocate to selected animals?' },
          autoFocus: false
        });
  
        dialogRef.afterClosed().subscribe(result => {
          if (result === 'YES') {
            this.dialogRef.close(this.gridApi.getSelectedRows());
          }
        });
      }else{
        this.dialogRef.close(this.gridApi.getSelectedRows());
      }
    }
  
}