<div mat-dialog-title>
    <div>
      <span>Select Food For - {{data.animal?.name}}</span>
    </div>
    <button mat-icon-button>
      <mat-icon (click)="onNoClick()">close</mat-icon>
    </button>
  </div>
  
  <div mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <form class="row" [formGroup]="foodForm">
            <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent">
                <mat-label>Food</mat-label>
                <mat-select formControlName="food">
                  <mat-option [value]="''">Select</mat-option>
                  <mat-option *ngFor="let food of data.animalFoodList" [value]="food">
                    {{food}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="foodForm.controls['food'].hasError('required')">
                  Food is required
                </mat-error>
              </mat-form-field>

              <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent">
                <mat-label>Quantity</mat-label>
                <input type="number" matInput formControlName="quantity" autocomplete="off" min="0.01" (focusout)="onFocusOutEvent($event)"/>
                <mat-error *ngIf="foodForm.controls['quantity'].hasError('required')">
                    Quantity is required
                </mat-error>
                <mat-error *ngIf="foodForm.controls['quantity'].hasError('min')">
                    Quantity should be greater than 0.01
                </mat-error>
              </mat-form-field>
    
              <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent">
                <mat-label>Unit</mat-label>
                <mat-select formControlName="unit">
                  <mat-option [value]="''">Select</mat-option>
                  <mat-option *ngFor="let unit of animalFoodUnit" [value]="unit">
                    {{unit}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="foodForm.controls['unit'].hasError('required')">
                  Unit is required
                </mat-error>
              </mat-form-field>

              <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent">
                    <mat-label>Start Date</mat-label>
                    <input type="text" matInput formControlName="start_date" autocomplete="off" [matDatepicker]="picker1">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                    <!--<input type="text" matInput appDateValidator appDateFormatter formControlName="start_date" autocomplete="off">-->
                    <mat-error *ngIf="foodForm.controls['start_date'].hasError('required')">
                      Start date is required
                    </mat-error>
                    <mat-error *ngIf="foodForm.controls['start_date'].hasError('invalid')">
                      Invalid date format
                    </mat-error>
              </mat-form-field>
      
              <mat-form-field class="form-field basic-field" [floatLabel]="'always'" color="accent">
                    <mat-label>End Date</mat-label>
                    <input type="text" matInput formControlName="end_date" autocomplete="off" [matDatepicker]="picker2">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <div class="mat-form-field-subscript-wrapper" style="top:31px" *ngIf="foodForm.errors?.range && foodForm.touched">
                      <div style="opacity: 1; transform: translateY(0%);">
                          <div class="mat-error">End date can not be less than From date</div>
                      </div>
                    </div>
                    <mat-error *ngIf="foodForm.controls['end_date'].hasError('required')">
                      End date is required
                    </mat-error>
                    <mat-error *ngIf="foodForm.controls['end_date'].hasError('invalid')">
                      Invalid date format
                    </mat-error>
              </mat-form-field>
  
        </form>
        <div class="row">
          <button mat-raised-button class="green-button" (click)="onCreateClicked()" cdkFocusInitial>
            CREATE
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col pl-0">
    
        <div class="row py-2 pl-3">
          <mat-card class="icon-card" matTooltip="Delete Food" [matTooltipPosition]="'above'"
            matTooltipClass="custom-tooltip" (click)="onRemoveSelectedMedication()">
            <mat-icon>close</mat-icon>
          </mat-card>
        </div>
    
        <ag-grid-angular style="width: 100%; height: 200px;" class="ag-theme-alpine" [rowData]="foodRowData | async"
          [frameworkComponents]="frameworkComponents" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
          [suppressRowClickSelection]="true" [gridOptions]="gridOptions" [getRowHeight]="getRowHeight" [rowSelection]="'multiple'"
          [headerHeight]="headerHeight" (gridReady)="onAnimalFoodGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
</div>



<div mat-dialog-actions>
    <button mat-raised-button class="white-button" (click)="onNoClick()">CANCEL</button>
    <button mat-raised-button class="green-button" (click)="onSaveClicked()" cdkFocusInitial [disabled]="isdisableCheck()">
      SAVE
    </button>
</div>