import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GridApi, GridOptions } from 'ag-grid-community';
import { Animal } from 'src/app/core/models/animal';
import { AgGridClickableCellComponent } from '../../components/ag-grid-clickable-cell/ag-grid-clickable-cell.component';

import { UtilService } from 'src/app/core/services/util.service';
import { Subscription, Observable, of, BehaviorSubject } from 'rxjs';
import { Utils } from 'src/app/core/utils/util';
import * as _moment from 'moment';
import { difference, update } from 'lodash';

const moment = _moment;

export interface DialogData {
  animal: Animal;
  selectedActivity?:any[];
  animalActivityList:any[];
}

@Component({
  selector: 'app-animal-activity',
  templateUrl: './animal-activity.component.html',
  styleUrls: ['./animal-activity.component.scss']
})

export class AnimalActivityComponent implements OnInit {
  animalActivityGridApi: GridApi;
  public gridOptions = {
    context: {
      componentParent: this,
    },
    suppressHorizontalScroll: false,
    rowStyle: { background: 'white' }
  } as GridOptions;
  defaultColDef: any;
  frameworkComponents;
  getRowHeight: any;
  headerHeight: any;
  columnDefs: any[] = [];
  rowsCreated:boolean=false;
  ActivityRowData: BehaviorSubject<Array<any[]>>;
  subs: Array<Subscription> = [];
  rowsDeleted:boolean=false;
  deleteIds:any[]=[];

  activityForm: FormGroup;

  //animalTraining:any[]=["Feeding","Fearful","Biting","Leash Handling","Aromatics"]; 
  
  constructor(public dialogRef: MatDialogRef<AnimalActivityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,private utilService:UtilService) {
      this.getRowHeight = (params) => {
        return 34;
      }
      this.headerHeight = 34;
    }

  ngOnInit(): void {
    this.initActivityForm();
    this.getMasterData();
    this.setupAnimalActivityGridColumn();
  }

  initActivityForm() {
    
    this.activityForm = this.fb.group({
                                      activity_value:[''],
                                      start_date: ['',[Validators.required]],
                                      end_date: [''],
                                  }, {validator: this.dateLessThan('start_date','end_date')});
  }

  dateLessThan(from:string,to:string) {
    return (group: FormGroup): {[key: string]: any} => {
      let f = group.controls[from].value;
      let t = group.controls[to].value;
      if((f !== '' && t !== ''))
      {
        return (f <= t) ? null : { range: true };
      }     
    }
 }

  getMasterData() {
    if(this.data.selectedActivity && this.data.selectedActivity.length > 0) {
      this.ActivityRowData = new BehaviorSubject<any[]>([]);
      this.ActivityRowData.next(this.data.selectedActivity);
    }
  }

  setupAnimalActivityGridColumn() {
    this.defaultColDef = Utils.getBaseThemeAgGridCol();
    this.columnDefs = [
      {
        headerName: '',
        field: 'check',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        suppressSizeToFit: true,
        sortable: false,
        resizable: false,
        filter: false,
        minWidth: 10,
        width: 31,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          display: 'flex',
          justifyContent: 'center',
          padding: '0 0 0 10px'
        },
      },
      {
        headerName: 'Date',
        field: 'routine_date',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filter: 'agDateColumnFilter',
        filterParams: Utils.dateFilterParams(),
      },
      
      {
        headerName: 'Activity',
        field: 'activity_value',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'flex-start'
        },
        filterParams: Utils.filterParams(),
      },
      
    ];
  }

  onActivityGridReady(params) {
    this.animalActivityGridApi = params.api;
    this.animalActivityGridApi.sizeColumnsToFit();
    if(this.data.selectedActivity.length == 0 || !this.data.selectedActivity)
      this.ActivityRowData = new BehaviorSubject<any[]>([]);
  }

  activityScheduleList(): Array<any> {
    let activityList: any[] = [];
    let startDate: _moment.Moment = moment(this.activityForm.get('start_date').value, 'MM/DD/YYYY');
    let endDate: _moment.Moment = moment(this.activityForm.get('end_date').value, 'MM/DD/YYYY');
    for (let index = 0; index <= endDate.diff(startDate, 'days'); index++) {
      let tempDate: _moment.Moment = moment(this.activityForm.get('start_date').value, 'MM/DD/YYYY');

     
        let activityPayload = {
                          activity_id: Utils.getUUIDv4(),
                          activity_value: this.activityForm.get('activity_value').value,
                          routine_date: tempDate.add(index, 'days').format('MM/DD/YYYY'),
                        };
                        activityList.push(activityPayload);
    }
    return activityList;
  }

  onRemoveSelectedActivity() {
    let selectedFoodRows: any[] = this.animalActivityGridApi.getSelectedRows();
    let allActivity: any[] = this.ActivityRowData.getValue();
    
    let filteredActivity = allActivity.filter(act => {
      this.rowsDeleted=true;
      let findIndexVal=selectedFoodRows.findIndex(ele => ele.activity_id == act.activity_id);
      if(findIndexVal == -1){
        return findIndexVal;
      }else if(Number(act.activity_id)){
        (act.activity_id) ? this.deleteIds.push(act.activity_id) : '';
      }
      //return selectedMedication.findIndex(ele => ele.medical_id == med.medical_id) == -1;
    });
    this.ActivityRowData.next(filteredActivity);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveClicked() {
    let ActivityResult={
      "delete":this.deleteIds,
      "update":this.ActivityRowData.getValue()
    }
    this.dialogRef.close(ActivityResult);
  }

  onCreateClicked() {
    if(this.activityForm.invalid){
      this.activityForm.markAllAsTouched();
      return;
    }
    
      let activityList: any[] = this.ActivityRowData.getValue();
      activityList = [...activityList, ...this.activityScheduleList()];
      this.ActivityRowData.next(activityList);
      this.rowsCreated=true;
    }

  isdisableCheck(){  
    if(this.ActivityRowData){
     
      let allActivity: any[] = this.ActivityRowData.getValue();
      if(this.rowsDeleted || (allActivity.length > 0 && this.rowsCreated))
          return false;
      else
          return true;
    }else
       return true;
  }

}
