<div mat-dialog-title>
    <div>
      <span>Animal:</span>
      <span class="link" (click)="onAanimalHeaderClicked()" *ngIf="data.animalNameHyperlinkFlag">{{data.animal.name}}</span>
      <span *ngIf="!data.animalNameHyperlinkFlag">{{data.animal.name}}</span>
    </div>
  
    <span>ID: {{data.animal.animal_id}}</span>
  
    <button mat-icon-button>
      <mat-icon (click)="onNoClick()">close</mat-icon>
    </button>
  </div>
  
  
  <div mat-dialog-content>
    <div class="row">
      <div class="col-8">
        <div class="row">
  
          <div class="label-container small">
            <span>Species</span>
            <span>{{data.animal.species}}</span>
          </div>
  
          <div class="label-container small">
            <span>Care Taker</span>
            <span>{{data.animal.care_taker}}</span>
          </div>
  
          <div class="label-container small">
            <span>Primary Breed</span>
            <span>{{data.animal.primary_breed}}</span>
          </div>
  
          <div class="label-container small">
            <span>Secondary Breed</span>
            <span>{{data.animal.secondary_breed}}</span>
          </div>
  
          <div class="label-container small">
            <span>Primary Color</span>
            <span>{{data.animal.primary_color}}</span>
          </div>
          <div class="label-container small">
            <span>Secondary Color</span>
            <span>{{data.animal.secondary_color}}</span>
          </div>
  
          <div class="label-container small">
            <span>Color Pattern</span>
            <span>{{data.animal.color_pattern}}</span>
          </div>
  
          <div class="label-container small">
            <span>Gender</span>
            <span>{{data.animal.gender}}</span>
          </div>
  
          <div class="label-container small">
            <span>Age</span>
            <span>{{data.animal.age}}</span>
          </div>
  
          <div class="label-container small">
            <span>Weight</span>
            <span>{{getWeight()}}</span>
          </div>
          
  
        </div>
      </div>
  
      <div class="col">
        <div class="row">
          <div class="label-container" style="width: 100%;">
            <span>Current Stage</span>
            <span>{{data.animal.existing_status[data.animal.existing_status.length - 1]?.value}}</span>
          </div>
  
          <div class="label-container" style="width: 100%;">
            <span>Agency Name</span>
            <span>{{data.animal.tenant_business_name}}</span>
          </div>
  
          <div class="label-container" style="width: 100%;">
            <span>Transferrable</span>
            <span>{{data.animal.transferrable ? 'Yes': 'No'}}</span>
          </div>
  
          <div class="label-container" style="width: 100%;">
            <span>Location</span>
            <span>{{data.animal.location_city}}, {{data.animal.location_state}}</span>
          </div>
        </div>
      </div>
    </div>
  
    <div class="row">
      <div class="label-container long">
        <span>Notes</span>
        <ag-grid-angular style="width: 100%; height: 200px; margin-top: .5em;" class="ag-theme-alpine"
          [rowData]="notesRowData | async" [columnDefs]="notesColumnDefs" [defaultColDef]="defaultColDef"
          [getRowHeight]="getRowHeight" [headerHeight]="headerHeight" [gridOptions]="gridOptions"
          [overlayNoRowsTemplate]="overlayNoRowsTemplate" (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
  
    </div>
  </div>
  
  
  <div mat-dialog-actions>
    <!-- <button mat-button (click)="onNoClick()">No Thanks</button>
        <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button> -->
  </div>