<div mat-dialog-title>
    <div>
        <span>Vet Lookup</span>
    </div>
    <button mat-icon-button>
        <mat-icon (click)="onNoClick()">close</mat-icon>
    </button>
</div>

<div mat-dialog-content>

    <form class="row" [formGroup]="vetLookupForm" style="justify-content: flex-start;">

        <mat-form-field class="form-field vet-form-field" [floatLabel]="'always'" color="accent">
            <mat-label>Business Type</mat-label>
            <mat-select formControlName="business_type">
                <mat-option>Select</mat-option>
                <mat-option *ngFor="let type of vetMasterData?.vet_type" [value]="type.value">
                    {{type.value}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="vetLookupForm.controls['business_type'].hasError('required')">
                Business Type is required
            </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field vet-form-field" [floatLabel]="'always'" color="accent">
            <mat-label>Business Name</mat-label>
            <input type="text" matInput appTitlecase formControlName="business_name" autocomplete="off">
            <mat-error *ngIf="vetLookupForm.controls['business_name'].hasError('required')">
                Business Name is required
            </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field vet-form-field" [floatLabel]="'always'" color="accent">
            <mat-label>Phone</mat-label>
            <input type="text" matInput formControlName="phone" autocomplete="off"
                   (change)="onPhoneInputChange($event)">
            <mat-error *ngIf="vetLookupForm.controls['phone'].hasError('len')">
                Phone number should be 10 digits
            </mat-error>
            <mat-error *ngIf="vetLookupForm.controls['phone'].hasError('required')">
                Phone number is required
            </mat-error>
        </mat-form-field>

        <!--        <mat-form-field class="form-field vet-form-field" [floatLabel]="'always'" color="accent">-->
        <!--            <mat-label>First Name</mat-label>-->
        <!--            <input type="text" matInput appTitlecase formControlName="first_name" autocomplete="off">-->
        <!--            <mat-error *ngIf="vetLookupForm.controls['first_name'].hasError('required')">-->
        <!--                First Name is required-->
        <!--            </mat-error>-->
        <!--        </mat-form-field>-->

        <!--        <mat-form-field class="form-field vet-form-field" [floatLabel]="'always'" color="accent">-->
        <!--            <mat-label>Last Name</mat-label>-->
        <!--            <input type="text" matInput appTitlecase formControlName="last_name" autocomplete="off">-->
        <!--            <mat-error *ngIf="vetLookupForm.controls['last_name'].hasError('required')">-->
        <!--                Last Name is required-->
        <!--            </mat-error>-->
        <!--        </mat-form-field>-->

        <!--        <mat-form-field class="form-field vet-form-field" [floatLabel]="'always'" color="accent">-->
        <!--            <mat-label>Email</mat-label>-->
        <!--            <input type="text" matInput formControlName="email" autocomplete="off">-->
        <!--            <mat-error *ngIf="vetLookupForm.controls['email'].hasError('required')">-->
        <!--                Email is required-->
        <!--            </mat-error>-->
        <!--        </mat-form-field>-->

        <div class="form-field vet-form-field"></div>
        <mat-form-field class="form-field vet-form-field" [floatLabel]="'always'" color="accent">
            <mat-label>Street 1</mat-label>
            <input type="text" matInput appTitlecase formControlName="street1" autocomplete="off">
            <mat-error *ngIf="vetLookupForm.controls['street1'].hasError('required')">
                Street 1 is required
            </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field vet-form-field" [floatLabel]="'always'" color="accent">
            <mat-label>Zip Code</mat-label>
            <input type="text" matInput formControlName="zip_code" autocomplete="off"
                   (change)="onZipcodeInputChange($event)">
            <!--      <mat-error *ngIf="vetLookupForm.controls['zip_code'].hasError('invalid')">-->
            <!--        Zip Code is invalid-->
            <!--      </mat-error>-->
            <mat-error *ngIf="vetLookupForm.controls['zip_code'].hasError('required')">
                Zip Code is required
            </mat-error>
            <mat-error *ngIf="vetLookupForm.controls['zip_code'].invalid">
                Enter valid zipcode
            </mat-error>
        </mat-form-field>

        <!-- <mat-form-field class="form-field vet-form-field" [floatLabel]="'always'" color="accent">
          <mat-label>County</mat-label>
          <mat-select formControlName="state">
            <mat-option *ngFor="let county of counties" [value]="county">
              {{county}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="vetLookupForm.controls['county'].hasError('required')">
            County is required
          </mat-error>
        </mat-form-field> -->

        <mat-form-field class="form-field vet-form-field" [floatLabel]="'always'" color="accent">
            <mat-label>State</mat-label>
            <mat-select formControlName="state">
                <mat-option *ngFor="let state of states" [value]="state">
                    {{state}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="vetLookupForm.controls['state'].hasError('required')">
                State is required
            </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field vet-form-field" [floatLabel]="'always'" color="accent">
            <mat-label>City</mat-label>
            <mat-select formControlName="city">
                <mat-option *ngFor="let city of cities" [value]="city">
                    {{city}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="vetLookupForm.controls['city'].hasError('required')">
                City is required
            </mat-error>
        </mat-form-field>

    </form>

    <div class="row mt-4" style="justify-content: flex-end;">
        <button mat-raised-button class="gray-button" [disabled]="isDisableButtons()" (click)="onRefreshClicked()"
                style="margin-right: 1em;">
            REFRESH
        </button>
        <button mat-raised-button class="blue-button" [disabled]="isDisableButtons()" (click)="onSearchClicked()">
            SEARCH
        </button>
        <button mat-raised-button class="green-button" *ngIf="!data.cantCreate" [disabled]="isDisableButtons()" (click)="onCreateNewClicked()">
            CREATE NEW
        </button>
    </div>

    <div class="row mt-4">
        <ag-grid-angular style="width: 100%; height: 230px;" class="ag-theme-alpine" [rowData]="rowData | async"
                         [columnDefs]="columnDefs" [getRowHeight]="getRowHeight" [headerHeight]="headerHeight"
                         [suppressRowClickSelection]="true" [defaultColDef]="defaultColDef" [rowSelection]="'single'"
                         [frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions"
                         (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>

</div>

<div mat-dialog-actions>
    <button mat-raised-button class="white-button" (click)="onNoClick()">CANCEL</button>
    <button mat-raised-button class="green-button" cdkFocusInitial [disabled]="gridApi?.getSelectedRows().length<1"
            (click)="onSelect()">
        SELECT
    </button>
    <!-- <button mat-raised-button class="green-button" cdkFocusInitial [disabled]="gridApi?.getSelectedRows().length<1" (click)="onSelect()">
      SELECT
    </button> -->
</div>
