import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UtilService } from 'src/app/core/services/util.service';
import { SettingsService } from 'src/app/core/services/settings.service';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import jp from "jsonpath";
import { AnimalDetailsDialogComponent } from '../animal-details-dialog/animal-details-dialog.component';
import { AnimalService } from 'src/app/core/services/animal.service';
export interface DialogData {
  rowdata: any,
  is_edit: boolean
}

@Component({
  selector: 'app-location-cage-dialog',
  templateUrl: './location-cage-dialog.component.html',
  styleUrls: ['./location-cage-dialog.component.scss']
})
export class LocationCageDialogComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  basicContactInfoForm: FormGroup;
  subs: Subscription[] = [];
  floors: any[] = []
  sections: any[] = []
  rooms: any[] = []
  selectedRoomObj: any[] = []
  campusTreeData: any;
  latestClickedNode:any;

  floorSelectedVal: string;
  sectionSelectedVal: string;
  roommSelectedVal: string;

  constructor(public dialogRef: MatDialogRef<LocationCageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private utilService: UtilService,
    private settingsService: SettingsService,
    public dialog: MatDialog,
    private animalService: AnimalService,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.initBasicContactInfoForm();
    this.campusDataList()
    this.addFormListeners();
    this.latestClickedNode=this.data.rowdata
  }
  async campusDataList() {
    await this.settingsService.geUserLocationTreeData().toPromise().then((res: any[]) => {
      let data = this.locationTreeCount(res ?.find((obj) => { return obj.id === this.data.rowdata.location_id }));
      this.campusTreeData = data.length>0?data[0]:{};
      
     
      if(this.data.rowdata.floor_id!=0 && this.data.rowdata.section_id!=0 && this.data.rowdata.room_id!=0){
        this.basicContactInfoForm.patchValue({
          'floor':this.data.rowdata.floor_id ? this.data.rowdata.floor_id : '',
          'section':this.data.rowdata.section_id ? this.data.rowdata.section_id : '',
          'room': this.data.rowdata.room_id ? this.data.rowdata.room_id : ''
        })

        this.campusTreeData?.children ?.forEach(floorEle => {
            let findObj = this.floors.find(e => e.id === floorEle.id)
            if (!findObj) {
              this.floors.push({ id: floorEle.id, value: floorEle.name })
            }
        })
        this.floorSelectedVal=this.data.rowdata.floor_id ? this.data.rowdata.floor_id : '';
        this.sectionSelectedVal=this.data.rowdata.section_id ? this.data.rowdata.section_id : '';
        this.roommSelectedVal=this.data.rowdata.room_id ? this.data.rowdata.room_id : '';
      }
      
      this.cagePopulate()
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  initBasicContactInfoForm() {
    this.basicContactInfoForm = this.fb.group({
      'floor': [''],
      'section': [''],
      'room': ['']
    })
  }

  onSaveClick() {
    // if (!this.basicContactInfoForm.valid) {
    //   this.basicContactInfoForm.markAllAsTouched();
    // }
    
   // console.log(this.latestClickedNode);
    if (this.latestClickedNode.is_selected) {
      this.dialogRef.close({selectedCageInfo:this.latestClickedNode });
    }
  }

  onClickAnimal(animalObj) {
    let sub = this.animalService.getAnimalDetailById(animalObj.animal_id, animalObj.mapping_id).subscribe(res => {
      const dialogRef = this.dialog.open(AnimalDetailsDialogComponent, {
        width: '70em',
        data: { animal: res, animalNameHyperlinkFlag: false },
        autoFocus: false
      });
    })
    this.subs.push(sub);
  }

  cageClick(sro, cl) {
    // cl['is_selected'] = !!!cl['is_selected'];
    this.latestClickedNode=cl;
    this.latestClickedNode.room_name=sro.room_name;
    this.selectedRoomObj.map((ele)=>{
      ele.case_list.map((obj)=>{
        if(obj.id === cl.id){
          obj['is_selected'] = !!!obj['is_selected'];
          return obj;
        }else{
          obj['is_selected'] = false;
          return obj
        }
      })
      return ele
    })
  }

  addFormListeners() {
    let sub = this.basicContactInfoForm.get('floor').valueChanges
      .subscribe(value => {
        this.sections = []
        this.rooms = []
        this.roommSelectedVal = ""
        this.sectionSelectedVal = ""
        this.floorSelectedVal = value;
        this.basicContactInfoForm.patchValue({ room: '' })
        this.basicContactInfoForm.patchValue({ section: '' })
        this.cagePopulate()
      })
    this.subs.push(sub);

    let sub2 = this.basicContactInfoForm.get('section').valueChanges
      .subscribe(value => {
        this.rooms = []
        this.roommSelectedVal = ""
        this.sectionSelectedVal = value;
        this.basicContactInfoForm.patchValue({ room: '' })
        this.cagePopulate()
      })
    this.subs.push(sub2);

    let sub3 = this.basicContactInfoForm.get('room').valueChanges
      .subscribe(value => {
        this.roommSelectedVal = value
        this.cagePopulate()
      })
    this.subs.push(sub3);
  }

  cagePopulate() {
   // console.log(this.data.rowdata);
    // console.log({campusTreeData:this.campusTreeData, floorSelectedVal: this.floorSelectedVal, sectionSelectedVal: this.sectionSelectedVal, roommSelectedVal: this.roommSelectedVal })
    this.selectedRoomObj = [];
   
    if (this.floorSelectedVal && this.sectionSelectedVal && this.roommSelectedVal) {//for room
        let roomArrResult = [jp.query([this.campusTreeData], `$[?(@.id==${this.data.rowdata.location_id})].children[?(@.id==${this.floorSelectedVal})].children[?(@.id==${this.sectionSelectedVal})].children[?(@.id==${this.roommSelectedVal})]`)]
  
        if (roomArrResult[0].length > 0) {
          roomArrResult[0].forEach((roomEle) => {
            let roomName = roomEle.name;
            let caseList = roomEle.children;
            let newObj = {
              roomObj: { mainObj: roomEle }, room_name: roomName, case_list: caseList
            };
            if(this.data.rowdata.floor_id!=0 && this.data.rowdata.section_id!=0 && this.data.rowdata.room_id!=0){
              caseList=caseList.map((e)=>{
                if(this.data.rowdata?.id === e.id){
                  e['is_selected'] = true
                  return e
                }else{
                  return e
                }
              })
            }
            
            this.selectedRoomObj.push(newObj);
          });
        }
        this.selectedRoomObj?.sort((a, b) => { return a.room_name > b.room_name ? 1 : -1 });    
      } else if (this.floorSelectedVal && this.sectionSelectedVal) { //section
        let roomArrResult = [jp.query([this.campusTreeData], `$[?(@.id==${this.data.rowdata.location_id})].children[?(@.id==${this.floorSelectedVal})].children[?(@.id==${this.sectionSelectedVal})].children`)]
  
        if (roomArrResult[0].length > 0) {
          roomArrResult[0].forEach((ele) => {
            ele ?.forEach(roomEle => {
              let findObj = this.rooms.find(e => e.id === roomEle.id)
              if (!findObj) {
                this.rooms.push({ id: roomEle.id, value: roomEle.name })
              }
              let roomName = roomEle.name;
              let caseList = roomEle.children;
              let newObj = {
                roomObj: { mainObj: roomEle }, room_name: roomName, case_list: caseList
              };
              this.selectedRoomObj.push(newObj);
            });
          });
        }
        this.selectedRoomObj?.sort((a, b) => { return a.room_name > b.room_name ? 1 : -1 });    
      } else if (this.floorSelectedVal) {
        let sectionArrResult = [jp.query([this.campusTreeData], `$[?(@.id==${this.data.rowdata.location_id})].children[?(@.id==${this.floorSelectedVal})].children`)]
  
        if (sectionArrResult[0].length > 0) {
          sectionArrResult[0].forEach((ele) => {
            ele.forEach(secEle => {
              let findObj = this.sections.find(e => e.id === secEle.id)
              if (!findObj) {
                this.sections.push({ id: secEle.id, value: secEle.name })
              }
              secEle.children ?.forEach(roomEle => {
                let roomName = roomEle.name;
                let caseList = roomEle.children;
                let newObj = {
                  roomObj: { mainObj: roomEle }, room_name: roomName, case_list: caseList
                };
                this.selectedRoomObj.push(newObj);
              });
            });
          })
        }
        this.selectedRoomObj?.sort((a, b) => { return a.room_name > b.room_name ? 1 : -1 });    
      } else {
        this.campusTreeData?.children ?.forEach(floorEle => {
          let findObj = this.floors.find(e => e.id === floorEle.id)
          if (!findObj) {
            this.floors.push({ id: floorEle.id, value: floorEle.name })
          }
          floorEle.children ?.forEach(secEle => {
            secEle.children ?.forEach(roomEle => {
              let roomId = roomEle.id;
              let roomName = roomEle.name;
              let caseList = roomEle.children;
              caseList=caseList.map((e)=>{
                if(this.data.rowdata?.id === e.id){
                  e['is_selected'] = true
                  return e
                }else{
                  return e
                }
              })
              let newObj = {
                roomObj: { mainObj: roomEle }, roomId: roomId, room_name: roomName, case_list: caseList
              };
             // console.log(newObj);
              this.selectedRoomObj.push(newObj);
            });
          });
        });
        this.selectedRoomObj?.sort((a, b) => { return a.room_name > b.room_name ? 1 : -1 });    
      }
   // }
    
  }

  locationTreeCount(data) {
    let countData:any[]=[]
    countData.push(data)

    countData?.reduce((accu, currnet) => {
      let floorCount = currnet?.children.reduce((accu1, currnet1) => {
        let sectionCount = currnet1?.children.reduce((accu2, currnet2) => {
          let roomCount = currnet2?.children.reduce((accu3, currnet3) => {
            let cageCount = currnet3?.children.reduce((accu4, currnet4) => {
              return (currnet4?.children?.length > 0 && currnet4?.children[0].animal_id == 0) ? accu4 + 1 : accu4;
              // return currnet4 ?.children ?.length === 0 ? accu4 + 1 : accu4;
            }, 0);
            currnet3['totalCount'] = currnet3?.children?.length, accu3[1];
            currnet3['empty'] = cageCount;
            return [accu3[0] + currnet3?.children?.length, accu3[1] + cageCount];
          }, [0, 0]);
          currnet2['totalCount'] = roomCount[0];
          currnet2['empty'] = roomCount[1];
          return [accu2[0] + roomCount[0], accu2[1] + roomCount[1]];
        }, [0, 0]);
        currnet1['totalCount'] = sectionCount[0];
        currnet1['empty'] = sectionCount[1];
        return [accu1[0] + sectionCount[0], accu1[1] + sectionCount[1]];
      }, [0, 0]);
      currnet['totalCount'] = floorCount[0];
      currnet['empty'] = floorCount[1];
      return [accu[0] + floorCount[0], accu[1] + floorCount[1]];
    }, [0, 0]);
    return countData;
  }

  ngOnDestroy() {
    this.subs.forEach(ele => ele.unsubscribe());
  }
}