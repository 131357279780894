import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiService } from "../core/services/api.service";
import { StorageService } from "../core/services/storage.service";

@Injectable({
  providedIn: "root",
})
export class ProcureService {
  constructor(
    private httpService: ApiService,
    private apiService: ApiService,
    private http: HttpClient
  ) {}

  /* getTransactionHistory(email: string): Observable<any> {
    const endPoint =
      "https://dev-procure.getsaint.com/rest/V1/orders?searchCriteria[filter_groups][0][filters][0][field]=customer_email&searchCriteria[filter_groups][0][filters][0][value]=";
    const token = "jtsqqtgnwwr1gp8506t2xnx1t1fmo9oh";
    const newEndPoint =
      "https://dev-procure.getsaint.com/rest/V1/orders/1?fields=items[name,price,order_id],billing_address,order_id,customer_firstname,customer_lastname,created_at,status,order_id,name,item_id=";
    const header = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    // header.append('Authorization', `${StorageService.getItem('token_type')} ${StorageService.getItem('token')}`);
    // header = header.append('Authorization', 'Bearer llw04vpmkfxotscmoo84ixwpe49an7d5');
    // header.append('X-Requested-With', 'XMLHttpRequest');
    // header.append('X-Tenant-ID', `${StorageService.getItem('tenant_id')}`);
    // header.append('Content-Type', 'application/json');
    const test =
      "https://dev-procure.getsaint.com/rest/V1/orders?searchCriteria[filter_groups][0][filters][0][field]=customer_email&searchCriteria[filter_groups][0][filters][0][value]=vishal@fx-pros.com";
    const url = endPoint + email;
    return this.http.get(url, header);
  }*/

  getPurchaseHistory(): Observable<any> {
    const user = JSON.parse(localStorage.getItem("user"));
    const email = user && user.email;
    const token = "jtsqqtgnwwr1gp8506t2xnx1t1fmo9oh";
    const header = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    };
    const enopint = `${this.getHostname()}/orderDetails.php?customer_email=${email}`;
    return this.http.get(enopint, header);
  }

  getHostname(): any {
    let megentoHostname = "";
    const hostname = window.location.hostname;
    const isDev =
      hostname.includes("dev-agency") ||
      hostname.includes("localhost") ||
      hostname.includes("127.0.0.1");
    const isDemo = hostname.includes("demo-agency");
    if (isDev || isDemo) {
      megentoHostname = "https://dev-procure.getsaint.com";
    } else {
      // megentoHostname = "https://procure.getsaint.com";
      megentoHostname = "https://getsaintshop.com";
    }
    return megentoHostname;
  }

  createPurchaseHistory(payload: any): Observable<any> {
    const endpoint = `api/procure/create-purchase-history`;
    return this.apiService.post(endpoint, payload);
  }

  getTenantPurchaseHistory(): Observable<any> {
    const endpoint = `api/procure/get-purchase-history-for-tenant`;
    return this.apiService.get(endpoint);
  }

  getAvailableMicrochip() {
    return this.apiService.get(`api/procure/get-microchips-for-tenant`);
  }

  getUsedMicrochip() {
    return this.apiService.get(`api/procure/get-all-microchips-used`);
  }

  getPaymentHistory() {
    return this.apiService.get(`api/agency/get-payment-details`)
  }

  getSubscriptionList(): Observable<any> {
    const endpoint = `api/agency/get-subscription-details`;
    return this.apiService.get(endpoint);
  }
}
