<div class="container-fluid main-container">
  <div class="row" style=" width: 100%; padding-left: 15px;">
    <div>
      <!-- <span class="">saint</span> -->
      <img src="assets/images/logo-saint-white.png" alt="" style="height: 44px;">
    </div>
  </div>
  <mat-card class="login-card mat-elevation-z8">
    <div class="header-text">Reset Password</div>
    <mat-card-content>
      <form class="login-form" [formGroup]="resetPasswordForm" (ngSubmit)="onPasswordResetClicked()">
        <mat-form-field class="example-full-width">
          <mat-icon matPrefix>email</mat-icon>
          <mat-label style="color: #d5d5d5;">Enter Your Email ID</mat-label>
          <input type="email" matInput formControlName="email">
          <mat-error
            *ngIf="resetPasswordForm.controls['email'].hasError('email') && !resetPasswordForm.controls['email'].hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="resetPasswordForm.controls['email'].hasError('required')">
            Email is required
          </mat-error>
        </mat-form-field>
        <input type="submit" style="visibility: hidden;" />
      </form>
      <!-- </div> -->
    </mat-card-content>
    <mat-card-actions style="display: flex;justify-content: space-between;margin-left: 0px;">
      <button mat-flat-button color="primary" (click)="back()">BACK</button>
      <button mat-flat-button color="primary" (click)="onPasswordResetClicked()"
        [disabled]="!resetPasswordForm.valid">RESET PASSWORD</button>
    </mat-card-actions>
  </mat-card>
  <div class="row">
  </div>
</div>