<div style="display: flex;flex-wrap: wrap;justify-content: space-between;width: 954px; padding-top: 1em;">
    <ng-container *ngFor="let stList of stagesList">
        <div *ngIf="stList">
            <div class="mat-elevation-z4 sl-card 1" (click)="onEventClick($event)" *ngIf="!stList?.date_time_stamp && !stList?.isEmpty" [ngClass]="{'systemDefined': isDisableSystemDefined(stList?.stage)}">
                <div class="btn-name"> {{stList?.stage}}</div>
            </div>

            <div class="mat-elevation-z4 sl-card 2" (click)="onEventClick($event)" *ngIf="stList?.date_time_stamp && !stList?.isEmpty" [ngClass]="{'systemDefined': isDisableSystemDefined(stList?.stage)}">
                <div class="btn-name1">{{stList?.stage}}</div>
            </div>

            <div class="sl-card2" *ngIf="stList?.isEmpty"></div>
        </div>
    </ng-container>
</div>

<div style="display: flex;flex-wrap: wrap;justify-content: space-between;width: 954px;">
    <div class="ac-field-container">
        <span>Current Stage</span>
        <span style="font-family: 'HELVETICA_NEUE_THIN';color: #959595;font-size: 18px;">
      {{animalCurrentStage}}
    </span>
    </div>
</div>
<!-- activites section -->
<div style="display: flex;flex-wrap: wrap;width: 954px;">
    <div class="ac-field-container">
        <span>Activites</span>
    </div>
    <div *ngFor="let stList of selectedList; last as isLast;">
        <div *ngIf="stList.date_time_stamp" style="display: flex;align-items: center;">
            <div *ngIf="stList.is_edited" style="margin-top: 6px;">
                <mat-icon class="mat-icon-sty" fontSet="material-icons-outlined" (click)="onDelete($event,stList.stage)"
                          style="cursor: pointer; color: red;">
                    close
                </mat-icon>
            </div>
            <div class="activitied-sl-card">
                <div class="activitied-btn-name">{{stList.stage}}</div>
                <div class="activitied-btn-date">{{stList.date_time_stamp | date:'MM/dd/yyyy'}}</div>
            </div>
            <mat-icon *ngIf="!isLast" class="mat-icon-sty" fontSet="material-icons-outlined" style="color: #d5d5d5;">
                east
            </mat-icon>
        </div>
    </div>
</div>
