import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Animal } from "../models/animal";

@Injectable({
    providedIn: 'root'
})

export class MedicalService {

    constructor(
        private apiService: ApiService
    ) {
    }

    getServicesMaster(): Observable<any> {
        const endpoint = `api/transact/services/get-services-for-tenant`;
        return this.apiService.get(endpoint);
    }

    getAnimalServiceById(animalId: any, mappingId: any): Observable<any> {
        const endpoint = `api/transact/services/get-animal-service-details-by-id?animalId=${animalId}&mappingId=${mappingId}`;
        return this.apiService.get(endpoint);
    }

    getAnimalDetailById(id: any, mappingId: any): Observable<Animal> {
        const endpoint = `api/animal/get-animal-details-by-id?animalId=${id}&mappingId=${mappingId}`;
        // return this.apiService.get(`api/transact/services/get-animal-service-details-by-id?animalId=${id}&mappingId=${mappingId}`)
        return this.apiService.get(endpoint);
    }

    /*lookupServiceAnimals(payload: any): Observable<any> {
        const endpoint = `api/transact/services/animal-lookup`;
        return this.apiService.post(endpoint, payload);
    }*/
    lookupInNetworkInAnimals(agencyId, payload: any): Observable<any> {
        return this.apiService.post(`api/transact/services/lookup-animal-for-in-agency?agencyId=${agencyId}`, payload);
    }

    getMedicalEventMaster(): Observable<any> {
        const endpoint = `api/input/MedicalServices`;
        return this.apiService.get(endpoint);
    }

    getSpeciesBreedMaster(): Observable<any> {
        const endpoint = `api/input/animal-service-species-breed`;
        return this.apiService.get(endpoint);
    }

    getAnimalMaster(): Observable<any> {
        const endpoint = `api/input/AnimalServices`;
        return this.apiService.get(endpoint);
    }

    createAnimals(payload: any): Observable<any> {
        // const endpoint = `api/transact/services/create-animals`;
        const endpoint = `api/animal/create-modify-animals`;
        return this.apiService.post(endpoint, payload);
    }

    createModifyServices(payload: any): Observable<any> {
        const endpoint = `api/transact/services/create-modify-services`;
        return this.apiService.post(endpoint, payload);
    }

    createModifyVitals(payload: any): Observable<any> {
        const endpoint = `api/transact/services/create-modify-vitals`;
        return this.apiService.post(endpoint, payload);
    }

    getVitalForAnimal(animalId: any): Observable<any> {
        const endpoint = `api/transact/services/get-vitals-for-animal?animalId=${animalId}`;
        return this.apiService.get(endpoint);
    }

    createModifyMedicalCondition(payload: any): Observable<any> {
        const endpoint = `api/transact/services/create-modify-medical-condition`;
        return this.apiService.post(endpoint, payload);
    }

    getMedicalConditionByService(serviceId: any): Observable<any> {
        const endpoint = `api/transact/services/get-medical-condition-by-service-id?serviceId=${serviceId}`;
        return this.apiService.get(endpoint);
    }

    createModifyClinicServices(payload: any): Observable<any> {
        const endpoint = `api/transact/services/create-modify-clinic-services`;
        return this.apiService.post(endpoint, payload);
    }

    updateServicePaymentDetails(payload: any): Observable<any> {
        const endpoint = `api/transact/services/update-service-payment-details`;
        return this.apiService.post(endpoint, payload);
    }

    getClinicServicesForTenant(animalId: any): Observable<any> {
        const endpoint = `api/transact/services/get-clinic-services-for-tenant?animalId=${animalId}`;
        return this.apiService.get(endpoint);
    }

    deleteServices(ids: any): Observable<any> {
        const endpoint = `api/transact/services/delete-services/${ids}`;
        return this.apiService.delete(endpoint, ids);
    }

    deleteClinicServices(ids: any): Observable<any> {
        const endpoint = `api/transact/services/delete-clinic-services`;
        return this.apiService.delete(endpoint, ids);
    }

    //medication
    getAllMedicationsForServices(): Observable<any> {
        const endpoint = `api/transact/services/get-all-medications-for-services`;
        return this.apiService.get(endpoint);
    }

    deleteMedication(medicationId: any): Observable<any> {
        const endpoint = `api/transact/services/delete-medications/${medicationId}`;
        return this.apiService.delete(endpoint, medicationId);
    }

    createModifyMedications(payload: any): Observable<any> {
        const endpoint = `api/transact/services/create-modify-medications`;
        return this.apiService.post(endpoint, payload);
    }

    getMedicationForAnimal(animalId: any): Observable<any> {
        const endpoint = `api/transact/services/get-medications-for-animal?animalId=${animalId}`;
        return this.apiService.get(endpoint);
    }


    //vaccination
    deleteVaccination(vaccinationId: any): Observable<any> {
        const endpoint = `api/transact/services/delete-vaccinations/${vaccinationId}`;
        return this.apiService.delete(endpoint, vaccinationId);
    }

    getAllVaccinationsForServices(): Observable<any> {
        const endpoint = `api/transact/services/get-all-vaccinations-for-services`;
        return this.apiService.get(endpoint);
    }

    createModifyVaccinations(payload: any): Observable<any> {
        const endpoint = `api/transact/services/create-modify-vaccination`;
        return this.apiService.post(endpoint, payload);
    }

    getVaccinationForAnimal(animalId: any): Observable<any> {
        const endpoint = `api/transact/services/get-vaccination-for-animal?animalId=${animalId}`;
        return this.apiService.get(endpoint);
    }

    //test and surgery

    getAllTestsForServices(): Observable<any> {
        const endpoint = `api/transact/services/get-all-tests-for-services`;
        return this.apiService.get(endpoint);
    }

    deleteTestsSurgery(ids): Observable<any> {
        return this.apiService.delete(`api/transact/services/delete-animal-service-test-surgery/${ids}`, null);
    }

    getAllSurgeriesForServices(): Observable<any> {
        const endpoint = `api/transact/services/get-all-surgery-for-services`;
        return this.apiService.get(endpoint);
    }

    //all Activity
    getAllServiceActivityDetails(): Observable<any> {
        return this.apiService.get(`api/transact/services/get-all-activities-for-services`);
    }
}
