import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/components/login/login.component';
import { LayoutNoPermissonComponent } from './layout/components/layout-no-permisson/layout-no-permisson.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'layout',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import("./auth/auth.module").then(m => m.AuthModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import("./layout/layout.module").then(m => m.LayoutModule)
  },
  {
    path: 'nopermisson',
    component: LayoutNoPermissonComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
