import { Injectable } from "@angular/core";
import {NavigationEnd, Router} from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';
import { stripeUrl } from "../constant/constant";

@Injectable()

export class ApiService {
  isStripeKey = false;
  baseUrl = environment.apiEndpoint;
  constructor(public http: HttpClient,private router: Router) {
    (StorageService.getItem('tenant_id')==null) ? (this.router.navigateByUrl('auth'),StorageService.clearAllOnLogout()) : '';

    if(window.location.hostname == 'dev-agency.getsaint.com' || window.location.hostname == 'localhost' || window.location.hostname == 'demo-agency.getsaint.com') {
      this.isStripeKey = false
    } else if (window.location.hostname == 'agency.getsaint.com') {
      this.isStripeKey = true
    }
   }
  // chatApi = 'http://192.168.182.128:3000/api/v1';
    chatApi = 'https://chat.getsaint.com/api/v1';

  get(url: string, param?: HttpParams): any {
    return this.http
      .get(this.baseUrl + url, {
        observe: 'response',
        params: param,
        headers: {
          Authorization: `${StorageService.getItem('token_type')} ${StorageService.getItem('token')}`,
          'X-Requested-With': 'XMLHttpRequest',
          'X-Tenant-ID': `${StorageService.getItem('tenant_id')}`,
          'Content-Type': 'application/json'
        },
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return data.body;
        })
      );
  }

  getBlob(url: string, param?: HttpParams): any {
    return this.http
      .get(this.baseUrl + url, {
        observe: 'response',
        params: param,
        headers: {
          Authorization: `${StorageService.getItem('token_type')} ${StorageService.getItem('token')}`,
          'X-Requested-With': 'XMLHttpRequest',
          'X-Tenant-ID': `${StorageService.getItem('tenant_id')}`,
          // 'Content-Type': 'application/pdf'
        },
        responseType: 'blob',
      })
      .pipe(
        map((data) => {
          return data.body;
        })
      );
  }

  getBlobUrl(url: string): any {
    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((data) => {
          return data.body;
        })
      );
  }

  put(url: string, body, param?: HttpParams) {
    return this.http
      .put(this.baseUrl + url, body, {
        observe: 'body',
        params: param,
        headers: {
          Authorization: `${StorageService.getItem('token_type')} ${StorageService.getItem('token')}`,
          'X-Requested-With': 'XMLHttpRequest',
          'X-Tenant-ID': `${StorageService.getItem('tenant_id')}`
        },
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  patch(url: string, body, param?: HttpParams) {
    return this.http
      .patch(this.baseUrl + url, body, {
        observe: 'body',
        params: param,
        headers: {
          Authorization: `${StorageService.getItem('token_type')} ${StorageService.getItem('token')}`,
          'X-Requested-With': 'XMLHttpRequest',
          'X-Tenant-ID': `${StorageService.getItem('tenant_id')}`
        },
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  post(endPointUrl: string, postData, param?: HttpParams) {
    return this.http
      .post(this.baseUrl + endPointUrl, postData, {
        params: param,
        headers: {
          Authorization: `${StorageService.getItem('token_type')} ${StorageService.getItem('token')}`,
          'X-Requested-With': 'XMLHttpRequest',
          'X-Tenant-ID': `${StorageService.getItem('tenant_id')}`
        },
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }


  postStripe(endPointUrl: string, postData, param?: HttpParams) {    
    return this.http
      .post(endPointUrl, param, {
        params: postData,
        headers: {
          Authorization: `Bearer ${this.isStripeKey ? stripeUrl.producationSecretKeyStripeToken : stripeUrl.testSecretKeyStripeToken}`,
          'Content-Type':`application/x-www-form-urlencoded`
        },
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  
  delete(endPointUrl: string, postData, param?: HttpParams) {
    return this.http
      .request('delete', this.baseUrl + endPointUrl, {
        params: param,
        body: postData,
        headers: {
          Authorization: `${StorageService.getItem('token_type')} ${StorageService.getItem('token')}`,
          'X-Requested-With': 'XMLHttpRequest',
          'X-Tenant-ID': `${StorageService.getItem('tenant_id')}`
        },
        responseType: 'json',
      })
      .pipe(
        map(
          (data) => {
            return data;
          },
          (error) => {
            return error;
          }
        )
      );
  }
}