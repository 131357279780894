import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';
import {Utils} from '../utils/util';
import {Animal} from '../models/animal';


@Injectable({
    providedIn: 'root'
})

export class AnimalService {
    response: any = [];

    constructor(
        private apiService: ApiService
    ) {
    }

    getAnimalMasterData(): Observable<any> {
        return this.apiService.get(`api/input/Animal`);
    }

    getAllAnimalList(): Observable<any> {
        return this.apiService.get(`api/animal/get-animals-for-tenant`).pipe(
            map((res: any[]) => {
                res.sort((a, b) => {
                    return b.animal_id - a.animal_id;
                });
                for (const ele of res) {
                    ele.medical_records = ele.medical_records ? 'Available' : 'Not Available';
                    ele.history_available = ele.history_available ? 'Available' : 'Not Available';
                    ele.weight = ele.weight + ' lbs';
                    const result = ele.birth_date ? Utils.ageUnitFromDOB(ele.birth_date) : null;
                    ele.birth_date = ele.birth_date ? result[0].birth_date : '';
                    ele.unit = ele.birth_date ? result[0].unit : '';
                    ele.age = ele.birth_date ? result[0].age : 0;
                    ele.animal_age = ele.birth_date ? result[0].animal_age : 0;
                    // ele.status = ele.existing_status[ele.existing_status.length - 1] ?.value;
                }
                return res;
            })
        );
    }

    getAnimalTransactInfo(): Observable<any> {
        return this.apiService.get(`api/transact/get-transactions-for-tenant`);
    }

    getAgencyAnimalInfo(tenantId: any): Observable<any> {
        const endpoint = `api/transact/services/get-transactions-for-agency?tenantId=${tenantId}`;
        return this.apiService.get(endpoint);
    }

    getAnimalTransactForTenant(is_medical_event_required?): Observable<any> {
        let apUrl= `api/animal/get-animal-transactions-for-tenant`;
        (is_medical_event_required) 
        apUrl +=`?is_medical_event_required=true`
        return this.apiService.get(apUrl);
    }

    getAnimalParentInfo(): Observable<any> {
        return this.apiService.get(`api/animal/get-parent-animal-details`);
    }

    getAnimalMastertForTenant(filter?: string): Observable<any> {
        const endpoint =  `api/animal/get-animals-for-tenant?status=${filter}`;
        return this.apiService.get(endpoint);
    }

    getAllAnimalSpeciesBreedList(): Observable<any> {
        return this.apiService.get(`api/input/animal-species-breed`)
    }

    getRowHistoryData(id: any): Observable<any> {
        return this.apiService.get(`api/animal/get-animal-history?animalId=${id}`);
    }

    getAnimalById(id): Observable<any> {
        return this.apiService.get(`api/animal/get-animal-by-id/${id}`);
    }

    getAnimalIntakeEligibility(id: any): Observable<any> {
        return this.apiService.get(`api/transact/get-animal-intake-eligibity?animalId=${id}`);
    }

    createAnimal(payload: any): Observable<any> {
        return this.apiService.post(`api/animal/create-modify-animal`, payload);
    }

    animalImgUpload(animalId,payload: any): Observable<any> {
        return this.apiService.post(`api/animal/${animalId}/upload-image`, payload);
       // return this.apiService.post(`api/animal/images-upload`, payload);
    }

    lookupAnimal(payload): Observable<any> {
        return this.apiService.post(`api/animal/animal-lookup`, payload);
    }

    // New flow implementation

    createAnimals(payload: any): Observable<any> {
        return this.apiService.post(`api/animal/create-modify-animals`, payload);
    }

    getAnimalDetailById(id: any, mapping_id: any): Observable<Animal> {
        return this.apiService.get(`api/animal/get-animal-details-by-id?animalId=${id}&mappingId=${mapping_id}`)
    }

    getAnimalHistoryById(id: any): Observable<any> {
        return this.apiService.get(`api/animal/get-animal-history?animalId=${id}`)
    }

    createAnimalVital(payload: any): Observable<any> {
        return this.apiService.post(`api/animal/medical/create-modify-vitals`, payload);
    }

    getAnimalVitalById(id: number): Observable<any> {
        return this.apiService.get(`api/animal/medical/get-vitals-for-animal?animalId=${id}`);
    }

    // Medical event services

    createModifyMedicalEvent(payload: any): Observable<any> {
        return this.apiService.post(`api/animal/medical/create-modify-medical-event`, payload);
    }

    getMedicalEventsForTenent(): Observable<any> {
        return this.apiService.get(`api/animal/medical/get-medical-events-for-tenant`);
    }

    getMedicalEventHistoryByAnimal(id): Observable<any> {
        return this.apiService.get(`api/animal/medical/get-medical-event-history-animal?animalId=${id}`);
    }

    createModifyMedicalCondtion(payload: any): Observable<any> {
        return this.apiService.post(`api/animal/medical/create-modify-medical-condition`, payload);
    }

    getMedicalConditoinByEventId(id: number): Observable<any> {
        return this.apiService.get(`api/animal/medical/get-medical-condition-by-event-id/?eventId=${id}`);
    }

    deleteMedicalEvent(id: any): Observable<any> {
        return this.apiService.delete(`api/animal/medical/delete-medical-event/${id}`, null);
    }

    medicalEventForAnimal(id: any,is_pdf_value?:any): Observable<any> {
        if(is_pdf_value)
        return this.apiService.get(`api/animal/get-medical-events-for-animal/${id}?is_pdf=${is_pdf_value}`);
        else
        return this.apiService.get(`api/animal/get-medical-events-for-animal/${id}`);
    }

    //Animal Resource Planning
    createModifyResourcePlanning(payload: any): Observable<any> {
        return this.apiService.post(`api/animal/resource/create-modify-resource`, payload);
    }

    getAnimalResources(payload: any): Observable<any> {
        return this.apiService.post(`api/animal/resource/get-resources`, payload);
    }

    getAnimalListResourceAssignment(payload: any): Observable<any> {
        return this.apiService.post(`api/animal/resource/get-animal-details`, payload);
    }

    deleteResources(ids): Observable<any> {
        return this.apiService.delete(`api/animal/resource/delete-resorces`, ids);
    }

    // Animal Routine
    getAnimalRoutineForTeanant(): Observable<any> {
        return this.apiService.get(`api/animal/routine/get-animal-routines-for-tenant`);
    }

    createModifyAnimalRoutine(payload: any): Observable<any> {
        return this.apiService.post(`api/animal/routine/create-modify-animal-routine`, payload);
    }

    createModifyAnimalRoutineFood(payload: any): Observable<any> {
        return this.apiService.post(`api/animal/routine/create-modify-animal-food`, payload);
    }

    deleteRoutineFood(ids): Observable<any> {
        return this.apiService.delete(`api/animal/routine/delete-animal-food`, ids);
    }

    createModifyAnimalRoutineTraining(payload: any): Observable<any> {
        return this.apiService.post(`api/animal/routine/create-modify-animal-training`, payload);
    }

    deleteRoutineTraining(ids): Observable<any> {
        return this.apiService.delete(`api/animal/routine/delete-animal-training`, ids);
    }

    createModifyAnimalRoutineActivity(payload: any): Observable<any> {
        return this.apiService.post(`api/animal/routine/create-modify-animal-activity`, payload);
    }

    deleteRoutineActivity(ids): Observable<any> {
        return this.apiService.delete(`api/animal/routine/delete-animal-activity`, ids);
    }

    // Care Schedule
    getAnimalCareScheduleList(): Observable<any> {
        return this.apiService.get(`api/animal/routine/get-schedule-details`);
    }

    getAnimalDetailsUsingAnimalIDs(payload): Observable<any> {
        return this.apiService.post(`api/animal/routine/get-schedule-details-for-animal`, payload);
    }

    createModifyAnimalCareSchedule(payload: any): Observable<any> {
        return this.apiService.post(`api/animal/routine/create-modify-careschedule`, payload);
    }

    //medication
    createModifyMedication(payload: any, inNetwork?: boolean): Observable<any> {
        const isInNetWork = inNetwork ? inNetwork : false;
        return this.apiService.post(`api/animal/medical/create-modify-medications?isInNetworkVet=${isInNetWork}`, payload);
    }

    getMedicationsForAnimal(id: any): Observable<any> {
        return this.apiService.get(`api/animal/medical/get-medications-for-animal?animalId=${id}`);
    }

    getAllMedicationDetails(): Observable<any> {
        return this.apiService.get(`api/animal/medical/get-all-medications`);
    }

    deleteMedication(ids): Observable<any> {
        return this.apiService.delete(`api/animal/medical/delete-medications/${ids}`, null);
    }
    //vaccination

    getAllVaccinationDetails(): Observable<any> {
        return this.apiService.get(`api/animal/medical/get-all-vaccinations`);
    }

    deleteVaccination(ids): Observable<any> {
        return this.apiService.delete(`api/animal/medical/delete-vaccinations/${ids}`, null);
    }

    createModifyVaccination(payload: any, inNetwork?: boolean): Observable<any> {
        const isInNetWork = inNetwork ? inNetwork : false;
        return this.apiService.post(`api/animal/medical/create-modify-vaccination?isInNetworkVet=${isInNetWork}`, payload);
    }

    getVaccinationForAnimal(id: any): Observable<any> {
       // const entryPoint = `api/transact/services/get-vaccination-for-animal?animalId=${id}`;
        return this.apiService.get(`api/animal/medical/get-vaccination-for-animal?animalId=${id}`);
    }

    //test and surgery

    deleteTestsSurgery(ids): Observable<any> {
        return this.apiService.delete(`api/animal/medical/delete-animal-medical-services/${ids}`, null);
    }

    getAllSurgeryDetails(): Observable<any> {
        return this.apiService.get(`api/animal/medical/get-all-surgery`);
    }

    getAllTestDetails(): Observable<any> {
        return this.apiService.get(`api/animal/medical/get-all-tests`);
    }

    getAllActivityDetails(): Observable<any> {
        return this.apiService.get(`api/animal/medical/get-all-activities-for-medical-event`);
    }

    //microchip
    modifyMicrochip(id,payload): Observable<any> {
        return this.apiService.put(`api/procure/update-microchip/${id}`,payload);
    }

    getAllMicrochipDetails(): Observable<any> {
        return this.apiService.get(`api/animal/medical/get-all-microchip-injections`);
    }
    
    validateMicrochip(microchip:any, animalId?:any): Observable<any> {
        return this.apiService.get(`api/procure/validate-microchip?microchip=${microchip}&animal_id=${animalId}`);
    }

    //Upload Animal Attachments
    animalDocument(payload: any, id: any): Observable<any> {
        return this.apiService.post(`api/animal/${id}/upload-attachment`,payload);
    }

    medicalDocument(payload: any, id: any): Observable<any> {
        return this.apiService.post(`api/animal/medical/${id}/upload-medical-event-attachment`,payload);
    }

    getRoutineByAnimalId(id:any): Observable<any> {
        return this.apiService.get(`api/animal/get-animal-special-care-details?animalId=${id}`);
    }

    getBlobUrl(url): Observable<any> {
        return this.apiService.getBlobUrl(url);
    }
}
