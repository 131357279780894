import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})

export class UtilService {

  constructor(
    private apiService: ApiService
  ) { }
  
  getSmartyZipCode(payload: any): Observable<any> {
    return this.apiService.post(`api/public/smarty-zip-code`, payload);
  }

  getAddressInfo(payload: any): Observable<any> {
    const zipcode = payload.zipcode;
    const endpoint = `api/agency/get-zipcode-address?zipcode=${zipcode}`;
    return this.apiService.get(endpoint);
  }

  getAllNotes(payload: any): Observable<any> {
    return this.apiService.post(`api/input/get-notes`, payload).pipe(
      map((res: any[]) => {
        res.forEach(ele => {
          ele['user'] = ele.modified_by?.split('@')[0];
        })

        return res;
      })
    );
  }

  createNote(payload: any): Observable<any> {
    return this.apiService.post(`api/input/create-modify-notes`, payload);
  }

  getMasterInputs(entity: string): Observable<any> {
    return this.apiService.get(`api/input/${entity}`);
  }

  lookupAnimal(payload: any): Observable<any> {
    return this.apiService.post(`api/cases/lookup-animal`, payload);
  }

  getMasterInputsForJurisdiction(): Observable<any> {
    return this.apiService.get(`api/agency/get-jurisdictions-for-tenant`);
  }

}
