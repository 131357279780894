<div mat-dialog-title class=m-0>
  <div>
    <span>Score: {{data && data.score}} - {{data && data.title}}</span>
  </div>
  <button mat-icon-button>
    <mat-icon (click)="onCloseDialog()">close</mat-icon>
  </button>
</div>
<div mat-dialog-content class="contents mt-3">
  <div class="image-container">
    <img [src]="data && data.path" [alt]="data && data.title">
  </div>
</div>