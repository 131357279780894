import { AuthModule } from './auth/auth.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NewMessageDialogComponent } from './home/components/new-message/new-message-dialog.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { DeviceDetectorModule } from 'ngx-device-detector';

export function momentAdapterFactory() {
  return adapterFactory(moment);
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule,
        AuthModule,
        NgbModule,
        DeviceDetectorModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory })
    ],
    providers: [AppComponent],
    entryComponents: [
        NewMessageDialogComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
