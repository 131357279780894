import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';


@Injectable({
    providedIn: "root"
})

export class AccountsService {

    constructor(
        private apiService: ApiService
    ) {
    }

    getContactInputs(): Observable<any> {
        return this.apiService.get(`api/input/Contact`);
    }

    createContact(payload: any): Observable<any> {
        return this.apiService.post(`api/contact/create-new`, payload);
    }

    deleteContact(payload: any): Observable<any> {
        return this.apiService.delete(`api/contact/delete-contact`, payload);
    }

    getAllOrgContacts(): Observable<any> {
        return this.apiService.get(`api/contact/get-all-org`).pipe(
            map((res: any[]) => {
                res.forEach(element => {
                    element['full_name'] = `${element.first_name} ${element.last_name}`;
                    element['check'] = '';
                });
                return res;
            })
        );
    }

    getAllContact(): Observable<any> {
        return this.apiService.get(`api/contact/get-all-list`).pipe(
            map((res: any[]) => {
                res.forEach(element => {
                    element['full_name'] = `${element.first_name} ${element.last_name}`;
                    element['address'] = `${this.concat(element.street1, '|')} ${this.concat(element.street2, '|')} ${this.concat(element.city, '|')} ${this.concat(element.county, '|')} ${this.concat(element.state, '|')} ${this.concat(element.zip_code, '')}`;
                    element['donated_amount'] = `$ 0`;
                    element['account_name'] = element.account_info?.account_name;
                    element['adopted'] = 0;
                    element['owner'] = `${element.created_by?.split('@')[0]}`;
                    element['origination'] = element.existing ? 'Existing' : 'In-Network';
                    element['for'] = 'main_contact_grid';
                });
                return res;
            })
        );
    }

    lookupContact(payload): Observable<any> {
        return this.apiService.post(`api/contact/lookup`, payload).pipe(
            map((res: any[]) => {
                res.forEach(element => {
                    element['full_name'] = `${element.first_name} ${element.last_name}`;
                    element['origination'] = element.existing ? 'Existing' : 'In-Network';
                    element['address'] = `${this.concat(element.street1, ',')} ${this.concat(element.street2, ',')} ${this.concat(element.city, ',')} ${this.concat(element.county, ',')} ${this.concat(element.state, ',')} ${this.concat(element.zip_code, '')}`;
                    // element['check'] = '';
                });
                return res;
            })
        );
    }


    getAgencySubTypes(): Observable<any> {
        const endpoint = 'api/input/veterinarian';
        return this.apiService.get(endpoint);
    }

    getContactById(id): Observable<any> {
        return this.apiService.get(`api/contact/get-contact-by-id/${id}`);
    }

    createAccount(payload): Observable<any> {
        return this.apiService.post(`api/account/create-modify-account`, payload);
    }

    getAllAccounts(): Observable<any> {
        return this.apiService.get(`api/account/get-all-accounts`).pipe(
            map((res: any[]) => {
                res.forEach(element => {
                    // let contacts: string[] = [];
                    let concatedNameString: string = '';
                    if (element.contact_list?.length == 1)
                        concatedNameString = `${element.contact_list[0].first_name} ${element.contact_list[0].last_name}`;
                    else {
                        let nameArray = [];
                        element.contact_list?.forEach((element) => {
                            nameArray.push(`${element.first_name} ${element.last_name}`);
                        });
                        concatedNameString = nameArray.join(' | ');
                    }
                    element['contactNames'] = concatedNameString;
                    element['address'] = `${this.concat(element.street1, '|')} ${this.concat(element.street2, '|')} ${this.concat(element.city, '|')} ${this.concat(element.county, '|')} ${this.concat(element.state, '|')} ${this.concat(element.zip_code, '')}`;
                    // element['donated_amount'] = `$ 0`;
                    element['status'] = element.is_active ? 'Active' : 'Inactive';
                    element['adopted'] = 0;
                    element['for'] = 'main_account_grid';
                    // element.contact_list.forEach(contact => {
                    //   contacts.push(`${contact.first_name} ${contact.last_name}`)
                    // });
                    // element['contacts'] = contacts.join(', ');
                    element['contacts'] = element.contact_list;
                });
                return res;
            })
        );
    }

    deleteAccount(payload): Observable<any> {
        return this.apiService.delete(`api/account/delete-accounts`, payload);
    }

    getAccountById(id): Observable<any> {
        return this.apiService.get(`api/account/get-account-by-id/${id}`);
    }

    createOutNetworkAgency(payload): Observable<any> {
        return this.apiService.post(`api/agency/create-new-out-network-agency`, payload);
    }

    createModifyNetworkAgency(payload): Observable<any> {
        return this.apiService.post(`api/agency/create-modify-out-network-agency`, payload);
    }

    getAllAgencies(): Observable<any> {
        return this.apiService.get(`api/agency/get-agency-all`).pipe(
            map((res: any[]) => {
                res.forEach(element => {
                    let pAddress = element.addresses?.find(ele => ele.location_name == 'HQ');
                    element['address'] = `${this.concat(pAddress?.street1, ',')} ${this.concat(pAddress?.street2, ',')} ${this.concat(pAddress?.city, ',')} ${this.concat(pAddress?.county, ',')} ${this.concat(pAddress?.state, ',')} ${this.concat(pAddress?.zip_code, '')}`;
                    element['phone'] = pAddress?.phone;
                    element['category'] = element.in_network ? 'In-Network' : 'Out of Network';
                    element['animals_available'] = 0;
                });
                return res;
            })
        );
    }

    lookupAgencies(payload): Observable<any> {
        return this.apiService.post(`api/agency/agency-lookup`, payload);
    }

    createModifyContactWarnig(payload): Observable<any> {
        return this.apiService.post(`api/contact/create-modify-contact-warning`, payload);
    }

    getPublicAgencyMasterData(): Observable<any> {
        return this.apiService.get(`api/public/input-for-agency`);
    }

    getAgencyById(id: any): Observable<any> {
        return this.apiService.get(`api/agency/get-agency-by-id/${id}`);
    }

    getContactWarningHistory(id: any): Observable<any> {
        return this.apiService.get(`api/contact/get-contact-warning-history?contactId=${id}`);
    }

    createModifyVet(paylod): Observable<any> {
        return this.apiService.post(`api/animal/medical/create-modify-vet`, paylod);
    }

    createModifyOutAgency(payload: any): Observable<any> {
        const endpoint = `api/agency/create-modify-out-network-agency`;
        return this.apiService.post(endpoint, payload);
    }

    lookupVets(payload): Observable<any> {
        return this.apiService.post(`api/animal/medical/vet-lookup`, payload);
    }

    concat(data, append) {
        return data ? `${data}${append}` : '';
    }

    //vets
    createModifyVets(payload): Observable<any> {
        return this.apiService.post(`api/animal/medical/create-modify-vet`, payload);
    }

    getVetsInputData(): Observable<any> {
        return this.apiService.get(`api/input/Vet`);
    }

    getAllVetsList(): Observable<any> {
        return this.apiService.get(`api/animal/medical/get-all-vets`);
    }

    getTransactionByContactId(id: any): Observable<any> {
        return this.apiService.get(`api/contact/get-animal-by-contactId/${id}`);
    }

    getTransactionByContactIds(ids: any): Observable<any> {
        return this.apiService.post(`api/account/get-transaction-for-contact`, ids);
    }
}
