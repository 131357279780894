import { Component, OnInit,Inject } from '@angular/core';
import { GridApi, GridOptions } from '@ag-grid-community/all-modules';
import { Utils } from 'src/app/core/utils/util';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnimalService } from 'src/app/core/services/animal.service';

export interface DialogData {
  care_taker_info:any;
}

@Component({
  selector: 'app-resource-animal-dialogs',
  templateUrl: './resource-animal-dialogs.component.html',
  styleUrls: ['./resource-animal-dialogs.component.scss']
})
export class ResourceAnimalDialogsComponent implements OnInit {
  public columnDefs: any[] = [];
  public gridOptions = {
    context: {
        componentParent: this,
    },
    suppressHorizontalScroll: false,
    rowStyle: { background: 'white' }
  } as GridOptions;

  gridApi: GridApi;
  gridColumnApi: any;

  defaultColDef: any;
  rowSelection: any;
  frameworkComponents;

  getRowHeight;
  headerHeight;
  rowData: any[]=[];                   

  constructor(public dialogRef: MatDialogRef<ResourceAnimalDialogsComponent>,private animalService:AnimalService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { 
    this.getRowHeight = (params) => {
      return 34;
    }
    this.headerHeight = 34;
  }

  ngOnInit(): void {
    this.setupGridColumn();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setupGridColumn() {
    this.defaultColDef = Utils.getBaseThemeAgGridCol();
    this.columnDefs = [
                        {
                          headerName: 'Animal ID',
                          field: 'animal_id',
                          cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'center' },
                          suppressSizeToFit: true,
                          width: 100,
                          filter:'agNumberColumnFilter',
                          filterParams: Utils.filterParams()
                      },
                      {
                        headerName: 'Animal Name',
                        field: 'animal_name',
                        cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
                        suppressSizeToFit: true,
                        width: 200,
                        filterParams: Utils.filterParams()
                      },
                      {
                        headerName: 'Species',
                        field: 'species',
                        cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
                        suppressSizeToFit: true,
                        width: 200,
                        filterParams: Utils.filterParams()
                      },
                      {
                        headerName: 'Room',
                        field: 'room_name',
                        cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
                        suppressSizeToFit: true,
                        width: 200,
                        filterParams: Utils.filterParams()
                      },
                      {
                        headerName: 'Kennel',
                        field: 'cage_name',
                        cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' },
                        suppressSizeToFit: true,
                        width: 200,
                        filterParams: Utils.filterParams()
                      },
                      
                      ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.refreshMainGrid();
  }

  refreshMainGrid(){
        let rowObj=this.data.care_taker_info;
        if(rowObj && rowObj.mapping_id && (rowObj.animal_id.split("/").length==rowObj.mapping_id.split("/").length)){
          let mappingIds=rowObj.mapping_id.split("/");
          let apiReqestParams:any[]=[];
          rowObj.animal_id.split("/").forEach((animalId,index) => {
            apiReqestParams.push({"animal_id":parseInt(animalId),"mapping_id":parseInt(mappingIds[index]),'schedule_date':this.data.care_taker_info.schedule_date});
          });     
        
          this.animalService.getAnimalDetailsUsingAnimalIDs(apiReqestParams).toPromise().then((res: any[]) => {  
            this.rowData=res;
          })
        }
  }

}