<div mat-dialog-title>
  <div></div>
  <button mat-icon-button>
    <mat-icon (click)="onNoClick()">close</mat-icon>
  </button>

</div>
<div mat-dialog-content>
  <div class="dialog-content">Intake cannot be initiated. The selected animal is currently associated with {{this.data.rowdata.agency_name}} .
    Please contact the concerned agency to update the animal status and initiate an outcome.</div>
    <div class="dialog-qu">Do you want to send a message?</div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="onNoClick()">NO</button>
  <button mat-raised-button color="accent" (click)="onYesClick()" cdkFocusInitial>Yes</button>
</div>