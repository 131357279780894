import { filter } from 'rxjs/operators';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { UtilService } from 'src/app/core/services/util.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

export interface DialogData {
  rowdata: any,
  callFrom: string,
  otherRowData: any
}

@Component({
  selector: 'app-delete-accordian-dialog',
  templateUrl: './delete-accordian-dialog.component.html',
  styleUrls: ['./delete-accordian-dialog.component.scss']
})
export class DeleteAccordianDialogComponent implements OnInit {
  subs: Subscription[] = [];
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  checkLabel: string
  constructor(public dialogRef: MatDialogRef<DeleteAccordianDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private utilService: UtilService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    if (this.data.callFrom === 'victim') {
      this.checkLabel = 'Victim'
    } else if (this.data.callFrom === 'animal') {
      this.checkLabel = 'Animal'
    } else if (this.data.callFrom === 'witness') {
      this.checkLabel = 'Witness'
    }
  }

  onCancelClick(): void {
    this.dialogRef.close([this.data.rowdata, []]);
  }

  toggleCheckbox(event: MatCheckboxChange, fg?: FormGroup, index?: number,) {
    if (event.checked) {
      fg.patchValue({ is_deleted: true })
    } else {
      fg.patchValue({ is_deleted: false })
    }
  }

  onRemoveClick() {
    const indArr = [];
    // let deletedAminal = []
    const formArray = this.data.rowdata as FormArray;
    for (let i = formArray.controls.length - 1; i >= 0; i--) {
      if (formArray.controls[i].get('is_deleted').value === true) {
        formArray.removeAt(i);
        indArr.push(i);
      }
    }
    // }
    // this.data.rowdata.controls = this.data.rowdata.controls.filter((e, ind) => {
    //   if (e.get('is_deleted').value === true) {
    //     indArr.push(ind)
    //     // if (this.data.callFrom === 'animal' && e.get('transaction_id').value) {
    //     //   deletedAminal.push(e.get('transaction_id').value)
    //     // }
    //   }
    //   return e.get('is_deleted').value === false
    // })

    if (this.data.callFrom === 'animal') {
      // this.callDeleteAnimal(deletedAminal)
      const depddFormArray = this.data.otherRowData as FormArray;
      for (const dIdx of indArr) {
        depddFormArray.removeAt(dIdx);
      }
      // this.data.otherRowData.controls = this.data.otherRowData.controls.filter((e, ind) => {
      //   return !indArr.includes(ind)
      // })
      this.dialogRef.close([this.data.rowdata, this.data.otherRowData]);
    } else {
      this.dialogRef.close([this.data.rowdata, []]);
    }
  }

  // callDeleteAnimal(animalArr) {
  //   this.caseService.deleteAnimal(animalArr).subscribe(res => {
  //   })
  // }

  ngOnDestroy() {
    this.subs.forEach(ele => ele.unsubscribe());
  }
}
