import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import * as _moment from 'moment';

const moment = _moment;

@Directive({
  selector: '[appDateValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DateValidatorDirective, multi: true }]
})
export class DateValidatorDirective implements Validator {

  constructor() { }

   validate(control: AbstractControl): { [key: string]: any } | null {
    const validated = {};

    if (control.value && !moment(control.value, 'MM/DD/YYYY').isValid()) {
      validated['invalid'] = true;
    } 

    return validated;
  }
}
