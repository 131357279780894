<div mat-dialog-title>
  <div>
    <span>Notes: {{data.isEdit ? 'Edit' : 'New'}}</span>
  </div>
  <button mat-icon-button>
    <mat-icon (click)="onNoClick()">close</mat-icon>
  </button>

</div>
<div mat-dialog-content>
  <form class="main-form" [formGroup]="noteForm">

    <mat-form-field class="basic-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>Topic</mat-label>
      <input type="text" matInput formControlName="noteName" autocomplete="off">
      <mat-error *ngIf="noteForm.controls['noteName'].hasError('required')">
        Topic is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="basic-form-field" [floatLabel]="'always'" color="accent">
      <mat-label>Notes</mat-label>
      <textarea matInput formControlName="notes" autocomplete="off"></textarea>
      <mat-error *ngIf="noteForm.controls['notes'].hasError('required')">
        Notes is required
      </mat-error>
    </mat-form-field>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="onNoClick()">CANCEL</button>
  <button mat-raised-button color="accent" (click)="onSaveClick()" cdkFocusInitial>SAVE</button>
</div>