<mat-form-field class="serch-field" floatLabel="never" appearance="outline">
  <mat-label>Search</mat-label>
  <input matInput [matAutocomplete]="auto" [formControl]="animalCtrl">
  <mat-icon matSuffix>search</mat-icon>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let animal of filteredAnimales | async" [value]="animal.name">
      <img class="flag-img" aria-hidden [src]="animal.img" height="25">
      <span>{{animal.name}}</span> |
      <small>Species: {{animal.species}}</small>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>