import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GridApi, GridOptions} from 'ag-grid-community';
import {of} from 'rxjs';
import {Utils} from 'src/app/core/utils/util';
import {HomeService} from 'src/app/core/services/home.service';
import {TransactService} from 'src/app/core/services/transact.service';
import {SettingsService} from 'src/app/core/services/settings.service';
import * as moment from 'moment';
import {map} from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export interface DialogData {
    type: string;
    result_mode: string;
    from_date: string;
    to_date: string;
    report_period: string;
    summary_frequency: string;
    intake_summary_options: string;
    outcome_summary_options: string;
    contact_summary_options: string;
    contact_animal_summary_options: string;
    medical_event_summary_options: string,
    campus_summary_options: string,
    lengthofstay_summary_options: string,
    jurisdiction_summary_options: string,
    revenue_summary_options: string,
    filter_payload: any;
    filter_option: any;
    //summary_options_details:string;
    //summary_details:any
}

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit {
    //dateHeaders:any[]=[""];
    tableRowData: any[] = [];
    //totalRow:any[]=[];
    //filterString:string='';
    gridApi: GridApi;
    eventHistoryGridApi: GridApi;
    gridColumnApi: any;

    rowSelection: any;
    public gridOptions = {
        context: {
            componentParent: this,
        },
        suppressHorizontalScroll: false,
        rowStyle: {background: 'white'}
    } as GridOptions;

    defaultColDef: any;
    frameworkComponents;
    getRowHeight: any;
    headerHeight: any;
    columnDefs: any[] = [];
    rowData: any;
    resultCount: number = 0
    summaryOptionVal: string;
    csvSummaryOutput: any = [];
    tableTitleName: string = '';

    constructor(public dialogRef: MatDialogRef<ReportsComponent>, private homeService: HomeService, private transactService: TransactService, private settingsService: SettingsService,
                @Inject(MAT_DIALOG_DATA) public data: DialogData) {

        this.getRowHeight = (params) => {
            return 34;
        }
        this.headerHeight = 34;
    }

    ngOnInit(): void {
        this.titleName();
        this.setSummaryOption();
        this.setupGridColumn();
        if (this.data.type == "summary")
            this.reportsData();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    titleName() {
        if (this.data.result_mode == "Intake")
            this.tableTitleName = "Animal Intake";
        else if (this.data.result_mode == "Outcome")
            this.tableTitleName = "Animal Outcome";
        else if (this.data.result_mode == "Contact List")
            this.tableTitleName = "Contact List";
        else if (this.data.result_mode == "Contact Animal")
            this.tableTitleName = "Contact Animal Association";
        else if (this.data.result_mode == "Medical Event")
            this.tableTitleName = "Medical Events";
        else if (this.data.result_mode == "Campus Utilization")
            this.tableTitleName = "Campus Utilization";
        else if (this.data.result_mode == "Length of Stay")
            this.tableTitleName = "Length of Stay";
        else if (this.data.result_mode == "By Jurisdiction")
            this.tableTitleName = "By Jurisdiction";
        else if (this.data.result_mode == "Revenue")
            this.tableTitleName = "Revenue";
    }

    setSummaryOption() {
        if (this.data.result_mode == "Intake")
            this.summaryOptionVal = this.data.intake_summary_options;
        else if (this.data.result_mode == "Outcome")
            this.summaryOptionVal = this.data.outcome_summary_options;
        else if (this.data.result_mode == "Contact List")
            this.summaryOptionVal = this.data.contact_summary_options;
        else if (this.data.result_mode == "Contact Animal")
            this.summaryOptionVal = this.data.contact_animal_summary_options;
        else if (this.data.result_mode == "Medical Event")
            this.summaryOptionVal = this.data.medical_event_summary_options;
        else if (this.data.result_mode == "Campus Utilization")
            this.summaryOptionVal = this.data.campus_summary_options;
        else if (this.data.result_mode == "Length of Stay")
            this.summaryOptionVal = this.data.lengthofstay_summary_options;
        else if (this.data.result_mode == "By Jurisdiction")
            this.summaryOptionVal = this.data.jurisdiction_summary_options;
        else if (this.data.result_mode == "Revenue")
            this.summaryOptionVal = this.data.revenue_summary_options;
    }

    setupGridColumn() {
        this.defaultColDef = Utils.getBaseThemeAgGridCol();

        if (this.data.result_mode == "Revenue") {
            this.columnDefs = [
                {
                    headerName: 'Transaction Date',
                    field: 'transaction_date',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 120,
                    filter: 'agDateColumnFilter',
                    filterParams: Utils.dateFilterParams(),
                },
                {
                    headerName: 'Type',
                    field: 'revenue_type',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 120,
                    filterParams: Utils.filterParams(),
                },
                {
                    headerName: 'Source',
                    field: 'contact_name',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 190,
                    filterParams: Utils.filterParams(),
                },
                {
                    headerName: 'Mode',
                    field: 'donation_mode',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 150,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Reason',
                    field: 'donation_reason',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 150,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Payment Method',
                    field: 'payment_method',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 190,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Amount',
                    field: 'amount',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-end'
                    },
                    suppressSizeToFit: true,
                    width: 120,
                    filterParams: Utils.filterParams()
                },
            ]
        } else if (this.data.result_mode == "Intake") {
            this.columnDefs = [
                {
                    headerName: 'Species',
                    field: 'species',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 90,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Animal ID',
                    field: 'animal_id',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 75,
                    filter: 'agNumberColumnFilter',
                    filterParams: Utils.filterParams(),
                },
                {
                    headerName: 'Animal Name',
                    field: 'animal_name',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Primary Breed',
                    field: 'primary_breed',
                    enableCellTextSelection: true,
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 150,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Gender',
                    field: 'gender',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 90,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Age Range',
                    field: 'age_range',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 80,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Intake Date',
                    field: 'intake_date',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filter: 'agDateColumnFilter',
                    filterParams: Utils.dateFilterParams(),
                },
                {
                    headerName: 'Intake Type',
                    field: 'intake_type',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Intake Reason',
                    field: 'intake_reason',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 108,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Intake Detail',
                    field: 'intake_reason_details',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 150,
                    filterParams: Utils.filterParams()
                }
            ]
        } else if (this.data.result_mode == "Outcome") {
            this.columnDefs = [
                {
                    headerName: 'Species',
                    field: 'species',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 90,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Animal ID',
                    field: 'animal_id',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 75,
                    filter: 'agNumberColumnFilter',
                    filterParams: Utils.filterParams(),
                },
                {
                    headerName: 'Animal Name',
                    field: 'animal_name',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 110,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Primary Breed',
                    field: 'primary_breed',
                    enableCellTextSelection: true,
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 150,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Gender',
                    field: 'gender',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 80,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Age Range',
                    field: 'age_range',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 75,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Outcome Date',
                    field: 'outcome_date',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filter: 'agDateColumnFilter',
                    filterParams: Utils.dateFilterParams(),
                },
                {
                    headerName: 'Outcome Type',
                    field: 'outcome_type',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Outcome Reason',
                    field: 'outcome_reason',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 112,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Outcome Detail',
                    field: 'outcome_reason_details',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 150,
                    filterParams: Utils.filterParams()
                }
            ]
        } else if (this.data.result_mode == "Contact List") {
            this.columnDefs = [
                {
                    headerName: 'Contact ID',
                    field: 'contact_id',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 80,
                    filter: 'agNumberColumnFilter',
                    filterParams: Utils.filterParams(),
                },
                {
                    headerName: 'First Name',
                    field: 'first_name',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 86,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Last Name',
                    field: 'last_name',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 86,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Account Name',
                    field: 'account_name',
                    enableCellTextSelection: true,
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Is Primary',
                    field: 'is_primary',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 70,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Address 1',
                    field: 'street1',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'City',
                    field: 'city',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 90,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'County',
                    field: 'county',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 90,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'State',
                    field: 'state',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 90,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Zip',
                    field: 'zip_code',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 80,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Do Not Adopt',
                    field: 'do_not_adopt',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 80,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Phone',
                    field: 'phone',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Email',
                    field: 'email',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 150,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Create Date',
                    field: 'create_date',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Creator',
                    field: 'owner',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Origination',
                    field: 'origination',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                }
            ]
        } else if (this.data.result_mode == "Contact Animal") {
            this.columnDefs = [
                {
                    headerName: 'Contact ID',
                    field: 'contact_id',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 80,
                    filter: 'agNumberColumnFilter',
                    filterParams: Utils.filterParams(),
                },
                {
                    headerName: 'First Name',
                    field: 'first_name',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 86,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Last Name',
                    field: 'last_name',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 86,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Account Name',
                    field: 'account_name',
                    enableCellTextSelection: true,
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Is Primary',
                    field: 'is_primary',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 70,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Address 1',
                    field: 'street1',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'City',
                    field: 'city',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 90,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'County',
                    field: 'county',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 90,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'State',
                    field: 'state',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 90,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Zip',
                    field: 'zip_code',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 80,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Do Not Adopt',
                    field: 'do_not_adopt',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 80,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Phone',
                    field: 'phone',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Email',
                    field: 'email',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 150,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Create Date',
                    field: 'create_date',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filter: 'agDateColumnFilter',
                    filterParams: Utils.dateFilterParams(),
                },
                {
                    headerName: 'Creator',
                    field: 'owner',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Animal ID',
                    field: 'animal_id',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 75,
                    filter: 'agNumberColumnFilter',
                    filterParams: Utils.filterParams(),
                },
                {
                    headerName: 'Species',
                    field: 'species',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 90,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Animal Name',
                    field: 'animal_name',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Primary Breed',
                    field: 'primary_breed',
                    enableCellTextSelection: true,
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 150,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Gender',
                    field: 'gender',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 90,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Age Range',
                    field: 'age_range',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 80,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Mode',
                    field: 'transaction_mode',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'I/O Type',
                    field: 'transaction_type',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'I/O Date',
                    field: 'transaction_date',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filter: 'agDateColumnFilter',
                    filterParams: Utils.dateFilterParams(),
                },
                {
                    headerName: 'I/O Reason',
                    field: 'transaction_reason',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 108,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'I/O Reason Details',
                    field: 'transaction_reason_details',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 150,
                    filterParams: Utils.filterParams()
                }
            ]
        } else if (this.data.result_mode == "Medical Event") {
            this.columnDefs = [
                {
                    headerName: 'Date',
                    field: 'event_date',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 90,
                    filter: 'agDateColumnFilter',
                    filterParams: Utils.dateFilterParams(),
                },
                {
                    headerName: 'Agency Sub Type',
                    // field: 'agency_sub_type',
                    field: 'vet_name',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 150,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Animal Name',
                    field: 'animal.name',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 120,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Species',
                    field: 'animal.species',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 90,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Condition',
                    field: 'condition',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'Center'
                    },
                    suppressSizeToFit: true,
                    width: 90,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Test',
                    field: 'tests',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    filterParams: Utils.filterParams(),
                    width: 130,
                },
                {
                    headerName: 'Surgery',
                    field: 'surgeries',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Vaccination',
                    field: 'vaccinationstr',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Medication',
                    field: 'medication',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    filterParams: Utils.filterParams()
                }
            ]
        } else if (this.data.result_mode == "Campus Utilization") {
            this.columnDefs = [
                {
                    headerName: 'Animal ID',
                    field: 'animal_id',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 75,
                    filter: 'agNumberColumnFilter',
                    filterParams: Utils.filterParams(),
                },
                {
                    headerName: 'Animal Name',
                    field: 'animal_name',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Species',
                    field: 'species',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 90,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    minWidth: 60,
                    cellStyle: {...this.defaultColDef.cellStyle, justifyContent: 'center'},
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Primary Breed',
                    field: 'primary_breed',
                    enableCellTextSelection: true,
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 150,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Gender',
                    field: 'gender',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 70,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Age Range',
                    field: 'animal_age',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 80,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Intake Date',
                    field: 'intake_date',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filter: 'agDateColumnFilter',
                    filterParams: Utils.dateFilterParams(),
                },
                {
                    headerName: 'Outcome Date',
                    field: 'outcome_date',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filter: 'agDateColumnFilter',
                    filterParams: Utils.dateFilterParams(),
                },
                {
                    headerName: 'Campus',
                    field: 'location_name',
                    minWidth: 120,
                    cellStyle: {...this.defaultColDef.cellStyle, justifyContent: 'flex-start'},
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Floor',
                    field: 'floor_name',
                    minWidth: 120,
                    cellStyle: {...this.defaultColDef.cellStyle, justifyContent: 'flex-start'},
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Section',
                    field: 'section_name',
                    minWidth: 120,
                    cellStyle: {...this.defaultColDef.cellStyle, justifyContent: 'flex-start'},
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Kennel',
                    field: 'cage',
                    minWidth: 100,
                    cellStyle: {...this.defaultColDef.cellStyle, justifyContent: 'flex-start'},
                    filterParams: Utils.filterParams()
                },
            ]
        } else if (this.data.result_mode == "Length of Stay") {
            this.columnDefs = [
                {
                    headerName: 'Animal ID',
                    field: 'animal_id',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 75,
                    filter: 'agNumberColumnFilter',
                    filterParams: Utils.filterParams(),
                },
                {
                    headerName: 'Animal Name',
                    field: 'animal_name',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Species',
                    field: 'species',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 90,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    minWidth: 60,
                    cellStyle: {...this.defaultColDef.cellStyle, justifyContent: 'center'},
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Primary Breed',
                    field: 'breed',
                    enableCellTextSelection: true,
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 150,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Gender',
                    field: 'gender',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 70,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Age Range',
                    field: 'animal_age',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 80,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Intake Date',
                    field: 'intake_date',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filter: 'agDateColumnFilter',
                    filterParams: Utils.dateFilterParams(),
                },
                {
                    headerName: 'Outcome Date',
                    field: 'outcome_date',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filter: 'agDateColumnFilter',
                    filterParams: Utils.dateFilterParams(),
                },
                {
                    headerName: 'Days In Shelter',
                    field: 'daysInShelter',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filter: 'agNumberColumnFilter',
                    filterParams: Utils.filterParams(),
                },
                {
                    headerName: 'Campus',
                    field: 'campus',
                    minWidth: 120,
                    cellStyle: {...this.defaultColDef.cellStyle, justifyContent: 'flex-start'},
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Kennel',
                    field: 'cage',
                    minWidth: 100,
                    cellStyle: {...this.defaultColDef.cellStyle, justifyContent: 'flex-start'},
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Current Stage',
                    field: 'stage',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
            ]
        } else if (this.data.result_mode == "By Jurisdiction") {
            this.columnDefs = [
                {
                    headerName: 'Animal ID',
                    field: 'animal_id',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 75,
                    filter: 'agNumberColumnFilter',
                    filterParams: Utils.filterParams(),
                },
                {
                    headerName: 'Animal Name',
                    field: 'animal_name',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Species',
                    field: 'species',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 90,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    minWidth: 60,
                    cellStyle: {...this.defaultColDef.cellStyle, justifyContent: 'center'},
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Primary Breed',
                    field: 'breed',
                    enableCellTextSelection: true,
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'flex-start'
                    },
                    suppressSizeToFit: true,
                    width: 150,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Gender',
                    field: 'gender',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 70,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Age Range',
                    field: 'animal_age',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 80,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Intake Date',
                    field: 'intake_date',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filter: 'agDateColumnFilter',
                    filterParams: Utils.dateFilterParams(),
                },
                {
                    headerName: 'Outcome Date',
                    field: 'outcome_date',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filter: 'agDateColumnFilter',
                    filterParams: Utils.dateFilterParams(),
                },
                {
                    headerName: 'Jurisdiction',
                    field: 'jurisdiction',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
                {
                    headerName: 'Current Stage',
                    field: 'stage',
                    cellStyle: {
                        ...this.defaultColDef.cellStyle,
                        justifyContent: 'center'
                    },
                    suppressSizeToFit: true,
                    width: 100,
                    filterParams: Utils.filterParams()
                },
            ]
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();

        this.refreshMainGrid(this.data.result_mode);
    }

    dateRange() {
        let startDate;
        let endDate;
        const todayDate=Utils.convertTZ(new Date);
        let currentYear = moment(todayDate).year();
        if (this.data.report_period == "Yesterday") {
            startDate = moment(todayDate).subtract(1, 'days').format("MM/DD/YYYY");
            endDate = moment(todayDate).subtract(1, 'days').format("MM/DD/YYYY");
        } else if (this.data.report_period == "Today") {
            startDate = moment(todayDate).format("MM/DD/YYYY");
            endDate = moment(todayDate).format("MM/DD/YYYY");
        } else if (this.data.report_period == "This Week") { //correct
            startDate = moment(todayDate).startOf('isoWeek').format("MM/DD/YYYY");
            endDate = moment(todayDate).format("MM/DD/YYYY");
        } else if (this.data.report_period == "Last Week") { //correct
            startDate = moment(todayDate).subtract(1, 'weeks').startOf('isoWeek').format("MM/DD/YYYY");
            endDate = moment(todayDate).subtract(1, 'weeks').endOf('isoWeek').format("MM/DD/YYYY");
        } else if (this.data.report_period == "Next Week") { //correct
            startDate = moment(todayDate).add(1, 'weeks').startOf('isoWeek').format("MM/DD/YYYY");
            endDate = moment(todayDate).add(1, 'weeks').endOf('isoWeek').format("MM/DD/YYYY");
        } else if (this.data.report_period == "This Month") { //correct
            startDate = moment(todayDate).startOf('month').format('MM/DD/YYYY');
            endDate = moment(todayDate).format("MM/DD/YYYY");
        } else if (this.data.report_period == "Last Month") { //correct
            startDate = moment(todayDate).subtract(1, 'months').startOf('month').format('MM/DD/YYYY');
            endDate = moment(todayDate).subtract(1, 'months').endOf('month').format('MM/DD/YYYY');
        } else if (this.data.report_period == "Next Month") { //correct
            startDate = moment(todayDate).add(1, 'months').startOf('month').format('MM/DD/YYYY');
            endDate = moment(todayDate).add(1, 'months').endOf('month').format('MM/DD/YYYY');
        } else if (this.data.report_period == "This Year") { //correct
            startDate = moment(todayDate).startOf('year').format('MM/DD/YYYY');
            endDate = moment(todayDate).format("MM/DD/YYYY");
        } else if (this.data.report_period == "Next Year") { //correct
            startDate =  moment(todayDate).add(1, 'years').startOf('year').format("MM/DD/YYYY");
            endDate = moment(todayDate).add(1, 'years').endOf('year').format("MM/DD/YYYY");
        } else if (this.data.report_period == "Last Year") { //correct
            startDate = moment(todayDate).add(-1, 'years').startOf('year').format("MM/DD/YYYY");
            endDate = moment(todayDate).add(-1, 'years').endOf('year').format("MM/DD/YYYY");
        } else if (this.data.report_period == "Since 30 Days Ago") { //correct
            startDate = moment(todayDate).subtract(30, 'days').format('MM/DD/YYYY');
            endDate = moment(todayDate).format("MM/DD/YYYY");
        } else if (this.data.report_period == "Since 60 Days Ago") { //correct
            startDate = moment(todayDate).subtract(60, 'days').format('MM/DD/YYYY');
            endDate = moment(todayDate).format("MM/DD/YYYY");
        } else if (this.data.report_period == "Since 90 Days Ago") { //correct
            startDate = moment(todayDate).subtract(90, 'days').format('MM/DD/YYYY');
            endDate = moment(todayDate).format("MM/DD/YYYY");
        } else if (this.data.report_period == "This Quarter") { //correct
            startDate = moment(todayDate).quarter(moment(todayDate).quarter()).startOf('quarter').format('MM/DD/YYYY');
            endDate = moment(todayDate).quarter(moment(todayDate).quarter()).endOf('quarter').format('MM/DD/YYYY');
        } else if (this.data.report_period == "Next Quarter") { //correct
            startDate = moment(todayDate).add(1, 'quarter').startOf('quarter').format('MM/DD/YYYY');
            endDate = moment(todayDate).add(1, 'quarter').endOf('quarter').format('MM/DD/YYYY');
        } else if (this.data.report_period == "Last Quarter") { //correct
            startDate = moment(Utils.convertTZ(new Date)).subtract(1, 'quarter').startOf('quarter').format('MM/DD/YYYY');
            endDate = moment(Utils.convertTZ(new Date)).subtract(1, 'quarter').endOf('quarter').format('MM/DD/YYYY');
        } else if (this.data.report_period == "Custom") { //correct
            startDate = this.data.from_date;
            endDate = this.data.to_date;
        }

        this.data.from_date = startDate;
        this.data.to_date = endDate;

        let reportType;
        reportType = (this.data.type == "detailed") ? "Details" : 'Summary';

        if (this.data.filter_payload && this.data.result_mode == "Medical Event") {
            let checkCondition = this.data.filter_payload.filter(elem => Object.keys(elem)[0].toLowerCase() === "condition");
            if (checkCondition.length > 0) {
                this.data.filter_payload.map(elem => {
                    if (Object.keys(elem)[0].toLowerCase() === "condition") {
                        let conditionArr = [];
                        elem['Condition'].forEach(me => {
                            let tag = '';
                            if (me === '5') {
                                tag = 'Excellent';
                            } else if (me == "4.1-5") {
                                tag = 'Good';
                            } else if (me == "3.1-4") {
                                tag = 'Concerning';
                            } else if (me == "2.1-3") {
                                tag = 'Poor';
                            } else if (me == "0-1" || me == "1.1-2") {
                                tag = 'Emergency';
                            }
                            conditionArr.push(tag);
                        });
                        elem['Condition'] = conditionArr;
                    }
                });
            }
        }

        let payload = {
            "period": this.data.report_period,
            "from": moment(this.data.from_date).format("YYYY-MM-DD"),
            "to": moment(this.data.to_date).format("YYYY-MM-DD"),
            "frequency": this.data.summary_frequency,
            "result_type": reportType,
            "result_mode": this.data.result_mode,
            "summary_option": this.summaryOptionVal,
            "filter": this.data.filter_payload,
        }
        return payload;
    }

    sortPropertiesByValue(object) {
        const keys = Object.keys(object);
        const valuesIndex = keys.map(key => ({key, value: object[key]}));

        valuesIndex.sort((a, b) => b.value - a.value); // reverse sort

        const newObject = {};

        for (const item of valuesIndex) {
            newObject[item.key] = item.value;
        }

        return newObject;
    }

    reportsData() {
        let payload = this.dateRange();
        let sub = this.homeService.getReportsData(payload).subscribe(result => {
            //console.log(result[0].report_map.constructor);
            if (Object.keys(result[0].report_map).length > 0) {
                result = this.sortPropertiesByValue(result[0].report_map);

                let sortedArrKeys;
                if (this.data.summary_frequency == "Daily" || this.data.result_mode == "Medical Event" || this.data.result_mode == "Revenue") {
                    sortedArrKeys = Object.keys(result).sort();
                } else
                    sortedArrKeys = Object.keys(result);

                let csvtabledata = [];
                let dateHeaders: any[] = [""];
                let totalRow: any[] = [];
                let filterOutput = [];
                let quarterArr = [{number: "1", "value": "Q1"}, {number: "2", "value": "Q2"}, {
                    number: "3",
                    "value": "Q3"
                }, {number: "4", "value": "Q4"}];
                totalRow.push("Total");

                sortedArrKeys.forEach(dateVal => {
                    if (this.data.summary_frequency == "Monthly")
                        dateHeaders.push(moment(dateVal, 'M').format('MMMM'));
                    else if (this.data.summary_frequency == "Weekly")
                        dateHeaders.push(dateVal + " Week");
                    else if (this.data.summary_frequency == "Quarterly")
                        dateHeaders.push(quarterArr.find(el => el.number === dateVal)?.value);
                    else
                        dateHeaders.push(moment(dateVal).format("MM/DD/YYYY"));
                    let filterData = result[dateVal];
                    let totalFilterAnimal = 0;
                    for (var filterName in filterData) {
                        if (filterData[filterName])
                            totalFilterAnimal += filterData[filterName];

                        filterOutput.push(filterName);
                    }
                    totalRow.push(totalFilterAnimal);
                })


                csvtabledata.push(dateHeaders);
                filterOutput = Array.from(new Set(filterOutput)); // Remove Duplicate values
                if (filterOutput.length > 0) {
                    filterOutput.forEach(obj1 => {
                        let csvAnimal = [];
                        csvAnimal.push(obj1);
                        sortedArrKeys.forEach(dateVal => {
                            (result[dateVal].hasOwnProperty(obj1)) ? (csvAnimal.push(result[dateVal][obj1])) : (csvAnimal.push(''))
                        })

                        csvtabledata.push(csvAnimal);
                    })
                }
                csvtabledata.push(totalRow);
                this.csvSummaryOutput = csvtabledata;
            }
        })
    }

    async refreshMainGrid(viewmode) {
        this.rowData = of([]);
        const payload = this.dateRange();
        if (viewmode === 'Medical Event') {
            this.rowData = this.homeService.getReportsData(payload).pipe(
                map((res: any[]) => {
                    res[0].forEach((ele: any) => {
                        ele.vet_name = (ele.agency.agency_sub_type) ? ele.agency.agency_sub_type : '';
                        // if (ele.med_service_opted.length > 0) {
                        const allTests: any[] = ele.med_service_opted.filter(elem => elem.service_type === 'TEST');
                        if (allTests.length === 1) {
                            ele.tests = allTests[0].service_name;
                        } else if (allTests.length > 1) {
                            ele.tests = allTests.reduce((prev, cur) => {
                                if (typeof prev === 'string') {
                                    return prev + ', ' + `${cur.service_name}`;
                                } else {
                                    return `${prev.service_name}, ${cur.service_name}`;
                                }
                            });
                        } else {
                            ele.tests = '';
                        }

                        const allSurgeris: any[] = ele.med_service_opted.filter(elm => elm.service_type === 'SURGERY');
                        if (allSurgeris.length === 1) {
                            if (allSurgeris[0].outcome && allSurgeris[0].service_name.toLowerCase() == "other") {
                              ele.surgeries = allSurgeris[0].outcome ? `${allSurgeris[0].service_name} - ${allSurgeris[0].outcome}` : "";
                            } else {
                              ele.surgeries = `${allSurgeris[0].service_name}`;
                            }
                        } else if (allSurgeris.length > 1) {
                            ele.surgeries = allSurgeris.reduce((prev, cur) => {
                                let outcomeValue = "";
                                if (cur.outcome && cur.service_name.toLowerCase() == "other") {
                                outcomeValue = cur.outcome ? ` - ${cur.outcome}` : "";
                                }
                                if (typeof prev === "string") {
                                return prev + ", " + `${cur.service_name} ${outcomeValue}`;
                                } else {
                                if (prev.outcome && prev.service_name.toLowerCase() == "other") {
                                    outcomeValue = prev.outcome ? ` - ${prev.outcome}` : "";
                                }
                                return `${prev.service_name} ${outcomeValue}, ${cur.service_name}`;
                                }
                            });
                        } else {
                            ele.surgeries = "";
                        }

                        if (ele.vaccination.length == 1) {
                            ele['vaccinationstr'] = ele.vaccination[0].vaccine;
                        } else if (ele.vaccination.length > 1) {
                            ele['vaccinationstr'] = [...new Set(ele.vaccination.reduce((prev, cur) => {
                                if (typeof prev == 'string') return prev + ', ' + `${cur.vaccine}`;
                                else return `${prev.vaccine}, ${cur.vaccine}`;
                            }).split(', '))].join(', ');
                        } else {
                            ele['vaccinationstr'] = '';
                        }

                        if (ele.medications_opted.length == 1) {
                            ele['medication'] = ele.medications_opted[0].medicine;
                        } else if (ele.medications_opted.length > 1) {
                            ele['medication'] = [...new Set(ele.medications_opted.reduce((prev, cur) => {
                                if (typeof prev == 'string') return prev + ', ' + `${cur.medicine}`;
                                else return `${prev.medicine}, ${cur.medicine}`;
                            }).split(', '))].join(', ');
                        } else {
                            ele['medication'] = '';
                        }

                        let conditionArray = ele.medical_condition.sort((a, b) => b.id - a.id);
                        ele['condition'] = conditionArray[0]?.condition;

                    })
                    this.resultCount = res[0].length;
                    return res[0];
                })
            );
        } else if (viewmode == "Intake" || viewmode == "Outcome") {
            let masterIntakeTypeList: any[] = [];
            let masterOutcomeTypeList: any[] = [];
            await this.transactService.getPickupListsForIntake().toPromise().then(res => {
                if (viewmode == "Intake")
                    masterIntakeTypeList = res.filter(ele => ele.mapping_id > 0 && ele.transaction_type == 'Intake');
                else
                    masterIntakeTypeList = res.filter(ele => ele.mapping_id > 0 && ele.transaction_type == 'Outcome');
            });


            this.rowData = this.homeService.getReportsData(payload).pipe(
                map((res: any[]) => {
                    res[0].forEach((ele: any) => {
                        ele.primary_breed = ele.animal.primary_breed;
                        ele.gender = ele.animal.gender;
                        ele.animal_name = ele.animal.name;
                        ele.species = ele.animal.species;
                        ele.age_range = moment(Utils.convertTZ(new Date)).diff(moment(ele.animal.dob, 'MM/DD/YYYY'), 'month');
                        if (viewmode == "Intake") {
                            ele.intake_date = moment(ele.intake_transaction_datetime).utc().format('MM/DD/YYYY');
                            ele.intake_type = masterIntakeTypeList.find(elm => elm.id == ele.intake_transaction_type_id)?.transaction_type_value;
                        } else {
                            ele.outcome_date = moment(ele.outcome_transaction_datetime).isValid() ? moment(ele.outcome_transaction_datetime).utc().format('MM/DD/YYYY') : '';
                            ele.outcome_type = masterIntakeTypeList.find(elm => elm.id == ele.outcome_transaction_type_id)?.transaction_type_value;
                        }
                    })
                    this.resultCount = res[0].length;
                    return res[0];
                })
            );

        } else if (viewmode == "Contact List") {
            this.rowData = this.homeService.getReportsData(payload).pipe(
                map((res: any[]) => {
                    res[0].forEach(ele => {
                        ele.account_name = (ele['account_info'] && ele['account_info'].account_name) ? ele['account_info'].account_name : '';
                        ele.create_date = moment(ele.created_time).format("MM/DD/YYYY");
                        let is_primary = (ele['account_info'] && ele['account_info'].account_contact) ? ((ele['account_info'].account_contact.find(el => el.contact_id === ele.contact_id)).is_primary) : '';
                        ele.is_primary = (is_primary) ? "Yes" : 'No';
                        if(ele.do_not_adopt){
                            ele.do_not_adopt=(ele.do_not_adopt=="Agree") ? "Yes" : "No";
                        }
                        ele.origination = ele.existing ? 'Existing' : 'In-Network';
                        ele.owner = `${ele.created_by?.split('@')[0]}`;
                    })
                    this.resultCount = res[0].length;
                    return res[0];
                })
            );
        } else if (viewmode == "Contact Animal") {
            let masterIntakeTypeList: any[] = [];
            let masterOutcomeTypeList: any[] = [];
            await this.transactService.getPickupListsForIntake().toPromise().then(res => {
                masterIntakeTypeList = res.filter(ele => ele.mapping_id > 0 && ele.transaction_type == 'Intake');
                masterOutcomeTypeList = res.filter(ele => ele.mapping_id > 0 && ele.transaction_type == 'Outcome');
            });
            this.rowData = this.homeService.getReportsData(payload).pipe(
                map((result: any[]) => {
                    result[0].forEach(ele => {

                        ele.age_range = moment(Utils.convertTZ(new Date)).diff(moment(ele.dob, 'MM/DD/YYYY'), 'month');
                        if (ele.intake_mode == "Intake") {
                            ele.transaction_mode = "Intake";
                            ele.transaction_date = moment(ele.intake_transaction_datetime).utc().format('MM/DD/YYYY');
                            ele.transaction_type = masterIntakeTypeList.find(elm => elm.id == ele.intake_transaction_type_id)?.transaction_type_value;
                            ele.transaction_reason = ele.intake_reason;
                            ele.transaction_reason_details = ele.intake_reason_details;
                        } else if (ele.outcome_mode == "Outcome") {
                            ele.transaction_mode = "Outcome";
                            ele.transaction_date = moment(ele.outcome_transaction_datetime).isValid() ? moment(ele.outcome_transaction_datetime).utc().format('MM/DD/YYYY') : '';
                            ele.transaction_type = masterOutcomeTypeList.find(elm => elm.id == ele.outcome_transaction_type_id)?.transaction_type_value;
                            ele.transaction_reason = ele.outcome_reason;
                            ele.transaction_reason_details = ele.outcome_reason_details;
                        }
                        ele.origination = ele.existing ? 'Existing' : 'In-Network';
                        ele.account_name = (ele['account_info'] && ele['account_info'].account_name) ? ele['account_info'].account_name : '';
                        ele.create_date = moment(ele.created_time).format("MM/DD/YYYY");
                        let is_primary = (ele['account_info'] && ele['account_info'].account_contact) ? ((ele['account_info'].account_contact.find(el => el.contact_id === ele.contact_id)).is_primary) : '';
                        ele.is_primary = (is_primary) ? "Yes" : 'No';
                        if(ele.do_not_adopt){
                            ele.do_not_adopt=(ele.do_not_adopt=="Agree") ? "Yes" : "No";
                        }
                        ele.owner = `${ele.created_by?.split('@')[0]}`;

                    })
                    this.resultCount = result[0].length;
                    return result[0];
                })
            )
        } else if (viewmode == "Campus Utilization") {
            let agencyLocationList: any[];
            await this.settingsService.getAgencyDetails().toPromise().then(res => {
                agencyLocationList = res.addresses.filter(ele => ele.is_active === true);
            });
            this.rowData = this.homeService.getReportsData(payload).toPromise().then((result: any[]) => {

                result[0].forEach(ele => {
                    const result = ele.dob ? Utils.ageUnitFromDOB(ele.dob) : null;
                    //ele.birth_date = ele.dob ? result[0].birth_date : '';
                    ele.intake_date = ele.intake_date ? (moment(ele.intake_date).utc().format("MM/DD/YYYY")) : '';
                    ele.outcome_date = ele.outcome_date ? (moment(ele.outcome_date).utc().format("MM/DD/YYYY")) : '';
                    ele.animal_age = ele.dob ? result[0].animal_age : 0;
                    const locationData = agencyLocationList && agencyLocationList.find((i: any) => (i.agency_sub_id === ele.location_id && (i.is_active == true)));
                    ele.location_city = locationData && locationData.location_name;
                    ele.cage = (locationData && locationData.address_type == "Campus") ? (ele.cage_name) : '';
                })
                this.resultCount = result[0].length;
                return result[0];
            })
        } else if (viewmode == "Length of Stay") {

            this.rowData = this.homeService.getReportsData(payload).toPromise().then((result: any[]) => {

                result[0].forEach(ele => {
                    const result = ele.dob ? Utils.ageUnitFromDOB(ele.dob) : null;
                    //ele.birth_date = ele.dob ? result[0].birth_date : '';
                    ele.intake_date = ele.intake_date ? (moment(ele.intake_date).utc().format("MM/DD/YYYY")) : '';
                    ele.outcome_date = ele.outcome_date ? (moment(ele.outcome_date).utc().format("MM/DD/YYYY")) : '';
                    ele.animal_age = ele.dob ? result[0].animal_age : 0;

                    const intakeDate = moment(ele && ele.intake_date);
                    const outcomeDate = moment(ele && ele.outcome_date).isValid() ? moment(ele.outcome_date).utc() : moment(Utils.convertTZ(new Date));
                    const daysInShelter = outcomeDate.diff(intakeDate, 'days');
                    ele.daysInShelter = daysInShelter;
                })
                this.resultCount = result[0].length;
                return result[0];
            })
        } else if (viewmode == "By Jurisdiction") {
            this.rowData = this.homeService.getReportsData(payload).toPromise().then((result: any[]) => {

                result[0].forEach(ele => {
                    const result = ele.dob ? Utils.ageUnitFromDOB(ele.dob) : null;
                    //ele.birth_date = ele.dob ? result[0].birth_date : '';
                    ele.intake_date = ele.intake_date ? (moment(ele.intake_date).utc().format("MM/DD/YYYY")) : '';
                    ele.outcome_date = ele.outcome_date ? (moment(ele.outcome_date).utc().format("MM/DD/YYYY")) : '';
                    ele.animal_age = ele.dob ? result[0].animal_age : 0;
                })
                this.resultCount = result[0].length;
                return result[0];
            })
        } else if (viewmode == "Revenue") {
            this.rowData = this.homeService.getReportsData(payload).toPromise().then((result: any[]) => {
                result[0].sort(function (a, b) {
                    var c = new Date(a.transaction_date).getTime();
                    var d = new Date(b.transaction_date).getTime();
                    return c < d ? 1 : -1;
                    ;
                });
                result[0].forEach(ele => {
                    ele.amount = ele.amount ? `$ ${parseFloat(ele.amount).toFixed(2)}` : 0;
                })

                this.resultCount = result[0].length;
                return result[0];
            })
        }
    }

    onBtnDownloadClicked() {
        let params = this.getDownloadParams();
        this.gridApi.exportDataAsCsv(params);
    }

    getValue(inputSelector) {
        var text = inputSelector;
        switch (text) {
            case 'none':
                return;
            default:
                return text;
        }
    }

    getDownloadParams() {
        return {
            fileName: `report_details_list_${this.getCurDateStr()}`,
            suppressQuotes: this.getValue('none'),
            columnSeparator: this.getValue('none'),
            customHeader: this.getValue(''),
            customFooter: this.getValue(''),
        };
    }

    exportAsExcelFile() {
        let json = this.csvSummaryOutput;
        let excelFileName = `report_summary_list_${this.getCurDateStr()}`
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {skipHeader: true});
        const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    getCurDateStr() {
        let d = Utils.convertTZ(new Date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('');
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    }

}
