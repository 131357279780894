import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filterList'
})

export class ListFilterPipe implements PipeTransform {

    transform(list: any[], filterText: string, filterMetadata?: any): any {
        filterMetadata = list.length;
        return list ? list.filter(item => item.business_name.search(new RegExp(filterText, 'i')) > -1) : [];
    }
}
