import * as moment from 'moment';

export class Utils {
  public static getBaseThemeAgGridCol() {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      editable: false,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        padding: ' 0 5px 0 2px',
        borderRight: '1px solid #ebebeb',
        fontFamily: 'HELVETICA_NEUE_LIGHT, sans-serif',
        color: '#000000',
        fontSize: '12px',
        textTransform: 'uppercase'
      }
    };
  }

  public static getBaseThemeAgGridColNotes() {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      editable: false,
      cellStyle: {
        display: 'flex',
        alignItems: 'start',
        padding: ' 0 5px 0 2px',
        borderRight: '1px solid #ebebeb',
        fontFamily: 'HELVETICA_NEUE_THIN, sans-serif',
        color: '#929292',
        fontSize: '12px'
      }
    };
  }

  public static userRole(role) {
    let userRole = '';
    if (role === 'ADMIN' || role === 'Admin') {
      userRole = 'Admin'
    } else if (role === 'AGENCY_USER') {
      userRole = 'Agency User'
    } else if (role === 'ADOPTION_COUNSELOR') {
      userRole = 'Adoption Counselor'
    } else if (role === 'ANIMAL_CARE_TECHNICIAN') {
      userRole = 'Animal Care Technician'
    } else if (role === 'AUDITOR') {
      userRole = 'Auditor'
    } else if (role === 'MEDICAL_TEAM_MEMBER') {
      userRole = 'Medical Team Member'
    } else if (role === 'SITE_DIRECTOR') {
      userRole = 'Site Director'
    } else {
      userRole = role;
    }
    return userRole
  }

  public static userStatusCorrection(data) {
    // console.log("__+data+++",data)
    if (data === 'Active') {
      return 0;
    } else if (data === 'Inactive') {
      return 1;
    } else if (data === 'Not Verified') {
      return 2;
    }
  }

  public static phoneNumberFormatChange(date) {
    let resDateSegment = date.split(' ')[0].split('-');
    return `${resDateSegment[1]}/${resDateSegment[2]}/${resDateSegment[0]}`
  }

  public static ageUnitFromDOB(dob) {
    let bdate = moment(dob, 'MM/DD/YYYY');
    let unitString = ''
    let ageNumber = 0
    // if (bdate.isValid()) {
    let curmoment = moment(this.convertTZ(new Date));
    let ageInYears = curmoment.diff(bdate, 'years');
    let ageMonths = curmoment.diff(bdate, 'months');
    let ageWeeks = curmoment.diff(bdate, 'weeks');

    if (ageInYears > 0) {
      unitString = 'Years'
      ageNumber = ageInYears
    } else if (ageMonths > 0) {
      unitString = 'Months'
      ageNumber = ageMonths
    } else if (ageWeeks > 0) {
      unitString = 'Weeks'
      ageNumber = ageWeeks
    }
    return [{ 'birth_date': bdate.format('MM/DD/YYYY'), 'age': ageNumber, 'unit': unitString, 'animal_age': ageNumber + ' ' + this.getReductedUnit(unitString) }]
    // this.animalAboutForm.patchValue({
    //   birth_date: bdate.format('MM/DD/YYYY'),
    //   age: ageNumber,
    //   unit: unitString
    // })
    // }
  }
  public static getReductedUnit(unit) {
    if (!unit)
      return '';
    if (unit == 'Years')
      return 'Y';
    else if (unit == 'Months')
      return 'M';
    else if (unit == 'Weeks')
      return 'W'
  }
  public static getGenderShort(gender) {
    if (!gender)
      return '';
    if (gender == 'Male')
      return 'M';
    else if (gender == 'Female')
      return 'F';
  }
  public static weightInLbs(weight) {
    if (!weight)
      return '';
    if (weight)
      return weight + ' lbs';
  }
  public static splitString(splitString, splitBy) {
    if (!splitString)
      return '';
    return splitString.split(`${splitBy}`)[1]
  }

  public static dollerStringToNumber(str: string): number {
    if (str.includes(',')) {
      str = str.split(',').join('');
    }
    if (str.includes('$')) {
      str = str.split('$').join('');
    }
    return parseFloat(str);
  }

  public static numberToString(num: number): string {
    let numStrSplitData = num.toFixed(2).split('.');
    return `${parseInt(numStrSplitData[0]).toLocaleString()}.${numStrSplitData[1]}`;
  }
  public static animalDBOFormat(dob) {
    let bdate = moment(dob, 'YYYY-MM-DD');
    // let curmoment = moment();
    // let ageInYears = curmoment.diff(bdate, 'years');
    // let ageMonths = curmoment.diff(bdate, 'months');
    // let ageWeeks = curmoment.diff(bdate, 'weeks');

    let diffTime = moment(this.convertTZ(new Date)).diff(bdate);
    let duration = moment.duration(diffTime);
    let years = duration.years();
    let days = duration.days();
    let months = duration.months();
    let weeks = duration.weeks();


    // console.log(years + 'Y-' + months  + 'M-' + weeks + 'W' )
    return years + 'Y-' + months + 'M-' + weeks + 'W'

  }

  public static getCurDateStr() {
    const d = this.convertTZ(new Date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('');
  }

  public static concat(data, append) {
    return data ? `${data}${append}` : '';
  }

  public static getUUIDv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  public static filterParams() {
    return {
      suppressAndOrCondition: true,
      buttons: ["apply", "reset"]
    }
  }

  /* public static monthToNum(date) {
     if (date === undefined || date === null || date.length !== 10) {
       return null;
     }
   
     var yearNumber = date.substring(6, 10);
     var monthNumber = date.substring(3, 5);
     var dayNumber = date.substring(0, 2);
   
     var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
     // 29/08/2004 => 20040829
     return result;
   }*/

  public static dateFilterParams() {
    return {
      buttons: ["apply", "reset"],
      debounceMs: 200,
      suppressAndOrCondition: true,
      /* comparator: function(filterLocalDateAtMidnight, cellValue) {
        /* var date1Number = date1 && new Date(date1).getTime();
         var date2Number = date2 && new Date(date2).getTime();
 
         if (date1Number == null && date2Number == null) {
           return 0;
         }
 
         if (date1Number == null) {
           return -1;
         } else if (date2Number == null) {
           return 1;
         }
 
         return date1Number - date2Number;
         if (cellValue == null) {
           return 0;
         }
         console.log(cellValue);
         var dateParts = cellValue.split('/');
         var year = Number(dateParts[2]);
         var month = Number(dateParts[0]) - 1;
         var day = Number(dateParts[1]);
         var cellDate = new Date(year, month, day);
 
         if ((cellDate < filterLocalDateAtMidnight)) {
           return -1;
         } else if (cellDate > filterLocalDateAtMidnight) {
           return 1;
         } else {
           return 0;
         }
       },*/
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;

        if (dateAsString == null) return -1;

        var cellDate = new Date(dateAsString);

        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
      browserDatePicker: true,
    }
  }

  public static dateComparator(date1: any, date2: any) {
    const date1Number = date1 && new Date(date1).getTime();
    const date2Number = date2 && new Date(date2).getTime();
    if (date1Number == null && date2Number == null) {
      return 0;
    }
    if (date1Number == null) {
      return -1;
    } else if (date2Number == null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  public static convertTZ(date,timezone?) {
    
   const userDetails= JSON.parse(localStorage.getItem('user'))
   let tzString=timezone ? timezone : userDetails.timezone;
   /* let tzFullName: string = '';
    if(!timezone){
      switch (tzString) {
        case 'EST':
          tzFullName = 'America/new_york';
          break;
        case 'PST':
          tzFullName = 'America/Los_Angeles';
          break;
        case 'MST':
          tzFullName = 'America/Denver';
          break;
        case 'UTC':
            tzFullName = 'Atlantic/Iceland';
            break;
        default: tzFullName = 'America/Chicago';
          break;
      }
    }*/
    if(timezone)
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-GB", {timeZone: tzString}));
    else
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
}

public static getPaymentLabel(item : any, paymentList): string {
  return paymentList.find((data : any) => data.value === item)?.label
}

public static getPaymentReceipt(getPaymentDetails, totalAmount,paymentList){
  totalAmount = 0
    let paymentReceipt = []
    paymentReceipt.push([
      { text: 'Payment Method', alignment: "left", color: "#000" },
      { text: 'Donation', alignment: "left", color: "#000" },
      { text: 'Price', alignment: "left", color: "#000" },
      { text: 'Line Total', alignment: "left", color: "#000", },
    ]);
    getPaymentDetails.forEach((payment: any) => {
      const priceAmount = Number(payment.amount - payment.donation_amount)
      totalAmount = totalAmount + payment.amount
      paymentReceipt.push([
        { text: this.getPaymentLabel(payment.payment_method, paymentList), alignment: "left" },
        { text: '$' + payment.donation_amount/100, alignment: "left" },
        { text: '$' + priceAmount/100, alignment: "left", },
        { text: '$' + payment.amount/100, alignment: "left" },
      ]);
    })
    paymentReceipt.push([
      { text: 'Total', alignment: "left", color: "#000" },
      { text: '', alignment: "left", color: "#000" },
      { text: '', alignment: "left", color: "#000" },
      { text: '$' + totalAmount/100, alignment: "left", color: "#000" },
    ]);
    return paymentReceipt;
}

public static agencyPrimary(currentAgency,agencyAddress){
  let agencyPrimaryAddCeck
  currentAgency.addresses.filter(
    (ele) => ele.is_primary == 1
  );
  if (agencyPrimaryAddCeck) {
    agencyAddress = agencyPrimaryAddCeck[0];
  }
  return agencyAddress
 }

 public static getLayoutLineSpacing(arrayOfLength){
  return {
    hLineWidth: function (i, node) {
      return arrayOfLength - 1 === i ? 1 : 0;
    },
    vLineWidth: function (i, node) {
      return arrayOfLength === i ? 1 : 0;
    },
    hLineColor: function (i, node) {
      return arrayOfLength - 1 === i ? "#000" : "#fff";
    },
    vLineColor: function (i, node) {
      return arrayOfLength === i ? "#fff" : "#000";
    },
    paddingLeft: function (i, node) {
      return 5;
    },
    paddingRight: function (i, node) {
      return 5;
    },
    paddingTop: function (i, node) {
      return 5;
    },
    paddingBottom: function (i, node) {
      return 5;
    },
  };
 }
}

export class ElementId {
  static newId(): string {
    return 'idxx-yxx'.replace(/[xy]/g, (c) => {
      const r: number = Math.ceil((Math.random() * 16 || 0));
      const v = (c === 'x') ? r : ((r && 0x3) || 0x8);
      return v.toString(16);
    });
  }
}

export const NO_PERMISSION_MSG = 'You do not have required permissions for this operation';