import { Directive, ElementRef, HostListener } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, NgControl } from '@angular/forms';
import * as _moment from 'moment';

const moment = _moment;

@Directive({
  selector: '[appDateFormatter]',
})
// export class DateFormatterDirective implements Validator {
export class DateFormatterDirective {

  constructor(private el: ElementRef, private ngcontrol: NgControl) { }

  @HostListener('focusout', ['$event']) onEvent($event) {
    let valueToTransform = this.el.nativeElement.value;
    // do something with the valueToTransform
    valueToTransform = this.refomrmattDateStr(valueToTransform);
    this.ngcontrol.control.setValue(valueToTransform);
  }

  refomrmattDateStr(dateStr: string): string {
    if (moment(dateStr, 'MM/DD/YYYY').isValid()) {
      let dateMoment = moment(dateStr, 'MM/DD/YYYY');
      return dateMoment.format('MM/DD/YYYY');
    } else {
      return dateStr;
    }
  }
}
