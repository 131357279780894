import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GridOptions, GridApi } from 'ag-grid-community';
import { AgGridClickableCellComponent } from '../ag-grid-clickable-cell/ag-grid-clickable-cell.component';
import { AgGridClickableSegmentedComponent } from '../ag-grid-clickable-segmented/ag-grid-clickable-segmented.component';
import { Utils } from 'src/app/core/utils/util';
import { Observable, of, Subscription } from 'rxjs';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-agency-lookup',
  templateUrl: './agency-lookup.component.html',
  styleUrls: ['./agency-lookup.component.scss']
})
export class AgencyLookupComponent implements OnInit, OnDestroy {

  @Input('selectedAgency') selectedAgency: any;
  @Output('onSelectAgency') onSelectAgency: EventEmitter<any> = new EventEmitter();

  actionTilesListLookupAgency = [
    {
      message: 'Lookup',
      messageColor: '#419010',
      actionSign: 'search',
      bgColor: '',
      messageFontSize: '13px',
      img: null,
      actionName: 'lookupAgency',
      messageTextForans: 'uppercase'
    },
    {
      message: 'Select',
      messageColor: '#419010',
      actionSign: '',
      bgColor: '',
      messageFontSize: '13px',
      actionName: 'selectAgency',
      img: 'assets/images/icon_select.png',
      messageTextForans: 'uppercase'
    },
  ];

  public gridOptions = {
    context: {
      componentParent: this,
    },
    suppressHorizontalScroll: false,
    rowStyle: { background: 'white' }
  } as GridOptions;
  agencyLookupGridApi: GridApi;
  agencyLookupGridColumnApi: any;
  defaultColDef: any;
  frameworkComponents;
  getRowHeight: any;
  headerHeight: any;

  agencyLookupRowData: Observable<any>;
  agencyLookupColumnDefs: any[] = [];

  agencyInfoForm: FormGroup;
  subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private accountService: AccountsService
  ) {
    this.frameworkComponents = {
      cusotmClickableCell: AgGridClickableCellComponent,
      customSegmetCell: AgGridClickableSegmentedComponent
    };

    this.getRowHeight = (params) => {
      return 34;
    }
    this.headerHeight = 34;
  }

  ngOnInit(): void {
    this.initAgencyInfoForm();
    this.setupAgencyLookupGridColumn();
  }

  initAgencyInfoForm() {
    this.agencyInfoForm = this.fb.group({
      agency_name: [''],
      address_1: [''],
      city: [''],
      zip_code: ['']
    });
  }

  setupAgencyLookupGridColumn() {
    this.defaultColDef = Utils.getBaseThemeAgGridCol();
    this.agencyLookupColumnDefs = [
      {
        headerName: '',
        field: 'check',
        checkboxSelection: true,
        suppressSizeToFit: true,
        width: 31,
        sortable:false,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          display: 'flex',
          justifyContent: 'center',
          padding: '0 0 0 10px'
        },
      },
      {
        headerName: 'Agency Name',
        field: 'business_name',
        // width: 100,
        // suppressSizeToFit: true,
        cellRenderer: this.columnRender(),
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Address',
        field: 'address',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center',
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Type',
        field: 'agency_type',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center',
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Category',
        field: 'category',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center',
        },
        filterParams: Utils.filterParams()
      },
    ];
  }

  columnRender() {
    return 'cusotmClickableCell';
  }

  onAgencyLookupGridReady(params) {
    this.agencyLookupGridApi = params.api;
    this.agencyLookupGridColumnApi = params.columnApi;
    this.agencyLookupGridApi.sizeColumnsToFit();

    this.agencyLookupRowData = of([]);
  }

  actionFired(event) {
    if (event == 'lookupAgency') {
      let payload = {
        business_name: this.agencyInfoForm.value.agency_name ? this.agencyInfoForm.value.agency_name : '',
        street1: this.agencyInfoForm.value.address_1 ? this.agencyInfoForm.value.address_1 : '',
        city: this.agencyInfoForm.value.city ? this.agencyInfoForm.value.city : '',
        zip_code: this.agencyInfoForm.value.zip_code ? this.agencyInfoForm.value.zip_code : ''
      }
      
      this.agencyLookupRowData = this.accountService.lookupAgencies(payload).pipe(
        map((res: any[]) => {
          res = res.filter(ele => ele.in_network == false);
          res.forEach(element => {
            let pAddress = element.addresses?.find(ele => ele.is_active == true);
            if (pAddress) {
              element['address'] = `${this.concat(pAddress.street1, ',')} ${this.concat(pAddress.street2, ',')} ${this.concat(pAddress.city, ',')} ${this.concat(pAddress.county, ',')} ${this.concat(pAddress.state, ',')} ${this.concat(pAddress.zip_code, '')}`;
            }
            else {
              element['address'] = '';
            }
  
            element['category'] = element.in_network ? 'In-Network' : 'Out of Network';
          });
          return res;
        })
      );
    } else if(event == 'selectAgency') {
    //  console.log(this.agencyLookupGridApi.getSelectedRows()[0]);
      this.selectedAgency = this.agencyLookupGridApi.getSelectedRows()[0];
      let pAddress = this.selectedAgency.addresses?.find(ele => ele.location_name == 'HQ');

      this.agencyInfoForm.patchValue({
        agency_name: this.selectedAgency.business_name,
        address_1: pAddress?.street1,
        city: pAddress?.city,
        zip_code: pAddress?.zip_code
      });
      this.onSelectAgency.emit(this.selectedAgency);
    }
  }

  isDisableActionTile(data) {
    if (data.actionName == 'selectAgency') {
      if (this.agencyLookupGridApi?.getSelectedRows().length > 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  concat(data, append) {
    return data ? `${data}${append}` : '';
  }

  ngOnDestroy() {
    this.subs.forEach(element => element.unsubscribe());
  }
}
