import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: "root"
})

export class AnimalMedicalConditionDialogService {

    constructor(
        private http: HttpClient
    ) { }


    getEarImages(): Observable<any> {
        return this.http.get('assets/animal-images/ears/get-ear-images.json');
    }
    getEyeImages(): Observable<any> {
        return this.http.get('assets/animal-images/eyes/get-eye-images.json');
    }
    getBodyImages(): Observable<any> {
        return this.http.get('assets/animal-images/body/get-body-images.json');
    }
    getGenitalImages(): Observable<any> {
        return this.http.get('assets/animal-images/genitals/get-genital-images.json');
    }
    getNoseImages(): Observable<any> {
        return this.http.get('assets/animal-images/nose/get-nose-images.json');
    }
    getSkinImages(): Observable<any> {
        return this.http.get('assets/animal-images/skin/get-skin-images.json');
    }
    getTeethImages(): Observable<any> {
        return this.http.get('assets/animal-images/teeth/get-teeth-images.json');
    }
}
