import { Component, OnInit, Inject } from '@angular/core';
import { AllCommunityModules, GridOptions, GridApi, Context } from "@ag-grid-community/all-modules";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utils } from 'src/app/core/utils/util';
import { AddEditUserDialogComponent } from 'src/app/settings/components/add-edit-user-dialog/add-edit-user-dialog.component';
import { AgGridClickableCellComponent } from 'src/app/shared/components/ag-grid-clickable-cell/ag-grid-clickable-cell.component';
import { HomeService } from 'src/app/core/services/home.service';
import { Observable } from 'rxjs';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { filter, map } from 'rxjs/operators';
import { StorageService } from 'src/app/core/services/storage.service';

export interface DialogData {
  msgInfoRow: any,
  is_edit: boolean
}

@Component({
  selector: 'app-new-msg-dialog',
  templateUrl: './new-msg-dialog.component.html',
  styleUrls: ['./new-msg-dialog.component.scss']
})
export class NewMsgDialogComponent implements OnInit {
  public columnDefs: any[] = [];
  rowData: Observable<any>;
  defaultColDef: any;
  getRowHeight;
  headerHeight;
  rowSelection: any;
  frameworkComponents;
  newMsg: FormGroup;
  agencyName: string;
  searchedAgency: string;
  tenantId: number;
  logedInUserTenantId: number;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  public gridOptions = {
    context: {
      componentParent: this,
    },
    suppressHorizontalScroll: false,
    rowStyle: { background: 'white' }
  } as GridOptions;
  gridApi: GridApi;
  contactLinkGridApi: GridApi;
  gridColumnApi: any;
  actionTilesListFirst: any[]
  callFormCase: boolean = false;

  constructor(public dialogRef: MatDialogRef<AddEditUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private fb: FormBuilder, private _snackBar: MatSnackBar, private homeService: HomeService) {
    //ag-grid config
    this.frameworkComponents = {
      cusotmClickableCell: AgGridClickableCellComponent
    };
    this.getRowHeight = (params) => {
      return 34;
    }
    this.headerHeight = 34;

    this.actionTilesListFirst = [
      {
        message: 'new',
        messageColor: '#419010',
        actionSign: 'add',
        bgColor: '',
        messageFontSize: '13px',
        actionName: 'new',
        img: null,
        messageTextForans: 'uppercase'
      },
      {
        message: 'reply',
        messageColor: '#419010',
        actionSign: 'clear',
        bgColor: '',
        messageFontSize: '13px',
        actionName: 'reply',
        img: null,
        messageTextForans: 'uppercase'
      },
    ]
  }

  ngOnInit(): void {
    this.initNewMsgForm();
    this.setupGridColumn();
    if (this.data.msgInfoRow ?.call_form === "case") {
      this.callFormCase = true
      this.setFormValue()
    }
  }
  initNewMsgForm() {
    this.newMsg = this.fb.group({
      'topic': ['', Validators.required],
      'message': ['', Validators.required]
    })
  }
  setFormValue() {
    if (this.callFormCase) {
      this.agencyName = this.data.msgInfoRow.agency_name;
      this.tenantId = this.data.msgInfoRow.agency_id;
    } else {
      this.agencyName = this.gridApi.getSelectedRows()[0].business_name
      this.tenantId = this.gridApi.getSelectedRows()[0].tenant_id
      // this.newMsg.patchValue({
      //   topic: this.gridApi.getSelectedRows()[0].agency_type
      // });
    }
  }
  setupGridColumn() {
    this.defaultColDef = Utils.getBaseThemeAgGridCol();

    this.columnDefs = [
      {
        headerName: '',
        field: 'check',
        checkboxSelection: true,
        // headerCheckboxSelection: true,
        suppressSizeToFit: true,
        sortable: false,
        resizable: false,
        filter: false,
        minWidth: 10,
        width: 31,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          display: 'flex',
          justifyContent: 'center',
          padding: '0 0 0 10px'
        },
      },
      { headerName: 'Name', field: 'business_name', cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' }, suppressSizeToFit: true, width: 130 },
      { headerName: 'Type', field: 'agency_type', cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'flex-start' } },
      { headerName: 'Address', field: 'address', cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'center' }, suppressSizeToFit: true, width: 165 },
      { headerName: 'Phone', field: 'phone', cellStyle: { ...this.defaultColDef.cellStyle, justifyContent: 'center' } }
    ];
    this.rowSelection = 'single';
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.refreshGrid();
  }

  columnRender() {
    return 'cusotmClickableCell';
  }

  refreshGrid() {
    this.logedInUserTenantId = JSON.parse(StorageService.getItem('tenant_id'));
    this.rowData = this.homeService.getAllAgencies().pipe(
      map((e) => {
        return e.filter(el => {
          if (el.tenant_id != this.logedInUserTenantId) {
            return el
          }
        })

      })
    );
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onKeyAgencySearch(event) {
    this.gridApi.setQuickFilter(this.searchedAgency);
  }
  onSelectRow() {
    this.setFormValue();
  }
  onSendClick() {
    if (!this.tenantId) {
      this._snackBar.open('Please select a agency', 'Ok', {
        duration: 4000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['danger-snackbar']
      });
    }
    if (this.newMsg.valid) {
      if (this.data.is_edit) {
        this.dialogRef.close(this.newMsg.value);
      } else {
        this.dialogRef.close({ ...this.newMsg.value, tenant_id: this.tenantId });
      }
    } else {
      this.newMsg.markAllAsTouched();
    }
  }
}
