import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UtilService} from 'src/app/core/services/util.service';
import {Observable, Subscription} from 'rxjs';
import * as _moment from 'moment';
import {GridApi, GridOptions} from 'ag-grid-community';
import {Utils} from 'src/app/core/utils/util';
import {AccountsService} from 'src/app/core/services/accounts.service';
import { isArray, isObject } from 'lodash';

const moment = _moment;

export interface DialogData {
    contact: any;
}

@Component({
    selector: 'app-contact-details-dialog',
    templateUrl: './contact-details-dialog.component.html',
    styleUrls: ['./contact-details-dialog.component.scss']
})
export class ContactDetailsDialogComponent implements OnInit, OnDestroy {

    subs: Subscription[] = [];
    contactType: string;
    contactStatus: string;
    isWarnigEnabled: boolean;

    gridApi: GridApi;
    gridColumnApi: any;
    notesRowData: Observable<any>;
    notesColumnDefs: any[] = [];
    defaultColDef: any;
    public gridOptions = {
        context: {
            componentParent: this,
        },
        suppressHorizontalScroll: false,
        rowStyle: {background: 'white'}
    } as GridOptions;
    getRowHeight;
    headerHeight;
    overlayNoRowsTemplate: string;

    constructor(
        public dialogRef: MatDialogRef<ContactDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private utilService: UtilService,
        private accountService: AccountsService
    ) {
        this.getRowHeight = (params) => {
            return 34;
        };
        this.headerHeight = 34;
    }

    ngOnInit(): void {
        this.getDataFromMaster();
        this.updateDontAdoptFlag();
        this.setColumns();
    }

    setColumns() {
        this.defaultColDef = Utils.getBaseThemeAgGridCol();
        this.overlayNoRowsTemplate = '<span style="padding: 10px; color: #929292">No notes to display</span>';

        this.notesColumnDefs = [
            {
                headerName: 'Date',
                field: 'modified_time',
                suppressSizeToFit: true,
                width: 138,
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center'
                },
                filterParams:Utils.dateFilterParams()
            },
            {
                headerName: 'Topic',
                field: 'note_name',
                suppressSizeToFit: true,
                width: 177,
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'flex-start'
                },
                filterParams:Utils.filterParams()
            },
            {
                headerName: 'Notes',
                field: 'notes',
                filterParams:Utils.filterParams()
            },
            {
                headerName: 'User',
                field: 'user',
                suppressSizeToFit: true,
                width: 144,
                cellStyle: {...this.defaultColDef.cellStyle, justifyContent: 'flex-start'},
                filterParams:Utils.filterParams()
            }
        ];
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();

        const payload = {
            entity: 'Contact',
            entity_id: this.data.contact.contact_id,
            noteId: '',
            noteName: '',
            notes: '',
            created_by: '',
            modified_by: ''
        };
        this.notesRowData = this.utilService.getAllNotes(payload);
    }

    getDataFromMaster() {
        const sub: Subscription = this.utilService.getMasterInputs('Contact').subscribe(res => {
            res.input.contact_type.forEach(masterContactType => {
                masterContactType.id === this.data.contact.contact_type_id ? this.contactType = masterContactType.value : '';
            });
            res.input.contact_status.forEach(masterContactStatus => {
                masterContactStatus.id === this.data.contact.contact_status_id ? this.contactStatus = masterContactStatus.value : '';
            });
        });
        this.subs.push(sub);
    }

    getAddress() {
        return `${this.concat(this.data.contact.street1, ',')} ${this.concat(this.data.contact.street2, ',')} ${this.concat(this.data.contact.city, ',')} ${this.concat(this.data.contact.county, ',')} ${this.concat(this.data.contact.state, ',')} ${this.concat(this.data.contact.zip_code, '')}`;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    getDobFormated() {
        const dob = this.data && this.data.contact && this.data.contact.dob;
        if (dob && dob.indexOf('-') > -1) {
            const dobSegment: string[] = dob.split('-');
            return `${dobSegment[1]}/${dobSegment[2]}/${dobSegment[0]}`;
        } else if (dob && dob.indexOf('/') > -1) {
            return dob;
        }
    }

    getAge() {
        const currentMoment = moment(Utils.convertTZ(new Date));
        const dob = this.data && this.data.contact && this.data.contact.dob;
        const age = currentMoment.diff(moment(dob).format('YYYY-MM-DD'), 'years');
        return `${age}                         ${age > 18 ? 'Adult' : 'Minor'}`;
    }

    getRelatedContact() {
        const data = this.data && this.data.contact && this.data.contact.account_info && this.data.contact.account_info.account_name;
        return (data && isArray(data)) ? data.filter(ele => ele.contact_id !== this.data.contact.contact_id) : data;
    }

    concat(data, append) {
        return data ? `${data}${append}` : '';
    }

    onContactHeaderClicked() {
        this.dialogRef.close({msg: 'redirect-to-contact-page', data: this.data.contact});
    }

    onRelatedContactClicked(contact) {
        const sub = this.accountService.getContactById(contact.contact_id).subscribe(res => {
            this.dialogRef.close({msg: 'redirect-to-contact-page', data: res});
        });
        this.subs.push(sub);
    }

    onAccountClicked() {
        const sub = this.accountService.getAccountById(this.data.contact.account_info.account_id).subscribe(res => {
            this.dialogRef.close({msg: 'redirect-to-account-edit', data: res[0]});
        });
        this.subs.push(sub);
    }

    updateDontAdoptFlag() {
        const sub = this.accountService.getContactWarningHistory(this.data.contact.contact_id).subscribe((res: any[]) => {
            res.forEach(ele => {
                if (ele.warning_decision === 'Agree') {
                    this.isWarnigEnabled = true;
                }
            });
        });
        this.subs.push(sub);
    }

    ngOnDestroy() {
        this.subs.forEach(ele => ele.unsubscribe());
    }

}
