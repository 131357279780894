<div mat-dialog-title>
    <div>
      <span>Resource Animals List</span>
    </div>
    
    <button mat-icon-button>
      <mat-icon (click)="onNoClick()">close</mat-icon>
    </button>
</div>

<div mat-dialog-content>   
    <div class="row">
        <ag-grid-angular (gridReady)="onGridReady($event)"
            [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [frameworkComponents]="frameworkComponents"
            [getRowHeight]="getRowHeight" [gridOptions]="gridOptions" [headerHeight]="headerHeight" [rowData]="rowData"
            [rowSelection]="rowSelection" [suppressRowClickSelection]="true" class="ag-theme-alpine"
            style="width: 100%; height: 368px;">
        </ag-grid-angular>     
    </div>
</div>

<div mat-dialog-actions>
  <button mat-raised-button class="white-button" (click)="onNoClick()">CLOSE</button>
  
</div>