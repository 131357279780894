<div mat-dialog-title>
    <div>
      <span>Care Schedule for {{ data.care_taker_info.user_name }}: {{data.care_taker_info.schedule_date | date: 'dd-MMM-yyyy'}}</span>
    </div>
    
    <button mat-icon-button>
      <mat-icon (click)="onNoClick()">close</mat-icon>
    </button>
</div>


<div mat-dialog-content>  
  <div class="row download-grid mb-2 mt-2" style="justify-content: flex-end;">
    <button mat-raised-button (click)="onBtnDownloadClicked()">DOWNLOAD</button>
</div> 
    <div class="row">
      <!-- <ag-grid-angular style="width: 100%; height: 362px;" class="ag-theme-alpine" [rowData]="rowData | async"
      [frameworkComponents]="frameworkComponents" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
      (rowSelected)="onRowSelected($event)"
        [rowMultiSelectWithClick]="true"
        [cellMultiSelectWithClick]="true"
        (selectionChanged)="onSelectionChanged($event)"
        [rowSelection]="'multiple'" [cellSelection]="'multiple'" [rowMultiSelectWithClick]="true" (cellClicked)='onCellClicked($event)' [suppressCellSelection]="false"

      >
    </ag-grid-angular> -->

        <ag-grid-angular (gridReady)="onGridReady($event)"
            [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [frameworkComponents]="frameworkComponents"
            (rowSelected)="onRowSelected($event)"
            [suppressRowClickSelection]="true"
            (cellClicked)="onCellClicked($event,cellArray)"
            (selectionChanged)="onSelectionChanged($event)"
            [rowSelection]="'multiple'" [getRowHeight]="getRowHeight" [gridOptions]="gridOptions" [headerHeight]="headerHeight" [rowData]="rowData | async"
            class="ag-theme-alpine animal_care_aggrid"
            style="width: 100%; height: 368px;">
        </ag-grid-angular>  
        
        
</div>
</div>
<div mat-dialog-actions *ngIf="data.care_taker_info.schedule_date <= todatDate">
  <button mat-raised-button class="white-button" (click)="onNoClick()">CANCEL</button>
  
  <button mat-raised-button class="green-button" (click)="onSubmit()" cdkFocusInitial [disabled]="isDisableTopTile()">
    SUBMIT
  </button>
</div>