import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { ApiService } from './services/api.service';
import { AccountsService } from './services/accounts.service';
import { SettingsService } from './services/settings.service';
import { UtilService } from './services/util.service';
import { TransactService } from './services/transact.service';
import { ResponseInterceptor } from './interceptors/response.interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CaseService } from './services/case.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    MatSnackBarModule
  ],
  providers: [
    ApiService,
    AuthService,
    AccountsService,
    SettingsService,
    UtilService,
    TransactService,
    CaseService,
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
  ],
})
export class CoreModule { }
