<div style="display: flex;justify-content: flex-start;flex-wrap: wrap;">
  <div *ngFor="let anmList of animaDataList" style="margin-right: 2em;margin-bottom: 2em;">
    <div class="img-pagent-div">
      <!-- <img mat-card-image [src]='sanitize(anmList.primary_image_url)'
        style="width: 100%; object-fit: contain; width: 155px; height: 117px;object-position: bottom;">  -->
      <img mat-card-image [src]='anmList.primary_image_url | safeUrl'
        style="width: 100%; object-fit: contain; width: 155px; height: 117px;object-position: bottom;">
      <div class="hover-overlay">
        <button mat-icon-button (click)="onImageClick(anmList)" color="info" mat-icon-button>
          <mat-icon style="color: #fff;">preview</mat-icon>
        </button>
      </div>
      <!-- <img mat-card-image [src]='anmList.primary_image_url | safeUrl'
        style="width: 100%; object-fit: contain; width: 155px; height: 117px;object-position: bottom;">  -->
    </div>
    <div class="content-body">
      <div class="animal-title" (click)="clickOnTitle(anmList)">
        {{(anmList.name?.length>14)? (anmList.name | slice:0:14)+'...':(anmList.name)}}
      </div>

      <div class="animal-attr">
        {{(anmList.species?.length>12)? (anmList.species | slice:0:12)+'...':(anmList.species)}} -
        {{(anmList.breed?.length>11)? (anmList.breed | slice:0:11)+'...':(anmList.breed)}}
      </div>

      <div class="animal-attr">
        <span style="margin-right: 3px;">{{anmList.gender_to_show}} </span>
        <span style="margin-right: 3px;"> |
          {{anmList.birth_date}} </span> <span> | {{anmList.weight_to_show}}</span>
      </div>

      <div class="animal-attr">
        {{(anmList.agency_name?.length>26)? (anmList.agency_name | slice:0:26)+'...':(anmList.agency_name)}}
      </div>

      <div class="animal-attr">
        <span>{{(anmList.agency_city?.length>16)? (anmList.agency_city |
          slice:0:16)+'...':(anmList.agency_city)}},</span>
        <span style="margin-left: 3px;">
          {{(anmList.agency_state?.length>6)? (anmList.agency_state | slice:0:6)+'...':(anmList.agency_state)}}
        </span>
      </div>

      <div style="display: flex;align-items: center;justify-content: center;">
        <mat-icon style="color:#008f00;cursor: pointer;" (click)="onSendMSG($event,anmList)">mail_outline</mat-icon>
        <img *ngIf="anmList.animal_stage=='READY TO ADOPT'" src="assets/images/adopt.png" style="margin-left: 10px;"/>
        <img *ngIf="anmList.transferable" src="assets/images/transfer.jpg" style="margin-left: 10px;width: 18px;margin-bottom: 3px;"/>
      </div>

    </div>
  </div>
</div>
<div *ngIf="animaDataList.length ==0" class="no-data-found">No result found</div>