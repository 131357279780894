import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-ag-grid-clickable-segmented',
  templateUrl: './ag-grid-clickable-segmented.component.html',
  styleUrls: ['./ag-grid-clickable-segmented.component.scss']
})
export class AgGridClickableSegmentedComponent implements OnInit, ICellRendererAngularComp {

  params: ICellRendererParams;
  constructor() { }

  ngOnInit(): void {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  onSegmentClick(contact) {
    if (this.params.context) {
      const parent = this.params.context.componentParent;
      parent.clickOnSegment(contact);
    }
  }
  // onClickCell(): void {
  //   if (this.params.context) {
  //     const parent = this.params.context.componentParent;
  //     parent.clickOnCell(this.params.value, this.params.colDef.field, this.params.data);
  //   }
  // }
}
