import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GridOptions, GridApi } from 'ag-grid-community';
import { Observable, of } from 'rxjs';
import { AgGridClickableCellComponent } from '../ag-grid-clickable-cell/ag-grid-clickable-cell.component';
import { AgGridClickableSegmentedComponent } from '../ag-grid-clickable-segmented/ag-grid-clickable-segmented.component';
import { Utils } from 'src/app/core/utils/util';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AccountDetailsDialogComponent } from '../account-details-dialog/account-details-dialog.component';
import { ContactDetailsDialogComponent } from '../contact-details-dialog/contact-details-dialog.component';

@Component({
  selector: 'app-contact-lookup',
  templateUrl: './contact-lookup.component.html',
  styleUrls: ['./contact-lookup.component.scss']
})
export class ContactLookupComponent implements OnInit {

  @Input('selectedContact') selectedContact: any;
  @Output('onSelectContact') onSelectContact: EventEmitter<any> = new EventEmitter();

  actionTilesListLookupContact = [
    {
      message: 'Lookup',
      messageColor: '#419010',
      actionSign: 'search',
      bgColor: '',
      messageFontSize: '13px',
      img: null,
      actionName: 'lookupContact',
      messageTextForans: 'uppercase'
    },
    {
      message: 'Select',
      messageColor: '#419010',
      actionSign: '',
      bgColor: '',
      messageFontSize: '13px',
      actionName: 'selectContact',
      img: 'assets/images/icon_select.png',
      messageTextForans: 'uppercase'
    },
  ];

  public gridOptions = {
    context: {
      componentParent: this,
    },
    suppressHorizontalScroll: false,
    rowStyle: { background: 'white' }
  } as GridOptions;
  contactLinkGridApi: GridApi;
  contactLinkGridColumnApi: any;
  defaultColDef: any;
  frameworkComponents;
  getRowHeight: any;
  headerHeight: any;
  contactInfoForm: FormGroup;
  contactLookupRowData: Observable<any>;
  contactLookupColumnDefs: any[] = [];
  // selectedContact: any;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountsService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.frameworkComponents = {
      cusotmClickableCell: AgGridClickableCellComponent,
      customSegmetCell: AgGridClickableSegmentedComponent
    };

    this.getRowHeight = (params) => {
      return 34;
    }
    this.headerHeight = 34;
  }

  ngOnInit(): void {
    this.initContactInfoForm();
    this.setupContactLinkGridColumn();
    if (this.selectedContact) {
      this.contactInfoForm.patchValue(this.selectedContact);
    }
  }

  initContactInfoForm() {
    this.contactInfoForm = this.fb.group({
      first_name: [''],
      last_name: [''],
      street1: [''],
      phone: ['']
    });
  }

  setupContactLinkGridColumn() {
    this.defaultColDef = Utils.getBaseThemeAgGridCol();
    this.contactLookupColumnDefs = [
      {
        headerName: '',
        field: 'check',
        checkboxSelection: true,
        suppressSizeToFit: true,
        width: 31,
        sortable:false,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          display: 'flex',
          justifyContent: 'center',
          padding: '0 0 0 10px'
        },
      },
      {
        headerName: 'Contact',
        field: 'full_name',
        width: 100,
        suppressSizeToFit: true,
        cellRenderer: this.columnRender(),
        filterParams:Utils.filterParams()
      },
      {
        headerName: 'Phone',
        field: 'phone',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center',
        },
        filterParams:Utils.filterParams()
      },
      {
        headerName: 'City',
        field: 'city',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'flex-start',
        },
        filterParams:Utils.filterParams()
      },
      {
        headerName: 'Origination',
        field: 'origination',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'flex-start',
        },
        filterParams:Utils.filterParams()
      },
    ]
  }

  columnRender() {
    return 'cusotmClickableCell';
  }

  onContactLookupGridReady(params) {
    this.contactLinkGridApi = params.api;
    this.contactLinkGridColumnApi = params.columnApi;
    this.contactLinkGridApi.sizeColumnsToFit();

    this.contactLookupRowData = of([]);
  }

  clickOnCell(clickedRowId, clickedCellName, rowObj) {
    this.openContactDialog(rowObj);
  }

  onAccountNameClicked() {
    this.accountService.getAccountById(this.selectedContact.account_info.account_id).subscribe(res => {
      const dialogRef = this.dialog.open(AccountDetailsDialogComponent, {
        width: '70em',
        data: { account: res[0] },
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.msg == 'redirect-to-account-page') {
          this.router.navigateByUrl(
            'dashboard/accounts/accountbase', {
            state: {
              data: {
                account: result.data
              }
            }
          });
        } else if (result && result.msg == 'redirect-to-contact-edit') {
          this.router.navigateByUrl(
            'dashboard/accounts/contacts', {
            state: {
              data: {
                contact: result.data
              }
            }
          });
        }
      });
    });
  }

  onContactNameClicked() {
    this.openContactDialog(this.selectedContact);
  }

  openContactDialog(contact) {
    const dialogRef = this.dialog.open(ContactDetailsDialogComponent, {
      width: '70em',
      data: { contact: contact },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.msg == 'redirect-to-contact-page') {
        this.router.navigateByUrl(
          'dashboard/accounts/contacts', {
          state: {
            data: {
              contact: result.data
            }
          }
        });
      } else if (result && result.msg == 'redirect-to-account-edit') {
        this.router.navigateByUrl(
          'dashboard/accounts/accountbase', {
          state: {
            data: {
              account: result.data
            }
          }
        });
      }
    });
  }

  actionFired(event) {
    if (event == 'lookupContact') {
      // if (this.contactInfoForm.value.first_name && this.contactInfoForm.value.first_name != '') {
      let payload = {
        first_name: this.contactInfoForm.value.first_name ? this.contactInfoForm.value.first_name : '',
        last_name: this.contactInfoForm.value.last_name ? this.contactInfoForm.value.last_name : '',
        phone: this.contactInfoForm.value.phone ? this.contactInfoForm.value.phone : '',
        email: '',
        street1: this.contactInfoForm.value.street1 ? this.contactInfoForm.value.street1 : '',
        zipCode: '',
        county: '',
        state: '',
        city: '',
      }
      this.contactLookupRowData = this.accountService.lookupContact(payload).pipe(
        map((res: any[]) => {
          res = res.filter(element => {
            if (element.origination == 'Existing') {
              return element;
            }
          })
          return res;
        })
      );
      // }
    } else if (event == 'selectContact') {
      this.selectedContact = this.contactLinkGridApi.getSelectedRows()[0];
      this.contactInfoForm.patchValue(this.selectedContact);
      this.onSelectContact.emit(this.selectedContact);
    }
  }

  onPhoneInputChange(event) {
    let phone: string = event.target.value;
    if (phone.indexOf('-') > -1) {
      phone = phone.split('-').join('');
    }
    if (phone.length == 10) {
      this.contactInfoForm.patchValue({
        phone: `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, 10)}`
      });
    } else {
      this.contactInfoForm.controls.phone.setErrors({ len: true });
    }
  }

  isDisableActionTile(data) {
    if(data.actionName == 'selectContact') {
      if(this.contactLinkGridApi?.getSelectedRows().length > 0) {
        return false;
      } else {
        return true;
      }
    }
  }
}
