<div class="row">
  <div class="col-md-7">

    <!-- Lookup form -->
    <form class="row" [formGroup]="contactInfoForm">

      <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
        <mat-label>First Name</mat-label>
        <input type="text" matInput formControlName="first_name" autocomplete="off">
      </mat-form-field>

      <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
        <mat-label>Last Name</mat-label>
        <input type="text" matInput formControlName="last_name" autocomplete="off">
      </mat-form-field>

      <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
        <mat-label>Street 1</mat-label>
        <input type="text" matInput formControlName="street1" autocomplete="off">
      </mat-form-field>

      <mat-form-field class="form-field contact-form-field" [floatLabel]="'always'" color="accent">
        <mat-label>Phone</mat-label>
        <input type="text" matInput formControlName="phone" autocomplete="off" (change)="onPhoneInputChange($event)">
        <mat-error *ngIf="contactInfoForm.controls['phone'].hasError('len')">
          Phone number should be 10 digits
        </mat-error>
      </mat-form-field>

    </form>

    <!-- Selected contact info -->
    <div class="row" *ngIf="selectedContact">
      <div class="selected-container">
        <span>Contact ID:</span>
        <span>{{selectedContact.contact_id}}</span>
      </div>
      <!-- <div class="selected-container">
        <span>WARNING:</span>
        <span class="warning">DO NOT ADOPT</span>
      </div> -->
      <div class="selected-container">
        <span>Contact Name:</span>
        <!-- <span class="link" (click)="onContactNameClicked()">{{selectedContact.full_name}}</span> -->
        <span class="link" (click)="onContactNameClicked()">{{selectedContact.first_name}} {{selectedContact.last_name}}</span>
      </div>
      <div class="selected-container">
        <span>Account Name:</span>
        <span class="link" (click)="onAccountNameClicked()">{{selectedContact.account_info?.account_name}}</span>
      </div>
    </div>

  </div>

  <div class="col link-container">
    <div class="row">
      <div class="col-3 px-0">
        <app-action-tile *ngFor="let titleAcData of actionTilesListLookupContact" [message]="titleAcData.message"
          [messageColor]="titleAcData.messageColor" [actionSign]="titleAcData.actionSign"
          [bgColor]="titleAcData.bgColor" [minHeight]="titleAcData.minHeight" [minWidth]="titleAcData.minWidth"
          [messageFontSize]="titleAcData.messageFontSize" [messageTextForans]="titleAcData.messageTextForans"
          [disabled]="isDisableActionTile(titleAcData)" [img]="titleAcData.img" [actionName]="titleAcData.actionName"
          (actionOutput)="actionFired($event)">
        </app-action-tile>
      </div>
      <div class="col pl-0">
        <div class="row mt-3">
          <ag-grid-angular style="width: 95%; height: 230px;" class="ag-theme-alpine"
            [rowData]="contactLookupRowData | async" [columnDefs]="contactLookupColumnDefs"
            [getRowHeight]="getRowHeight" [headerHeight]="headerHeight" [suppressRowClickSelection]="true"
            [defaultColDef]="defaultColDef" [rowSelection]="'single'" [frameworkComponents]="frameworkComponents"
            [gridOptions]="gridOptions" (gridReady)="onContactLookupGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>
