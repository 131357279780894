import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UtilService} from 'src/app/core/services/util.service';
import {GridApi, GridOptions} from 'ag-grid-community';
import {Observable, of, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {Utils} from 'src/app/core/utils/util';
import {AgGridClickableCellComponent} from 'src/app/shared/components/ag-grid-clickable-cell/ag-grid-clickable-cell.component';
import {AccountsService} from 'src/app/core/services/accounts.service';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import * as _ from 'lodash';

export interface VetLookupDialogData {
}

@Component({
    selector: 'app-vet-lookup-dialog',
    templateUrl: './vet-lookup-dialog.component.html',
    styleUrls: ['./vet-lookup-dialog.component.scss']
})
export class VetLookupDialogComponent implements OnInit, OnDestroy {

    vetLookupForm: FormGroup;
    cities: any[] = [];
    states: any[] = [];
    counties: any[] = [];
    public gridOptions = {
        context: {
            componentParent: this,
        },
        suppressHorizontalScroll: false,
        rowStyle: {background: 'white'}
    } as GridOptions;
    gridApi: GridApi;
    gridColumnApi: any;
    defaultColDef: any;
    frameworkComponents;
    getRowHeight: any;
    headerHeight: any;
    rowData: Observable<any>;
    columnDefs: any[] = [];
    subs: Subscription[] = [];
    vetMasterData: any;
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(
        public dialogRef: MatDialogRef<VetLookupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private utilService: UtilService,
        private accountService: AccountsService,
        private toast: MatSnackBar
    ) {
        this.frameworkComponents = {
            cusotmClickableCell: AgGridClickableCellComponent
        };

        this.getRowHeight = (params) => {
            return 34;
        };
        this.headerHeight = 34;
    }

    ngOnInit(): void {
        this.initVetLookupForm();
        this.getMasterData();
        this.setupGridColumn();
    }

    initVetLookupForm() {
        this.vetLookupForm = this.fb.group({
            business_type: [''],
            business_name: [''],
            first_name: [''],
            last_name: [''],
            email: [''],
            phone: [''],
            street1: [''],
            zip_code: [''],
            county: [''],
            state: [''],
            city: ['']
        });
    }

    getMasterData() {
        const sub = this.utilService.getMasterInputs('Vet').subscribe(res => {
            this.vetMasterData = res.input;
        });
        this.subs.push(sub);
    }

    setupGridColumn() {
        this.defaultColDef = Utils.getBaseThemeAgGridCol();
        this.columnDefs = [
            {
                headerName: '',
                field: 'check',
                checkboxSelection: true,
                suppressSizeToFit: true,
                width: 31,
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0 0 0 10px'
                },
            },
            {
                headerName: 'Business Name',
                field: 'business_name',
                width: 150,
                suppressSizeToFit: true,
                filterParams: Utils.filterParams(),
            },
            {
                headerName: 'Business Type',
                field: 'agency_sub_type',
                width: 150,
                suppressSizeToFit: true,
                filterParams: Utils.filterParams(),
            },
            {
                headerName: 'Address',
                field: 'address',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'flex-start',
                },
                filterParams: Utils.filterParams(),
            },
            {
                headerName: 'Phone',
                field: 'phone',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                width: 100,
                suppressSizeToFit: true,
                filterParams: Utils.filterParams(),
            },
            {
                headerName: 'Email',
                field: 'email',
                cellStyle: {
                    ...this.defaultColDef.cellStyle,
                    justifyContent: 'center',
                },
                width: 150,
                suppressSizeToFit: true,
                filterParams: Utils.filterParams(),
            }
        ];
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.rowData = of([]);
    }

    onSelect() {
        const vet = this.gridApi?.getSelectedRows()[0];
        // console.log(vet);
        this.dialogRef.close(vet);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onZipcodeInputChange(event) {
        const payload = {
            zipcode: event.target.value,
            city: '',
            state: '',
        };
        this.cities = [];
        this.states = [];
        this.counties = [];
        this.getAddressInfo(payload);
        // let sub = this.utilService.getSmartyZipCode(payload).subscribe(res => {
        //   if (res[0].status == 'invalid_zipcode') {
        //     this.vetLookupForm.get('zip_code').setErrors({ invalid: true })
        //     return;
        //   }
        //   res[0].city_states?.forEach(city_state => {
        //     if (!this.cities.includes(city_state.city)) {
        //       this.cities.push(city_state.city)
        //     }
        //     if (!this.states.includes(city_state.state)) {
        //       this.states.push(city_state.state)
        //     }
        //   });
        //   res[0].zipcodes?.forEach(zipcode => {
        //     if (!this.counties.includes(zipcode.county_name)) {
        //       this.counties.push(zipcode.county_name)
        //     }
        //   });
        //
        //   this.vetLookupForm.patchValue({
        //     city: this.cities.length > 0 ? this.cities[0] : '',
        //     county: this.counties.length > 0 ? this.counties[0] : '',
        //     state: this.states.length > 0 ? this.states[0] : '',
        //   })
        // });
        // this.subs.push(sub);
    }

    getAddressInfo(payload: any): any {
        const sub = this.utilService.getAddressInfo(payload).subscribe((res: any) => {
            if (res && res.length) {
                const data = res[0];
                const cities = [];
                cities.push(data.primary_city);
                const acceptableCities = data && data.acceptable_cities && data.acceptable_cities.split('_');
                if (acceptableCities && acceptableCities.length) {
                    _.forEach(acceptableCities, (item: any) => cities.push(item));
                }
                _.forEach(cities, (item) => this.cities.push(item));
                this.states.push(data.state);
                this.counties.push(data.county);
                this.vetLookupForm.patchValue({
                    city: this.cities.length > 0 ? this.cities[0] : '',
                    county: this.counties.length > 0 ? this.counties[0] : '',
                    state: this.states.length > 0 ? this.states[0] : '',
                });
            } else {
                this.vetLookupForm.controls.zip_code.setErrors({incorrect: true});
                this.toast.open(
                    'You have entered invalid Zipcode',
                    'ok', {
                        horizontalPosition: 'right',
                        verticalPosition: 'top',
                        duration: 2000,
                        panelClass: 'danger'
                    });
            }
        });

        this.subs.push(sub);
    }

    onPhoneInputChange(event) {
        let phone: string = event.target.value;
        if (phone.indexOf('-') > -1) {
            phone = phone.split('-').join('');
        }
        if (phone.length === 10) {
            this.vetLookupForm.patchValue({
                phone: `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, 10)}`
            });
        } else {
            this.vetLookupForm.get('phone').setErrors({len: true});
        }
    }

    isDisableButtons() {
        return false;
    }

    onRefreshClicked() {
        this.vetLookupForm.reset();
        this.rowData = of([]);
    }

    onCreateNewClicked() {
        this.enableFrormValidation();
        if (this.vetLookupForm.valid) {
            // const payload = this.vetLookupForm.value;

            const payload = {
                agency_type: 'Veterinarian',
                agency_sub_type: this.vetLookupForm.value.business_type ? this.vetLookupForm.value.business_type : '',
                agency_website: '',
                annual_revenue: '',
                business_name: this.vetLookupForm.value.business_name ? this.vetLookupForm.value.business_name : '',
                contract_agreement: '',
                employee_size: '',
                in_network: false,
                income_tax_return: '',
                is_active: false,
                user: {
                    first_name: '',
                    last_name: '',
                    phone_number: '',
                    email: '',
                    position: '',
                    location_i: 0,
                    role: ''
                },
                addresses: [
                    {
                        location_name: 'HQ',
                        street1: this.vetLookupForm.value.street1 ? this.vetLookupForm.value.street1 : '',
                        street2: '',
                        zip_code: this.vetLookupForm.value.zip_code ? this.vetLookupForm.value.zip_code : '',
                        county: this.vetLookupForm.value.county ? this.vetLookupForm.value.county : '',
                        state: this.vetLookupForm.value.state ? this.vetLookupForm.value.state : '',
                        city: this.vetLookupForm.value.city ? this.vetLookupForm.value.city : '',
                        phone: this.vetLookupForm.value.phone ? this.vetLookupForm.value.phone : '',
                        address_type: 'Campus',
                        foster_home_animal_size: 0,
                        is_primary: true,
                        is_active: true
                    }
                ],
                agency_logo_url: ''
            };

            // const payload = {
            //     business_name: this.vetLookupForm.value.business_name ? this.vetLookupForm.value.business_name : '',
            //     agency_sub_type: this.vetLookupForm.value.business_type ? this.vetLookupForm.value.business_type : '',
            //     street1: this.vetLookupForm.value.street1 ? this.vetLookupForm.value.street1 : '',
            //     city: this.vetLookupForm.value.city ? this.vetLookupForm.value.city : '',
            //     zip_code: this.vetLookupForm.value.zip_code ? this.vetLookupForm.value.zip_code : '',
            //     county: this.vetLookupForm.value.county ? this.vetLookupForm.value.county : '',
            //     state: this.vetLookupForm.value.state ? this.vetLookupForm.value.state : '',
            //     phone: this.vetLookupForm.value.phone ? this.vetLookupForm.value.phone : '',
            // };
            const sub = this.accountService.createModifyOutAgency(payload).subscribe(res => {
                this.toast.open('Veterinarian created', 'Ok', {
                    duration: 2000,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    panelClass: ['light-snackbar']
                });
                this.onSearchClicked();
            });
            this.subs.push(sub);
        } else {
            this.vetLookupForm.markAllAsTouched();
        }
    }

    onSearchClicked() {
        let count = 0;
        Object.keys(this.vetLookupForm.value).forEach(ele => {
            if (this.vetLookupForm.value[ele] !== '') {
                count = count + 1;
            }
        });
        if (count === 0) {
            this.rowData = of([]);
            this.toast.open('No vet found', 'Ok', {
                duration: 2000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                panelClass: ['warning-snackbar']
            });
            return;
        }

        const payload = {
            business_name: this.vetLookupForm.value.business_name ? this.vetLookupForm.value.business_name : '',
            agency_sub_type: this.vetLookupForm.value.business_type ? this.vetLookupForm.value.business_type : '',
            street1: this.vetLookupForm.value.street1 ? this.vetLookupForm.value.street1 : '',
            city: this.vetLookupForm.value.city ? this.vetLookupForm.value.city : '',
            zip_code: this.vetLookupForm.value.zip_code ? this.vetLookupForm.value.zip_code : '',
            county: this.vetLookupForm.value.county ? this.vetLookupForm.value.county : '',
            state: this.vetLookupForm.value.state ? this.vetLookupForm.value.state : '',
            phone: this.vetLookupForm.value.phone ? this.vetLookupForm.value.phone : '',

            // first_name: this.vetLookupForm.value.first_name ? this.vetLookupForm.value.first_name : '',
            // last_name: this.vetLookupForm.value.last_name ? this.vetLookupForm.value.last_name : '',
            // email: this.vetLookupForm.value.email ? this.vetLookupForm.value.email : '',
        };

        // console.log(payload);

        this.rowData = this.accountService.lookupVets(payload).pipe(
            map((res: any[]) => {
                const isService = this.data && this.data.is_services;
                const isAgencySelected = this.data && this.data.is_agency_selected;
                const isContactSelected = this.data && this.data.is_contact_selected;
                if (isService && (isAgencySelected || isContactSelected)) {
                    res.forEach((element: any) => {
                        const addressInfo: any = this.getSourceAddress(element && element.addresses.length && element.addresses[0]);
                        element.full_name = `${element.first_name} ${element.last_name}`;
                        // element.address = `${Utils.concat(element.street1, ',')} ${Utils.concat(element.street2, ',')} ${Utils.concat(element.city, ',')} ${Utils.concat(element.county, ',')} ${Utils.concat(element.state, ',')} ${Utils.concat(element.zip_code, '')}`;
                        element.address = addressInfo.address;
                        element.phone = addressInfo.phone;
                        element.email = element.user && element.user.email;
                    });
                    res = res.filter((item: any) => item.in_network);
                    if (res.length === 0) {
                        this.noContactFound();
                    }
                    return res;
                } else {
                    res.forEach((element: any) => {
                        const addressInfo: any = this.getSourceAddress(element && element.addresses.length && element.addresses[0]);
                        element.full_name = `${element.first_name} ${element.last_name}`;
                        // element.address = `${Utils.concat(element.street1, ',')} ${Utils.concat(element.street2, ',')} ${Utils.concat(element.city, ',')} ${Utils.concat(element.county, ',')} ${Utils.concat(element.state, ',')} ${Utils.concat(element.zip_code, '')}`;
                        element.address = addressInfo.address;
                        element.phone = addressInfo.phone;
                        element.email = element.user && element.user.email;
                    });

                    if (res.length === 0) {
                        this.noContactFound();
                    }
                    return res;
                }
            })
        );
    }

    noContactFound(): any {
        this.toast.open('No contact found', 'Ok', {
            duration: 2000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['warning-snackbar']
        });
    }

    getSourceAddress(address: any): string {
        let newAddress: any;
        let addressVal:string='';
        if(address){
            addressVal +=(address.street1) ? address.street1 : '';
            addressVal +=(address.street2) ? ", "+address.street2 : '';
            addressVal +=(address.city) ? ", "+address.city : '';
            addressVal +=(address.state) ? ", "+address.state : '';
            addressVal=addressVal.replace(/^,/, '');
        }
        newAddress = {address: addressVal,
                     phone: (address.phone) ? address.phone : ''};
        return newAddress;
    }

    enableFrormValidation() {
        this.vetLookupForm.get('business_type').setValidators([Validators.required]);
        this.vetLookupForm.get('business_type').updateValueAndValidity();

        this.vetLookupForm.get('business_name').setValidators([Validators.required]);
        this.vetLookupForm.get('business_name').updateValueAndValidity();

        // this.vetLookupForm.get('first_name').setValidators([Validators.required]);
        // this.vetLookupForm.get('first_name').updateValueAndValidity();
        //
        // this.vetLookupForm.get('last_name').setValidators([Validators.required]);
        // this.vetLookupForm.get('last_name').updateValueAndValidity();

        this.vetLookupForm.get('street1').setValidators([Validators.required]);
        this.vetLookupForm.get('street1').updateValueAndValidity();

        this.vetLookupForm.get('zip_code').setValidators([Validators.required]);
        this.vetLookupForm.get('zip_code').updateValueAndValidity();

        this.vetLookupForm.get('county').setValidators([Validators.required]);
        this.vetLookupForm.get('county').updateValueAndValidity();

        this.vetLookupForm.get('state').setValidators([Validators.required]);
        this.vetLookupForm.get('state').updateValueAndValidity();

        this.vetLookupForm.get('city').setValidators([Validators.required]);
        this.vetLookupForm.get('city').updateValueAndValidity();
    }

    ngOnDestroy() {
        this.subs.forEach(sub => sub.unsubscribe());
    }
}
