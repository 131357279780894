import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GridApi, GridOptions } from 'ag-grid-community';
import { Animal } from 'src/app/core/models/animal';
import { AgGridClickableCellComponent } from '../../components/ag-grid-clickable-cell/ag-grid-clickable-cell.component';

import { UtilService } from 'src/app/core/services/util.service';
import { Subscription, Observable, of, BehaviorSubject } from 'rxjs';
import { Utils } from 'src/app/core/utils/util';
import * as _moment from 'moment';
import { difference, update } from 'lodash';

const moment = _moment;

export interface DialogData {
  animal: Animal;
  selectedFood?:any[];
  animalFoodList:any[];
}

@Component({
  selector: 'app-animal-food',
  templateUrl: './animal-food.component.html',
  styleUrls: ['./animal-food.component.scss']
})

export class AnimalFoodComponent implements OnInit {
  animalFoodGridApi: GridApi;
  public gridOptions = {
    context: {
      componentParent: this,
    },
    suppressHorizontalScroll: false,
    rowStyle: { background: 'white' }
  } as GridOptions;
  defaultColDef: any;
  frameworkComponents;
  getRowHeight: any;
  headerHeight: any;
  columnDefs: any[] = [];
  foodForm: FormGroup;
  rowsCreated:boolean=false;

  /*animalFoodList:any[]=[{"value":"I/D (Urinary Health)"},
                        {"value":"C/D"},
                        {"value":"C/D Plus"},
                        {"value":"K/D"},
                        {"value":"D/D"},
                        {"value":"W/D"},
                        {"value":"Z/D"}];*/

  animalFoodUnit:any[]=["cup","drop"];
  foodRowData: BehaviorSubject<Array<any[]>>;
  subs: Array<Subscription> = [];
  medicalMasterData: any;
  rowsDeleted:boolean=false;
  deleteIds:any[]=[];

  constructor(public dialogRef: MatDialogRef<AnimalFoodComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,private utilService:UtilService) {
      this.getRowHeight = (params) => {
        return 34;
      }
      this.headerHeight = 34;
     }

  ngOnInit(): void {
    this.initFoodForm();
    this.getMasterData();
    this.setupAnimalFoodGridColumn();
  }

  initFoodForm() {
    this.foodForm = this.fb.group({
                                    food: ['',[Validators.required]],
                                    unit:['',[Validators.required]],
                                    quantity:['0.00',[Validators.required,Validators.min(0.01)]],
                                    start_date: ['',[Validators.required]],
                                    end_date: [''],
                                    instruction: ['']
                                  }, {validator: this.dateLessThan('start_date','end_date')});

    /*if(this.data.selectedFood!=undefined){
      this.foodForm.controls['animal_food'].setValue(this.data.selectedFood.animal_food);
      this.foodForm.controls['unit'].setValue(this.data.selectedFood.unit);
      this.foodForm.controls['quantity'].setValue(this.data.selectedFood.quantity);
    }*/
  }

  dateLessThan(from:string,to:string) {
    return (group: FormGroup): {[key: string]: any} => {
      let f = group.controls[from].value;
      let t = group.controls[to].value;
      if((f !== '' && t !== ''))
      {
        return (f <= t) ? null : { range: true };
      }     
    }
 }

 onCreateClicked() {
  if(this.foodForm.invalid){
    this.foodForm.markAllAsTouched();
    return;
  }
  
    let foodList: any[] = this.foodRowData.getValue();
    foodList = [...foodList, ...this.foodScheduleList()];
    this.foodRowData.next(foodList);
    this.rowsCreated=true;
  }

  onSaveClicked() {
    let FoodResult={
      "delete":this.deleteIds,
      "update":this.foodRowData.getValue()
    }
    this.dialogRef.close(FoodResult);
  }

  getMasterData() {
    if(this.data.selectedFood && this.data.selectedFood.length > 0) {
      this.foodRowData = new BehaviorSubject<any[]>([]);
      this.foodRowData.next(this.data.selectedFood);
    }
    /*let sub = this.utilService.getMasterInputs('MedicalEvent').subscribe(res => {
      this.medicalMasterData = res.input;
    })
    this.subs.push(sub);*/
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isdisableCheck(){  
    if(this.foodRowData){
     
      let allMedication: any[] = this.foodRowData.getValue();
      if(this.rowsDeleted || (allMedication.length > 0 && this.rowsCreated))
          return false;
      else
          return true;
    }else
       return true;
  }

  onRemoveSelectedMedication() {
    let selectedFoodRows: any[] = this.animalFoodGridApi.getSelectedRows();
    let allFood: any[] = this.foodRowData.getValue();
    
    let filteredMedication = allFood.filter(med => {
      this.rowsDeleted=true;
      let findIndexVal=selectedFoodRows.findIndex(ele => ele.food_id == med.food_id);
      if(findIndexVal == -1){
        return findIndexVal;
      }else if(Number(med.food_id)){
        (med.food_id) ? this.deleteIds.push(med.food_id) : '';
      }
      //return selectedMedication.findIndex(ele => ele.medical_id == med.medical_id) == -1;
    });
    this.foodRowData.next(filteredMedication);
  }

  foodScheduleList(): Array<any> {
  //  console.log(this.foodRowData.getValue());
    let foodList: any[] = [];
    let startDate: _moment.Moment = moment(this.foodForm.get('start_date').value, 'MM/DD/YYYY');
    let endDate: _moment.Moment = moment(this.foodForm.get('end_date').value, 'MM/DD/YYYY');
    for (let index = 0; index <= endDate.diff(startDate, 'days'); index++) {
      
      let tempDate: _moment.Moment = moment(this.foodForm.get('start_date').value, 'MM/DD/YYYY');

      let tempDateAdd=tempDate.add(index, 'days').format('MM/DD/YYYY');
      let findIndexVal=this.foodRowData.getValue().findIndex(ele => ele['routine_date'] == tempDateAdd);
      
      if(findIndexVal == -1){
        let food = {
                      food_id: Utils.getUUIDv4(),
                      food: this.foodForm.get('food').value,
                      routine_date: tempDateAdd,
                      quantity: this.foodForm.get('quantity').value,
                      unit: this.foodForm.get('unit').value,
                    };
                    foodList.push(food);
      }else{
            let foodRowData=this.foodRowData.getValue();
            if(Number(foodRowData[findIndexVal]['food_id'])){
              foodRowData[findIndexVal]['id']=foodRowData[findIndexVal]['food_id'];
            }
                  
            foodRowData[findIndexVal]['food']=this.foodForm.get('food').value;
            foodRowData[findIndexVal]['quantity']=this.foodForm.get('quantity').value;
            foodRowData[findIndexVal]['unit']=this.foodForm.get('unit').value;
      }                
    }
    return foodList;
  }

  onAnimalFoodGridReady(params) {
    this.animalFoodGridApi = params.api;
    this.animalFoodGridApi.sizeColumnsToFit();
    if(this.data.selectedFood.length == 0 || !this.data.selectedFood)
      this.foodRowData = new BehaviorSubject<any[]>([]);
  }

  setupAnimalFoodGridColumn() {
    this.defaultColDef = Utils.getBaseThemeAgGridCol();
    this.columnDefs = [
      {
        headerName: '',
        field: 'check',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        suppressSizeToFit: true,
        sortable: false,
        resizable: false,
        filter: false,
        minWidth: 10,
        width: 31,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          display: 'flex',
          justifyContent: 'center',
          padding: '0 0 0 10px'
        },
      },
      {
        headerName: 'Date',
        field: 'routine_date',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filter: 'agDateColumnFilter',
        filterParams: Utils.dateFilterParams()
      },
      
      {
        headerName: 'Food',
        field: 'food',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'flex-start'
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Unit',
        field: 'unit',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Quantity',
        field: 'quantity',
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.filterParams()
      },
      
    ];
  }

  columnRender() {
    return 'cusotmClickableCell';
  }

  onFocusOutEvent(event) {
    if (!isNaN(event.target.value)) {
      this.foodForm.get('quantity').setValue(parseFloat(event.target.value).toFixed(2));
    }
  }

}
