import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {

  @Input('name') name: string;
  @Input('section') section: string;
  @Input('selectable') selectable: boolean;
  @Input('removable') removable: boolean;
  @Output('removeChip') removeChip: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  remove(e,section){
    this.removeChip.emit([e,section]);
  }
}
