import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilService } from 'src/app/core/services/util.service';
import { Subscription, Observable } from 'rxjs';
import { GridApi, GridOptions } from 'ag-grid-community';
import { Utils } from 'src/app/core/utils/util';

export interface DialogData {
  animal: any;
  animalNameHyperlinkFlag: boolean;
}

@Component({
  selector: 'app-agency-details-dialog',
  templateUrl: './agency-details-dialog.component.html',
  styleUrls: ['./agency-details-dialog.component.scss']
})
export class AgencyDetailsDialogComponent implements OnInit {
  subs: Subscription[] = [];
  primaryColor: string;
  secondaryColor: string;

  gridApi: GridApi;
  gridColumnApi: any;
  notesRowData: Observable<any>;
  notesColumnDefs: any[] = [];
  defaultColDef: any;
  public gridOptions = {
    context: {
      componentParent: this,
    },
    suppressHorizontalScroll: false,
    rowStyle: { background: 'white' }
  } as GridOptions;
  getRowHeight;
  headerHeight;
  overlayNoRowsTemplate: string;

  constructor(
    public dialogRef: MatDialogRef<AgencyDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private utilService: UtilService
  ) {
    this.getRowHeight = (params) => {
      return 34;
    }
    this.headerHeight = 34;
  }

  ngOnInit(): void {
    this.setColumns();
  }
  setColumns() {
    this.defaultColDef = Utils.getBaseThemeAgGridCol();
    this.overlayNoRowsTemplate = '<span style="padding: 10px; color: #929292">No notes to display</span>';

    this.notesColumnDefs = [
      {
        headerName: 'Date',
        field: 'modified_time',
        suppressSizeToFit: true,
        width: 138,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.dateFilterParams()
      },
      {
        headerName: 'Topic',
        field: 'note_name',
        suppressSizeToFit: true,
        width: 177,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'flex-start'
        },
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'Notes',
        field: 'notes',
        filterParams: Utils.filterParams()
      },
      {
        headerName: 'User',
        field: 'user',
        suppressSizeToFit: true,
        width: 144,
        cellStyle: {
          ...this.defaultColDef.cellStyle,
          justifyContent: 'center'
        },
        filterParams: Utils.filterParams()
      }
    ];
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();

    let payload = {
      "entity": 'Animal',
      "entity_id": this.data.animal.animal_id,
      "noteId": "",
      "noteName": "",
      "notes": "",
      "created_by": "",
      "modified_by": ""
    }
    this.notesRowData = this.utilService.getAllNotes(payload);
  }

  getWeight() {
    return `${Utils.numberToString(this.data.animal.weight)} lbs ${Utils.numberToString(this.data.animal.weight_ounces)} oz`;
  }
  onAanimalHeaderClicked() { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subs.forEach(ele => ele.unsubscribe());
  }
}
